<template>
	<div class="mb-3" :class="{ 'form-control-auto form-control': showBorder }">
		<b-form-group class="m-0">
			<label v-if="title" class="form-control-label">
				{{ title }}
			</label>

			<div v-for="(option, index) in options" :key="index">
				<div class="form-check my-3">
					<b-form-radio
						class="px-0 m-0"
						v-model="selected"
						name="some-radios"
						:state="state"
						:value="option.value"
					>
						<div v-if="option.name">
							<label class="form-control-label mb-0" :for="option.name">{{
								option.name
							}}</label>
						</div>
						<div v-if="option.description">
							<h6 style="color: gray">{{ option.description }}</h6>
						</div>
					</b-form-radio>
				</div>
				<slot :name="option.value"></slot>
				<hr v-if="index !== options.length - 1" class="" />
			</div>
		</b-form-group>
	</div>

	<!--

	
	<div :class="{ 'form-control-auto form-control': showBorder }">
		<div v-for="(option, index) in options" :key="index">
			<div class="form-check mb-3">

				<input
					class="form-check-input"
					type="radio"
					name="formRadioGroup"
					v-model="model"
					:disabled="disabled"
					:value="option.value"
					:id="option.name"
				/>
				<div v-if="option.name">
					<label class="form-control-label mb-0" :for="option.name">{{
						option.name
					}}</label>
				</div>
				<div v-if="option.description">
					<h6 style="color: gray">{{ option.description }}</h6>
				</div>
			</div>
			<slot :name="option.value"></slot>
		</div>
	</div>
--></template>
<script>
export default {
	name: 'button-radio-group',
	props: {
		title: {
			type: String,
			default: null,
		},
		options: {
			type: Array,
			description:
				'Radio options. Should be an array of objects {value: "", name: ""}',
			default: () => [],
		},
		disabled: {
			type: Boolean,
		},
		value: {
			type: String,
			description: 'Radio value',
		},
		buttonClasses: {
			type: [String, Object],
			description: 'Inner button css classes',
		},
		showBorder: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			selected: null,
		};
	},
	computed: {
		state() {
			this.$emit('change', this.selected);
		},
	},
	mounted() {
		this.options.forEach((option, index) => {
			if (option.selected) {
				this.selected = option.value;
			}
		});
	},
};
</script>
<style></style>
