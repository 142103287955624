import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { AccountInformation } from '@/models/AccountInformation/AccountInformation';

Vue.use(Vuex);

const initialState = () => {
	return {
		user: null,
		organization: null,
		notification: null,
		accountInformation: AccountInformation.accountInformation,
		appInformation: null,
		websiteInformation: null,
		paymentInformation: null,
		publicInformation: null,
		socialInformation: null,
		bookingInformation: null,
		stripeTerminal: null,
		stripeTerminalReaders: null,
	};
};

export default new Vuex.Store({
	state: initialState(),
	mutations: mutations,
	getters: getters,
	actions: actions,
});
