<template>
	<Modal
		:show="showModal"
		@close="closeModal"
		body-classes="m-0 pt-0 pb-0"
		:hideFooter="true"
		footer-classes="pt-0"
		header-classes="pb-1"
		size="lg"
	>
		<template v-slot:header>
			<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
		</template>

		<b-overlay :show="isLoading" rounded="sm">
			<div class="pt-2">
				<h5 style="flex: auto; text-align: center">
					Please select an organization.
				</h5>
				<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
					{{ errorMessage }}
				</h6>
			</div>

			<form class="pt-1 pb-1">
				<BaseInput label="Organization" type="select" placeholderOption="Select Organization" v-model="organization" :options="options" required />
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton
						type="primary"
						:loading="isLoading"
						:shake="shake"
						@click="submit"
						>Select Organization</BaseButton
					>
				</b-row>
			</form>
		</b-overlay>
	</Modal>
</template>
<script>
import { mapActions } from 'vuex';
import BaseButton from '../../components/BaseButton.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'Select Organization',
		},
        options: {
            type: Array,
            default: []
        },
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: { Modal, BaseInput, BaseButton },
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			organization: null,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.$emit('submit', this.organization);
            this.$emit('close');
		},
	},
};
</script>
