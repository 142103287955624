<template>
	<SlideYUpTransition :duration="animationDuration">
		<b-modal
			class="modal fade"
			ref="app-modal"
			:size="size"
			:hide-header="hideHeader"
			:modal-class="[{ 'modal-mini': type === 'mini' }, ...modalClasses]"
			@mousedown.self="closeModal"
			tabindex="-1"
			role="dialog"
			centered
			@close="closeModal"
			@hide="closeModal"
			:hide-footer="hideFooter"
			:header-class="headerClasses"
			:footer-class="footerClasses"
			:content-class="[
				gradient ? `bg-gradient-${gradient}` : '',
				...modalContentClasses,
			]"
			:body-class="bodyClasses"
			:aria-hidden="!show"
		>
			<template v-slot:modal-header>
				<slot name="header"></slot>
				<slot name="close-button">
					<button
						type="button"
						class="close"
						v-if="showClose"
						@click="closeModal"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span :aria-hidden="!show">×</span>
					</button>
				</slot>
			</template>
			<slot>
				<h4 :class="buttonData.length > 2 ? 'text-center' : ''">
					{{ question }}
				</h4>
				<div
					class="my-3"
					:class="
						buttonData.length > 2
							? 'row p-1'
							: 'd-flex justify-content-end align-items-center'
					"
					style="flex-grow: 1; gap: 5px"
				>
					<div
						:class="buttonData.length > 2 ? 'btn-block' : ''"
						v-for="buttonItem in buttonData"
						:key="buttonItem.id"
					>
						<BaseButton
							:class="buttonData.length > 2 ? 'btn-block' : ''"
							:type="buttonItem.type != null ? buttonItem.type : 'default'"
							:outline="buttonItem.outline"
							@click="buttonCLicked(buttonItem)"
							>{{ buttonItem.title }}</BaseButton
						>
					</div>
				</div>
			</slot>
			<template v-slot:modal-footer>
				<slot v-if="buttonData" name="footer" class="m-0 b-0" style="display: flex">
				</slot>
			</template>
		</b-modal>
	</SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import BaseButton from '../BaseButton.vue';

export default {
	name: 'modal',
	components: {
		SlideYUpTransition,
		BaseButton,
	},
	props: {
		show: Boolean,
		showClose: {
			type: Boolean,
			default: true,
		},
		question: {
			type: String,
			description: 'Question to show in modal',
		},
		type: {
			type: String,
			default: '',
			validator(value) {
				let acceptedValues = ['', 'notice', 'mini'];
				return acceptedValues.indexOf(value) !== -1;
			},
			description: 'Modal type (notice|mini|"") ',
		},
		modalClasses: {
			type: [Object, String],
			description: 'Modal dialog css classes',
			default: '',
		},
		size: {
			type: String,
			description: 'Modal size',
			validator(value) {
				let acceptedValues = ['', 'sm', 'lg'];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		modalContentClasses: {
			type: [Object, String],
			description: 'Modal dialog content css classes',
			default: '',
		},
		gradient: {
			type: String,
			description: 'Modal gradient type (danger, primary etc)',
		},
		headerClasses: {
			type: [Object, String],
			description: 'Modal Header css classes',
		},
		bodyClasses: {
			type: [Object, String],
			description: 'Modal Body css classes',
			default: 'mb-0 pb-0',
		},
		footerClasses: {
			type: [Object, String],
			description: 'Modal Footer css classes',
		},
		hideHeader: {
			type: Boolean,
			default: true,
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
		buttonData: {
			type: [Object, Array],
			default() {
				return [
					{
						id: 0,
						title: 'No',
						type: 'danger',
					},
					{
						id: 1,
						title: 'Yes',
						type: 'success',
					},
				];
			},
		},
		animationDuration: {
			type: Number,
			default: 500,
			description: 'Modal transition duration',
		},
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		buttonCLicked(buttonItem) {
			if (buttonItem.title == 'No') {
				this.$emit('noClicked');
			} else if (buttonItem.title == 'Yes') {
				this.$emit('yesClicked');
			} else {
				this.$emit('buttonClicked', buttonItem);
			}
			this.closeModal();
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.$refs['app-modal'].show();
			} else {
				this.$refs['app-modal'].hide();
			}
		},
	},
};
</script>
<style>
.modal-backdrop {
	background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
