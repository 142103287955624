<template>
	<div class="d-flex justify-content-between align-items-center">
		<div>
			<h5
				class="mb-0 mr-2"
				style="
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				"
			>
				{{ chargeItem.description }}
			</h5>
			<h6
				class="text-muted m-0"
				style="
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				"
			>
				Purchased
				{{ $helpers.formatDate(chargeItem.created * 1000) }}
			</h6>
			<h6
				class="text-muted m-0"
				style="
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				"
			>
				{{ getChargeDetails().bottomText }}
			</h6>
		</div>
		<div class="d-flex justify-content-end align-items-center m-0 p-0">
			<h5 class="m-0" :class="`${getChargeDetails().color}`">
				{{ getChargeDetails().text }}
			</h5>
			<i
				class="ml-2"
				:class="`${getChargeDetails().icon} ${getChargeDetails().color}`"
			></i>
			<i class="fas fa-chevron-right ml-2 text-muted"></i>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		chargeItem: null,
	},
	methods: {
		getChargeDetails() {
			if (this.chargeItem.subscription_usage) {
				if (this.chargeItem.subscription_usage.subscriptionTitle) {
					return {
						color: 'text-primary',
						text: `Qty: ${this.chargeItem.quantity}`,
						bottomText: `Subscription: ${this.chargeItem.subscription_usage.subscriptionTitle}`,
						icon: 'fas fa-check-circle',
					};
				} else {
					return {
						color: 'text-primary',
						text: `Qty: ${this.chargeItem.quantity}`,
						bottomText: `Subscription`,
						icon: 'fas fa-check-circle',
					};
				}
			}
			return this.returnChargeInfo();
		},
		returnChargeInfo() {
			switch (this.chargeItem.status) {
				case 'succeeded':
					return {
						color: 'text-success',
						text: `${this.$helpers.amountToString(this.chargeItem.amount)}`,
						bottomText: `Receipt sent to: ${this.chargeItem.receipt_email}`,
						icon: 'fas fa-check-circle',
					};
					break;
				case 'processing':
					return {
						color: 'text-yellow',
						text: `${this.$helpers.amountToString(this.chargeItem.amount)}`,
						bottomText: `Receipt sent to: ${this.chargeItem.receipt_email}`,
						icon: 'fas fa-exclamation-circle',
					};
					break;
				default:
					if (this.chargeItem.refunded || this.chargeItem.disputed) {
						return {
							color: 'text-orange',
							text: `${this.$helpers.amountToString(this.chargeItem.amount)}`,
							bottomText: `Receipt sent to: ${this.chargeItem.receipt_email}`,
							icon: 'fas fa-undo',
						};
					} else {
						return {
							color: 'text-danger',
							text: `${this.$helpers.amountToString(this.chargeItem.amount)}`,
							bottomText: `Receipt sent to: ${this.chargeItem.receipt_email}`,
							icon: 'fas fa-times-circle',
						};
					}
					break;
			}
		},
	},
};
</script>
