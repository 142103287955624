<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">Upload a new featured item.</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						class="p-0"
						id="logo"
						style="border: 0"
						v-on:vdropzone-files-added="addedItem"
						:options="dropzoneOptions"
					>
					</VueDropZone>

					<BaseInput
						label="Featured Web Link"
						type="url"
						v-model="featuredLink"
					></BaseInput>
					<BaseInput
						label="Featured Placement"
						type="number"
						min="1"
						max="10"
						required
						v-model="featuredIndex"
					></BaseInput>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Featured</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import { mapGetters } from 'vuex';
import BaseButton from '../../components/BaseButton.vue';
import FeaturedItemManager from '../../models/FeaturedItem/FeaturedItemManager';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Featured',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueAspectRatio,
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
		BaseButton,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			imageItem: null,
			featuredLink: null,
			featuredIndex: 1,
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.imageItem = null;
			this.featuredLink = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedItem(file) {
			this.imageItem = file;
		},
		submit() {
			this.shake = false;

			if (this.imageItem == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addFeaturedItem();
			}
		},
		addFeaturedItem() {
			const featuredFile = this.imageItem[0];
			const index = this.featuredIndex;
			const featuredLink = this.featuredLink;

			this.isLoading = true;

			FeaturedItemManager.addFeaturedItem(featuredFile, index, featuredLink)
				.then((featuredData) => {
					this.isLoading = false;
					this.$emit('newFeatured', featuredData);
					this.resetView();
					this.closeModal();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.errorMessage = errorMessage;
					this.shake = true;
				});
		},
	},
	mounted() {},
};
</script>
