<template>
	<div>
		<Card no-body>
			<CardHeader :title="headerTitle" />
			<div class="table-responsive">
				<b-table
					:fields="tableHeaders"
					:items="tableData"
					:busy="isLoading"
					:filter="filter"
					small
					hover
					responsive="sm"
					@row-clicked="rowClicked"
					class="px-1"
				>
					<template #table-busy>
						<b-overlay :show="isLoading" rounded="sm" no-wrap />
					</template>
					<template #cell(videoTitle)="data">
						<div class="d-flex align-items-center" style="gap: 5px">
							<VueAspectRatio ar="16:9" style="width: 70px">
								<Card no-body class="h-100">
									<b-img
										fluid-grow
										rounded
										:src="data.item.imageItem.imageUrl"
										style="height: 100%; object-fit: cover"
									/>
								</Card>
							</VueAspectRatio>
							<div
								style="
									max-width: 200px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								"
							>
								{{ data.item.videoTitle }}
							</div>
						</div>
					</template>
					<template #cell(actions)="row">
						<b-dropdown id="actions" variant="null" dropleft no-caret>
							<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
							<b-dropdown-item v-if="false" @click="viewClicked(row.item)"
								>View</b-dropdown-item
							>
							<b-dropdown-item @click="viewClicked(row.item)">View</b-dropdown-item>
							<b-dropdown-divider></b-dropdown-divider>
							<b-dropdown-item variant="danger" @click="deleteClicked(row.item)"
								>Delete</b-dropdown-item
							>
						</b-dropdown>
					</template>
				</b-table>
			</div>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import { Card } from '@/components';
import CardHeader from '@/components/Cards/CardHeader.vue';

export default {
	props: {
		isLoading: false,
		filter: null,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
			default() {
				return [
					{
						key: 'videoTitle',
						label: 'Title',
						sortable: true,
						tdClass: 'align-middle',
						thStyle: { width: '50%' },
						sortByFormatted: (value, key, item) => {
							return item.videoTitle;
						},
					},
					{
						key: 'videoDescription',
						label: 'Description',
						tdClass: 'align-middle',
						thStyle: { width: '20%' },
					},
					{
						key: 'uploadTimestamp',
						label: 'Upload Date',
						sortable: true,
						tdClass: 'align-middle',
						thStyle: { width: '20%' },
						formatter: (value, key, item) => {
							return `${this.$helpers.formatDate(value.toDate())}`;
						},
					},
					{
						key: 'actions',
						label: '',
						thStyle: { width: '10%' },
					},
				];
			},
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('viewClicked', item);
		},
	},
	components: { Card, CardHeader, VueAspectRatio },
};
</script>
