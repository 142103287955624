import store from '@/store/store';
import helpers from '../../helpers/helpers';

export default class CalendarObject {
	static allScheduleItems = [];

	static getCalendarItem(eventData, classData, calendarData) {
		const accountInfo = store.state.accountInformation;
		const yearFromDate = helpers.addYear(new Date(), 1);
		if (eventData) {
			if (!eventData.eventId){return null}
			const id = `${eventData.eventId}${eventData.eventStart.toDate()}`;
			const title = eventData.eventTitle;
			const start = eventData.eventStart.toDate();
			const end = helpers.addSeconds(start, eventData.eventDuration);
			const calendarColor = eventData.calendarHex
				? eventData.calendarHex
				: accountInfo.appInformation.calendarColors.eventColor;
			const calendarObject = {
				id: id,
				title: title,
				start: start,
				end: end,
				extendedProps: {
					eventData: eventData,
				},
				backgroundColor: calendarColor,
			};
			if (calendarObject.start > yearFromDate) {
				return null;
			}
			return calendarObject;
		} else if (classData) {
			if (!classData.classId){return null}
			const id = `${classData.classId}${classData.classStart.toDate()}`;
			const title = classData.classTitle;
			const start = classData.classStart.toDate();
			const end = helpers.addSeconds(start, classData.classDuration);
			const calendarColor = classData.calendarHex
				? classData.calendarHex
				: accountInfo.appInformation.calendarColors.classColor;
			const calendarObject = {
				id: id,
				title: title,
				start: start,
				end: end,
				extendedProps: {
					classData: classData,
				},
				backgroundColor: calendarColor,
			};
			if (calendarObject.start > yearFromDate) {
				return null;
			}
			return calendarObject;
		} else if (calendarData) {
			if (!calendarData.calendarId){return null}
			const id = `${
				calendarData.calendarId
			}${calendarData.calendarStart.toDate()}`;
			const title = calendarData.calendarTitle;
			const start = calendarData.calendarStart.toDate();
			const end = helpers.addSeconds(start, calendarData.calendarDuration);
			const calendarColor = calendarData.calendarHex
				? calendarData.calendarHex
				: accountInfo.appInformation.calendarColors.defaultColor;
			const calendarObject = {
				id: id,
				title: title,
				start: start,
				end: end,
				extendedProps: {
					calendarData: calendarData,
				},
				backgroundColor: calendarColor,
			};
			if (calendarObject.start > yearFromDate) {
				return null;
			}
			return calendarObject;
		} else {
			return null;
		}
	}

	static addObjectForSchedule(eventData, classData, calendarData) {
		const calendarObject = this.getCalendarItem(
			eventData,
			classData,
			calendarData
		);
		if (calendarObject) {
			if (!this.allScheduleItems.find((e) => e.id == calendarObject.id)) {
				this.allScheduleItems.push(calendarObject);
			}
		}
	}
}
