<template>
	<Modal
		:show="showModal"
		@close="closeModal"
		body-classes="m-0 pt-0 pb-0"
		:hideFooter="true"
		footer-classes="pt-0"
		header-classes="pb-1"
		size="lg"
	>
		<template v-slot:header>
			<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
		</template>

		<b-overlay :show="isLoading" rounded="sm">
			<div class="pt-2">
				<h5 style="flex: auto; text-align: center"></h5>
				<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
					{{ errorMessage }}
				</h6>
			</div>

			<form class="pt-1 pb-1" v-if="bookingData">
				<b-row>
					<b-col>
						<BaseInput
							label="First Name"
							v-model="bookingData.bookFirstName"
							disabled
						/>
					</b-col>
					<b-col>
						<BaseInput
							label="Last Name"
							v-model="bookingData.bookLastName"
							disabled
						/>
					</b-col>
				</b-row>
				<BaseInput label="Email" v-model="bookingData.bookingEmail" disabled />
				<BaseInput
					label="Phone Number"
					v-model="bookingData.bookingPhone"
					disabled
				/>
				<BaseInput label="Booking Date" :value="returnDate()" disabled />
				<BaseInput
					label="Booking Location"
					:value="returnBookingLocation()"
					disabled
				/>
				<base-input class="mb-0" label="Message">
					<textarea
						placeholder="Enter your message..."
						name="message"
						v-model="bookingData.text"
						class="form-control mb-0"
						id="notification"
						rows="9"
						disabled
					></textarea>
				</base-input>
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton
						type="primary"
						:loading="isLoading"
						:shake="shake"
						@click="closeModal()"
						>Close</BaseButton
					>
				</b-row>
			</form>
		</b-overlay>
	</Modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '../../components/BaseButton.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'Booking Information',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		bookingData: {
			type: Object,
			default: null,
		},
	},
	computed: {
		...mapGetters({
			bookingInformation: 'getBookingInformation',
		}),
	},
	components: { Modal, BaseInput, BaseButton },
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		returnDate() {
			return this.$helpers.formatDate(
				this.bookingData.bookingDate.toDate(),
				`MMMM dd, yyyy 'at' HH:mm a`
			);
		},
		returnBookingLocation() {
			if (this.bookingData.bookingLocation) {
				const bookingLocationId = this.bookingData.bookingLocation;
				const bookingLocations = this.bookingInformation.bookingLocations;
				const locationName =
					bookingLocationId != null
						? bookingLocations.find((e) => e.locationId == bookingLocationId).title
						: 'No Location Selected';
				return `${locationName}`;
			} else {
				return `No Location Selected`;
			}
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
	},
};
</script>
