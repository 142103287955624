<template>
    <div>
        <b-row v-if="returnHeaderBanner() != null" class="container-fluid">
			<b-col class="mt-2">
				<b-alert
					class="m-0 p-2 text-center"
					:variant="returnHeaderBanner().variant"
					show
					:dismissible="false"
					fade
				>
					<h5 class="mb-0 text-white">
						<i
							v-if="returnHeaderBanner().icon"
							:class="returnHeaderBanner().icon"
						></i>
						{{ returnHeaderBanner().message }}
					</h5>
				</b-alert>
			</b-col>
		</b-row>
        <base-nav
			container-classes="container-fluid my-2"
			class="navbar-top border-bottom navbar-expand p-0"
		>
			<b-navbar-nav class="align-items-start">
				<li class="nav-item d-xl-none">
					<!-- Sidenav toggler -->
					<div
						class="pr-3 sidenav-toggler"
						:class="{
							active: $sidebar.showSidebar,
							'sidenav-toggler-light': 'light',
						}"
						@click="toggleSidebar"
					>
						<div class="sidenav-toggler-inner">
							<i class="sidenav-toggler-line"></i>
							<i class="sidenav-toggler-line"></i>
							<i class="sidenav-toggler-line"></i>
						</div>
					</div>
				</li>
			</b-navbar-nav>
			<!-- Search form 	 -->
			
			<b-form
				class="navbar-search"
				style="width: 100%"
				:class="{
					'navbar-search-light': 'default',
				}"
				id="navbar-search-main"
			>
				<b-form-group class="mb-0">
					<b-input-group class="input-group-alternative input-group-merge">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fas fa-search"></i></span>
						</div>
						<b-form-input placeholder="Search" type="text"> </b-form-input>
					</b-input-group>
				</b-form-group>

				<button
					type="button"
					class="close"
					data-action="search-close"
					data-target="#navbar-search-main"
					aria-label="Close"
				>
					<span aria-hidden="true">×</span>
				</button>
			</b-form>
			<!-- Navbar links -->
			<b-navbar-nav class="align-items-center ml-md-auto">
				<!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
				<li class="nav-item d-sm-none" v-if="false">
					<a
						class="nav-link"
						href="#"
						data-action="search-show"
						data-target="#navbar-search-main"
					>
						<i class="ni ni-zoom-split-in"></i>
					</a>
				</li>
				<b-nav-item @click.prevent="openSettings"
					><i class="fa fa-cog"></i
				></b-nav-item>
				<b-nav-item
					:href="DominionInformation.dominionSupportTicket"
					target="_blank"
					><i class="fa fa-question-circle"></i
				></b-nav-item>
				<b-nav-item @click.prevent="openNotifications" target="_blank"
					><i class="fa fa-bell"></i
				></b-nav-item>
				<base-dropdown
					menu-on-right
					class="nav-item ml-3"
					tag="li"
					title-tag="a"
					title-classes="nav-link"
				>
					<a v-if="userData" href="#" class="nav-link pl-0" @click.prevent="" slot="title-container">
						<b-avatar 
							:src="userData.profilePhotoURL"
							variant="default"
							:text="userData.firstName"
						/>
					</a>

					<template>
						<b-dropdown-header class="noti-title">
							<h6 v-if="userData" class="text-overflow m-0">
								Welcome, {{ userData.firstName }}!
							</h6>
						</b-dropdown-header>
						<b-dropdown-item href="#!" @click.prevent="openProfile">
							<i class="fas fa-user"></i>
							<span>My Profile</span>
						</b-dropdown-item>
						<b-dropdown-item href="#!" @click.prevent="openSettings">
							<i class="fas fa-cog"></i>
							<span>Settings</span>
						</b-dropdown-item>
						<div class="dropdown-divider"></div>
						<b-dropdown-item v-on:click.prevent="logOutOfApp">
							<i class="fas fa-power-off"></i>
							<span>Logout</span>
						</b-dropdown-item>
					</template>
				</base-dropdown>
			</b-navbar-nav>
		</base-nav>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DominionInformation } from '../../resources/DominionInformation';
import { DashboardChildren, LoginPaths } from '../../routes/routesNames';
import AppUserManager from '../../models/AppUser/AppUserManager';

export default {
	components: {  },
    computed: {
		...mapGetters({
            appInformation: 'getAppInformation',
			user: 'getUser',
		}),
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
    data(){
        return {
            DominionInformation,
            userData: null
        }
    },
	methods: {
        ...mapActions(['logOut']),
        returnHeaderBanner(){
            if (this.appInformation.accountBanner) {
				return this.appInformation.accountBanner;
			}
            return null
            /*
			return {
				icon: 'fa fa-info-circle',
				variant: 'warning',
				message:
					"You're account is pending verification with the Dominion Team. Please allow 24-48 hours for our team to respond.",
			};
            */
        },
        capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		toggleSidebar() {
			this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
		},
		hideSidebar() {
			this.$sidebar.displaySidebar(false);
		},
        openProfile() {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
                params: {
                    userData: this.userData
                },
				query: {
					uid: this.user.appUid,
				},
			});
		},
		openWebsite(url) {
			this.$helpers.openUrl(url);
		},
		openNotifications() {
			this.$router.push({
				name: DashboardChildren.adminNotifications.name,
			});
		},
		openSettings() {
			this.$router.push({
				name: DashboardChildren.settings.name,
			});
		},
        getUserData(){
            AppUserManager.fetchUserByUid(this.user.appUid).then((userData) => {
			if (userData) {
				this.userData = userData;
			}
		});
        },
		logOutOfApp() {
			this.logOut()
				.then(() => {
					this.$router.push({
						name: LoginPaths.login.name,
					});
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
	},
    mounted(){
        this.getUserData()
    }
};
</script>
