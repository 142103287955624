<template>
	<div>
		<DashboardHeaderVue :title="title" />
		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm" no-wrap />
			<Card no-body>
				<b-tabs
					active-nav-item-class="bg-white font-weight-bold description"
					nav-class="bg-white description"
					card
					no-body
				>
					<b-tab title="All Notifications" active>
						<NotificationCard :all-notifications="allNotifications" />
					</b-tab>
					<b-tab title="Billing Notifications">
						<NotificationCard :all-notifications="billingNotifications" />
					</b-tab>
					<b-tab title="Event Notifications">
						<NotificationCard :all-notifications="eventNotifications" />
					</b-tab>
					<b-tab title="Class Notifications">
						<NotificationCard :all-notifications="classNotifications" />
					</b-tab>
				</b-tabs>
			</Card>
		</div>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../components/Cards/Card.vue';
import NotificationCard from '../../Components/Notifications/NotificationCard.vue';
import NotificationsManager from '../../../models/NotificationItem/NotificationsManager';

export default {
	name: 'notifications',
	props: {
		title: {
			type: String,
			default: 'Admin Notifications',
		},
	},
	components: {
		DashboardHeaderVue,
		Card,
		NotificationCard,
	},
	data() {
		return {
			isLoading: false,
			buttonItems: [],
			allNotifications: [],
			billingNotifications: [],
			eventNotifications: [],
			classNotifications: [],
		};
	},
	methods: {
		sortNotifications() {
			this.isLoading = true;
			this.allNotifications = NotificationsManager.allAdminNotifications;
			this.billingNotifications = this.allNotifications.filter(
				(e) => e.type == 'subscriptionItem' || e.type == 'paymentItem'
			);
			this.eventNotifications = this.allNotifications.filter(
				(e) => e.type == 'eventItem'
			);
			this.classNotifications = this.allNotifications.filter(
				(e) => e.type == 'classItem'
			);
			this.isLoading = false;
		},
		editNotification(notification) {
			console.log(`Moses edit on ${notification.messageId}`);
		},
		deletetNotification(notification) {
			console.log(`Moses delete on ${notification.messageId}`);
		},
	},
	mounted() {
		if (NotificationsManager.didCheckAdminNotifications) {
			this.sortNotifications();
		} else {
			this.isLoading = true;
			NotificationsManager.fetchAdminNotifications().then(() => {
				this.sortNotifications();
			});
		}
	},
};
</script>
