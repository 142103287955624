<template>
	<div v-if="classData">
		<DashboardHeaderVue
			:title="classData.classTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<b-row v-if="allPendingUsers">
				<b-col>
					<MembersTable
						:isLoading="isLoading"
						headerTitle="Pending Members"
						:tableData="allPendingUsers"
						:tableHeaders="userPendingTitles"
						@onChanged="onSwitchChange"
						@pendingClicked="pendingClicked"
						@acceptClicked="acceptClicked"
						@declineClicked="declineClicked"
						@headerButtonClicked="headerButtonClicked"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<MembersTable
						:isLoading="isLoading"
						headerTitle="Class Members"
						footerTitle="TIP: By removing users, this will cancel their class billing subscription."
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@onChanged="onSwitchChange"
						@viewClicked="viewClicked"
						@declineClicked="removeClicked"
						@headerButtonClicked="headerButtonClicked"
					/>
				</b-col>
			</b-row>
		</div>
		<UsersModal
			headerTitle="All Users"
			:show="showUsersModal"
			:exempted-users="rawUsers"
			@selectedUsers="selectedUsers"
			@close="showUsersModal = false"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="addUsers"
			@noClicked="selectedUsers == null"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import MembersTable from '../../../Components/MembersTable.vue';
import ReportsManager from '../../../../models/ReportsManager/ReportsManager'
import ClassItemManager from '../../../../models/ClassItem/ClassItemManager';
import UsersModal from '../../../../components/Modals/UsersModal.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		classId: {
			type: String,
			default: null,
		},
	},
	components: {
		DashboardHeaderVue,
		MembersTable,
		UsersModal,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			showUsersModal: false,
			showQuestionModal: false,
			modalQuestion: null,
			classData: null,
			allUsers: [],
			rawUsers: [],
			allPendingUsers: [],
			selectedAllUsers: [],
			isUpdating: false,
			selectedId: null,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
			userPendingTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					tdClass: 'align-middle',
					sortable: true,
					thStyle: { width: '100%' },
					sortByFormatted: (value, key, item) => {
						return `${item.userData.firstName} ${item.userData.lastName}`;
					},
					formatter: (value, key, item) => {
						return item.userData;
					},
				},
				{
					key: 'pendingActions',
					label: '',
					tdClass: 'align-middle',
				},
			],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					tdClass: 'align-middle',
					sortable: true,
					thStyle: { width: '100%' },
					sortByFormatted: (value, key, item) => {
						return `${item.userData.firstName} ${item.userData.lastName}`;
					},
					formatter: (value, key, item) => {
						return item.userData;
					},
				},
				{
					key: 'waiverStatus',
					label: 'Documents',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item.userData);
					},
					formatter: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item.userData);
					},
				},
				{
					key: 'classActions',
					label: '',
					tdClass: 'align-middle',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: 0,
					title: 'Add Members',
				},
				/*
				{
					index: 1,
					title: 'Remove Members',
				},
				*/
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: 2,
					title: 'Export Users',
				},
				/*
				{
					index: 3,
					title: 'Send Payment Link',
				},
				*/
			];
		},
		getClassData() {
			this.isLoading = true;
			ClassItemManager.getClassById(this.classId).then((result) => {
				if (result) {
					this.classData = result;
					this.getClassUsers();
				} else {
					this.navigateToClasses();
				}
			});
		},
		getClassUsers() {
			this.isLoading = true;
			ClassItemManager.getClassUsers(this.classData).then((userItems) => {
				this.allUsers = userItems;
				if (this.allUsers) {
					this.rawUsers = this.allUsers.map((e) => e.userData);
				}
				ClassItemManager.getClassPendingUsers(this.classData).then(
					(pendingUserItems) => {
						this.allPendingUsers = pendingUserItems;
						this.setButtons();
						this.isLoading = false;
					}
				);
			});
		},
		headerButtonClicked() {
			this.updateAttendance();
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			if (this.selectedId == 0) {
				this.showUsersModal = true;
			} else if (this.selectedId == 2){
				this.exportUsers()
			}
		},
		onSwitchChange(item) {},
		pendingClicked(item) {},
		selectedUsers(item) {
			this.selectedAllUsers = item;
			this.modalQuestion =
				'Are you sure you want to add these users to this class? They will bypass any registration or billing.';
			this.showQuestionModal = true;
		},
		addUsers() {
			this.showQuestionModal = false;
			this.showUsersModal = false;
			this.isLoading = true;
			ClassItemManager.manuallyAddUsersToClass(
				this.selectedAllUsers,
				this.classData
			)
				.then(() => {
					this.getClassUsers();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		acceptClicked(item) {
			ClassItemManager.adjustUserAttendance(item.userData, this.classData, true)
				.then(() => {
					this.getClassUsers();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		declineClicked(item) {
			this.isLoading = true;
			ClassItemManager.adjustUserAttendance(
				item.userData,
				this.classData,
				false,
				true
			)
				.then(() => {
					this.getClassUsers();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		removeClicked(item) {
			this.isLoading = true;
			ClassItemManager.adjustUserAttendance(
				item.userData,
				this.classData,
				false,
				false
			)
				.then(() => {
					this.getClassUsers();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		viewClicked(item) {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
				query: {
					uid: item.userData.uid,
				},
			});
		},
		exportUsers() {
			this.isLoading = true;
			ReportsManager.exportClassUsers(this.classData.classTitle, this.allUsers)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					if (reportData.reportDownloadLink) {
						var open = window.open(reportData.reportDownloadLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		navigateToClasses() {
			this.$router.replace({
				name: DashboardChildren.classes.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		if (!this.classId) {
			this.navigateToClasses();
		} else if (this.$route.params.classData) {
			this.classData = this.$route.params.classData;
			this.getClassUsers();
		} else {
			this.getClassData();
		}
	},
};
</script>
