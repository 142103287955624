<template>
	<div>
		<DashboardHeader :title="this.$route.name" :buttonItems="buttonItems" @click="handleHeaderClick" />

		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<b-row>
					<b-col md="7" lg="7">
						<Card>
							<CardHeader slot="header" title="Invoice Information" />
							<Card>
								<CardHeader slot="header" title="Customer"
									:endButton="invoiceUserData == null ? null : { title: 'Change Customer' }"
									@click="changeCustomer" />
								<b-row>
									<b-col>
										<BaseInput v-if="!invoiceUserData" type="select" placeholderOption="Select Customer"
											v-model="selectedUser" :options="inputUsers" required></BaseInput>
										<b-row v-else class="align-items-center">
											<b-col>

												<h4 class="mb-0">{{ invoiceUserData.firstName }} {{ invoiceUserData.lastName
												}}</h4>
												<h5 class="mb-0 text-muted">{{ invoiceUserData.email }}</h5>
											</b-col>
											<b-col cols="auto" class="text-left" v-if="false">
												<BaseButton size="lg" type="null" @click="changeCustomer"><i
														class="fas fa-ellipsis-h"></i>
												</BaseButton>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</Card>
							<Card>
								<CardHeader slot="header" title="Invoice Items" :endButton="{ title: 'New Item' }"
									@click="newInvoiceItem" />
								<CardFooter slot="footer"
									:title="`Please note: the final amount will include a transaction fee: ${getTransactionFee(invoiceData.price.amount)[1]}`" />

								<b-table-simple v-if="invoiceData.invoiceItems.length > 0" outlined striped caption-top
									responsive="sm">
									<caption class="p-0">
										<h5 style="color: gray;">INVOICE DETAILS</h5>
									</caption>
									<b-tr>
										<b-th>
											<h5 class="m-0 caption">Description</h5>
										</b-th>
										<b-th style="text-align: right;">
											<h5 class="m-0 caption">Quantity</h5>
										</b-th>
										<b-th style="text-align: right;">
											<h5 class="m-0 caption">Amount</h5>
										</b-th>
										<b-th style="text-align: right;">
										</b-th>
									</b-tr>
									<b-tbody>
										<b-tr v-for="(item, index) in invoiceData.invoiceItems">
											<b-th>
												<h5 class="m-0 caption">{{ item.name }}</h5>
											</b-th>
											<b-th style="text-align: right;">
												<h5 class="m-0 caption">
													{{ item.quantity ? item.quantity : 1 }}
												</h5>
											</b-th>
											<b-th style="text-align: right;">
												<h5 class="m-0 caption">{{ toCurrencyFormat(item.amount) }}</h5>
											</b-th>
											<b-th style="text-align: right;" class="m-0 py-1 px-0"><b-dropdown id="actions"
													variant="null" dropleft no-caret>
													<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
													<b-dropdown-item @click="editInvoiceItem(item)">Edit</b-dropdown-item>
													<b-dropdown-divider></b-dropdown-divider>
													<b-dropdown-item variant="danger"
														@click="deleteInvoiceItem(item)">Delete</b-dropdown-item>
												</b-dropdown></b-th>
										</b-tr>
									</b-tbody>
								</b-table-simple>
								<div v-for="(item, index) in blankInvoiceItems">
									<div style="border: 1px solid lightgray;border-radius: 4px;"
										class="p-3 form-control-auto">
										<b-row>
											<b-col>
												<label class="form-control-label">Item Details</label>
											</b-col>
											<b-col class="text-right">
												<label class="form-control-label">{{ toCurrencyFormat(item.amount * 100)
												}}</label>
											</b-col>
										</b-row>
										<hr class="my-2" />
										<b-row>
											<b-col>
												<BaseInput label="Name" placeholder="Item Name" type="textarea" rows="1"
													v-model="item.name" />
											</b-col>
										</b-row>
										<b-row class="mb-0">
											<b-col>
												<CurrencyInput label="Amount" placeholder="Amount" v-model="item.amount" />
											</b-col>
											<b-col cols="2">
												<BaseInput label="Quantity" placeholder="1" type="number" :min="1" :max="20"
													v-model="item.quantity" />
											</b-col>
										</b-row>
										<b-row class="mr-0 mt-0 mb-3">
											<div style="flex-grow: 1"></div>
											<base-button v-if="invoiceData.invoiceItems.length > 0" size="sm" type="primary"
												@click="blankInvoiceItems = []">Cancel</base-button>
											<base-button size="sm" type="primary" @click="addInvoiceItem(item)"
												:disabled="!canAddInvoiceItem(item)">Add Item</base-button>
										</b-row>
									</div>
								</div>

							</Card>
							<Card>
								<CardHeader slot="header" title="Payment Settings" />
								<BaseInput label="Due Date" type="date" v-model="dueDate"></BaseInput>
							</Card>
						</Card>
						<Card>
							<CardHeader slot="header" title="Additional Settings" />
							<Card v-if="false" :no-body="true">
								<CardHeader slot="header" title="Include Transaction Fee"
									:endSwitch="{ title: `${getTransactionFee(this.subAmount)[1]}` }"
									:endSwitchValue="includeTransactionFee"
									@change="includeTransactionFee = !includeTransactionFee" />
							</Card>
							<Card :no-body="true">
								<CardHeader slot="header" title="Memo" :endSwitch="{ title: '' }"
									:endSwitchValue="includeMemo" @change="includeMemo = !includeMemo" />
								<BaseInput v-if="includeMemo" class="m-2" type="textarea" placeholder="Enter your memo..."
									rows="6" v-model="invoiceData.invoiceMemo"></BaseInput>
							</Card>
							<Card :no-body="true">
								<CardHeader slot="header" title="Footer" :endSwitch="{ title: '' }"
									:endSwitchValue="includeFooter" @change="includeFooter = !includeFooter" />
								<BaseInput v-if="includeFooter" class="m-2" type="textarea"
									placeholder="Enter your footer..." rows="6" v-model="invoiceData.invoiceFooter">
								</BaseInput>
							</Card>
						</Card>
					</b-col>
					<b-col md="5" lg="5" class="d-md-block d-none">

						<Card no-body>
							<CardHeader slot="header" title="Preview" />
							<InvoicePreviewCard :invoiceData="invoiceData"></InvoicePreviewCard>
						</Card>
					</b-col>
				</b-row></b-overlay>
		</div>
		<InvoiceItemModal :show="showInputModal" @close="showInputModal = false" @submit="newInvoiceItem">
		</InvoiceItemModal>
		<QuestionModal :question="userQuestion" :show="showQuestionModal" @close="showQuestionModal = false"
			@yesClicked="yesCustomer" @noClicked="noCustomer" />
		<UsersModal headerTitle="All Users" :show="showUsersModal" singleSelect @close="showUsersModal = false"
			@viewClicked="selectedUsers" />
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Card from '../../../../components/Cards/Card';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import UsersModal from '../../../../components/Modals/UsersModal.vue';
import InvoiceItemModal from '../../../../components/Modals/InvoiceItemModal.vue'
import DashboardHeader from '../../../mainViews/HeaderFooterComponents/DashboardHeader';
import CheckoutCard from '../../BillingViews/CheckoutCard.vue';
import ProfileCard from '../../MemberViews/BaseMemberViews/ProfileCard.vue';
import MemberHeaderCard from '../../MemberViews/BaseMemberViews/MemberHeaderCard.vue';
import BaseInput from '../../../../components/Inputs/BaseInput.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import BaseButton from '../../../../components/BaseButton.vue';
import BaseSwitch from '../../../../components/BaseSwitch.vue';
import InvoicePreviewCard from '../InvoicePreviewCard.vue';
import CurrencyInput from '../../../../components/Inputs/CurrencyInput.vue';
import InvoiceManager from '../../../../models/Payment-Billing/InvoiceManager'
import { DashboardChildren } from '../../../../routes/routesNames';
import CardFooter from '../../../../components/Cards/CardFooter.vue';

export default {
	props: {
		userData: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			isLoading: false,
			includeMemo: false,
			includeFooter: false,
			showQuestionModal: false,
			showInputModal: false,
			showNewUser: false,
			showUsersModal: false,
			includeTransactionFee: true,
			invoiceUserData: null,
			selectedUser: null,
			allUsers: [],
			inputUsers: [],
			blankInvoiceItems: [
				{
					name: null,
					quantity: 1,
					amount: 0,
					index: 0,
				}
			],
			userQuestion: 'Does this customer have an account?',
			subAmount: 0,
			dueDate: null,
			invoiceData: {
				uid: null,
				firstName: null,
				lastName: null,
				email: null,
				dueDate: null,
				invoiceItems: [],
				price: {
					subAmount: 0,
					transactionFee: null,
					amount: 0
				},
				invoiceMemo: null,
				invoiceFooter: null,

			},
			stripeFee: 0.029,
			dominionFee: 0.02,
			buttonItems: [
				{
					index: 0,
					title: 'Save & Publish',
					type: 'primary',
					isDisabled: true,
				},
			],
		};
	},
	watch: {
		selectedUser: {
			handler(val) {
				this.invoiceUserData = this.allUsers.filter((e) => e.uid == val)[0]
				this.invoiceData.uid = this.invoiceUserData.uid
				this.invoiceData.firstName = this.invoiceUserData.firstName
				this.invoiceData.lastName = this.invoiceUserData.lastName,
					this.invoiceData.email = this.invoiceUserData.email
			}
		},
		dueDate: {
			handler(val) {
				if (val) {
					this.invoiceData.dueDate = new Date(val)
				}
			}
		},
		invoiceData: {
			handler(val) {
				this.buttonItems[0].isDisabled = !this.checkIfReady()

			},
			deep: true
		}
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.onSave();
			}
		},
		yesCustomer() {
			this.showUsersModal = true;
		},
		noCustomer() {
			this.showNewUser = true;
		},
		changeCustomer() {
			this.invoiceUserData = null
			this.selectedUser = null
		},
		getAllUsers() {
			this.isLoading = true;
			AppUserManager.getUsers().then((allUsers, lastDocument) => {
				this.allUsers = allUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))
				this.inputUsers = allUsers.map((e) => ({ key: `${e.uid}`, value: `${e.firstName} ${e.lastName} · ${e.email}` }))
				this.isLoading = false;
				if (this.userData) { this.selectedUser = this.userData.uid }
			});
		},
		getDominionFee(amount) {
			if (amount <= 0) {
				return 0;
			}
			const finalAmount = this.getFinalAmount(amount)[0];
			return parseFloat((finalAmount * this.dominionFee).toFixed(2));
		},
		getTransactionFee(amount) {
			if (amount / 100 <= 0) {
				//this.invoiceData.price.transactionFee = null
				//this.checkoutData.transactionFee = 0;
				return [parseFloat(0), this.toCurrencyFormat(0)];
			}
			//if (this.checkTransactionFee()) {
			//	this.checkoutData.transactionFee = this.checkTransactionFee();
			//	return this.checkTransactionFee();
			//}
			const step1 = amount / 100 + 0.3;
			const step2 = 1 - (this.stripeFee + this.dominionFee);
			const step3 = step1 / step2 - amount / 100;
			const finalFeeAmount = step3.toFixed(2) * 100
			return [parseFloat(finalFeeAmount), this.toCurrencyFormat(finalFeeAmount)];
		},
		getFinalAmount(amount) {
			//var transactionFee = 0
			if (amount <= 0) {
				return [parseFloat(0), this.toCurrencyFormat(0)];
			}
			const transactionFee = this.getTransactionFee(amount)[0]
			const finalAmount = amount// + transactionFee;
			this.invoiceData.price.transactionFee = transactionFee
			return [parseFloat(finalAmount), this.toCurrencyFormat(finalAmount)];
		},
		selectedUsers(data) {
			console.log('User: ', data)
		},
		newInvoiceItem() {
			if (this.blankInvoiceItems.length == 0) {
				this.blankInvoiceItems.push({
					name: null,
					quantity: 1,
					amount: 0,
					index: this.invoiceData.invoiceItems.length,
				})
			}
		},
		canAddInvoiceItem(item) {
			if (!item.name) {
				return false
			} else if (item.amount <= 0) {
				return true
			}
			return true
		},
		updateInvoiceItemAmount(data) {
			//console.log('Input,', data)
		},
		addInvoiceItem(item) {
			var invoiceItem = item
			invoiceItem.amount *= 100
			this.invoiceData.invoiceItems[item.index] = invoiceItem
			this.blankInvoiceItems = []
			this.updatePrice()
		},
		editInvoiceItem(item) {
			var invoiceItem = item
			invoiceItem.amount /= 100
			this.blankInvoiceItems.push(invoiceItem)
		},
		deleteInvoiceItem(item) {
			this.invoiceData.invoiceItems = this.invoiceData.invoiceItems.filter((e) => e.index !== item.index)
			this.updatePrice()
		},
		updatePrice() {
			this.invoiceData.price.subAmount = 0
			this.invoiceData.invoiceItems.forEach((item) => {
				this.invoiceData.price.subAmount += item.amount
			})
			this.invoiceData.price.transactionFee = this.getTransactionFee(this.invoiceData.price.subAmount)[0]
			this.invoiceData.price.amount = this.getFinalAmount(this.invoiceData.price.subAmount)[0]
		},
		toCurrencyFormat(amount) {
			return this.$helpers.amountToString(amount);
		},
		checkIfReady() {
			const firstName = this.invoiceData.firstName
			const lastName = this.invoiceData.lastName
			const email = this.invoiceData.email
			const dueDate = this.invoiceData.dueDate
			if (this.$helpers.isBlank(firstName) || this.$helpers.isBlank(lastName) || this.$helpers.isBlank(email)) {
				return false
			} else if (this.invoiceData.invoiceItems.length <= 0) {
				return false
			} else if (!dueDate) {
				return false
			} else if (this.invoiceData.price.amount < 1) {
				return false
			}
			return true
		},
		onSave() {
			const uid = this.invoiceData.uid
			const dueDate = this.invoiceData.dueDate
			if (this.$helpers.isBlank(uid)) {
				this.showNotification({
					type: 'danger',
					message: 'Please select a customer.',
				});
				return
			} else if (this.invoiceData.invoiceItems.length <= 0) {
				this.showNotification({
					type: 'danger',
					message: 'Please add atleast 1 invoice item.',
				});
				return
			} else if (this.$helpers.isBlank(dueDate)) {
				this.showNotification({
					type: 'danger',
					message: 'Please select an invoice due date.',
				});
				return
			} else if (this.invoiceData.price.amount < 1) {
				this.showNotification({
					type: 'danger',
					message: 'The total amount of the invoice must be greater than $1.00',
				});
				return
			}

			this.isLoading = true

			InvoiceManager.addInvoice(uid, this.invoiceData).then(() => {
				this.isLoading = false;
				this.showNotification({
					type: 'success',
					message: 'New Invoice has been added.',
				});
				this.navigateToInvoices();
			}).catch((error) => {
				this.isLoading = false
				this.showNotification({
					type: 'danger',
					message: error,
				});
			})

		},
		navigateToInvoices() {
			this.$router.replace({
				name: DashboardChildren.billing.name,
				params: { refresh: true },
			});
		}
	},
	mounted() {
		//this.invoiceData.dueDate = this.$helpers.formatDate(new Date(), "yyyy-MM-dd")
		this.getAllUsers()
	},
	components: { Card, DashboardHeader, CardHeader, CheckoutCard, QuestionModal, InvoiceItemModal, UsersModal, ProfileCard, MemberHeaderCard, BaseInput, BaseButton, BaseSwitch, InvoicePreviewCard, CurrencyInput, CardFooter },
};
</script>
