<template>
	<div v-if="readerData">
		<DashboardHeader
			:title="readerData.label"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<b-row>
					<b-col md="5">
						<Card no-body
							><CardHeader :title="'1. Purchase'" slot="header" />
							<EventCard v-if="eventData" :eventData="eventData" readOnly />
							<ShopCard v-if="productData" :shopItem="productData" readOnly />
						</Card>
					</b-col>
					<b-col md="7"
						><Card no-body
							><CardHeader
								:title="'2. Customer Details'"
								slot="header"
								:end-button="{
									title: userData == null ? 'Select Customer' : 'Change Customer',
								}"
								@click="showQuestionModal = true"
							/>
							<CheckoutCard
								:userData="userData"
								:eventData="eventData"
								:productData="productData"
								:priceItem="eventData ? eventData.price : productData.price"
								@confirmPayment="confirmPayment"
							/> </Card
					></b-col>
					<b-col v-if="false" md="2" class="d-none d-md-block">
						<Card class="text-center">
							<b-img
								fluid
								style="max-height: 150px"
								:src="
									require(`../../../../../public/img/icons/cards/${getDeviceType()}`)
								"
							/>
							<h5>{{ readerData.label }}</h5>
							<hr class="mb-2 mt-0" />
							<Badge :type="readerData.status == 'online' ? 'success' : 'primary'"
								><i
									:class="
										readerData.status == 'online'
											? 'fas fa-check-circle'
											: 'fas fa-info-circle'
									"
								></i>
								{{ readerData.status == 'online' ? 'Online' : 'Inactive' }}</Badge
							>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</div>
		<QuestionModal
			:question="userQuestion"
			:show="showQuestionModal"
			@close="showQuestionModal = false"
			@yesClicked="yesCustomer"
			@noClicked="noCustomer"
		/>
		<UsersModal
			headerTitle="All Users"
			:show="showUsersModal"
			singleSelect
			@close="showUsersModal = false"
			@viewClicked="selectedUsers"
		/>
		<NewUser
			:show-modal="showNewUser"
			@close="showNewUser = false"
			@newUser="setListener"
		/>
	</div>
</template>
<script>
import { loadStripeTerminal } from '@stripe/terminal-js';
import { mapActions, mapGetters } from 'vuex';
import DashboardHeader from '../../HeaderFooterComponents/DashboardHeader.vue';
import PaymentManager from '../../../../models/Payment-Billing/PaymentManager';
import Card from '../../../../components/Cards/Card.vue';
import Badge from '../../../../components/Badge.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import EventCard from '../../../Components/EventViews/BaseEventView/EventCard.vue';
import EventManager from '../../../../models/EventItem/EventManager';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import MemberHeaderCard from '../../../Components/MemberViews/BaseMemberViews/MemberHeaderCard.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import NewUser from '../../../mainForms/NewUser.vue';
import UsersModal from '../../../../components/Modals/UsersModal.vue';
import CheckoutCard from '../../../Components/BillingViews/CheckoutCard.vue';
import { getOrganizationFirestore } from '../../../../resources/orgFirebase';
import { doc, onSnapshot } from '@firebase/firestore';
import ShopCard from '../../../Components/ShopViews/ShopCard.vue';
import ProductItemManager from '../../../../models/Payment-Billing/ProductItemManager';

export default {
	computed: {
		...mapGetters({
			appInformation: 'getAppInformation',
			paymentInformation: 'getPaymentInformation',
			stripeTerminal: 'getStripeTerminal',
			stripeTerminalReaders: 'getStripeTerminalReaders',
		}),
	},
	watch: {
		stripeTerminal: {
			handler(val) {
				this.setupButtons();
				this.connectToReader();
				return;
			},
		},
	},
	components: {
		DashboardHeader,
		Card,
		Badge,
		CardHeader,
		BaseButton,
		EventCard,
		MemberHeaderCard,
		QuestionModal,
		NewUser,
		UsersModal,
		CheckoutCard,
		ShopCard,
	},
	props: {
		readerId: {
			type: String,
			default: null,
		},
		eventId: {
			type: String,
			default: null,
		},
		productId: {
			type: String,
			default: null,
		},
		eventStart: {
			type: [String, Number],
			default: null,
		},
		eventData: {
			type: Object,
			default: null,
		},
		productData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			showNewUser: false,
			showUsersModal: false,
			readerStatus: false,
			readerData: null,
			userData: null,
			userQuestion: 'Does this customer have an account?',
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setupButtons() {
			this.buttonItems[0].children = [
				/*
				{
					index: 0,
					title: 'Collect Payment',
					isDisabled: false,
				},
				*/
				{
					index: 1,
					title: 'Cancel Payment',
					type: 'danger',
				},
			];
		},
		childClick(childClicked) {
			switch (childClicked.index) {
				case 1:
					this.cancelTerminal();
					break;
				default:
					break;
			}
		},
		resetView() {
			this.isLoading = true;
			this.userData = null;
			this.productData = null;
			this.setupView();
		},
		setupView() {
			this.isLoading = false;
			if (this.eventId || this.productId) {
				if (this.eventId && this.eventData) {
					//this.showQuestionModal = true;
				} else if (this.productId && this.productData) {
					//this.showQuestionModal = true;
				} else if (this.eventId) {
					this.isLoading = true;
					this.getEventData()
						.then((eventData) => {
							if (eventData.price) {
								this.eventData = eventData;
								//this.showQuestionModal = true;
								this.isLoading = false;
							} else {
								this.routeBack();
							}
						})
						.catch(() => {
							this.routeBack();
						});
				} else if (this.productId) {
					this.isLoading = true;
					this.getProductData().then((productData) => {
						if (productData.price) {
							if (productData.price.subscription) {
								this.routeBack();
							} else {
								this.productData = productData;
								//this.showQuestionModal = true;
								this.isLoading = false;
							}
						} else {
							this.routeBack();
						}
					});
				}
			} else {
				this.routeBack();
			}
		},
		getEventData() {
			return new Promise((resolve, reject) => {
				EventManager.getEventById(this.eventId, this.eventStart).then((result) => {
					if (result) {
						return resolve(result);
					} else {
						return reject();
					}
				});
			});
		},
		getProductData() {
			return new Promise((resolve, reject) => {
				ProductItemManager.getProductById(this.productId)
					.then((result) => {
						if (result) {
							return resolve(result);
						} else {
							return reject();
						}
					})
					.catch((error) => {
						return reject();
					});
			});
		},
		connectToReader() {
			if (this.stripeTerminal) {
				if (this.stripeTerminal.getConnectionStatus() == 'connected') {
					this.setupView();
				} else if (this.stripeTerminalReaders) {
					if (this.stripeTerminalReaders.length > 0) {
						this.readerData = this.stripeTerminalReaders.find(
							(e) => e.id == this.readerId
						);
						if (this.readerData) {
							this.readerStatus = this.readerData.status == 'online';
							this.stripeTerminal.connectReader(this.readerData).then((result) => {
								if (result.error) {
									this.showNotification({
										type: 'danger',
										message: result.error.message,
									});
									this.routeBack();
								} else {
									this.setupView();
								}
							});
						} else {
							this.showNotification({
								type: 'danger',
								message:
									'There was an error connecting to this reader. Please try again.',
							});
							this.routeBack();
						}
					}
				}
			}
		},
		getDeviceType() {
			if (this.readerData.device_type == 'bbpos_wisepos_e') {
				return 'wisepoe.png';
			} else {
				return 'unknown.png';
			}
		},
		confirmPayment(chargeData) {
			this.isLoading = true;
			PaymentManager.createStripeTerminalCharge(this.userData.uid, chargeData)
				.then((response) => {
					const [result, uid, databaseId, databaseRef, chargeData] = response;
					const clientSecret = result.client_secret;
					this.stripeTerminal.collectPaymentMethod(clientSecret).then((result) => {
						if (result.error) {
							this.isLoading = false;
							this.showNotification({
								type: 'danger',
								message: result.error.message,
							});
						} else {
							this.stripeTerminal
								.processPayment(result.paymentIntent)
								.then((result) => {
									if (result.error) {
										this.isLoading = false;
										this.showNotification({
											type: 'danger',
											message: result.error.message,
										});
									} else {
										this.completePayment(
											result.paymentIntent.id,
											uid,
											databaseId,
											databaseRef,
											chargeData
										);
									}
								});
						}
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: error,
					});
				});
		},
		completePayment(paymentId, uid, databaseId, databaseRef, chargeData) {
			PaymentManager.capturePaymentIntent(
				paymentId,
				uid,
				databaseId,
				databaseRef,
				chargeData,
				this.eventData,
				this.productData
			)
				.then((receiptLink) => {
					this.showNotification({
						type: 'success',
						message: 'Payment Complete.',
					});
					if (receiptLink) {
						var open = window.open(receiptLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
					this.resetView();
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		yesCustomer() {
			this.showUsersModal = true;
		},
		noCustomer() {
			this.showNewUser = true;
		},
		selectedUsers(userData) {
			this.userData = userData;
			this.showUsersModal = false;
		},
		setListener(userData) {
			this.isLoading = true;
			const firestore = getOrganizationFirestore();
			const userRef = doc(firestore, `users/${userData.uid}`);
			const unsubscribe = onSnapshot(
				userRef,
				(snapshot) => {
					const userData = snapshot.data();
					if (userData.stripeId) {
						unsubscribe();
						this.userData = userData;
						this.isLoading = false;
					}
				},
				(error) => {
					unsubscribe();
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
					this.isLoading = false;
				}
			);
		},
		cancelTerminal() {
			this.stripeTerminal.cancelCollectPaymentMethod();
			this.stripeTerminal.clearReaderDisplay();
			this.resetView();
		},
		routeBack() {
			this.$router.back();
		},
	},
	mounted() {
		if (!this.readerId) {
			return this.routeBack();
		}

		this.setupButtons();
		this.connectToReader();
	},
};
</script>
