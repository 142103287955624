<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="saveClicked"
		/>
		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<Card no-body>
					<CardHeader
						slot="header"
						title="Base Settings"
						:end-button="{ title: 'Save', disabled: !needsUpdate }"
					/>
					<CardFooter
									slot="footer"
								title="When updating any fields, please give the software about 10 minutes to reflect the changes." />
					<b-tabs
						active-nav-item-class="bg-white font-weight-bold description"
						nav-class="bg-white description"
						card
						no-body
					>
						<b-tab title="Base Information" active>
							<Card>
								<BaseInput
									label="Studio Name"
									:disabled="true"
									v-model="publicInformation.appName"
								/>
								<BaseInput
									label="Studio Address"
									:disabled="true"
									v-model="publicInformation.appAddress"
								/>
							</Card>
							<Card v-if="publicInformation.publicRef">
								<b-row class="align-items-center">
									<b-col>
								<BaseInput cols="12" md="" class="mb-0"
									label="Client Portal"
									:disabled="true"
									:value="getClientPortalLink()"
								/>
								</b-col>
								<b-col cols="12" md="auto">
									<a :href="getClientPortalLink()" target="_blank">
									<BaseButton >Visit Link</BaseButton></a>
								</b-col>
								</b-row>
							</Card>
							<Card>
								<CardHeader
									slot="header"
									title="Categories"
									tooltip="Add, remove, or edit categories for your platform."
								/>
								<b-row>
									<b-col>
										<BaseInput
									label="Class Categories"
									id="categories"
									type="multiselect"
									internalSearch="false"
									@tag="onNewClassTag"
									@remove="onTagClassRemoved"
									:allowMultiple="true"
									:multiSelectOptions="classCategories"
									:multiSelectValue="classCategories"
									:multiSelectTaggable="true"
								/>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<BaseInput
									label="Shop Categories"
									id="shop categories"
									type="multiselect"
									internalSearch="false"
									@tag="onNewShopTag"
									@remove="onTagClassRemoved"
									:allowMultiple="true"
									:multiSelectOptions="shopCategories"
									:multiSelectValue="shopCategories"
									:multiSelectTaggable="true"
								/>
										</b-col>
										</b-row>
							</Card>
							<Card :no-body="true">
								<CardHeader
									slot="header"
									title="Shop Tab"
									tooltip="Enable the shop tab & product items."
									:endSwitch="{ title: ''}"
									:endSwitchValue="appInformation.isProductActive"
									@change="onShopChanged"
								/>
								
							</Card>
							
						</b-tab>
						<b-tab title="App Information">
							<Card>
								<CardHeader
									slot="header"
									title="App Messaging"
									tooltip="Adjust the messaging settings for the mobile app"
								/>
								<b-row class="align-items-center ">
									<b-col>
										<label class="form-control-label">Direct Messaging</label>
									</b-col>
									<b-col class="text-right">
										<BaseSwitch title="Direct Message" :value="appInformation.isMessagingActive" @change="onMessageChanged" />
									</b-col>
								</b-row>
								<hr class="my-4"/>
								<b-row class="mt-4 align-items-center" v-if="appInformation.isMessagingActive">
									<b-col>
										<label class="form-control-label">Leader's Only Messaging</label>
									</b-col>
									<b-col class="text-right">
										<BaseSwitch title="Direct Message" :value="appInformation.isMessagingTeachersOnly != null
											? appInformation.isMessagingTeachersOnly
											: true" @change="onLeaderMessageChanged" />
									</b-col>
								</b-row>
							</Card>
						</b-tab>
						<b-tab v-if="false" title="Payment Information">
							<Card :no-body="paymentInformation.transactionFee == null">
								<CardHeader
									slot="header"
									title="Enable Transaction Fee"
									tooltip="Enable the shop tab & product items."
									:endSwitch="{
										title: ``,
									}"
									:endSwitchValue="paymentInformation.transactionFee != null"
								/>
								<CurrencyInput
									v-if="paymentInformation.transactionFee != null"
									label="Fixed Transaction Fee"
									v-model="paymentInformation.transactionFee"
								/>
							</Card>
						</b-tab>
						<b-tab v-if="false" title="Default Calendar Colors">
							<Card>
								<b-row>
									<b-col
										md="8"
										lg="8"
										class="d-flex align-items-center"
										style="gap: 5px"
									>
										<h3 class="mb-0">Default Calendar Color</h3>
									</b-col>
									<b-col class="d-flex justify-content-end">
										<verte
											v-if="colorMounted"
											:enableAlpha="false"
											model="hex"
											menuPosition="right"
											v-model="appInformation.calendarColors.defaultColor"
										/>
									</b-col>
								</b-row>
							</Card>
							<Card>
								<b-row>
									<b-col
										md="8"
										lg="8"
										class="d-flex align-items-center"
										style="gap: 5px"
									>
										<h3 class="mb-0">Class Calendar Color</h3>
									</b-col>
									<b-col class="d-flex justify-content-end">
										<verte
											:enableAlpha="false"
											model="hex"
											menuPosition="right"
											v-model="appInformation.calendarColors.classColor"
										/>
									</b-col>
								</b-row>
							</Card>
							<Card>
								<b-row>
									<b-col
										md="8"
										lg="8"
										class="d-flex align-items-center"
										style="gap: 5px"
									>
										<h3 class="mb-0">Event Calendar Color</h3>
									</b-col>
									<b-col class="d-flex justify-content-end">
										<verte
											:enableAlpha="false"
											model="hex"
											menuPosition="right"
											v-model="appInformation.calendarColors.eventColor"
										/>
									</b-col>
								</b-row>
							</Card>
						</b-tab>
					</b-tabs>
				</Card>
			</b-overlay>
			<QuestionModal
				:show="showQuestionModal"
				:question="modalQuestion"
				@close="showQuestionModal = false"
				@yesClicked="deleteCategory"
				@noClicked="selectedCategory = null"
			/>
		</div>
	</div>
</template>
<script>
import verte from 'verte';
import 'verte/dist/verte.css';
import { BaseInput, Card } from '@/components';
import CardHeader from '@/components/Cards/CardHeader.vue';
import CurrencyInput from '@/components/Inputs/CurrencyInput.vue';
import store from '@/store/store';
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import CategoryItemManager from '../../../models/CategoryItem/CategoryItemManager';
import { mapActions, mapGetters } from 'vuex';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { AccountInformation } from '../../../models/AccountInformation/AccountInformation';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseButton from '../../../components/BaseButton.vue';

export default {
	name: 'settings',
	computed: {
		...mapGetters({
			appInformation: 'getAppInformation',
			publicInformation: 'getPublicInformation',
			paymentInformation: 'getPaymentInformation',
		}),
	},
	components: {
    verte,
    DashboardHeaderVue,
    Card,
    CardHeader,
    CurrencyInput,
    BaseInput,
    QuestionModal,
    CardFooter,
    BaseSwitch,
    BaseButton
},
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			modalQuestion: null,
			colorMounted: false,
			buttonItems: [],
			classCategories: [],
			shopCategories: [],
			categoryOptions: [],
			selectedCategory: null,
			needsUpdate: false,
			updatedAppInformation: null,
			updatedPublicInformation: null,
			updatedPaymentInformation: null,
			accountInformation: null,
		};
	},
	watch: {
		accountInformation: {
			handler(val) {
				return (this.needsUpdate = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
	},
	methods: {
		...mapActions(['showNotification']),
		getClientPortalLink(){
			const base = "https://client.dominionstudio.com/"
			return base + this.publicInformation.publicRef
		},
		onNewClassTag(categoryName) {
			this.isLoading = true;
			CategoryItemManager.addNewCategory(categoryName, false).then((categoryData) => {
				this.getCategoryItems();
			});
		},
		onNewShopTag(categoryName) {
			this.isLoading = true;
			CategoryItemManager.addNewCategory(categoryName, true).then((categoryData) => {
				this.getCategoryItems();
			});
		},
		onTagClassRemoved(categoryData) {
			this.selectedCategory = categoryData;
			this.modalQuestion = 'Are you sure you want to delete this category?';
			this.showQuestionModal = true;
		},
		deleteCategory() {
			this.isLoading = true;
			CategoryItemManager.deleteCategory(this.selectedCategory.categoryId)
				.then(() => {
					this.getCategoryItems();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		getCategoryItems() {
			this.isLoading = true;
			this.classCategories = [];
			this.shopCategories = []
			this.categoryOptions = [];
			CategoryItemManager.getAllCategories().finally(() => {
				this.classCategories = CategoryItemManager.allCategories.filter((item) => {
					var isClass = true
					var isProduct = false
					var isEvent = false
					if (item.isClass != null){isClass = item.isClass}
					if (item.isProduct != null){isProduct = item.isProduct}
					return isClass && !isProduct
				});
				this.shopCategories = CategoryItemManager.allCategories.filter((item) => {
					var isProduct = false
					if (item.isProduct != null){isProduct = item.isProduct}
					return isProduct
				});
				this.categoryOptions = CategoryItemManager.allCategories
				this.isLoading = false;
			});
		},
		onShopChanged(value) {
			this.updateAccountInfo({isProductActive: value})
		},
		onMessageChanged(value){
			this.updateAccountInfo({isMessagingActive: value.target.checked})
		},
		onLeaderMessageChanged(value){
			this.updateAccountInfo({isMessagingTeachersOnly: value.target.checked})
		},
		checkIfUpdateNeeded() {
			return this.accountInformation != store.state.accountInformation;
		},
		saveClicked(){},
		updateAccountInfo(data){
			this.isLoading = true
			AccountInformation.updateAppInformation(data).then(() => {
				this.isLoading = false
				this.showNotification({
						type: 'success',
						message: 'Account Information Updated.',
					});
			}).catch((error) => {
					this.isLoading = false;
					console.log(error)
					this.showNotification({
						type: 'danger',
						message: 'Unknown Error.',
					});
				});
		}
	},
	mounted() {
		this.colorMounted = true
		this.accountInformation = store.state.accountInformation;
		this.getCategoryItems();
	},
};
</script>
