export const DominionInformation = {
	dominionName: 'Dominion Studio',
	dominionFullName: 'Dominion IT Consulting, LLC',
	dominionWebsite: 'https://www.dominionstudio.com',
	dominionWebsiteFeatures: 'https://www.dominionstudio.com',
	dominionEmail: 'info@dominionstudio.com',
	dominionSupport: 'https://support.dominionstudio.com/',
	dominionSupportTicket:
		'https://support.dominionstudio.com/support/tickets/new',
	dominionStripePubKey:
		'pk_live_51HPBseHRR4QjYqaH1EOsQ0Ta7DPtIbVnVxEuaXcdgD49LljatKhvu89K1iPlNBEmN5H4DWG4mg6wY0T7C3CQkvyR00PD6OIpds',
	dominionStripeTestPubKey:
		'pk_test_51HPBseHRR4QjYqaHxtXTIlXrextYBuwipa6Lv3X655YPRtP0H8xmiKj9lFcXZiABl87QjTQdXPjr5qzOSv3c5UZh001xDb73KF',
};

export const DominionTerminalFunctions = {
	createStripeCharge:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/createStripeCharge',
	createTerminalReader:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/createTerminalReader',
	createTerminalConnection:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/createTerminalConnection',
	setTerminalDisplay:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/setTerminalDisplay',
	cancelReaderAction:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/cancelReaderAction',
	getAllReaders:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/getAllReaders',
	capturePaymentIntent:
		'https://us-central1-dominionstudio-d9898.cloudfunctions.net/capturePaymentIntent',
};

//export default { DominionInformation, DominionTerminalFunctions };
