<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">Upload a new image.</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="pt-1 pb-1">
					<VueDropZone
						class="p-0"
						id="logo"
						style="border: 0"
						v-on:vdropzone-files-added="addedItem"
						:options="dropzoneOptions"
					>
					</VueDropZone>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Save</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import Modal from '../../components/Modals/Modal.vue';
import BaseButton from '../../components/BaseButton.vue';
export default {
	props: {
		headerTitle: {
			type: String,
			default: 'Edit Image',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueDropZone,
		BaseButton,
		Modal,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			imageItem: null,
			errorMessage: null,
		};
	},
	methods: {
		resetView() {
			this.imageItem = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedItem(file) {
			this.imageItem = file;
		},
		submit() {
			this.$emit('updatedImage', this.imageItem);
			this.resetView();
			this.closeModal();
		},
	},
	mounted() {},
};
</script>
