<template>
	<div v-if="userData">
		<b-overlay :show="isLoading" rounded="sm" no-wrap />
		<Card>
			<CardHeader
				slot="header"
				:title="'Account Information'"
				:end-button="{ title: 'Update Profile', disabled: !isUpdatedNeed }"
				@click="updateUserInformation"
			/>
			<form>
				<div>
					<b-row>
						<b-col>
							<BaseInput
								label="First Name"
								type="text"
								placeholder="First Name"
								name="first name"
								v-model="updatedData.firstName"
								required
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Last Name"
								placeholder="Last Name"
								name="last name"
								type="text"
								v-model="updatedData.lastName"
								required
							/>
						</b-col>
					</b-row>
					<BaseInput
						type="email"
						label="Email"
						placeholder="Email"
						name="email"
						disabled
						v-model="userData.email"
						required
					/>
					<BaseInput
						type="phoneNumber"
						label="Phone Number"
						placeholder="Add Phone Number"
						name="phoneNumber"
						v-model="updatedData.phoneNumber"
					>
					</BaseInput>
					<BaseInput
						type="text"
						label="Member Id"
						placeholder="Member Id"
						disabled
						:value="`${userData.uid.substring(0, 10)}****`"
					>
					</BaseInput>
				</div>
				<hr class="my-3" />
				<h6 class="heading-small text-muted mb-2">Additional Information</h6>
				<div>
					<BaseInput
						type="text"
						label="Address"
						placeholder="Add Current Address"
						name="address"
						v-model="updatedData.address"
					>
					</BaseInput>
					<BaseInput
						type="date"
						label="Birthday"
						placeholder="Enter Date Of Birth"
						name="birthday"
						v-model="updatedData.dateOfBirth"
					>
					</BaseInput>
					<BaseInput
						type="text"
						label="Gender"
						placeholder="Enter Gender"
						name="gender"
					>
						<select
							class="form-control"
							id="gender"
							v-model="updatedData.gender"
							required
						>
							<option
								v-for="options in genderOptions"
								:key="options.value"
								:value="options.value"
							>
								{{ options.name }}
							</option>
						</select></BaseInput
					>
				</div>
				<hr class="my-3" />
				<div>
					<BaseInput label="Medical Notes">
						<textarea
							type="text"
							class="form-control"
							rows="5"
							placeholder="Please use a comma-seperaed list for brevity. Ex: Pollen, nutes, dairy."
							name="medical notes"
							v-model="updatedData.medicalNotes"
						></textarea>
					</BaseInput>
				</div>
			</form>
		</Card>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../../components/Cards/Card.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../../components/Inputs/BaseInput.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';

export default {
	props: {
		userData: null,
	},
	data() {
		return {
			isLoading: false,
			isUpdatedNeed: false,
			updatedData: {
				firstName: null,
				lastName: null,
				phoneNumber: null,
				address: null,
				dateOfBirth: null,
				gender: null,
				medicalNotes: null,
			},
			genderOptions: [
				{
					name: 'Female',
					value: 'female',
				},
				{
					name: 'Male',
					value: 'male',
				},
			],
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
	},
	components: { Card, CardHeader, BaseInput },
	methods: {
		...mapActions(['showNotification']),
		setupView() {
			this.updatedData = {
				firstName: this.userData.firstName,
				lastName: this.userData.lastName,
				phoneNumber: this.getValue(this.userData.phoneNumber),
				address: this.getValue(this.userData.address),
				gender: this.getValue(this.userData.gender),
				medicalNotes: this.getValue(this.userData.medicalNotes),
			};
			if (this.userData.dateOfBirth) {
				this.updatedData.dateOfBirth = this.$helpers.formatDate(
					this.userData.dateOfBirth.toDate(),
					'yyyy-MM-dd'
				);
			}
		},
		checkIfUpdateNeeded() {
			if (
				this.updatedData.firstName !== this.userData.firstName ||
				this.updatedData.lastName !== this.userData.lastName
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.phoneNumber) !==
				this.getValue(this.userData.phoneNumber)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.address) !==
				this.getValue(this.userData.address)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.gender) !==
				this.getValue(this.userData.gender)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.medicalNotes) !==
				this.getValue(this.userData.medicalNotes)
			) {
				return true;
			} else if (
				this.getValue(this.updatedData.dateOfBirth) !==
				this.getValue(this.userData.dateOfBirth)
			) {
				if (this.updatedData.dateOfBirth) {
					if (this.userData.dateOfBirth) {
						var userDOB = this.userData.dateOfBirth.toDate();
						var timestampDOB = new Date(this.updatedData.dateOfBirth);
						userDOB.setHours(0, 0, 0, 0);
						timestampDOB.setHours(0, 0, 0, 0);
						return timestampDOB.getTime() == userDOB.getTime();
					} else {
						return true;
					}
				}
			}
			return false;
		},
		updateUserInformation() {
			this.isLoading = true;
			if (this.updatedData.dateOfBirth) {
				const timestampDOB = new Date(this.updatedData.dateOfBirth);
				this.updatedData.dateOfBirth = timestampDOB;
			}
			AppUserManager.updateUserInformation(this.userData.uid, this.updatedData)
				.then(() => {
					this.isLoading = false;
					this.$emit('onUpdate');
					this.showNotification({
						type: 'success',
						message: 'Information Updated',
					});
					this.isUpdatedNeed = false;
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		getValue(value) {
			if (value === undefined || value === null || this.$helpers.isBlank(value)) {
				return null;
			}
			return value;
		},
	},
	mounted() {
		this.setupView();
	},
};
</script>
