<template>
	<div>
		<DashboardHeader
			:title="`${classId ? getClassTitle() : this.$route.name}`"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>

		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<Card no-body>
					<b-tabs
						active-nav-item-class="bg-white font-weight-bold description"
						nav-class="bg-white description"
						card
						no-body
					>
						<b-tab title="Event Details" active>
							<NewEventDetailsCard @onChange="onEventDetails" />
							<NewEventTimeCard
								@onChange="onEventTime"
								:eventDetails="eventDateTime"
							/>
							<LocationCard @onChange="onEventLocation" :isNew="true" />
						</b-tab>
						<b-tab title="Price Information" v-if="appInformation.isPaymentActive">
							<PriceCard @onChange="onEventPrice" :price="eventPrice" :isNew="true" />
						</b-tab>
						<b-tab title="Registration">
							<RegistrationCard
								@onChange="onEventRegistration"
								:showMaxUsers="eventPrice.isFree && eventPrice.eventButtonLink == null"
								:isNew="true"
							/>
						</b-tab>
						<b-tab title="Additional Settings">
							<AdditionalSettingsCard @onChange="onEventSettings" :isNew="true" />
						</b-tab>
					</b-tabs>
				</Card>
			</b-overlay>
		</div>
	</div>
</template>
<script>
import DashboardHeader from '../../../mainViews/HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../../components/Cards/Card.vue';
import Tab from '../../../../components/Tabs/Tab.vue';
import Tabs from '../../../../components/Tabs/Tabs.vue';
import NewEventDetailsCard from './DetailsView/NewEventDetailsCard.vue';
import NewEventTimeCard from './DetailsView/NewEventTimeCard.vue';
import NewEventFacultyCard from './DetailsView/NewEventFacultyCard.vue';
import EventManager from '../../../../models/EventItem/EventManager';
import { mapActions, mapGetters } from 'vuex';
import LocationCard from '../../GeneralViews/LocationCard.vue';
import PriceCard from '../../GeneralViews/PriceCard.vue';
import RegistrationCard from '../../GeneralViews/RegistrationCard.vue';
import AdditionalSettingsCard from '../../GeneralViews/AdditionalSettingsCard.vue';
import { DashboardChildren } from '../../../../routes/routesNames';
import store from '../../../../store/store';
import ChangeImage from '../../../mainForms/ChangeImage.vue';

export default {
	name: 'eventView',
	props: {
		classId: null,
		classTitle: null,
	},
	computed: {
		...mapGetters({
			appInformation: 'getAppInformation',
		}),
	},
	components: {
		DashboardHeader,
		Card,
		Tab,
		Tabs,
		NewEventDetailsCard,
		NewEventTimeCard,
		NewEventFacultyCard,
		LocationCard,
		PriceCard,
		RegistrationCard,
		AdditionalSettingsCard,
		ChangeImage,
	},
	data() {
		return {
			isLoading: false,
			buttonItems: [
				{
					index: 0,
					title: 'Save & Publish',
					type: 'primary',
					isDisabled: true,
				},
			],
			validItems: {
				eventDetails: false,
				eventDateTime: false,
				eventLocation: true,
				eventPrice: false,
				eventRegistration: true,
				eventSettings: true,
			},
			eventDetails: {
				eventTitle: null,
				eventDescription: null,
				eventImage: null,
				eventCategories: [],
			},
			eventDateTime: {
				eventStartDate: null,
				eventDuration: 0,
				eventRecurrence: null,
				eventRecurrenceDays: [],
				eventEndDate: null,
			},
			eventLocation: {
				title: null,
				description: null,
			},
			eventPrice: {
				isFree: false,
				isPrepaidOption: true,
				oneTimePurchase: false,
				amount: 0,
				quantity: null,
				eventButtonTitle: null,
				eventButtonLink: null,
				eventCurrency: 'US Dollar - USD ($)',
				transactionFee: 0,
			},
			eventRegistration: {
				eventMaxUsers: null,
				registrationStartDuration: null,
				registrationCloseDuration: null,
				startAge: null,
				endAge: null,
				enforced: false,
			},
			eventSettings: {
				isFeatured: false,
				requiredDocuments: true,
				facultyLeaders: null,
				eventPolicy: null,
				calendarHex: null,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.onSave();
			}
		},
		getClassTitle(){
			return `New Class Event · ${this.classTitle}`
		},
		onEventDetails(eventData) {
			this.eventDetails = eventData;
			if (
				this.eventDetails.eventTitle &&
				this.eventDetails.eventDescription &&
				this.eventDetails.eventImage
			) {
				this.validItems.eventDetails = true;
			} else {
				this.validItems.eventDetails = false;
			}
			this.checkIfReady();
		},
		onEventTime(eventData) {
			this.eventDateTime = eventData;
			console.log(this.eventDateTime.eventDuration);
			if (
				this.eventDateTime.eventStartDate &&
				this.eventDateTime.eventDuration > 0 &&
				this.eventDateTime.eventRecurrence == null
			) {
				this.validItems.eventDateTime = true;
			} else if (
				this.eventDateTime.eventRecurrence &&
				this.eventDateTime.eventStartDate &&
				this.eventDateTime.eventDuration > 0 &&
				this.eventDateTime.eventEndDate &&
				this.eventDateTime.eventEndDate > this.eventDateTime.eventStartDate
			) {
				this.validItems.eventDateTime = true;
			} else {
				this.validItems.eventDateTime = false;
			}
			this.checkIfReady();
		},
		onEventLocation(eventData) {
			this.eventLocation = eventData;
			this.checkIfReady();
		},
		onEventPrice(eventData) {
			this.eventPrice = eventData.price;
			if (!this.eventPrice.isFree && this.eventPrice.amount < 1) {
				this.validItems.eventPrice = false;
			} else {
				this.validItems.eventPrice = true;
			}

			this.checkIfReady();
		},
		onEventRegistration(eventData) {
			this.eventRegistration = eventData.registration;
			console.log(this.eventRegistration);
			this.checkIfReady();
		},
		onEventSettings(eventData) {
			this.eventSettings = eventData.settings;
			this.checkIfReady();
		},
		checkIfReady() {
			const isValid = Object.values(this.validItems).every(
				(value) => value === true
			);
			this.buttonItems[0].isDisabled = !isValid;
		},

		onSave() {
			const eventDetails = this.eventDetails;
			const eventDateTime = this.eventDateTime;
			const eventLocation = this.eventLocation;
			const eventPrice = this.eventPrice;
			const eventRegistration = this.eventRegistration;
			const eventSettings = this.eventSettings;

			var eventData = {
				uploadTimestamp: this.$helpers.returnTimestamp(),
				eventTitle: eventDetails.eventTitle,
				eventDescription: eventDetails.eventDescription,
				eventStart: this.$helpers.changeTimeZone(
					new Date(eventDateTime.eventStartDate)
				),
				eventDuration: parseInt(eventDateTime.eventDuration),
				location: {
					title: eventLocation.title,
					description: eventLocation.description,
				},
				calendarHex: eventSettings.calendarHex.toLowerCase(),
				requiredDocuments: eventSettings.requiredDocuments,
			};

			if (this.classId){
				eventData['classId'] = this.classId
			}

			if (eventDateTime.eventRecurrence) {
				const eventEnd = this.$helpers.changeTimeZone(
					new Date(eventDateTime.eventEndDate)
				);
				const recurrenceTime = eventDateTime.eventRecurrence;
				var recurrence = {
					recurrenceTime: recurrenceTime,
				};
				if (eventDateTime.eventRecurrenceDays) {
					if (eventDateTime.eventRecurrenceDays.length > 0) {
						recurrence['recurrenceDays'] = eventDateTime.eventRecurrenceDays;
					}
				}
				eventData['recurrence'] = recurrence;
				eventData['eventEnd'] = eventEnd;
			} else {
				eventData['eventEnd'] = eventData.eventStart;
			}

			if (eventRegistration.eventMaxUsers) {
				eventData['eventMaxUsers'] = parseInt(eventRegistration.eventMaxUsers);
			}

			if (!eventPrice.isFree) {
				var price = {
					amount: eventPrice.amount * 100,
				};
				if (eventPrice.quantity) {
					price['quantity'] = parseInt(eventPrice.quantity);
				}
				if (eventPrice.oneTimePurchase) {
					price['oneTimePurchase'] = eventPrice.oneTimePurchase;
				}
				eventData['isPrepaidOption'] = eventPrice.isPrepaidOption;
				eventData['price'] = price;
			} else if (eventPrice.eventButtonLink) {
				eventData['eventButtonLink'] = eventPrice.eventButtonLink;
			}

			if (eventPrice.transactionFee) {
				eventData['transactionFee'] = eventPrice.transactionFee * 100;
			}

			if (eventPrice.eventButtonTitle) {
				eventData['eventButtonTitle'] = eventPrice.eventButtonTitle;
			}

			if (eventRegistration.startAge) {
				var ageItem = {
					startAge: parseInt(eventRegistration.startAge),
					enforced: eventRegistration.enforced,
				};
				if (eventRegistration.endAge) {
					ageItem['endAge'] = parseInt(eventRegistration.endAge);
				}
				eventData['ageItem'] = ageItem;
			}

			if (eventSettings.eventPolicy) {
				eventData['eventPolicy'] = eventSettings.eventPolicy;
			}

			if (eventSettings.facultyLeaders) {
				eventData['eventLeaders'] = [eventSettings.facultyLeaders]; //should be array
			}

			if (eventSettings.isFeatured) {
				eventData['isFeatured'] = eventSettings.isFeatured;
			}

			if (eventRegistration.registrationStartDuration) {
				eventData['registrationStartDuration'] =
					eventRegistration.registrationStartDuration;
			}

			if (eventRegistration.registrationCloseDuration) {
				eventData['registrationCloseDuration'] =
					eventRegistration.registrationCloseDuration;
			}

			this.isLoading = true;
			EventManager.addEvent(eventDetails.eventImage[0], eventData, this.classId)
				.then((eventData) => {
					EventManager.getAllEvents();
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'New Event has been added.',
					});
					this.navigateToSchedule();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'error',
						message: errorMessage,
					});
				});
		},
		navigateToSchedule() {
			this.$router.replace({
				name: this.classId ? DashboardChildren.classView.name : DashboardChildren.schedule.name,
				params: this.classId ? {
					classId: this.classId,
					refresh: true
				} : { refresh: true }
			});
		},
	},
	mounted() {
		this.eventPrice.isFree = !this.appInformation.isPaymentActive;
		this.validItems.eventPrice = !this.appInformation.isPaymentActive;
	},
};
</script>
