import { getOrganizationFirestore } from '@/resources/orgFirebase';
import { deleteDoc, doc, getDocs } from 'firebase/firestore';
import FirebaseManager from '../Firebase/FirebaseManager';

export default class VideoItemManager {
	static isLoading = true;
	static allVideoItems = [];

	static getAllVideos() {
		return new Promise((resolve, reject) => {
			this.allVideoItems = [];
			this.isLoading = true;
			const videoRef = FirebaseManager.allVideosRef(getOrganizationFirestore());
			getDocs(videoRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							var documentData = document.data();
							this.allVideoItems.push(documentData);
							this.allVideoItems.sort((a, b) => {
								return b.uploadTimestamp - a.uploadTimestamp;
							});
							if (counter == total - 1) {
								this.isLoading = false;
								return resolve();
							}
							counter += 1;
						});
					} else {
						this.isLoading = false;
						return resolve(null);
					}
				})
				.catch((error) => {
					this.isLoading = false;
					return resolve(null);
				});
		});
	}

	static deleteVideo(videoId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const videoRef = doc(
				firestore,
				`${FirebaseManager.allVideosRef(firestore).path}/${videoId}`
			);
			deleteDoc(videoRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
