import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	setDoc,
} from 'firebase/firestore';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';

export default class FileItemManager {
	static isLoading = false;
	static allFiles = [];

	static getAllFiles() {
		return new Promise((resolve, reject) => {
			this.allFiles = [];
			this.isLoading = true;
			const firestore = getOrganizationFirestore();
			const filesRef = FirebaseManager.allFilesRef(firestore);
			getDocs(filesRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							if (!this.allFiles.find(($0) => $0.fileId == documentData.fileId)) {
								this.allFiles.push(documentData);
							}
							if (counter == total - 1) {
								this.isLoading = false;
								return resolve();
							}
							counter += 1;
						});
					} else {
						this.isLoading = false;
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.isLoading = false;
					return resolve(null);
				});
		});
	}

	static deleteFile(fileId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const filesRef = FirebaseManager.allFilesRef(firestore);
			const fileDocRef = doc(firestore, `${filesRef.path}/${fileId}`);
			deleteDoc(fileDocRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addFileToDB(fileData) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const filesRef = FirebaseManager.allFilesRef(firestore);
			const newFileRef = doc(filesRef);
			const orgId = store.state.organization.orgId;

			var data = fileData;
			data['fileId'] = newFileRef.id;
			data[
				'fileLink'
			] = `https://files.dominionstudio.com/files/${orgId}/${newFileRef.id}`;
			const urlExtended = `file?&fileId=${newFileRef.id}`;

			FirebaseManager.createDynamicLink(urlExtended, data.fileTitle).then(
				(shareLink) => {
					if (shareLink) {
						data['shareLink'] = shareLink;
					}
					setDoc(newFileRef, data)
						.then(() => {
							return resolve(data);
						})
						.catch((error) => {
							return reject(error);
						});
				}
			);
		});
	}

	static getUserFiles(uid) {
		return new Promise((resolve, reject) => {
			const firstore = getOrganizationFirestore();
			const ref = collection(
				firstore,
				`${FirebaseManager.allUserRef(firstore).path}/${uid}/files`
			);
			getDocs(ref)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							const documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					return resolve(null);
				});
		});
	}
}
