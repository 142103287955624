import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	increment,
	query,
	setDoc,
	Timestamp,
	updateDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import CalendarObject from '../DataModels/CalendarObject';
import Recurrence from '../DataModels/Reccurrence';
import FirebaseManager from '../Firebase/FirebaseManager';
import AppUserManager from '../AppUser/AppUserManager';

export default class EventManager {
	static isLoading = false;
	static allEvents = [];

	static getAllEvents() {
		return new Promise((resolve, reject) => {
			this.allEvents = [];
			this.isLoading = true;
			const firestore = getOrganizationFirestore();
			const eventRef = collection(firestore, `events`);
			//const queryRef = query(eventRef, where('eventEnd', '>=', new Date()));
			getDocs(eventRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							this.processEvent(documentData).then((items) => {
								items.forEach((eventItem) => {
									if (eventItem.recurrence) {
										const uniqueId = `${eventItem.eventId}${eventItem.eventStart}`;
										if (!this.allEvents.find(($0) => $0.eventId == uniqueId)) {
											this.allEvents.push(eventItem);
											CalendarObject.addObjectForSchedule(eventItem);
										}
									} else {
										if (!this.allEvents.find(($0) => $0.eventId == eventItem.eventId)) {
											this.allEvents.push(eventItem);
											CalendarObject.addObjectForSchedule(eventItem);
										}
									}
									if (counter == total - 1) {
										this.isLoading = false;
										return resolve();
									}
									counter += 1;
								});
							});
						});
					} else {
						this.isLoading = false;
						//return reject('No Events Found');
						return resolve();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					return reject(error.message);
				});
		});
	}

	static getAllClassEvents(classId) {
		return new Promise((resolve, reject) => {
			var allEvents = [];
			const firestore = getOrganizationFirestore();
			const eventRef = collection(firestore, `classes/${classId}/events`);
			//const queryRef = query(eventRef, where('eventEnd', '>=', new Date()));
			getDocs(eventRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							this.processEvent(documentData).then((items) => {
								items.forEach((eventItem) => {
									if (eventItem.recurrence) {
										const uniqueId = `${eventItem.eventId}${eventItem.eventStart}`;
										if (!allEvents.find(($0) => $0.eventId == uniqueId)) {
											allEvents.push(eventItem);
										}
									} else {
										if (!allEvents.find(($0) => $0.eventId == eventItem.eventId)) {
											allEvents.push(eventItem);
										}
									}
									if (counter == total - 1) {
										return resolve(allEvents);
									}
									counter += 1;
								});
							});
						});
					} else {
						return resolve([]);
					}
				})
				.catch((error) => {
					this.isLoading = false;
					return reject(error.message);
				});
		});
	}

	static updateEvent(eventData, updateData, recurrenceData, eventStart) {
		return new Promise((resolve, reject) => {
			if (eventData && updateData) {
				const batch = writeBatch(getOrganizationFirestore());
				var baseRef = doc(
					getOrganizationFirestore(),
					`events/${eventData.eventId}`
				);
				if (eventData.classId){
					baseRef = doc(
						getOrganizationFirestore(),
						`classes/${eventData.classId}/events/${eventData.eventId}`
					);
				}

				batch.set(baseRef, updateData, { merge: true });

				if (eventData.recurrence && recurrenceData) {
					if (eventData.price) {
						if (!eventData.price.oneTimePurchase) {
							const eventRecRef = doc(
								getOrganizationFirestore(),
								`${baseRef.path}/recurrence/${eventStart}`
							);
							batch.set(eventRecRef, recurrenceData, { merge: true });
						}
					}
				}

				batch
					.commit()
					.then(() => {
						this.getAllEvents();
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				return reject();
			}
		});
	}

	static updateEventAttendance(eventData, uid, attendanceData) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			var baseRef = doc(
				getOrganizationFirestore(),
				`events/${eventData.eventId}`
			);
			if (eventData.classId){
				baseRef = doc(
					getOrganizationFirestore(),
					`classes/${eventData.classId}/events/${eventData.eventId}`
				);
			}
			var eventRef = doc(
				firestore,
				`${baseRef.path}/eventUsers/${uid}`
			);
			if (eventData.recurrence) {
				const eventStart = eventData.eventStart.toDate();
				eventStart.setMilliseconds(0);
				eventRef = doc(
					firestore,
					`${baseRef.path}/recurrence/${eventStart.getTime()}/eventUsers/${uid}`
				);
			}
			setDoc(eventRef, attendanceData, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static processEvent(eventData) {
		return new Promise((resolve, reject) => {
			var items = [];
			if (eventData) {
				if (eventData.recurrence) {
					const eventDates = Recurrence.getRecurrenceDates(
						eventData.eventStart,
						eventData.eventEnd,
						eventData.recurrence
					);
					const total = eventDates.length;
					var counter = 0;
					if (total <= 0) {
						return resolve(items);
					}
					eventDates.forEach((newEventStartDate) => {
						var newEventItem = structuredClone(eventData);
						newEventItem.eventStart = Timestamp.fromDate(newEventStartDate);
						this.checkEventRecurrence(newEventItem).then((processedEventItem) => {
							const uniqueId = `${processedEventItem.eventId}${processedEventItem.eventStart}`;
							if (!items.find(($0) => `${$0.eventId}${$0.eventStart}` == uniqueId)) {
								items.push(processedEventItem);
							}

							if (counter == total - 1) {
								return resolve(items);
							}
							counter += 1;
						});
					});
				} else {
					items.push(eventData);
					return resolve(items);
				}
			} else {
				return resolve(items);
			}
		});
	}

	static checkEventRecurrence(eventData, classId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const eventStart = eventData.eventStart.toDate();
			eventStart.setMilliseconds(0);
			var eventRef = doc(
				firestore,
				`events/${eventData.eventId}/recurrence/${eventStart.getTime()}`
			);
			if (classId){
				eventRef = doc(
					firestore,
					`classes/${classId}/events/${eventData.eventId}/recurrence/${eventStart.getTime()}`
				);
			}
			getDoc(eventRef)
				.then((snapshot) => {
					if (snapshot.exists) {
						const documentData = snapshot.data();
						this.returnRecurrenceDate(documentData, eventData).then(
							(updatedEventItem) => {
								return resolve(updatedEventItem);
							}
						);
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static returnRecurrenceDate(data, eventItem) {
		return new Promise((resolve, reject) => {
			if (data) {
				var updateEventItem = eventItem;

				if (data['eventDescription']) {
					updateEventItem.eventDescription = data['eventDescription'];
				}

				if (data['location']) {
					updateEventItem['location'] = data['location'];
				}

				if (data['eventUsers'] != null) {
					updateEventItem['eventUsers'] = data['eventUsers'];
				}

				if (data['eventMaxUsers'] != null) {
					updateEventItem['eventMaxUsers'] = data['eventMaxUsers'];
				}

				//additionalParams
				if (data['isPrepaidOption'] != null) {
					updateEventItem['isPrepaidOption'] = data['isPrepaidOption'];
				}
				if (data['isCancelled'] != null) {
					updateEventItem['isCancelled'] = data['isCancelled'];
				}
				if (data['isPrivate'] != null) {
					updateEventItem['isPrivate'] = data['isPrivate'];
				}
				if (data['isViewable'] != null) {
					updateEventItem['isViewable'] = data['isViewable'];
				}
				if (data['isSearchable'] != null) {
					updateEventItem['isSearchable'] = data['isSearchable'];
				}
				if (data['isActive'] != null) {
					updateEventItem['isActive'] = data['isActive'];
				}
				if (data['isLocked'] != null) {
					updateEventItem['isLocked'] = data['isLocked'];
				}
				if (data['isOnCalendar'] != null) {
					updateEventItem['isOnCalendar'] = data['isOnCalendar'];
				}
				if (data['requiredDocuments'] != null) {
					updateEventItem['requiredDocuments'] = data['requiredDocuments'];
				}
				if (data['transactionFee'] != null) {
					updateEventItem['transactionFee'] = data['transactionFee'];
				}
				if (data['calendarHex'] != null) {
					updateEventItem['calendarHex'] = data['calendarHex'];
				}
				if (data['shareLink'] != null) {
					updateEventItem['shareLink'] = data['shareLink'];
				}

				if (updateEventItem.price) {
					if (data['price']) {
						var updatedPriceItem = updateEventItem.price;
						var priceItem = data['price'];
						if (priceItem['purchased'] != null) {
							updatedPriceItem['purchased'] = priceItem['purchased'];
						}
						if (priceItem['quantity'] != null) {
							updatedPriceItem['quantity'] = priceItem['quantity'];
						}
						updateEventItem['price'] = updatedPriceItem;
					}
				}

				if (data['eventLeaders'] != null) {
					const eventLeadersKeys = data['eventLeaders'];
					var updatedEventsLeaders = [];
					var total = eventLeadersKeys.length;
					var counter = 0;
					eventLeadersKeys.forEach((uid) => {
						const gotUser = AppUserManager.allClassLeaders.find((e) => e.uid == uid);

						if (gotUser) {
							updatedEventsLeaders.push(gotUser.uid);
							if (counter == total - 1) {
								updateEventItem['eventLeaders'] = updatedEventsLeaders;
								return resolve(updateEventItem);
							}
							counter += 1;
						} else {
							AppUserManager.fetchUserByUid(uid).then((appUser) => {
								if (appUser) {
									updatedEventsLeaders.push(appUser.uid);
								}
								if (counter == total - 1) {
									updateEventItem['eventLeaders'] = updatedEventsLeaders;
									return resolve(updateEventItem);
								}
								counter += 1;
							});
						}
					});
				} else {
					return resolve(updateEventItem);
				}
			} else {
				return resolve(eventItem);
			}
		});
	}

	static getEventById(eventId, eventStart, classId) {
		return new Promise((resolve, reject) => {
			var eventRef = doc(getOrganizationFirestore(), `events/${eventId}`);
			if (classId){
				eventRef = doc(getOrganizationFirestore(), `classes/${classId}/events/${eventId}`);
			}
			getDoc(eventRef)
				.then((snapshot) => {
					if (snapshot.exists) {
						const documentData = snapshot.data();
						this.processEvent(documentData)
							.then((eventItems) => {
								if (eventItems) {
									const gotEvent = eventItems.find((e) => {
										const eventStartCheck = e.eventStart.toDate();
										eventStartCheck.setMilliseconds(0);
										return eventStartCheck.getTime() == eventStart;
									});

									if (gotEvent) {
										return resolve(gotEvent);
									} else {
										return resolve(eventItems[0]);
									}
								} else {
									return resolve(null);
								}
							})
							.catch(() => {
								return resolve(null);
							});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static getEventUsers(eventData) {
		return new Promise((resolve, reject) => {
			var eventRef = collection(
				getOrganizationFirestore(),
				`events/${eventData.eventId}/eventUsers`
			);
			if (eventData.recurrence) {
				if (eventData.price) {
					if (!eventData.price.oneTimePurchase) {
						const eventStart = eventData.eventStart.toDate();
						eventStart.setMilliseconds(0);
						eventRef = collection(
							getOrganizationFirestore(),
							`events/${
								eventData.eventId
							}/recurrence/${eventStart.getTime()}/eventUsers`
						);
					}
				}
			}
			getDocs(eventRef)
				.then((snapshot) => {
					if (snapshot.empty) {
						return resolve(null);
					} else {
						var items = [];
						var total = snapshot.size;
						var counter = 0;
						snapshot.forEach((document) => {
							const documentData = document.data();
							if (documentData.uid) {
								AppUserManager.fetchUserByUid(documentData.uid).then((user) => {
									if (user) {
										items.push({
											userData: user,
											eventData: documentData,
										});
									}
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								});
							}
						});
					}
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static cancelEvent(eventData) {
		return new Promise((resolve, reject) => {
			var baseRef = doc(
				getOrganizationFirestore(),
				`events/${eventData.eventId}`
			);
			if (eventData.classId){
				baseRef = doc(
					getOrganizationFirestore(),
					`classes/${eventData.classId}/events/${eventData.eventId}`
				);
			}
			var eventRef = baseRef

			if (eventData.recurrence) {
				if (eventData.price) {
					if (!eventData.price.oneTimePurchase) {
						const eventStart = eventData.eventStart.toDate();
						eventStart.setMilliseconds(0);
						eventRef = doc(
							getOrganizationFirestore(),
							`${baseRef.path}/recurrence/${eventStart.getTime()}`
						);
					}
				} else {
					const eventStart = eventData.eventStart.toDate();
					eventStart.setMilliseconds(0);
					eventRef = doc(
						getOrganizationFirestore(),
						`${baseRef.path}/recurrence/${eventStart.getTime()}`
					);
				}
			}
			setDoc(eventRef, { isCancelled: true }, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteEvent(eventData) {
		return new Promise((resolve, reject) => {
			var baseRef = doc(
				getOrganizationFirestore(),
				`events/${eventData.eventId}`
			);
			if (eventData.classId){
				baseRef = doc(
					getOrganizationFirestore(),
					`classes/${eventData.classId}/events/${eventData.eventId}`
				);
			}
			deleteDoc(baseRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static adjustUserAttendance(userData, eventData, isEventMember) {
		return new Promise((resolve, reject) => {
			if (isEventMember) {
				/*
				 */
			} else {
				//remove user from group
				this.removeUserFromEvent(userData, eventData)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			}
		});
	}

	static manuallyAddUsersToEvent(users, eventData) {
		return new Promise((resolve, reject) => {
			const batch = writeBatch(getOrganizationFirestore());
			const total = users.length;
			var counter = 0;
			var batchItems = [];
			users.forEach((userData) => {
				var eventMainRef = doc(
					getOrganizationFirestore(),
					`events/${eventData.eventId}`
				);
				var eventUserRef = doc(
					getOrganizationFirestore(),
					`events/${eventData.eventId}/eventUsers/${userData.uid}`
				);
				if (eventData.recurrence) {
					if (eventData.price) {
						if (!eventData.price.oneTimePurchase) {
							const eventStart = eventData.eventStart.toDate();
							eventStart.setMilliseconds(0);
							eventMainRef = doc(
								getOrganizationFirestore(),
								`events/${eventData.eventId}/recurrence/${eventStart.getTime()}`
							);
							eventUserRef = doc(
								getOrganizationFirestore(),
								`events/${
									eventData.eventId
								}/recurrence/${eventStart.getTime()}/eventUsers/${userData.uid}`
							);
						}
					}
				}
				const userInfo = {
					uid: userData.uid,
					quantity: 1,
				};
				batchItems.push(batch.set(eventUserRef, userInfo, { merge: true }));
				if (counter == total - 1) {
					var updatedInfo = {
						eventUsers: increment(users.length),
					};

					if (eventData.price) {
						var updatedPrice = {
							purchased: increment(users.length),
						};
						if (eventData.price.quantity) {
							const eventQuantity = eventData.price.quantity;
							updatedPrice['quantity'] = eventQuantity - users.length;
						}
						updatedInfo['price'] = updatedPrice;
					}

					batch.set(eventMainRef, updatedInfo, { merge: true });
					batch
						.commit()
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error);
						});
				}
				counter += 1;
			});
		});
	}

	static removeUserFromEvent(userData, eventData) {
		return new Promise((resolve, reject) => {
			const batch = writeBatch(getOrganizationFirestore());
			var eventMainRef = doc(
				getOrganizationFirestore(),
				`events/${eventData.eventId}`
			);
			var eventUserRef = doc(
				getOrganizationFirestore(),
				`events/${eventData.eventId}/eventUsers/${userData.uid}`
			);
			if (eventData.recurrence) {
				if (eventData.price) {
					if (!eventData.price.oneTimePurchase) {
						const eventStart = eventData.eventStart.toDate();
						eventStart.setMilliseconds(0);
						eventMainRef = doc(
							getOrganizationFirestore(),
							`events/${eventData.eventId}/recurrence/${eventStart.getTime()}`
						);
						eventUserRef = doc(
							getOrganizationFirestore(),
							`events/${
								eventData.eventId
							}/recurrence/${eventStart.getTime()}/eventUsers/${userData.uid}`
						);
					}
				}
			}

			batch.delete(eventUserRef);

			var updatedInfo = {
				eventUsers: increment(-1),
			};

			if (eventData.price) {
				var updatedPrice = {
					purchased: increment(-1),
				};
				if (eventData.price.quantity) {
					const eventQuantity = eventData.price.quantity;
					updatedPrice['quantity'] = eventQuantity + 1;
				}
				updatedInfo['price'] = updatedPrice;
			}

			batch.set(eventMainRef, updatedInfo, { merge: true });

			batch.set(
				eventMainRef,
				{
					eventUsers: increment(-1),
					price: {
						purchased: increment(-1),
						quantity: increment(1),
					},
				},
				{ merge: true }
			);
			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static changeEventImage(eventFile, eventData) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const eventRef = doc(firestore, `events/${eventData.eventId}`);
			const newEventStoreref = ref(storage, `events/${eventData.eventId}`);
			const metadata = {
				contentType: 'image/jpg',
			};
			uploadBytes(newEventStoreref, eventFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							setDoc(
								eventRef,
								{
									eventImages: [downloadURL],
								},
								{ merge: true }
							)
								.then(() => {
									return resolve();
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addEvent(eventFile, eventData, classId) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			var eventRef = collection(firestore, `events`);
			if (classId){
				eventRef = collection(firestore, `classes/${classId}/events`)
			}
			const newEventRef = doc(eventRef);
			var newEventStorageRef = ref(storage, `events/${newEventRef.id}`);
			if (classId){
				newEventStorageRef = ref(storage, `classes/${classId}/events/${newEventRef.id}`)
			}
			const metadata = {
				contentType: 'image/jpg',
			};

			uploadBytes(newEventStorageRef, eventFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							var data = eventData;

							if (data.isFeatured && !classId) {
								const newFeaturedRef = doc(collection(firestore, `featured`));
								var featuredData = {
									featuredId: newFeaturedRef.id,
									featuredItemId: newEventRef.id,
									featuredItemName: 'eventItem',
									imageItem: {
										imageUrl: downloadURL,
									},
									index: 0,
								};
								setDoc(newFeaturedRef, featuredData).then(() => {
									this.addEventToDB(eventData, downloadURL, newEventRef)
										.then(() => {
											return resolve(data);
										})
										.catch((error) => {
											return reject(error.message);
										});
								});
							} else {
								this.addEventToDB(eventData, downloadURL, newEventRef)
									.then(() => {
										return resolve(data);
									})
									.catch((error) => {
										return reject(error.message);
									});
							}
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addEventToDB(data, downloadURL, newEventRef) {
		return new Promise((resolve, reject) => {
			var eventImages = [downloadURL];
			data['eventId'] = newEventRef.id;
			data['eventImages'] = eventImages;

			const urlExtended = `event?&eventId=${newEventRef.id}&eventStart=${data.eventStart.time}`;

			FirebaseManager.createDynamicLink(
				urlExtended,
				data.eventTitle,
				downloadURL
			).then((shareLink) => {
				if (shareLink) {
					data['shareLink'] = shareLink;
				}

				setDoc(newEventRef, data)
					.then(() => {
						return resolve(data);
					})
					.catch((error) => {
						return reject(error);
					});
			});
		});
	}
}
