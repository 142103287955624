<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			/>
			<div class="table-responsive">
				<b-table
					:fields="tableHeaders"
					:items="tableData"
					:busy="isLoading"
					:filter="filter"
					small
					hover
					responsive="sm"
					@row-clicked="rowClicked"
					class="px-1"
				>
					<template #table-busy>
						<b-overlay :show="isLoading" rounded="sm" no-wrap />
					</template>
					<template #cell(classTitle)="data">
						<div class="d-flex align-items-center" style="gap: 5px">
							<VueAspectRatio ar="16:9" style="width: 80px">
								<Card no-body class="h-100">
									<b-img
										fluid-grow
										rounded
										:src="data.item.classImages[0]"
										style="height: 100%; object-fit: cover"
									/>
								</Card>
							</VueAspectRatio>
							<div
								style="
									max-width: 150px;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								"
							>
								{{ data.item.classTitle }}
							</div>
						</div>
					</template>
					<template #cell(isPrivate)="data">
						<BaseSwitch
							:value="data.value"
							:isDisabled="true"
							onText="Private"
							offText="Public"
							:type="data.value ? 'success' : 'primary'"
						/>
					</template>
					<template #cell(isActive)="data">
						<BaseSwitch
							:value="data.value"
							:isDisabled="true"
							:type="data.value ? 'success' : 'primary'"
						/>
					</template>
					<template #cell(actions)="row">
						<BaseButton v-if="true" size="sm" outline @click="viewClicked(row.item)"
							>View</BaseButton
						>
						<BaseButton
							v-if="false"
							size="sm"
							type="danger"
							@click="deleteClicked(row.item)"
							>Report</BaseButton
						>
					</template>
				</b-table>
			</div>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import Recurrence from '../../../models/DataModels/Reccurrence';

export default {
	components: {
		VueAspectRatio,
		BaseButton,
		BaseSwitch,
		BaseInput,
		Card,
		CardHeader,
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		filter: null,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
			default() {
				return [
					{
						key: 'classTitle',
						label: 'Title',
						sortable: true,
						tdClass: 'align-middle',
						sortByFormatted: (value, key, item) => {
							return item.classTitle;
						},
					},
					{
						key: 'recurrence',
						label: 'Class Recurrence',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							return this.getRecurrence(item);
						},
					},
					{
						key: 'classStart',
						label: 'Start Date',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							return `${this.$helpers.formatDate(value.toDate(), 'MMMM dd, yyyy')}`;
						},
					},
					{
						key: 'classUsers',
						label: 'Enrollment',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							const classMaxUsers =
								item.classMaxUsers != null ? `${item.classMaxUsers}` : `Unlimited`;
							return `${value != null ? value : '0'} / ${classMaxUsers}`;
						},
					},
					{
						key: 'pendingUsers',
						label: 'Pending',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							const pendingUsers =
								item.pendingUsers != null ? `${item.pendingUsers}` : `0`;
							return `${pendingUsers}`;
						},
					},
					{
						key: 'isPrivate',
						label: 'Privacy',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							const isPrivate = item.isPrivate != null ? item.isPrivate : false;
							return isPrivate;
						},
					},
				];
			},
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		getRecurrence(data) {
			if (data.recurrence) {
				return Recurrence.returnReadableString(data.recurrence);
			} else {
				return '';
			}
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('viewClicked', item);
		},
	},
};
</script>
