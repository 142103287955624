import {
	deleteDoc,
	doc,
	getDocs,
	increment,
	query,
	setDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import FirebaseManager from '../Firebase/FirebaseManager';

export default class CouponItemManager {
	static isLoading = false;
	static allCoupons = [];

	static getAllCoupons() {
		return new Promise((resolve, reject) => {
			this.allCoupons = [];
			this.isLoading = true;
			const couponsRef = FirebaseManager.allCouponsRef(getOrganizationFirestore());
			getDocs(couponsRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							const documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								this.isLoading = false;
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						this.isLoading = false;
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.isLoading = false;
					return resolve(null);
				});
		});
	}

	static deleteCoupon(couponId) {
		return new Promise((resolve, reject) => {
			const ref = doc(
				getOrganizationFirestore(),
				`${
					FirebaseManager.allCouponsRef(getOrganizationFirestore()).path
				}/${couponId}`
			);
			deleteDoc(ref)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deactivateCoupon(couponData) {
		return new Promise((resolve, reject) => {
			const ref = doc(
				getOrganizationFirestore(),
				`${FirebaseManager.allCouponsRef(getOrganizationFirestore()).path}/${
					couponData.couponId
				}`
			);
			setDoc(
				ref,
				{ isValid: couponData.isValid != null ? !couponData.isValid : false },
				{ merge: true }
			)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addCouponTODB(data) {
		return new Promise((resolve, reject) => {
			const newCouponRef = doc(
				FirebaseManager.allCouponsRef(getOrganizationFirestore())
			);

			data['couponId'] = newCouponRef.id;

			const urlExtended = `coupon?&couponId=${newCouponRef.id}`;

			FirebaseManager.createDynamicLink(urlExtended, data.couponCode).then(
				(shareLink) => {
					if (shareLink) {
						data['shareLink'] = shareLink;
					}
					setDoc(newCouponRef, data)
						.then(() => {
							return resolve(data);
						})
						.catch((error) => {
							return reject(error);
						});
				}
			);
		});
	}

	static fetchCouponByCode(couponCode) {
		return new Promise((resolve, reject) => {
			const couponsRef = FirebaseManager.allCouponsRef(getOrganizationFirestore());
			//const queryRef = query(eventRef, where('eventEnd', '>=', new Date()));
			const couponQuery = query(
				couponsRef,
				where('couponCode', '==', couponCode),
				where('isValid', '==', true)
			);
			getDocs(couponQuery)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documentSnap = snapshot.docs[0].data();
						if (documentSnap.isUserLimited) {
							return resolve(null);
						} else {
							return resolve(documentSnap);
						}
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					return resolve(null);
				});
		});
	}

	static redeemCoupon(uid, couponId, chargeId, chargeTimestamp) {
		return new Promise((resolve, reject) => {
			const itemInfo = {
				uid: uid,
				chargeId: chargeId,
				timestamp: chargeTimestamp,
			};

			const batch = writeBatch(getOrganizationFirestore());
			const couponRef = doc(
				getOrganizationFirestore(),
				`${
					FirebaseManager.allCouponsRef(getOrganizationFirestore()).path
				}/${couponId}`
			);
			const redeemRef = doc(
				getOrganizationFirestore(),
				`${couponRef.path}/couponRedeemed/${chargeId}`
			);

			batch.set(
				couponRef,
				{
					redeemed: increment(1),
				},
				{ merge: true }
			);
			batch.set(redeemRef, itemInfo);
			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					console.log(error.message);
					return reject();
				});
		});
	}

	static getDiscountAmount(couponData) {
		if (couponData.amountOff) {
			const discountText = `${helpers.amountToString(couponData.amountOff)} off`;
			return [parseFloat(couponData.amountOff.toFixed(2) / 100), discountText];
		} else if (couponData.percentOff) {
			const discountText = `${couponData.percentOff}% off`;
			return [parseFloat(couponData.percentOff.toFixed(2) / 100), discountText];
		}
		return [0, null];
	}
}
