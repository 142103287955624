<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
				style="overflow: hidden"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(bookName)="row">
					{{ row.item.bookFirstName }} {{ row.item.bookLastName }}
				</template>
				<template #cell(bookingDate)="row">
					{{
						$helpers.formatDate(
							row.item.bookingDate.toDate(),
							"MMMM dd, yyyy 'at' HH:mm a"
						)
					}}
				</template>
				<template #cell(timestamp)="row">
					{{ $helpers.formatDate(row.item.timestamp.toDate()) }}
				</template>
				<template #cell(actions)="row">
					<b-dropdown id="actions" variant="null" dropleft no-caret>
						<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
						<b-dropdown-item @click="adjustActiveClicked(row.item)">{{
							row.item.isValid ? 'Deactivate' : 'Activate'
						}}</b-dropdown-item>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item variant="danger" @click="deleteClicked(row.item)">{{
							row.item.redeemed != null ? 'Archive' : 'Delete'
						}}</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import store from '../../../store/store';
import BookingItemManager from '../../../models/BookingItem/BookingItemManager';

export default {
	components: { Card, BaseButton, BaseInput, BaseSwitch },
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
			bookingInformation: 'getBookingInformation',
		}),
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {
			filter: null,
			appLogo: null,
			allBookingTypes: [],
			tableHeaders: []
		};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		adjustActiveClicked(item) {
			this.$emit('adjustActiveClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('rowClicked', item);
		},
		getBookingTypes(){
			this.isLoading = true
			BookingItemManager.getAllBookingTypes().then((data) => {
				this.allBookingTypes = data
				this.setupTableHeaders()
			})
		},
		returnLocation(value){
			const bookingLocations = this.bookingInformation.bookingLocations;
			if (bookingLocations && value){
				const found = bookingLocations.find((e) => e.locationId == value)
				if (found){
					return found.title
				} else {
					return 'No Location Selected'
				}
			}
			return null
		},
		setupTableHeaders(){
			this.tableHeaders = [
					{
						key: 'bookName',
						label: 'Name',
						sortable: true,

						tdClass: 'align-middle',
					},
					{
						key: 'bookingEmail',
						label: 'Email',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'bookingDate',
						label: 'Booking Date',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'bookingPhone',
						label: 'Phone',
						sortable: false,
						tdClass: 'align-middle',
					},
					{
						key: 'bookingType',
						label: 'Type',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'bookingOption',
						label: 'Option',
						sortable: true,
						tdClass: 'align-middle',
						
					},
					
					{
						key: 'timestamp',
						label: 'Date',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'bookingMessage',
						label: 'Message',
						sortable: false,

						tdClass: 'align-middle',
					},
				];
				if (this.bookingInformation.bookingLocations){
					this.tableHeaders.push({
						key: 'bookingLocation',
						label: 'Location',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							return this.returnLocation(value)
						},
					})
				} else 
				this.isLoading = false
		}
	},
	mounted() {
		this.getBookingTypes()
		//this.appLogo = store.state.organization.appLogo;
	},
};
</script>
