<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<Card>
			<CardHeader
				slot="header"
				title="Calendar"
				:end-button="{ title: 'New Event', disabled: false }"
				@click="openNewEventView"
			/>
			<b-row>
				<b-col>
					<b-overlay :show="isLoading" rounded="sm">
                        <CalendarView :calendarData="calendarData" @next="next" @prev="prev" @event-click="eventClicked"/>
                    </b-overlay>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import NewResourceItem from '../../mainForms/NewResourceItem.vue';
import ClassItemManager from '../../../models/ClassItem/ClassItemManager'
import CalendarView from '../../../components/Calendar/CalendarView.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import EventManager from '../../../models/EventItem/EventManager';


export default {
	props: {
		classData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
            showQuestionModal: false,
            modalQuestion: null,
            selectedEvent: null,
			allEvents: [],
			calendarEvents: [],
			previouslyAdded: [],
            calendarData: {
				title: null,
				events: []
			},
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					//this.getCalendarItems();
				}
			},
		},
	},
	methods: {
        ...mapActions(['showNotification']),
		openNewResourceView() {
			this.showNewResouceModal = false;
			this.showNewResouceModal = true;
		},
		getCalendarItems() {
			this.isLoading = true
			this.allEvents = [];
			EventManager.getAllClassEvents(this.classData.classId).then((allItems) => {
				this.allEvents = allItems
				this.setupCalendarItems();
			});
		},
		setupCalendarItems(){
			if (this.allEvents.length > 0){
				this.allEvents.forEach((eventData) => {
					const accountInfo = this.$store.state.accountInformation;
					const yearFromDate = this.$helpers.addYear(new Date(), 1);
					if (eventData) {
						if (!eventData.eventId){return}
						const id = `${eventData.eventId}${eventData.eventStart.toDate()}`;
						const title = eventData.eventTitle;
						const start = eventData.eventStart.toDate();
						const end = this.$helpers.addSeconds(start, eventData.eventDuration);
						const calendarColor = eventData.calendarHex
							? eventData.calendarHex
							: accountInfo.appInformation.calendarColors.eventColor;
						const calendarObject = {
							id: id,
							title: title,
							start: start,
							end: end,
							extendedProps: {
								eventData: eventData,
							},
							backgroundColor: calendarColor,
						};
						if (calendarObject.start > yearFromDate) {
							return;
						}
						this.calendarEvents.push(calendarObject);
					}
				})
				this.addCalendarItems(new Date());
			} else {
				this.allEvents = []
				this.calendarData.events = []
				this.isLoading = false
			}
		},
		addCalendarItems(newDate){
			this.isLoading = true;
			var currentMonthDate = newDate
			currentMonthDate.setDate(1);
			currentMonthDate.setHours(0, 0, 0, 0);
			var endMonthDate = new Date(
				currentMonthDate.getFullYear(),
				currentMonthDate.getMonth() + 1,
				24
			);
			const thisMonthsItems = this.calendarEvents.filter(
				(e) =>
					e.start >= currentMonthDate &&
					e.start <= endMonthDate &&
					!this.previouslyAdded.find((pe) => e.id == pe.id)
			);
			thisMonthsItems.forEach((item) => {
				this.previouslyAdded.push(item);
				this.calendarData.events.push(item)
			});
			this.isLoading = false;
		},
        next(newDate) {
			this.isLoading = true;
			this.getCalendarItems(newDate);
		},
		prev(newDate) {
			this.isLoading = true;
			this.getCalendarItems(newDate);
		},
        eventClicked(info){
			const eventSelectedStart = info.event.start.getTime();
			if (info.event.extendedProps.eventData) {
				this.openEvent(eventSelectedStart, info.event.extendedProps.eventData);
			} else if (info.event.extendedProps.calendarData) {
				this.$emit("calendarItemClicked", info.event.extendedProps.calendarData);
			}
        },
		openEvent(eventStart, eventData) {
			this.$router.push({
				name: DashboardChildren.eventView.name,
				params: {
					eventId: eventData.eventId,
					eventData: eventData,
				},
				query: {
					eventStart: eventStart,
					classId: this.classData.classId,
					classTitle: this.classData.classTitle
				},
			});
		},
        deleteClicked(item) {
			this.modalQuestion = 'Are you sure you want to delete this resource?';
			this.selectedResource = item;
			this.showQuestionModal = true;
		},
		viewClicked(item) {
			
		},
		openNewEventView(item){
			this.$router.push({
				name: DashboardChildren.newEventView.name,
				query: {
					classId: this.classData.classId,
					classTitle: this.classData.classTitle
				},
			});
		},
        deleteResource() {
			this.isLoading = true;
            
		},
        onNewResource(resource) {
			
		},
	},
	mounted(){
		this.getCalendarItems();
	},
	components: { Card, CardHeader, NewResourceItem, QuestionModal, CalendarView },
};
</script>