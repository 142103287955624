import { AccountInformation } from '@/models/AccountInformation/AccountInformation';
import {
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
} from 'firebase/auth';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import {
	AppInformation,
	PaymentInformation,
	PublicInformation,
} from '../models/AccountInformation/AccountInformation';
import FirebaseManager from '../models/Firebase/FirebaseManager';
import { auth, firestore } from '../resources/firebase';
import { getOrganizationFirestore } from '../resources/orgFirebase';
import { loadStripeTerminal } from '@stripe/terminal-js';
import PaymentManager from '../models/Payment-Billing/PaymentManager';
import store from './store';

const actions = {
	getUserData({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			const user = data.user ? data.user : data
			const isSuperAdmin = data.isSuperAdmin ? data.isSuperAdmin : false
			const userRef = doc(firestore, 'users', user.uid);
			getDoc(userRef)
				.then((snapshot) => {
					const userData = snapshot.data();
					const orgId = data.orgId ? data.orgId : userData.orgId
					if (userData.isSuperAdmin != null){
						if (isSuperAdmin && !userData.isSuperAdmin){
							dispatch('logOut');
							reject();
							return;
						}
					}
					if (orgId) {
						var completeUserData = userData
						completeUserData.orgId = orgId
						const orgRef = doc(firestore, 'organizations', orgId); 
						getDoc(orgRef).then((organizationSnapshot) => {
							const orgData = organizationSnapshot.data();
							commit('setUser', completeUserData);
							commit('setOrganization', orgData);
							resolve();
						});
					}
				})
				.catch(() => {
					dispatch('logOut');
					reject();
				});
		});
	},
	getAllOrganizations({commit}){
		return new Promise((resolve, reject) => {
			const orgsRef = collection(firestore, "organizations")
			getDocs(orgsRef).then((snapshot) => {
				const orgsData = snapshot.docs.map((doc) => doc.data())
				resolve(orgsData)
			}).catch((error) => {
				reject(error.message);
			});
		})
	},
	getAppInformation({ commit }) {
		return new Promise((resolve, reject) => {
			if (!store.state.organization) {
				return reject(null);
			}
			const firestore = getOrganizationFirestore();
			const accountRef = FirebaseManager.accountRef(firestore);
			getDocs(accountRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					var counter = 0;
					var accountInformation = AccountInformation.accountInformation;
					documents.forEach((document) => {
						const documentData = document.data();
						if (
							document.ref.path ==
							FirebaseManager.accountAppInformationRef(firestore).path
						) {
							accountInformation.appInformation =
								AppInformation.returnData(documentData);
							commit('setAppInformation', documentData);
						} else if (
							document.ref.path ==
							FirebaseManager.accountPaymentInformationRef(firestore).path
						) {
							accountInformation.paymentInformation =
								PaymentInformation.returnData(documentData);
							commit('setPaymentInformation', documentData);
						} else if (
							document.ref.path ==
							FirebaseManager.accountPublicInformationRef(firestore).path
						) {
							accountInformation.publicInformation =
								PublicInformation.returnData(documentData);
							commit('setPublicInformation', documentData);
						} else if (
							document.ref.path ==
							FirebaseManager.accountSocialInformationRef(firestore).path
						) {
							commit('setSocialInformation', documentData);
						} else if (
							document.ref.path ==
							FirebaseManager.accountBookingInformationRef(firestore).path
						) {
							commit('setBookingInformation', documentData);
						}
						if (counter == total - 1) {
							commit('setAccountInformation', accountInformation);
							return resolve(accountInformation);
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
		/*
		return AccountInformation.getAccountInformation()
			.then((accountInfo) => {
				commit('setAccountInformation', accountInfo);
			})
			.catch((error) => {
				console.log(error);
			});
			*/
	},
	getTerminalReaders() {
		return new Promise((resolve, reject) => {
			if (!store.state.stripeTerminal) {
				return reject('No Stripe Terminal');
			} else {
				return store.state.stripeTerminal.discoverReaders().then((result) => {
					if (result.error) {
						return reject('Failed to discover: ', discoverResult.error);
					} else if (result.discoveredReaders.length === 0) {
						return reject('No available readers. Please try again.');
					} else {
						const allReaders = result.discoveredReaders;
						store.commit('setStripeTerminalReaders', allReaders);
						return resolve();
					}
				});
			}
		});
	},
	showNotification({ commit }, data) {
		commit('setNotification', data);
	},
	removeNotification({ commit }) {
		commit('setNotification', null);
	},

	forgotPassword({ commit }, data) {
		return new Promise((resolve, reject) => {
			return sendPasswordResetEmail(auth, data.email)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	},
	login({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			signInWithEmailAndPassword(auth, data.email, data.password)
				.then((response) => {
					var getUserData = {
						user: response.user,
						isSuperAdmin: data.isSuperAdmin,
						orgId: data.orgId
					}
					dispatch('getUserData', getUserData);
					resolve(response);
				})
				.catch((error) => {
					reject(error.message);
				});
		});
	},
	logOut({ commit }) {
		return new Promise((resolve, reject) => {
			signOut(auth)
				.then(() => {
					commit('setUser', null);
					resolve();
				})
				.catch((error) => {
					reject(error.message);
				});
		});
	},
};

export default actions;
