<template>
	<div>
		<b-list-group :flush="isHeader">
			<b-list-group-item
				button
				v-for="notification in allNotifications"
				v-on:click="viewNotification"
			>
				<b-row>
					<b-col class="pl-3 pr-1 pb-0" cols="auto">
						<!-- Avatar -->
						<b-img
							alt="Image placeholder"
							:src="publicInformation.appLogo"
							class="avatar"
							rounded="circle"
						/>
					</b-col>
					<b-col class="pl-2 pr-0 pb-0">
						<div class="d-flex align-items-center">
							<div>
								<h4 class="mb-0 mr-1 text-sm">
									{{
										notification.title ? notification.title : publicInformation.appName
									}}
								</h4>
							</div>
							<div class="text-right text-muted">
								<small
									>-
									{{ $helpers.timeSince(notification.uploadTimestamp.toDate()) }}
								</small>
							</div>
						</div>
						<p class="text-sm mb-0">
							{{ notification.message }}
						</p>
					</b-col>
					<b-col v-if="!isHeader" class="px-2 pb-0" cols="auto">
						<b-dropdown
							:id="notification.messageId"
							variant="link"
							size="sm"
							class="m-2"
							dropleft
							no-caret
						>
							<template v-slot:button-content>
								<span class="btn btn-sm btn-icon-only text-light">
									<i class="fas fa-ellipsis-v mt-2"></i>
								</span>
							</template>
							<b-dropdown-item-button v-on:click="editNotification(notification)"
								>Edit</b-dropdown-item-button
							>
							<b-dropdown-item-button
								variant="danger"
								v-on:click="deleteNotification(notification)"
								>Delete</b-dropdown-item-button
							>
						</b-dropdown>
					</b-col>
				</b-row>
			</b-list-group-item>
		</b-list-group>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import store from '../../../store/store';

export default {
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
	},
	props: {
		allNotifications: [],
		isHeader: false,
	},
	components: { Card },
	methods: {
		viewNotification() {
			this.$emit('viewNotification');
		},
		editNotification(notification) {
			this.$emit('editNotification', notification);
		},
		deleteNotification(notification) {
			this.$emit('deleteNotification', notification);
		},
	},
	mounted() {},
};
</script>
