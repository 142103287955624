<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<BookingTable
						:isLoading="isLoading"
						:tableData="allBooking"
						@rowClicked="rowClicked"
					/>
				</b-col>
			</b-row>
		</div>
		<BookingView
			:showModal="showBookingModal"
			:bookingData="bookingData"
			@close="showBookingModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BookingTable from '../../Components/BookingViews/BookingTable.vue';
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';
import BookingItemManager from '../../../models/BookingItem/BookingItemManager';
import BookingView from '../../mainForms/BookingView.vue';

export default {
	data() {
		return {
			isLoading: false,
			allBooking: [],
			allBookingTypes: [],
			buttonItems: [],
			showBookingModal: false,
			bookingData: null,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {},
		getBookingTypes(){
			this.isLoading = true
			BookingItemManager.getAllBookingTypes().then((data) => {
				this.allBookingTypes = data
				this.getBookingItems()
			})
		},
		getBookingItems() {
			this.isLoading = true;
			BookingItemManager.getAllBooking().then(() => {
				this.allBooking = BookingItemManager.allBookings;
				this.isLoading = false;
			});
		},
		rowClicked(item) {
			this.bookingData = item;
			this.showBookingModal = true;
		},
	},
	mounted() {
		this.getBookingTypes();
	},
	components: { DashboardHeader, BookingTable, BookingView },
};
</script>
