<template>
	<div>
		<Notifications />
		<!-- Page content -->

		<b-container class="my-8 px-5">
			<b-row class="justify-content-center my-4 mx-4">
				<b-col lg="5">
					<b-img fluid src="img/logo/LogoHorizontal_Shadow.png" class="px-4" />
				</b-col>
			</b-row>
			<b-row class="justify-content-center">
				<b-col lg="7">
					<Card>
						<validation-observer v-slot="{ handleSubmit }" ref="formValidator">
							<b-form @submit.prevent="handleSubmit(onSubmit)">
								<BaseInput
									name="Email"
									:rules="{ required: true }"
									placeholder="Email"
									v-model="model.email"
								/>
								<BaseInput
									name="Password"
									:rules="{ required: true, min: 6 }"
									placeholder="Password"
									type="password"
									v-model="model.password"
								/>
								<b-row class="mb-3 mb-0">
									<b-col>
										<BaseButton type="button" class="p-0" @click="onForgotPassword" link>
											<small>Forgot Password?</small>
										</BaseButton>
									</b-col>
								</b-row>
								<BaseButton type="primary" native-type="submit" block
									>Sign In</BaseButton
								>
							</b-form>
						</validation-observer>
						<hr />
						<b-row class="justify-content-center">
							<a>
								<small class="text-light">Not a registered admin user?&nbsp;</small></a
							>
							<a href="mailto:support@dominionstudio.com" target="_blank"
								><small>Contact Us</small></a
							>
						</b-row>
					</Card>
				</b-col>
			</b-row>
			<b-row class="justify-content-center">
				<a
					:href="DominionInformation.dominionWebsite + '/service-agreement'"
					target="_blank"
					class="text-light"
					><small>Terms of Service&nbsp;·&nbsp;</small></a
				>
				<a
					:href="DominionInformation.dominionWebsite + '/privacy-policy'"
					class="text-light"
					target="_blank"
					><small>&nbsp;Privacy Policy&nbsp;·&nbsp;</small></a
				>
				<a
					:href="DominionInformation.dominionSupport + '/privacy-policy'"
					class="text-light"
					target="_blank"
					><small>&nbsp;Support</small></a
				>
			</b-row>
		</b-container>
		<ForgotPassword
			:show-modal="showForgotPassword"
			@close="showForgotPassword = false"
		/>
		<SelectOrganization
			:show-modal="showOrganizations"
			:options="organizations"
			@submit="onSelectOrganization"
			@close="showOrganizations = false" />
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import Card from '../../components/Cards/Card.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import BaseButton from '../../components/BaseButton.vue';
import ForgotPassword from '../mainForms/ForgotPassword.vue';
import SelectOrganization from '../mainForms/SelectOrganization.vue';
import { DominionInformation } from '../../resources/DominionInformation';

export default {
	name: 'login',
	props: {
		isSuperAdmin: {
			default: false
		},
	},
	components: {
		Notifications,
		Card,
		BaseInput,
		BaseButton,
		ForgotPassword,
		SelectOrganization
	},
	data() {
		return {
			isLoading: false,
			showOrganizations: false,
			orgId: null,
			DominionInformation,
			showForgotPassword: false,
			organizations: [],
			model: {
				email: '',
				password: '',
				rememberMe: false,
			},
		};
	},
	methods: {
		...mapActions(['login', 'getAllOrganizations']),
		onForgotPassword() {
			this.showForgotPassword = true;
			this.showOrganizations = false;
		},
		onSelectOrganization(orgId){
			this.orgId = orgId
			console.log(this.orgId)
		},
		onSubmit() {
			this.isLoading = true;
			if (this.isSuperAdmin && this.orgId == null){
				this.showOrganizations = true
				return this.getAllOrganizations({}).then((organizations) => {
					return organizations.forEach((org) => {
						this.organizations.push({
							key: org.orgId,
							value: org.appName
						})
					})
				}).catch((errorMessage) => {
					this.$notify({
						verticalAlign: 'top',
						horizontalAlign: 'center',
						type: 'danger',
						message: errorMessage,
					});
					return
				})
			} else if (this.isSuperAdmin && this.orgId){
				this.showOrganizations = false
			}
			this.login({
				email: this.model.email,
				password: this.model.password,
				isSuperAdmin: this.isSuperAdmin,
				orgId: this.orgId
			})
				.then(() => {
					this.isLoading = false;
					this.$router.push('/');
				})
				.catch((error) => {
					this.isLoading = false;
					this.$notify({
						verticalAlign: 'top',
						horizontalAlign: 'center',
						type: 'danger',
						message: 'The username or password you entered is incorrect.',
					});
				});
		},
	},
};
</script>
