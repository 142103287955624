import { deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";
import { getOrganizationFirestore, getOrganizationStorage } from "../../resources/orgFirebase";
import FirebaseManager from "../Firebase/FirebaseManager";
import mimiDB from 'mime-db';
import { ref } from "firebase/storage";

export default class ResourceItemManager {
	static isLoading = false;
	static allResources = [];

	static getAllResources() {
		return new Promise((resolve, reject) => {
			this.allResources = [];
			this.isLoading = true;
			const firestore = getOrganizationFirestore();
			const resourceRef = FirebaseManager.allResourceRef(firestore);
			getDocs(resourceRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							if (!this.allResources.find(($0) => $0.resourceId == documentData.resourceId)) {
								this.allResources.push(documentData);
							}
							if (counter == total - 1) {
								this.isLoading = false;
								return resolve();
							}
							counter += 1;
						});
					} else {
						this.isLoading = false;
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.isLoading = false;
					return resolve(null);
				});
		});
	}

	static addResourceToDB(fileData, file, fileName, fileType,loadingCallback) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
            const storage = getOrganizationStorage()
			const resourceRef = FirebaseManager.allResourceRef(firestore);
			const newFileRef = doc(resourceRef);
            const cleanFileName = fileName.replace(/\s+/g, '');

            const storageRef = ref(
				storage,
				`${FirebaseManager.allResourceRef(firestore).path}/${newFileRef.id}/file:${cleanFileName}.${mimiDB[fileType].extensions[0]}`
			);

            FirebaseManager.uploadFile(storageRef, file, fileType, (progress) => {
                loadingCallback(progress);
            }).then((downloadURL) => {
                const urlExtended = `resources?&resourceId=${newFileRef.id}`
                FirebaseManager.createDynamicLink(urlExtended, fileData.fileTitle).then(
                    (shareLink) => {
                        var data = fileData
                        fileData.resourceId = newFileRef.id
                        fileData.resourceLink = downloadURL;
                        fileData.timestamp = FirebaseManager.timestamp();
                        if (shareLink) {
                            data['shareLink'] = shareLink;
                        }
                        setDoc(newFileRef, data)
                            .then(() => {
                                return resolve(data);
                            })
                            .catch((error) => {
                                return reject(error);
                            });
                    }
                );
            })
		});
	}

    static deleteResourceItem(resourceId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
            const storage = getOrganizationStorage()
			const resourcesRef = FirebaseManager.allResourceRef(firestore);
			const storageRef = ref(storage, `${resourcesRef.path}/${resourceId}`);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
                
				return this.completeFileDelete(resourceId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeFileDelete(resourceId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const resourcesRef = FirebaseManager.allResourceRef(firestore);
			const resourceDocRef = doc(firestore, `${resourcesRef.path}/${resourceId}`);
			deleteDoc(resourceDocRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

}