<template>
	<div>
		<Card>
			<CardHeader slot="header" title="Date and Time" />
			<form>
				<b-row>
					<b-col>
						<div class="">
							<b-row class="mx-1">
								<b-col sm="4" lg="6">
									<BaseInput
										label="Start Date"
										id="date"
										placeholder="Start Date"
										type="datetime-local"
										required
										v-model="classDetails.classStartDate"
									/>
								</b-col>
								<b-col sm="4" lg="6">
									<label class="form-control-label">Class Duration</label>
									<b-row>
										<b-col>
											<BaseInput
												id="duration"
												placeholder="Hours"
												type="mask"
												:mask="['# hours', '## hours']"
												required
												v-model="classHoursHandle"
											/>
										</b-col>
										<b-col>
											<BaseInput
												id="duration"
												placeholder="Minutes"
												type="mask"
												:mask="['# minutes', '## minutes']"
												required
												v-model="classMinutesHandle"
											/>
										</b-col>
									</b-row>
								</b-col>
							</b-row>
							<b-row class="mx-1">
								<b-col sm="6" lg="6">
									<BaseInput
										label="Recurrence"
										id="recurrence"
										placeholder="Class Recurrence"
										required
										type="text"
									>
										<select
											class="form-control"
											id="recurrence"
											v-model="classDetails.classRecurrence"
											required
										>
											<option
												v-for="recurrence in recurrenceOptions"
												:key="recurrence.value"
												:value="recurrence.value"
											>
												{{ recurrence.name }}
											</option>
										</select></BaseInput
									>
								</b-col>
								<b-col sm="6" lg="6">
									<BaseInput
										label="Recurrence End Date"
										id="event date"
										placeholder="Recurrence End Date"
										type="datetime-local"
										:max="
											this.$helpers.formatDate(
												this.$helpers.addYear(classDetails.classStartDate, 1),
												`yyyy-MM-dd'T'HH:mm`
											)
										"
										:min="classDetails.classStartDate"
										required
										v-model="classDetails.classEndDate"
									/>
								</b-col>
							</b-row>
							<b-row v-if="showWeekDays" class="mx-1">
								<b-col>
									<ButtonCheckboxGroup
										title="Reoccuring Days"
										:options="daysOfWeek"
										required
										@change="onCheckboxChanged"
									/>
								</b-col>
							</b-row>
						</div>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import ButtonCheckboxGroup from '../../../components/ButtonCheckboxGroup.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Recurrence from '../../../models/DataModels/Reccurrence';

export default {
	components: {
		CardHeader,
		Card,
		BaseInput,
		ButtonCheckboxGroup,
	},
	props: {
		classDetails: {
			type: Object,
			default() {
				return {
					classStartDate: null,
					classDuration: null,
					classRecurrence: null,
					classRecurrenceDays: [],
					classEndDate: null,
				};
			},
		},
	},
	data() {
		return {
			showWeekDays: true,
			classHoursHandle: null,
			classMinutesHandle: null,
			recurrenceOptions: [],
			daysOfWeek: [],
		};
	},
	watch: {
		classHoursHandle: {
			handler(val) {
				return this.handleDuration();
			},
		},
		classMinutesHandle: {
			handler(val) {
				return this.handleDuration();
			},
		},
		classDetails: {
			handler(val) {
				if (val.classRecurrence) {
					if (
						val.classRecurrence == 'everyDay' ||
						val.classRecurrence == 'everyMonth' ||
						val.classRecurrence == 'everyYear'
					) {
						this.showWeekDays = false;
					} else {
						this.showWeekDays = true;
					}
				}
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		onCheckboxChanged(values) {
			const daysofWeek = [];
			values.forEach((index) => {
				daysofWeek.push(this.daysOfWeek[index].name.toLowerCase());
			});
			this.classDetails.classRecurrenceDays = daysofWeek;
		},
		resetDaysOfWeek() {
			return [
				{
					name: 'Monday',
					selected: false,
				},
				{
					name: 'Tuesday',
					selected: false,
				},
				{
					name: 'Wednesday',
					selected: false,
				},
				{
					name: 'Thursday',
					selected: false,
				},
				{
					name: 'Friday',
					selected: false,
				},
				{
					name: 'Saturday',
					selected: false,
				},
				{
					name: 'Sunday',
					selected: false,
				},
			];
		},
		handleStartDate() {
			if (this.classDetails.classStartDate) {
				var startDate = new Date(this.classDetails.classStartDate);
				var startWeekday = this.$helpers.formatDate(startDate, 'EEEE');
				const optionIndex = this.daysOfWeek.findIndex(
					(e) => e.name == startWeekday
				);
				this.daysOfWeek[optionIndex].selected = true;
			}
		},
		handleDuration() {
			const hours = this.classHoursHandle;
			const minutes = this.classMinutesHandle;
			const totalSeconds = hours * 3600 + minutes * 60;
			if (totalSeconds > 0) {
				this.classDetails.classDuration = totalSeconds;
			} else {
				this.classDetails.classDuration = null;
				this.classHoursHandle = null;
				this.classMinutesHandle = null;
			}
		},
		onChange() {
			//this.handleStartDate();
			this.$emit('onChange', this.classDetails);
		},
	},
	mounted() {
		this.daysOfWeek = this.resetDaysOfWeek();
		this.recurrenceOptions = Recurrence.options;
		this.classDetails.classStartDate = this.$helpers.formatDate(
			new Date(),
			"yyyy-MM-dd'T'HH:mm"
		);
	},
};
</script>
