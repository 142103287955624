<template>
    <div>
        <DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<ResourceTable
						:isLoading="isLoading"
						:tableData="allResources"
						@viewClicked="viewClicked"
						@copyClicked="copyClicked"
						@deleteClicked="deleteClicked"
					/>
				</b-col>
			</b-row>
		</div>
        <NewResourceItem
			:headerTitle="buttonItems[0].title"
			:showModal="showNewResouceModal"
			@close="showNewResouceModal = false"
			@newResource="onNewResource"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deleteResource"
			@noClicked="selectedResource == null"
		/>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import ResourceItemManager from '../../../models/ResourceItem/ResourceItemManager';
import ResourceTable from '../../Components/ResourceViews/ResourceTable.vue';
import NewResourceItem from '../../mainForms/NewResourceItem.vue';
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';
export default {
    props: {
		refresh: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isLoading: true,
			showQuestionModal: false,
			showNewResouceModal: false,
			modalQuestion: null,
			selectedResource: null,
			allResources: [],
			buttonItems: [
				{
					index: 0,
					title: 'New Resource',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
    methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			this.showQuestionModal = false;
			if (buttonItem.index == 0) {
				this.showNewResouceModal = true;
			}
		},
		viewClicked(item) {
			if (item.resourceLink) {
				var open = window.open(item.resourceLink, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
		copyClicked(item){
			if (item.resourceLink){
				navigator.clipboard.writeText(item.resourceLink);
				this.showNotification({
					type: 'success',
					message: 'Link Copied',
				});
			} else {
				this.showNotification({
					type: 'danger',
					message: 'Unable To Copy Link',
				});
			}
		},
		deleteClicked(item) {
			this.modalQuestion = 'Are you sure you want to delete this resource?';
			this.selectedResource = item;
			this.showQuestionModal = true;
		},
		deleteResource() {
			this.isLoading = true;
            ResourceItemManager.deleteResourceItem(this.selectedResource.resourceId)
				.then(() => {
					this.getAllResources();
					this.showNotification({
						type: 'success',
						message: `${this.selectedResource.resourceTitle} has been deleted.`,
					});
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		onNewResource(resource) {
			this.allResources.push(resource);
			this.showNotification({
				type: 'success',
				message: `${resource.resourceTitle} Added.`,
			});
		},
		getAllResources() {
			this.isLoading = true;
            ResourceItemManager.getAllResources().then(() => {
				this.allResources = ResourceItemManager.allResources;
				this.isLoading = false;
			});
		},
	},
	mounted() {
		if (this.refresh) {
			this.getAllResources();
		} else {
			this.allResources = ResourceItemManager.allResources;
			this.isLoading = false;
		}
	},
    components: { DashboardHeader, QuestionModal, ResourceTable, NewResourceItem }
}
</script>