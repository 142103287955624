<template>
	<div>
		<Card :no-body="eventLocationSwitch">
			<CardHeader
				slot="header"
				:title="isNew ? 'Location (optional)' : 'Location'"
				:endSwitch="isNew ? { title: 'Our Location' } : null"
				:endSwitchValue="eventLocationSwitch"
				@change="eventLocationSwitchChanged"
			/>

			<form v-if="!eventLocationSwitch">
				<b-row>
					<b-col cols="12" md="6">
						<BaseInput
							label="Title"
							id="title"
							placeholder="Title"
							required
							type="text"
							v-model="title"
						/>
					</b-col>
					<b-col cols="12" md="6">
						<BaseInput
							label="Address (or Subtitle)"
							id="address or subtitle"
							placeholder="Address (or Subtitle)"
							required
							type="text"
							v-model="description"
						/>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseButton from '../../../components/BaseButton.vue';
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
	},
	components: {
		Card,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		BaseButton,
		VueDropZone,
		CardHeader,
	},
	props: {
		isNew: false,
		eventData: null,
		classData: null,
	},
	data() {
		return {
			eventLocationSwitch: false,
			title: null,
			description: null,
		};
	},
	watch: {
		title: {
			handler() {
				return this.checkIfUpdate();
			},
		},
		description: {
			handler() {
				return this.checkIfUpdate();
			},
		},
	},
	methods: {
		eventLocationSwitchChanged(value) {
			this.eventLocationSwitch = value;
		},
		checkIfUpdate() {
			if (this.eventData) {
				const isDifferent =
					this.title != this.eventData.location.title ||
					this.description != this.eventData.location.description;
				this.$emit('onChange', {
					title: this.title,
					description: this.description,
					isDifferent: isDifferent,
				});
			} else if (this.classData) {
				const isDifferent =
					this.title != this.classData.location.title ||
					this.description != this.classData.location.description;
				this.$emit('onChange', {
					title: this.title,
					description: this.description,
					isDifferent: isDifferent,
				});
			} else {
				this.onChange();
			}
		},
		onChange() {
			this.$emit('onChange', {
				title: this.title,
				description: this.description,
			});
		},
	},
	mounted() {
		if (this.eventData) {
			this.eventLocationSwitch = false;
			this.title = this.eventData.location.title;
			this.description = this.eventData.location.description;
		} else if (this.classData) {
			this.eventLocationSwitch = false;
			this.title = this.classData.location.title;
			this.description = this.classData.location.description;
		} else {
			this.eventLocationSwitch = true;
			this.title = this.publicInformation.appName;
			this.description = this.publicInformation.appAddress;
		}
	},
};
</script>
