<template>
	<SlideYUpTransition :duration="animationDuration">
		<b-modal
			class="modal fade"
			ref="app-modal"
			:size="size"
			:hide-header="hideHeader"
			:modal-class="[{ 'modal-mini': type === 'mini' }, ...modalClasses]"
			@mousedown.self="closeModal"
			tabindex="-1"
			role="dialog"
			centered
			:content-class="[
				gradient ? `bg-gradient-${gradient}` : '',
				...modalContentClasses,
			]"
			@close="closeModal"
			@hide="closeModal"
			:hide-footer="hideFooter"
			:header-class="headerClasses"
			:footer-class="footerClasses"
			:body-class="bodyClasses"
			:aria-hidden="!show"
		>
			<b-overlay :show="isLoading" rounded="sm" no-wrap />
			<template v-slot:modal-header>
				<slot name="header"></slot>
				<slot name="close-button">
					<button
						type="button"
						class="close"
						v-if="showClose"
						@click="closeModal"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span :aria-hidden="!show">×</span>
					</button>
				</slot>
			</template>
			<slot>
				<MembersTable
					:isLoading="isLoading"
					:headerTitle="singleSelect ? 'Select User' : 'All Users'"
					:headerButtonData="
						singleSelect ? null : { title: 'Add Users', isDisabled: true }
					"
					:isSelecting="!singleSelect"
					:tableData="allUsers"
					:tableHeaders="userTitles"
					@headerButtonClicked="selectedUsers"
					@viewClicked="viewClicked"
					@deleteClicked="deleteClicked"
				/>
			</slot>
			<template v-slot:modal-footer>
				<slot name="footer"></slot>
			</template>
		</b-modal>
	</SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import AppUserManager from '../../models/AppUser/AppUserManager';
import MembersTable from '../../views/Components/MembersTable.vue';

export default {
	name: 'modal',
	components: {
		SlideYUpTransition,
		MembersTable,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		showClose: {
			type: Boolean,
			default: true,
		},
		type: {
			type: String,
			default: '',
			validator(value) {
				let acceptedValues = ['', 'notice', 'mini'];
				return acceptedValues.indexOf(value) !== -1;
			},
			description: 'Modal type (notice|mini|"") ',
		},
		modalClasses: {
			type: [Object, String],
			description: 'Modal dialog css classes',
			default: '',
		},
		size: {
			type: String,
			description: 'Modal size',
			default: 'lg',
			validator(value) {
				let acceptedValues = ['', 'sm', 'lg'];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		modalContentClasses: {
			type: [Object, String],
			description: 'Modal dialog content css classes',
			default: '',
		},
		gradient: {
			type: String,
			description: 'Modal gradient type (danger, primary etc)',
		},
		headerClasses: {
			type: [Object, String],
			description: 'Modal Header css classes',
		},
		bodyClasses: {
			type: [Object, String],
			description: 'Modal Body css classes',
		},
		footerClasses: {
			type: [Object, String],
			description: 'Modal Footer css classes',
		},
		hideHeader: {
			type: Boolean,
			default: true,
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
		animationDuration: {
			type: Number,
			default: 500,
			description: 'Modal transition duration',
		},
		exemptedUsers: {
			type: Array,
			description: 'Users to not show in this list',
			default() {
				return [];
			},
		},
		singleSelect: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isLoading: false,
			allUsers: [],
			userTitles: [
				{
					key: 'selected',
					label: '',
					tdClass: 'align-middle',
				},
				{
					key: 'nameAndPhoto',
					label: 'Name',
					tdClass: 'align-middle',
					sortable: true,
					thStyle: { width: '100%' },
					sortByFormatted: (value, key, item) => {
						return `${item.firstName} ${item.lastName}`;
					},
					formatter: (value, key, item) => {
						return item;
					},
				},
				{
					key: 'waiverStatus',
					label: 'Documents',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item);
					},
					formatter: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item);
					},
				},
			],
		};
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		selectedUsers(item) {
			this.$emit('selectedUsers', item);
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
			//this.closeModal()
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		getAllUsers() {
			this.isLoading = true;
			AppUserManager.getUsers().then((allUsers, lastDocument) => {
				this.allUsers = allUsers.filter(
					(e) => !this.exemptedUsers.find((a) => a.uid == e.uid)
				);
				this.isLoading = false;
			});
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.$refs['app-modal'].show();
			} else {
				this.$refs['app-modal'].hide();
			}
		},
	},
	mounted() {
		this.getAllUsers();
	},
};
</script>
<style>
.modal-backdrop {
	background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
