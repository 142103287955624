import Excel from 'exceljs';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import helpers from '../../helpers/helpers';
import FirebaseManager from '../Firebase/FirebaseManager';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import EventManager from '../EventItem/EventManager';
import ClassItemManager from '../ClassItem/ClassItemManager';
import CalendarObject from '../DataModels/CalendarObject';
import AppUserManager from '../AppUser/AppUserManager';

export default class ReportsManager {
	static checkIfReportsAllowed(isEvent) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const reportsRef = isEvent
				? FirebaseManager.allEventReportsRef(firestore)
				: FirebaseManager.allUsersReportsRef(firestore);
			var date = new Date();
			var startMonthDate = new Date(date.getFullYear(), date.getMonth(), 1);
			var endMonthDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			const q = query(
				reportsRef,
				where('uploadTimestamp', '>=', startMonthDate),
				where('uploadTimestamp', '<=', endMonthDate)
			);
			getDocs(q)
				.then((snapshot) => {
					if (snapshot.empty) {
						resolve(true);
					} else {
						resolve(snapshot.size < 5);
					}
				})
				.catch((error) => {
					resolve(false);
				});
		});
	}

	static exportEventMonthlyReport(eventMonth) {
		return new Promise((resolve, reject) => {
			return;
			const currentDate = new Date();
			currentDate.setMonth(eventMonth);
			currentDate.setDate(1);
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();
			//setheaders
			worksheet.columns = [
				{ header: 'Title', key: 'eventTitle' },
				{ header: 'Date ', key: 'eventStart' },
				{ header: 'Cancelled', key: 'isCancelled' },
				{ header: 'Faculty Leader', key: 'leaderName' },
				{ header: 'Event Users', key: 'eventUsers' },
				{ header: 'Purchased', key: 'purchased' },
			];
			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };
			const events = EventManager.allEvents.filter((e) => {
				const eventDate = e.eventStart.toDate();

				var ev_month = eventDate.getMonth() + 1;
				var ev_year = eventDate.getFullYear();
				var cur_month = currentDate.getMonth() + 1;
				var cur_year = currentDate.getFullYear();
				return ev_month == cur_month && ev_year == cur_year;
			});
			events.sort((a, b) => {
				return a.eventStart.toDate() - b.eventStart.toDate();
			});
			events.forEach((event) => {
				console.log(event);
				var eventLeader = event.eventLeaders
					? AppUserManager.allClassLeaders.find(
							(e) => e.uid == event.eventLeaders[0]
					  )
					: null;

				var data = {
					eventTitle: event.eventTitle,
					eventStart: event.eventStart.toDate(),
					leaderName: eventLeader
						? `${eventLeader.firstName} ${eventLeader.lastName}`
						: null,
					eventUsers: event.eventUsers,
					purchased: event.price ? event.price.purchased : 0,
					isCancelled: event.isCancelled ? event.isCancelled : null,
				};
				worksheet.addRow(data);
			});
			const firestore = getOrganizationFirestore();
			const reportRef = FirebaseManager.allEventReportsRef(firestore);
			const fileName = `${helpers.formatDate(
				currentDate,
				'MMM'
			)}_report-${helpers.formatDate(new Date(), 'yyyy-MM-dd')}.csv`;
			return this.exportReport(workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportClasses(allClasses){
		return new Promise((resolve, reject) => {
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();
			//setheaders
			worksheet.columns = [
				{ header: 'Class Title', key: 'classTitle' },
				{ header: 'Class Description', key: 'classDescription' },
				{ header: 'Class Start', key: 'classStart' },
				{ header: 'Class End', key: 'classEnd' },
				{ header: 'Class Users', key: 'classUsers' },
				{ header: 'Class Pending Users', key: 'pendingUsers' },
				{ header: 'Class Location Name', key: 'locationTitle' },
				{ header: 'Class Location Description', key: 'locationDescription' },
				{ header: 'Class Recurrence', key: 'recurrenceDays' },
				{ header: 'Class Created', key: 'uploadTimestamp' },
				{ header: 'Class Last Update', key: 'lastUpdatedAt'},
				{ header: 'Class Is Private', key: 'isPrivate' },
			];

			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };
			allClasses.sort((a, b) => {
				return a.classStart.toDate() - b.classEnd.toDate();
			});
			allClasses.forEach((classData) => {
				console.log(classData);
				var classLeader = classData.classLeaders
					? AppUserManager.allClassLeaders.find(
							(e) => e.uid == classData.classLeaders[0]
					  )
					: null;

				var data = {
					classTitle: classData.classTitle,
					classDescription: classData.classDescription,
					classStart: classData.classStart.toDate(),
					classEnd: classData.classEnd.toDate(),
					classUsers: classData.classUsers,
					pendingUsers: classData.pendingUsers,
					isPrivate: classData.isPrivate,
					lastUpdatedAt: classData.lastUpdatedAt ? classData.lastUpdatedAt.toDate() : null,
					uploadTimestamp: classData.uploadTimestamp.toDate(),
				};
				if (classData.location){
					data['locationTitle'] = classData.location.title
					data['locationDescription'] = classData.location.description
				}
				if (classData.recurrence){
					data['recurrenceDays'] = classData.recurrence.recurrenceDays
				}
				worksheet.addRow(data);
			});
			const firestore = getOrganizationFirestore();
			const reportRef = FirebaseManager.allClassReportsRef(firestore);
			const fileName = `AllClasses_report-${helpers.formatDate(
				new Date(),
				'yyyy-MM-dd'
			)}.csv`;
			return this.exportReport(workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportClassUsers(classTitle, allUsers){
		return new Promise((resolve, reject) => {
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();
			//setheaders
			worksheet.columns = [
				{ header: 'First Name', key: 'firstName' },
				{ header: 'Last Name', key: 'lastName' },
				{ header: 'Email', key: 'email' },
				{ header: 'Documents', key: 'waiverStatus' },
			];
			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };
			allUsers.forEach((item) => {
				var data = {
					firstName: item.userData.firstName,
					lastName: item.userData.lastName,
					email: item.userData.email,
					waiverStatus: helpers.isAllRequiredDocumentsSigned(item.userData),
				};
				worksheet.addRow(data);
			});
			const firestore = getOrganizationFirestore();
			const reportRef = FirebaseManager.allClassReportsRef(firestore);
			const fileName = `${classTitle}_report-${helpers.formatDate(
				new Date(),
				'yyyy-MM-dd'
			)}.csv`;
			return this.exportReport(workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportEventUsers(eventTitle, allUsers) {
		return new Promise((resolve, reject) => {
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();
			//setheaders
			worksheet.columns = [
				{ header: 'First Name', key: 'firstName' },
				{ header: 'Last Name', key: 'lastName' },
				{ header: 'Email', key: 'email' },
				{ header: 'Is Leader', key: 'isLeader' },
				{ header: 'Documents', key: 'waiverStatus' },
				{ header: 'Quantity', key: 'quantity' },
				{ header: 'Attendance', key: 'attendance' },
			];
			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };
			allUsers.forEach((item) => {
				var data = {
					firstName: item.userData.firstName,
					lastName: item.userData.lastName,
					email: item.userData.email,
					waiverStatus: helpers.isAllRequiredDocumentsSigned(item.userData),
					quantity: item.eventData.quantity,
					attendance:
						item.eventData.attendance != null
							? item.eventData.attendance.quantity
							: 0,
				};
				worksheet.addRow(data);
			});
			const firestore = getOrganizationFirestore();
			const reportRef = FirebaseManager.allEventReportsRef(firestore);
			const fileName = `${eventTitle}_report-${helpers.formatDate(
				new Date(),
				'yyyy-MM-dd'
			)}.csv`;
			return this.exportReport(workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportProductUsers(productTitle, allUsers) {
		return new Promise((resolve, reject) => {
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();
			//setheaders
			worksheet.columns = [
				{ header: 'First Name', key: 'firstName' },
				{ header: 'Last Name', key: 'lastName' },
				{ header: 'Email', key: 'email' },
				{ header: 'Documents', key: 'waiverStatus' },
				{ header: 'Quantity', key: 'quantity' },
				{ header: 'Purchased', key: 'timestamp' },
			];
			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };
			allUsers.forEach((item) => {
				var data = {
					firstName: item.userData.firstName,
					lastName: item.userData.lastName,
					email: item.userData.email,
					waiverStatus: helpers.isAllRequiredDocumentsSigned(item.userData),
					quantity: item.productData.quantity,
					timestamp: item.productData.timestamp.toDate(),
				};
				worksheet.addRow(data);
			});
			const firestore = getOrganizationFirestore();
			const reportRef = FirebaseManager.allProductReportsRef(firestore);
			const fileName = `${productTitle}_report-${helpers.formatDate(
				new Date(),
				'yyyy-MM-dd'
			)}.csv`;
			return this.exportReport(workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportUsers(allUsers) {
		return new Promise((resolve, reject) => {
			const workbook = new Excel.Workbook();
			const worksheet = workbook.addWorksheet();
			//setheaders
			worksheet.columns = [
				{ header: 'First Name', key: 'firstName' },
				{ header: 'Last Name', key: 'lastName' },
				{ header: 'Email', key: 'email' },
				{ header: 'Phone Number', key: 'phoneNumber' },
				{ header: 'Address', key: 'address' },
				{ header: 'Birthday', key: 'dateOfBirth' },
				{ header: 'Gender', key: 'gender' },
				{ header: 'Sign Up', key: 'createdTimestamp' },
				{ header: 'Medical Notes', key: 'medicalNotes' },
				{ header: 'Last Logged In', key: 'lastLoggedIn' },
				{ header: 'Is Leader', key: 'isLeader' },
			];
			//set columns length to atleast the size of header
			worksheet.columns.forEach((column) => {
				column.width = column.header.length < 12 ? 12 : column.header.length;
			});
			//set header row to bold
			worksheet.getRow(1).font = { bold: true };

			allUsers.forEach((userData) => {
				var data = {
					firstName: userData.firstName,
					lastName: userData.lastName,
					email: userData.email,
					phoneNumber: userData.phoneNumber,
					address: userData.address,
					dateOfBirth: userData.dataOfBirth,
					gender: userData.gender,
					createdTimestamp: userData.createdTimestamp
						? userData.createdTimestamp.toDate()
						: null,
					medicalNotes: userData.medicalNotes,
					lastLoggedIn: userData.lastLoggedIn
						? userData.lastLoggedIn.toDate()
						: null,
					isLeader: userData.appLeader ? true : false,
				};
				worksheet.addRow(data);
			});
			const firestore = getOrganizationFirestore();
			const reportRef = FirebaseManager.allUsersReportsRef(firestore);
			const fileName = `users_report-${helpers.formatDate(
				new Date(),
				'yyyy-MM-dd'
			)}.csv`;
			return this.exportReport(workbook, reportRef, fileName)
				.then((data) => {
					resolve(data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	static exportReport(workbook, reportRef, fileName) {
		return new Promise((resolve, reject) => {
			workbook.csv
				.writeBuffer()
				.then((buffer) => {
					const blob = new Blob([buffer], { type: 'text/csv' });
					const storage = getOrganizationStorage();
					const newReportRef = doc(reportRef);
					const newReportStorageRef = ref(storage, `${reportRef.path}/${fileName}`);
					//saveAs(blob, fileName);
					return uploadBytes(newReportStorageRef, blob)
						.then((snapshot) => {
							return getDownloadURL(snapshot.ref)
								.then((downloadURL) => {
									const reportData = {
										reportId: newReportRef.id,
										reportTitle: fileName,
										reportDownloadLink: downloadURL,
										uploadTimestamp: helpers.returnTimestamp(),
									};
									return setDoc(newReportRef, reportData)
										.then(() => {
											return resolve(reportData);
										})
										.catch((error) => {
											return reject(error.message);
										});
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}
}
