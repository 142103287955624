<template>
	<div>
		<DashboardHeaderVue
			v-if="userData"
			:title="`${userData.firstName} ${userData.lastName}`"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div v-if="userData" class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="align-items-center">
					<b-col md="9">
						<MemberHeaderCard :userData="userData" @imageClicked="showEditImage = true" />
					</b-col>
					<b-col md="3">
						<CreditCard :creditCard="this.userData.defaultCard" />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<Card no-body>
							<b-tabs
								active-nav-item-class="bg-white font-weight-bold description"
								nav-class="bg-white description"
								card
								no-body
							>
								<b-tab title="Profile" active>
									<ProfileCard :userData="userData" @onUpdate="getUserData" />
								</b-tab>
								<b-tab v-if="userData.appLeader" title="Leader Information">
									<ProfileLeaderCard :userData="userData" @onUpdate="getUserData" />
								</b-tab>
								<b-tab title="Classes" @click="tabClicked(1)">
									<ProfileClassesCard
										:userData="userData"
										:refresh="activeTabs.includes(1)"
									/>
								</b-tab>
								<b-tab title="Documents" @click="tabClicked(2)">
									<ProfileDocumentsCard
										:userData="userData"
										:refresh="activeTabs.includes(2)"
									/>
								</b-tab>
								<b-tab title="Subscriptions" @click="tabClicked(4)">
									<ProfileSubscriptionsCard
										:user-data="userData"
										:refresh="activeTabs.includes(4)"
									/>
								</b-tab>
								<b-tab title="Invoices" @click="tabClicked(5)">
									<ProfileInvoicesCard
										:user-data="userData"
										:refresh="activeTabs.includes(5)"
									/>
								</b-tab>
								<b-tab title="Purchase History" @click="tabClicked(6)">
									<ProfilePaymentHistoryCard
										:userData="userData"
										:refresh="activeTabs.includes(6)"
									/>
								</b-tab>
								<b-tab title="Relationships" @click="tabClicked(7)">
									<ProfileRelationshipsCard
										:userData="userData"
										:refresh="activeTabs.includes(7)"
									/>
								</b-tab>
								<b-tab title="Notes">
									<ProfileNotesCard :userData="userData" />
								</b-tab>
							</b-tabs>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</div>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="adjustAppLeader(false)"
			@noClicked="showQuestionModal = false"
		/>
		<ChangeImage
			:showModal="showEditImage"
			@updatedImage="addedImage"
			@close="showEditImage = false"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../../routes/routesNames';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import MemberHeaderCard from '../../../Components/MemberViews/BaseMemberViews/MemberHeaderCard.vue';
import ProfileCard from '../../../Components/MemberViews/BaseMemberViews/ProfileCard.vue';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../../components/Cards/Card.vue';
import CreditCard from '../../../Components/MemberViews/CreditCard.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import ProfileInvoicesCard from '../../../Components/MemberViews/BaseMemberViews/ProfileInvoicesCard.vue';
import ProfilePaymentHistoryCard from '../../../Components/MemberViews/BaseMemberViews/ProfilePaymentHistoryCard.vue';
import ProfileSubscriptionsCard from '../../../Components/MemberViews/BaseMemberViews/ProfileSubscriptionsCard.vue';
import ProfileClassesCard from '../../../Components/MemberViews/BaseMemberViews/ProfileClassesCard.vue';
import ProfileDocumentsCard from '../../../Components/MemberViews/BaseMemberViews/ProfileDocumentsCard.vue';
import ProfileNotesCard from '../../../Components/MemberViews/BaseMemberViews/ProfileNotesCard.vue';
import ProfileRelationshipsCard from '../../../Components/MemberViews/BaseMemberViews/ProfileRelationshipsCard.vue';
import ProfileLeaderCard from '../../../Components/MemberViews/BaseMemberViews/ProfileLeaderCard.vue';
import ChangeImage from '../../../mainForms/ChangeImage.vue';

export default {
	name: 'member',

	components: {
		QuestionModal,
		MemberHeaderCard,
		DashboardHeaderVue,
		ProfileCard,
		Card,
		CreditCard,
		CardHeader,
		ProfileSubscriptionsCard,
		ProfilePaymentHistoryCard,
		ProfileClassesCard,
		ProfileDocumentsCard,
		ProfileNotesCard,
		ProfileRelationshipsCard,
		ProfileLeaderCard,
		ProfileInvoicesCard,
		ChangeImage
	},
	props: {
		uid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			userData: null,
			modalQuestion: null,
			showQuestionModal: false,
			needUpdateProfile: false,
			showEditImage: false,
			emailUserId: 0,
			textUserId: 1,
			resetUserPassId: 2,
			blockUserId: 3,
			adminUserId: 4,
			activeTabs: [],
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.textUserId,
					title: 'Send Text',
					isDisabled: true,
				},
				{
					index: this.emailUserId,
					title: 'Send Email',
				},
				{
					index: this.resetUserPassId,
					title: 'Send Password Reset',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.adminUserId,
					title: this.userData.appLeader ? 'Remove Leader' : 'Make Leader',
				},
				{
					index: -2,
					title: '',
					isDivider: true,
				},

				{
					index: this.blockUserId,
					title: 'Block User',
					type: 'danger',
				},
			];
		},
		getUserData() {
			this.isLoading = true;
			AppUserManager.fetchUserByUid(this.uid).then((userData) => {
				this.userData = userData;
				this.setButtons();
				this.isLoading = false;
			});
		},
		tabClicked(index) {
			if (!this.activeTabs.find((i) => i == index)) {
				this.activeTabs.push(index);
			}
		},
		askAppLeaderAdjust() {
			const isAppLeader = this.userData.appLeader;
			if (isAppLeader) {
				this.modalQuestion = 'Are you sure you want to remove this leader?';
				this.showQuestionModal = true;
				//ask are you sure?
			} else {
				this.adjustAppLeader(true);
			}
		},
		adjustAppLeader(isAppLeader) {
			this.isLoading = true;
			AppUserManager.adjustAppLeader(this.userData.uid, isAppLeader)
				.then(() => {
					this.getUserData();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		sendPasswordReset() {
			this.isLoading = true;
			AppUserManager.sendUserPasswordReset(this.userData.email)
				.then(() => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: `An email has been sent to: ${this.userData.email} to reset password.`,
					});
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		openEmail() {
			if (this.userData.email != null) {
				var open = window.open(`mailto:${this.userData.email}`, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
		openWaiverForm() {
			const waiverForm = this.$helpers.userReturnSignedWaiver(
				this.userData,
				this.activeWaiverId
			);
			if (waiverForm != null) {
				var open = window.open(waiverForm.waiverSignedUrl, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
		addedImage(file) {
			const updatedImage = file[0];
			this.isLoading = true;
			AppUserManager.changeUserImage(updatedImage, this.userData)
				.then(() => {
					this.isLoading = false;
					this.getUserData();
					this.showNotification({
						type: 'success',
						message: 'User Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error, //'Unknown Error.',
					});
				});
		},
		onUpdateProfile() {},
		childClick(childClicked) {
			switch (childClicked.index) {
				case this.textUserId:
					break;
				case this.emailUserId:
					this.openEmail();
					break;
				case this.resetUserPassId:
					this.sendPasswordReset();
					break;
				case this.adminUserId:
					this.askAppLeaderAdjust();
					break;
				case this.blockUserId:
					//this.shareEvent();
					break;
				default:
					break;
			}
		},
	},
	mounted() {
		if (!this.uid) {
			this.$router.replace({
				name: DashboardChildren.members.name,
			});
		} else if (this.$route.params.userData) {
			this.userData = this.$route.params.userData;
			this.setButtons();
		} else {
			this.getUserData();
		}
	},
};
</script>
