<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(couponCode)="data">
					<div class="d-flex align-items-center">
						<b-avatar
							alt="Image placeholder"
							:src="publicInformation.appLogo"
							size="md"
						/>
						<div class="ml-2">
							<h5
								class="mb-0 mr-2"
								style="
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
								"
							>
								{{ data.item.couponCode }}
							</h5>
							<h6
								class="text-muted m-0"
								style="
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
								"
							>
								{{ data.item.couponDescription }}
							</h6>
						</div>
					</div>
				</template>
				<template #cell(couponDiscount)="data">
					{{
						data.item.percentOff != null
							? `${data.item.percentOff}% off`
							: data.item.amountOff != null
							? `${$helpers.amountToString(data.item.amountOff)} off`
							: ''
					}}
				</template>
				<template #cell(isActive)="data">
					<BaseSwitch
						:value="data.item.isValid"
						:is-disabled="true"
						type="success"
					/>
				</template>
				<template #cell(actions)="row">
					<b-dropdown id="actions" variant="null" dropleft no-caret>
						<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
						<b-dropdown-item @click="adjustActiveClicked(row.item)">{{
							row.item.isValid ? 'Deactivate' : 'Activate'
						}}</b-dropdown-item>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item variant="danger" @click="deleteClicked(row.item)">{{
							row.item.redeemed != null ? 'Archive' : 'Delete'
						}}</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import store from '../../../store/store';

export default {
	components: { Card, BaseButton, BaseInput, BaseSwitch },
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
			default() {
				return [
					{
						key: 'couponCode',
						label: 'Title',
						sortable: true,
						thStyle: { width: '100%' },
						tdClass: 'align-middle',
					},
					{
						key: 'couponDiscount',
						label: 'Discount',
						tdClass: 'align-middle text-center',
					},
					{
						key: 'isUserLimited',
						label: 'Limited',
						tdClass: 'align-middle text-center',
					},
					{
						key: 'isActive',
						label: 'Active',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'redeemed',
						label: 'Redeemed',
						sortable: true,
						tdClass: 'align-middle text-center',
					},
					{
						key: 'actions',
						label: '',
						tdClass: 'align-middle',
					},
				];
			},
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {
			filter: null,
			appLogo: null,
		};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		adjustActiveClicked(item) {
			this.$emit('adjustActiveClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('rowClicked', item);
		},
	},
	mounted() {
		this.appLogo = store.state.organization.appLogo;
	},
};
</script>
