<template>
	<div>
		<Card>
			<div class="text-center d-flex justify-content-between align-items-center">
				<div class="d-flex justify-content-start align-items-center"
					style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
					<b-row class="mr-1">
						<b-col @mouseover="imageHover = true" @mouseleave="imageHover = false">
							<VueAspectRatio ar="1:1" style="width: 75px">
								<div class="b-img-helpers card-img">
									<div :style="{ opacity: imageHover ? 0.4 : 1 }">
										<b-avatar badge-variant="success" variant="dark" class="avatar-xl b-img-base" style="border: 1px darkgray solid;"
											:text="this.userData.firstName" :src="this.userData.profilePhotoURL">
											<template v-if="this.userData.appLeader" #badge><b-icon icon="star-fill"></b-icon></template>
										</b-avatar>
									</div>
									<div v-if="imageHover" style="
									height: 100%;
									width: 100%;
									border: dashed 1px black;
									display: flex;
									flex-wrap: wrap;
									align-content: center;
								" class="justify-content-center b-img-base">
										<BaseButton type="primary" size="sm" block @click="imageClicked">Edit
										</BaseButton>
									</div>
								</div>
							</VueAspectRatio>
						</b-col>
					</b-row>
					<div class="align-items-left text-left">
						<h2 class="title m-0">
							{{ userData.firstName }} {{ userData.lastName }}
						</h2>
						<h5 class="m-0">{{ userData.email }}</h5>
						<h6 class="text-muted mb-0">
							Member Since:
							{{ this.$helpers.formatDate(userData.createdTimestamp.toDate()) }}
						</h6>

						<h6 v-if="userData.lastLoggedIn" class="text-muted">
							Last Seen:
							{{ this.$helpers.formatDate(userData.lastLoggedIn.toDate()) }}
						</h6>
					</div>
				</div>
				<div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
					<div class="d-flex justify-content-end align-items-center m-0 p-0">
						<h6 class="small text-muted mx-2 my-1"
							style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
							Documents
						</h6>
						<BaseSwitch :value="this.$helpers.isAllRequiredDocumentsSigned(this.userData)"
							:isDisabled="true" type="success" />
					</div>
					<div class="d-flex justify-content-end align-items-center">
						<h6 class="small text-muted mx-2 my-2">Active Subscription</h6>
						<BaseSwitch :value="this.userData.activeSubscriptions
							? this.userData.activeSubscriptions.length > 0
							: false
							" :isDisabled="true" type="success" />
					</div>
				</div>
			</div>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../../../../components/BaseButton.vue';
import BaseSwitch from '../../../../components/BaseSwitch.vue';
import Card from '../../../../components/Cards/Card.vue';

export default {
	props: {
		userData: null,
	},
	data() {
		return {
			imageHover: false,
		};
	},
	methods:{
		imageClicked() {
			this.$emit('imageClicked');
		}
	},
	components: { Card, VueAspectRatio, BaseButton, BaseSwitch },
};
</script>
