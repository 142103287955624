<template>
	<div slot="header">
		<b-row>
			<b-col md="8" lg="8" class="d-flex align-items-center" style="gap: 5px">
				<h3 class="mb-0">{{ title }}</h3>
				<i
					v-if="tooltip"
					class="fa fa-question-circle"
					v-b-tooltip.hover
					:title="tooltip"
				></i>
			</b-col>
			<b-col md="4" lg="4" v-if="endTitle" class="text-right">
				<h3 class="mb-0">{{ endTitle }}</h3>
			</b-col>
			<b-col md="4" lg="4" v-if="endButton" class="text-right">
				<BaseButton
					:disabled="endButton.disabled"
					size="sm"
					@click="endButtonClicked"
					>{{ endButton.title }}</BaseButton
				>
			</b-col>
			<b-col md="4" lg="4" v-if="endSwitch" class="text-right">
				<div class="d-flex align-items-center justify-content-end">
					<h5 v-if="endSwitch.title" class="my-0 mr-2 p-0">{{ endSwitch.title }}</h5>
					<BaseSwitch
						:value="endSwitchValue"
						:is-disabled="endSwitch.disabled"
						@change="endSwitchValueChanged"
					/>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import BaseSwitch from '../BaseSwitch.vue';
import BaseButton from '../BaseButton.vue';
export default {
	name: 'cardHeader',
	props: {
		title: {
			type: String,
			default: 'Card Header',
		},
		tooltip: {
			type: String,
			default: null,
		},
		endTitle: {
			type: String,
			default: null,
		},
		endButton: {
			type: Object,
			decription: "{title: 'buttontitle', 'disabled': true/false,}",
			default: null,
		},
		endSwitch: {
			type: Object,
			description: "{title: 'switch title', 'disabled': true/false}",
			default: null,
		},
		endSwitchValue: {
			type: Boolean,
		},
	},
	components: { BaseSwitch, BaseButton },
	methods: {
		endButtonClicked() {
			this.$emit('click');
		},
		endSwitchValueChanged(value) {
			this.$emit('change', value.target.checked);
		},
	},
};
</script>
