import { getOrganizationFirestore } from '@/resources/orgFirebase';
import { doc, getDocs, setDoc } from 'firebase/firestore';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';

export class AccountInformation {
	constructor(appInformation, paymentInformation, publicInformation) {
		this.appInformation = appInformation;
		this.paymentInformation = paymentInformation;
		this.publicInformation = publicInformation;
	}

	static appInformation = null; // AppInformation.appInformation;
	static paymentInformation = null; //PaymentInformation.paymentInformation;
	static publicInformation = null; //PublicInformation.publicInformation;
	static accountInformation = new AccountInformation(
		this.appInformation,
		this.paymentInformation,
		this.publicInformation
	);

	static getAccountInformation() {
		return new Promise((resolve, reject) => {
			const accountRef = FirebaseManager.accountRef(getOrganizationFirestore());
			getDocs(accountRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					var counter = 0;
					var accountInformation = this.accountInformation;
					documents.forEach((document) => {
						var documentData = document.data();
						if (
							document.ref.id ==
							FirebaseManager.accountAppInformationRef(getOrganizationFirestore()).id
						) {
							accountInformation.appInformation =
								AppInformation.returnData(documentData);
						} else if (
							document.ref.id ==
							FirebaseManager.accountPaymentInformationRef(getOrganizationFirestore())
								.id
						) {
							accountInformation.paymentInformation =
								PaymentInformation.returnData(documentData);
						} else if (
							document.ref.id ==
							FirebaseManager.accountPublicInformationRef(getOrganizationFirestore())
								.id
						) {
							accountInformation.publicInformation =
								PublicInformation.returnData(documentData);
						}

						if (counter == total - 1) {
							return resolve(accountInformation);
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static updateAppInformation(updateData){
		return new Promise((resolve, reject) => {
			if (updateData){
				const accountRef = FirebaseManager.accountRef(getOrganizationFirestore());
				const appInformation = doc(accountRef, `appInformation`)
				setDoc(appInformation, updateData,{merge: true}).then(() => {
					return resolve()
				}).catch((error) => {
					return reject(error.message);
				})
			}
		})
	}

}

export class AppInformation {
	constructor(
		isActive,
		isPaymentActive,
		isProductActive,
		isClassesActive,
		isDocumentActive,
		isMessagingActive,
		isMessagingTeachersOnly,
		calendarColors,
		menuItems
	) {
		this.isActive = isActive;
		this.isPaymentActive = isPaymentActive;
		this.isProductActive = isProductActive;
		this.isClassesActive = isClassesActive;
		this.isDocumentActive = isDocumentActive;
		this.isMessagingActive = isMessagingActive;
		this.isMessagingTeachersOnly = isMessagingTeachersOnly;
		this.calendarColors = calendarColors;
		this.menuItems = menuItems;
	}
	static isActive = false;
	static isPaymentActive = true;
	static isProductActive = false;
	static isClassesActive = true;
	static isDocumentActive = true;
	static isMessagingActive = true;
	static isMessagingTeachersOnly = true;
	static calendarColors = null; //CalendarColors.calendarColors;
	static menuItems = [];
	static appInformation = new AppInformation(
		this.isActive,
		this.isPaymentActive,
		this.isProductActive,
		this.isClassesActive,
		this.isDocumentActive,
		this.isMessagingActive,
		this.isMessagingTeachersOnly,
		this.calendarColors,
		this.menuItems
	);

	static returnData(data) {
		var isActive = this.isActive;
		var isPaymentActive = this.isPaymentActive;
		var isProductActive = this.isProductActive;
		var isClassesActive = this.isClassesActive;
		var isDocumentActive = this.isDocumentActive;
		var isMessagingActive = this.isMessagingActive;
		var isMessagingTeachersOnly = this.isMessagingTeachersOnly;
		var calendarColors = CalendarColors.calendarColors; //this.calendarColors;
		var menuItems = this.menuItems;

		if (data['isActive'] != null) {
			isActive = data['isActive'];
		}
		if (data['isPaymentActive'] != null) {
			isPaymentActive = data['isPaymentActive'];
		}
		if (data['isProductActive'] != null) {
			isProductActive = data['isProductActive'];
		}
		if (data['isClassesActive'] != null) {
			isClassesActive = data['isClassesActive'];
		}
		if (data['isDocumentActive'] != null) {
			isDocumentActive = data['isDocumentActive'];
		}
		if (data['isMessagingActive'] != null) {
			isMessagingActive = data['isMessagingActive'];
		}
		if (data['isMessagingTeachersOnly'] != null) {
			isMessagingTeachersOnly = data['isMessagingTeachersOnly'];
		}
		if (data['calendarColors']) {
			calendarColors = CalendarColors.returnData(data['calendarColors']);
		}

		return new AppInformation(
			isActive,
			isPaymentActive,
			isProductActive,
			isClassesActive,
			isDocumentActive,
			isMessagingActive,
			isMessagingTeachersOnly,
			calendarColors,
			menuItems
		);
	}
}

export class PaymentInformation {
	constructor(
		isTestMode,
		country,
		currency,
		transactionFee,
		applePayMerchantId,
		stripeAccountId
	) {
		this.isTestMode = isTestMode;
		this.country = country;
		this.currency = currency;
		this.transactionFee = transactionFee;
		this.applePayMerchantId = applePayMerchantId;
		this.stripeAccountId = stripeAccountId;
	}

	static isTestMode = false;
	static country = 'US';
	static currency = 'USD';
	static transactionFee = 0;
	static applePayMerchantId = '';
	static stripeAccountId = '';
	static paymentInformation = new PaymentInformation(
		this.isTestMode,
		this.country,
		this.currency,
		this.transactionFee,
		this.applePayMerchantId,
		this.stripeAccountId
	);

	static returnData(data) {
		var isTestMode = this.isTestMode;
		var country = this.country;
		var currency = this.currency;
		var transactionFee = this.transactionFee;
		var applePayMerchantId = this.applePayMerchantId;
		var stripeAccountId = this.stripeAccountId;

		if (data['isTestMode'] != null) {
			isTestMode = data['isTestMode'];
		}

		if (data['country']) {
			country = data['country'];
		}

		if (data['currency']) {
			currency = data['currency'];
		}

		if (data['transactionFee']) {
			transactionFee = data['transactionFee'];
		}

		if (data['applePayMerchantId']) {
			applePayMerchantId = data['applePayMerchantId'];
		}

		if (data['stripeAccountId']) {
			stripeAccountId = data['stripeAccountId'];
		}

		return new PaymentInformation(
			isTestMode,
			country,
			currency,
			transactionFee,
			applePayMerchantId,
			stripeAccountId
		);
	}
}

export class BookingInformation {
	constructor(isActive) {
		this.isActive = isActive;
	}
	static returnData(data) {
		var isActive = this.isActive;

		if (data['isActive']) {
			isActive = data['isActive'];
		}

		return new BookingInformation(isActive);
	}
}

export class PublicInformation {
	constructor(
		appName,
		appAddress,
		appLogo,
		appOrgId,
		appDynamicLink,
		iosBundle,
		androidBundle,
		appStoreId
	) {
		this.appName = appName;
		this.appAddress = appAddress;
		this.appLogo = appLogo;
		this.appOrgId = appOrgId;
		this.appDynamicLink = appDynamicLink;
		this.iosBundle = iosBundle;
		this.androidBundle = androidBundle;
		this.appStoreId = appStoreId;
	}

	static appName = '';
	static appAddress = '';
	static appLogo = '';
	static appOrgId = '';
	static appDynamicLink = '';
	static iosBundle = null;
	static androidBundle = null;
	static appStoreId = null;
	static publicInformation = new PublicInformation(
		this.appName,
		this.appAddress,
		this.appLogo,
		this.appOrgId,
		this.appDynamicLink,
		this.iosBundle,
		this.androidBundle,
		this.appStoreId
	);

	static returnData(data) {
		var appName = this.appName;
		var appAddress = this.appAddress;
		var appLogo = this.appLogo;
		var appOrgId = this.appOrgId;
		var appDynamicLink = this.appDynamicLink;
		var iosBundle = this.iosBundle;
		var androidBundle = this.androidBundle;
		var appStoreId = this.appStoreId;

		if (data['appName']) {
			appName = data['appName'];
		}

		if (data['appAddress']) {
			appAddress = data['appAddress'];
		}

		if (data['appLogo']) {
			appLogo = data['appLogo'];
		}

		if (data['appOrgId']) {
			appOrgId = data['appOrgId'];
		}

		if (data['appDynamicLink']) {
			appDynamicLink = data['appDynamicLink'];
		}

		if (data['iosBundle']) {
			iosBundle = data['iosBundle'];
		}

		if (data['androidBundle']) {
			androidBundle = data['androidBundle'];
		}

		if (data['appStoreId']) {
			appStoreId = data['appStoreId'];
		}

		return new PublicInformation(
			appName,
			appAddress,
			appLogo,
			appOrgId,
			appDynamicLink,
			iosBundle,
			androidBundle,
			appStoreId
		);
	}
}

export class CalendarColors {
	constructor(eventColor, classColor, defaultColor) {
		this.eventColor = eventColor;
		this.classColor = classColor;
		this.defaultColor = defaultColor;
	}

	static eventColor = '#0000ff';
	static classColor = '#00FF00';
	static defaultColor = '#000000';
	static calendarColors = new CalendarColors(
		this.eventColor,
		this.classColor,
		this.defaultColor
	);

	static returnData(data) {
		var eventColor = this.eventColor;
		var classColor = this.classColor;
		var defaultColor = this.defaultColor;

		if (data['eventColor']) {
			eventColor = data['eventColor'];
		}

		if (data['classColor']) {
			classColor = data['classColor'];
		}

		if (data['defaultColor']) {
			defaultColor = data['defaultColor'];
		}

		return new CalendarColors(eventColor, classColor, defaultColor);
	}
}
