<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<DocumentTable
			:isLoading="isLoading"
			:tableData="allUserFiles"
			:tableHeaders="tableTitles"
			@rowClicked="rowClicked"
		/>
	</div>
</template>
<script>
import DocumentTable from '../../DocumentViews/DocumentTable.vue';
import FileItemManager from '../../../../models/FileItem/FileItemManager';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
			allUserFiles: [],
			tableTitles: [
				{
					key: 'titleAndDoc',
					label: 'Title',
					sortable: true,
					thStyle: { width: '100%' },
					tdClass: 'align-middle',
				},
			],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserFiles();
				}
			},
		},
	},
	components: { DocumentTable },
	methods: {
		rowClicked(fileData) {
			if (fileData.fileSignedLink) {
				var open = window.open(fileData.fileSignedLink, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
		getUserFiles() {
			this.isLoading = true;
			FileItemManager.getUserFiles(this.userData.uid)
				.then((items) => {
					if (items) {
						this.allUserFiles = items;
					}
					this.isLoading = false;
				})
				.catch((error) => {});
		},
	},
};
</script>
