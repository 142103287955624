var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',[_c('CardFooter',{attrs:{"slot":"footer","title":"TIP: Any changes to the title, description, or start time will reflect for this entire item."},slot:"footer"}),_c('form',[_c('base-input',{attrs:{"label":_vm.eventData ? 'Event Title' : 'Class Title',"id":"title","placeholder":"Title","disabled":_vm.isDisabled,"required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('base-input',{attrs:{"label":_vm.eventData ? 'Event Description' : 'Class Description'}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"form-control",attrs:{"placeholder":"Enter your description...","disabled":_vm.isDisabled,"id":"description","rows":"4","required":""},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing)return;_vm.description=$event.target.value}}})]),(_vm.classData != null)?_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Class Category","id":"class category","type":"multiselect","internalSearch":false,"multiSelectOptions":_vm.categoryOptions,"multiSelectValue":_vm.category},on:{"remove":_vm.onCategoryRemoved,"select":_vm.onCategorySelected}})],1)],1):_vm._e(),(
					_vm.classData != null || (_vm.eventData != null && _vm.eventData.recurrence == null)
				)?_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('base-input',{attrs:{"label":_vm.eventData ? 'Event Start' : 'Class Start',"id":"start time","placeholder":"Start Time","disabled":_vm.isDisabled,"required":"","type":"datetime-local","max":this.$helpers.formatDate(
								this.$helpers.addYear(new Date(), 1),
								`yyyy-MM-dd'T'HH:mm`
							)},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('base-input',{attrs:{"label":_vm.eventData ? 'Event End' : 'Class End (recurring)',"id":"end time","placeholder":"End Time","disabled":_vm.isDisabled,"required":"","type":"datetime-local","max":this.$helpers.formatDate(
								this.$helpers.addYear(new Date(), 1),
								`yyyy-MM-dd'T'HH:mm`
							)},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)],1):_vm._e(),(_vm.showWeekDays)?_c('b-row',[_c('b-col',[_c('ButtonCheckboxGroup',{attrs:{"title":"Reoccuring Days","options":_vm.daysOfWeek,"required":""},on:{"change":_vm.onDaysOfWeekCheckboxChanged}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }