const getters = {
	getUser(state) {
		return state.user;
	},
	getOrganization(state) {
		return state.organization;
	},
	getNotification(state) {
		return state.notification;
	},
	getAccountInformation(state) {
		return state.accountInformation;
	},
	getAppInformation(state) {
		return state.appInformation;
	},
	getWebsiteInformation(state) {
		return state.websiteInformation;
	},
	getPaymentInformation(state) {
		return state.paymentInformation;
	},
	getPublicInformation(state) {
		return state.publicInformation;
	},
	getSocialInformation(state) {
		return state.socialInformation;
	},
	getBookingInformation(state) {
		return state.bookingInformation;
	},
	getStripeTerminal(state) {
		return state.stripeTerminal;
	},
	getStripeTerminalReaders(state) {
		return state.stripeTerminalReaders;
	},
	isUserAuth(state) {
		return !!state.user;
	},
};
export default getters;
