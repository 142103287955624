<template>
	<div>
		<Card>
			<CardHeader slot="header" title="General Information" />
			<CardFooter
				slot="footer"
				title="Customize your class image on different pages and find the perfect
							dimensions."
			/>
			<form>
				<b-row>
					<b-col sm="6" lg="8" order="2" order-sm="1">
						<BaseInput
							label="Class Title"
							id="title"
							placeholder="Class Title"
							required
							type="text"
							v-model="classData.classTitle"
						/>
						<BaseInput
							label="Class Description"
							id="description"
							placeholder="Enter your description..."
							rows="6"
							required
							type="textarea"
							v-model="classData.classDescription"
						/>
						<BaseInput
							label="Class Category"
							id="class category"
							type="multiselect"
							:internalSearch="false"
							@remove="onCategoryRemoved"
							@select="onCategorySelected"
							:multiSelectOptions="categoryOptions"
							:multiSelectValue="selectedCategory"
						/>
					</b-col>
					<b-col sm="6" lg="4" order="1" order-sm="2">
						<label class="form-control-label">Class Image</label>

						<div>
							<VueDropZone
								class="p-0 form-control-auto form-control"
								id="eventImage"
								style="border: 1px solid lightgray; border-radius: 5px"
								:duplicateCheck="true"
								v-on:vdropzone-files-added="addedItem"
								:options="$helpers.getVueDropZoneOptions()"
							>
							</VueDropZone>
						</div>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import VueDropZone from 'vue2-dropzone';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import CategoryItemManager from '../../../models/CategoryItem/CategoryItemManager';

export default {
	components: {
		VueDropZone,
		Multiselect,
		Card,
		CardHeader,
		CardFooter,
		BaseInput,
	},
	data() {
		return {
			classData: {
				classTitle: null,
				classDescription: null,
				classImage: null,
				categoryId: null,
			},
			selectedCategory: null,
			categoryOptions: [],
		};
	},
	watch: {
		classData: {
			handler(val) {
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		addedItem(file) {
			this.classData.classImage = file;
		},
		onCategoryRemoved() {
			this.selectedCategory = null;
			this.classData.categoryId = null;
		},
		onCategorySelected(categoryData) {
			this.classData.categoryId = categoryData.categoryId;
		},
		onChange() {
			this.$emit('onChange', this.classData);
		},
	},
	mounted() {
		this.categoryOptions = CategoryItemManager.allCategories;
	},
};
</script>
