var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{attrs:{"no-body":""}},[(_vm.footerTitle)?_c('CardFooter',{attrs:{"slot":"footer","title":_vm.footerTitle},slot:"footer"}):_vm._e(),(_vm.headerTitle)?_c('b-row',{attrs:{"slot":"header","align-v":"center"},slot:"header"},[_c('b-col',[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.headerTitle))])]),(_vm.headerButtonData)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"4"}},[_c('BaseButton',{attrs:{"disabled":!_vm.isSelecting && _vm.headerButtonData.isDisabled != null
								? _vm.headerButtonData.isDisabled
								: _vm.selected.length == 0,"size":"sm"},on:{"click":_vm.headerButtonClicked}},[_vm._v(_vm._s(_vm.headerButtonData.title))])],1):_vm._e()],1):_vm._e(),_c('BaseInput',{staticClass:"mb-0 mt-3 mx-3",attrs:{"id":"filter-input","type":"search","placeholder":"Type to Search","prependIcon":"fa fa-search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-table',{staticClass:"px-1",attrs:{"fields":_vm.tableHeaders,"items":_vm.tableData,"busy":_vm.isLoading,"filter":_vm.filter,"small":"","hover":"","responsive":"sm"},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm","no-wrap":""}})]},proxy:true},(_vm.isSelecting)?{key:"cell(selected)",fn:function(data){return [_c('input',{attrs:{"type":"checkbox"},on:{"change":function($event){return _vm.selectedItem($event, data.item)}}})]}}:null,{key:"cell(photo)",fn:function(data){return [_c('b-avatar',{attrs:{"src":data.value,"variant":"dark"}})]}},{key:"cell(nameAndPhoto)",fn:function(data){return [_c('b-avatar',{staticClass:"mr-2",attrs:{"badge-variant":"success","variant":"dark","text":data.value.firstName,"src":data.value.profilePhotoURL},scopedSlots:_vm._u([(data.value.appLeader)?{key:"badge",fn:function(){return [_c('b-icon',{attrs:{"icon":"star-fill"}})]},proxy:true}:null],null,true)}),_vm._v(" "+_vm._s(data.value.firstName)+" "+_vm._s(data.value.lastName)+" ")]}},{key:"cell(waiverStatus)",fn:function(data){return [_c('BaseSwitch',{attrs:{"value":data.value,"isDisabled":true,"type":"success"}})]}},{key:"cell(subscriptionStatus)",fn:function(data){return [_c('BaseSwitch',{attrs:{"value":data.value,"isDisabled":true,"type":data.value ? 'success' : 'primary'}})]}},{key:"cell(attendanceQuantity)",fn:function(data){return [_c('input',{staticClass:"form-control",attrs:{"type":"number","max":data.item.eventData.quantity != null
								? `${data.item.eventData.quantity}`
								: '1',"min":"0"},domProps:{"value":data.value},on:{"change":function($event){_vm.attendanceQuantityChange({
								value: parseInt($event.target.value),
								userData: data.item.userData,
							})}}})]}},{key:"cell(attendanceStatus)",fn:function(data){return [_c('select',{staticClass:"form-control",attrs:{"id":"recurrence","required":""}},_vm._l((_vm.attendanceStatusOption),function(status){return _c('option',{key:status.value,domProps:{"value":status.value}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0)]}},{key:"cell(attendanceProgress)",fn:function(data){return [_c('b-progress',{attrs:{"max":"2","value":"1","variant":"info"}})]}},{key:"cell(actions)",fn:function(row){return [(false)?_c('BaseButton',{attrs:{"size":"sm","outline":""},on:{"click":function($event){return _vm.viewClicked(row.item)}}},[_vm._v("View")]):_vm._e(),(false)?_c('BaseButton',{attrs:{"size":"sm","type":"danger"},on:{"click":function($event){return _vm.deleteClicked(row.item)}}},[_vm._v("Report")]):_vm._e()]}},{key:"cell(pendingActions)",fn:function(row){return [(row.item.classData.isPendingPayment)?_c('div',[_c('BaseButton',{attrs:{"size":"sm","type":"info"},on:{"click":function($event){return _vm.pendingClicked(row.item)}}},[_vm._v("Pending Payment")]),_c('BaseButton',{attrs:{"size":"sm","outline":"","type":"danger"},on:{"click":function($event){return _vm.declineClicked(row.item)}}},[_vm._v("Remove")])],1):_c('div',[_c('BaseButton',{attrs:{"size":"sm","outline":"","type":"danger"},on:{"click":function($event){return _vm.declineClicked(row.item)}}},[_vm._v("Decline")]),_c('BaseButton',{attrs:{"size":"sm","type":"success"},on:{"click":function($event){return _vm.acceptClicked(row.item)}}},[_vm._v("Accept")])],1)]}},{key:"cell(classActions)",fn:function(row){return [_c('BaseButton',{attrs:{"size":"sm","outline":"","type":"danger"},on:{"click":function($event){return _vm.declineClicked(row.item)}}},[_vm._v("Remove")])]}},{key:"cell(productActions)",fn:function(row){return [_c('BaseButton',{attrs:{"size":"sm","outline":""},on:{"click":function($event){return _vm.receiptClicked(row.item)}}},[_vm._v("View Receipt")])]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }