<template>
	<div>
		<Card>
			<CardHeader slot="header" title="General Information" />
			<CardFooter
				slot="footer"
				title="Customize your event image on different pages and find the perfect
							dimensions."
			/>
			<form>
				<b-row>
					<b-col sm="6" lg="8" order="2" order-sm="1">
						<BaseInput
							label="Event Title"
							id="event title"
							placeholder="Event Title"
							required
							type="text"
							v-model="eventData.eventTitle"
						/>
						<BaseInput
							label="Event Description"
							id="event description"
							placeholder="Enter your description..."
							rows="6"
							required
							type="textarea"
							v-model="eventData.eventDescription"
						/>
					</b-col>
					<b-col sm="6" lg="4" order="1" order-sm="2">
						<label class="form-control-label">Event Image</label>
						<div>
							<VueDropZone
								class="p-0 form-control-auto form-control"
								id="eventImage"
								style="border: 1px solid lightgray; border-radius: 5px"
								:duplicateCheck="true"
								v-on:vdropzone-files-added="addedItem"
								:options="$helpers.getVueDropZoneOptions()"
							>
							</VueDropZone>
						</div>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../../../components/Cards/Card.vue';
import BaseInput from '../../../../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../../../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../../../../components/BaseSwitch.vue';
import BaseButton from '../../../../../components/BaseButton.vue';
import CardHeader from '../../../../../components/Cards/CardHeader.vue';
import CardFooter from '../../../../../components/Cards/CardFooter.vue';
import Multiselect from 'vue-multiselect';
import VueDropZone from 'vue2-dropzone';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import CategoryItemManager from '../../../../../models/CategoryItem/CategoryItemManager';

export default {
	components: {
		Card,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		BaseButton,
		VueDropZone,
		Multiselect,
		CardHeader,
		CardFooter,
	},
	data() {
		return {
			eventData: {
				eventTitle: null,
				eventDescription: null,
				eventImage: null,
				eventCategories: [],
			},
			categoryOptions: [],
		};
	},
	watch: {
		eventData: {
			handler(val) {
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		addedItem(file) {
			this.eventData.eventImage = file;
		},
		onChange() {
			this.$emit('onChange', this.eventData);
		},
	},
	mounted() {
		this.categoryOptions = CategoryItemManager.allCategories;
	},
};
</script>
