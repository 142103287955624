<template>
	<div>
		<Card v-if="classData">
			<b-row v-if="selectedFacultyLeader != null" align-v="center" slot="footer">
				<b-col>
					<div
						v-if="selectedFacultyLeader"
						class="d-flex justify-content-start align-items-center"
					>
						<b-avatar :src="selectedFacultyLeader.profilePhotoURL" variant="dark" />
						<h5 class="my-0 mx-2">
							{{ selectedFacultyLeader.firstName }}
							{{ selectedFacultyLeader.lastName }}
						</h5>
					</div>
				</b-col>
			</b-row>
			<b-row class="d-flex align-items-center" style="gap: 10px">
				<b-col
					cols="auto"
					@mouseover="imageHover = true"
					@mouseleave="imageHover = false"
				>
					<VueAspectRatio ar="16:9" style="width: 250px">
						<div class="b-img-helpers card-img">
							<div :style="{ opacity: imageHover ? 0.4 : 1 }">
								<b-img
									fluid-grow
									rounded
									class="rounded"
									:src="classData.classImages[0]"
									style="height: 100%; object-fit: cover"
								/>
							</div>
							<div
								v-if="imageHover"
								style="
									height: 100%;
									width: 100%;
									border: dashed 1px black;
									display: flex;
									flex-wrap: wrap;
									align-content: center;
								"
								class="justify-content-center b-img-base"
							>
								<BaseButton type="primary" size="sm" block @click="imageClicked"
									>Edit</BaseButton
								>
							</div>
						</div>
					</VueAspectRatio>
				</b-col>
				<b-col cols="auto" class="flex-grow-1 p-0">
					<div class="align-items-left text-left flex-grow-1">
						<div class="d-flex">
							<i class="fas fa-calendar mr-2"></i>
							<h5>
								{{
									`${$helpers.formatDate(
										classData.classStart.toDate(),
										'EEEE, MMMM dd, yyyy - hh:mm a'
									)}`
								}}
							</h5>
						</div>
						<div v-if="classData.recurrence" class="d-flex">
							<i class="fas fa-redo mr-2"></i>
							<h5>
								{{ getRecurrence() }}
							</h5>
							<h5
								v-if="
									getNextDate(
										classData.recurrence,
										classData.classStart,
										classData.classEnd
									)
								"
								class="text-muted"
							>
								- Next Class:
								{{
									getNextDate(
										classData.recurrence,
										classData.classStart,
										classData.classEnd
									)
								}}
							</h5>
						</div>
						<div v-if="classData.location" class="d-flex">
							<i class="fas fa-location-arrow mr-2"></i>
							<h5 v-if="classData.location.title">
								{{ this.classData.location.title }}
							</h5>
							<h5 v-if="classData.location.description" class="text-muted">
								-
								{{ this.classData.location.description }}
							</h5>
						</div>
						<div v-if="classData.price" class="d-flex">
							<i class="fas fa-dollar mr-2"></i>
							<h5>
								{{ getPriceTop(this.classData.price) }}
							</h5>
							<h5 v-if="getPriceBottom(this.classData.price)" class="text-muted">
								-
								{{ getPriceBottom(this.classData.price) }}
							</h5>
						</div>
						<div v-if="classData.customButtonTitle" class="d-flex">
							<i class="fas fa-link mr-2"></i>
							<h5>
								{{ classData.customButtonTitle }}
							</h5>
							<h5 v-if="classData.customButtonLink" class="text-muted">
								-
								{{ classData.customButtonLink }}
							</h5>
						</div>
						<div class="d-flex">
							<i class="fas fa-users mr-2"></i>
							<h5>
								Class Members: {{ classData.classUsers ? classData.classUsers : '0' }}
							</h5>
							<h5 v-if="getClassUsersBottom()" class="text-muted">
								-
								{{ getClassUsersBottom() }}
							</h5>
						</div>
						<div v-if="classData.ageItem" class="d-flex">
							<i class="fas fa-user-shield mr-2"></i>
							<h5>Ages: {{ getAgeRestriction(classData.ageItem) }}</h5>
						</div>

						<div class="d-flex">
							<i class="fas fa-eye mr-2"></i>
							<h5>
								{{
									classData.isPrivate
										? 'Private - Users must request access'
										: 'Public - Anyone can join'
								}}
							</h5>
						</div>
					</div>
				</b-col>
				<b-col cols="auto">
					<Badge v-if="classData.pendingUsers" type="info" rounded>
						{{ classData.pendingUsers }} Pending Members
					</Badge>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Badge from '../../../../components/Badge.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import Card from '../../../../components/Cards/Card.vue';
import reoccuringOptions from '../../../../data/reoccuringOptions';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import Recurrence from '../../../../models/DataModels/Reccurrence';

export default {
	props: {
		classData: {
			type: Object,
			default: null,
		},
	},
	components: { VueAspectRatio, Card, Badge, BaseButton },
	data() {
		return {
			reoccuringOptions,
			selectedFacultyLeader: null,
			imageHover: false,
		};
	},
	methods: {
		getNextDate(recurrence, startDate, endDate) {
			if (recurrence && startDate) {
				const dates = Recurrence.getRecurrenceDates(startDate, endDate, recurrence);
				var closestDate = dates.map((date) =>
					Math.abs(new Date() - date.getTime())
				);
				var index = closestDate.indexOf(Math.min(...closestDate));
				return this.$helpers.formatDate(dates[index], "MMMM dd, yyyy 'at' hh:mm a");
			}
			return null;
		},
		getPriceTop(priceItem) {
			if (this.classData) {
				if (priceItem.subscription) {
					return this.$helpers.amountToString(priceItem.subscription.amount);
				}
				return this.$helpers.amountToString(priceItem.amount);
			} else {
				if (priceItem.oneTimePurchase) {
					return `One Time: ${this.$helpers.amountToString(priceItem.amount)}`;
				} else {
					return this.$helpers.amountToString(priceItem.amount);
				}
			}
		},
		getPriceBottom(priceItem) {
			var priceBottomString = '';
			if (priceItem.subscription) {
				if (priceItem.subscription && priceItem.amount) {
					priceBottomString += `One-time Fee: ${this.$helpers.amountToString(
						priceItem.amount
					)}`;
				}
				priceBottomString += ` - Billed: ${Recurrence.returnReadableString(
					priceItem.subscription.recurrence
				)}`;
				return priceBottomString;
			}

			if (priceItem.quantity != null) {
				var quantity = priceItem.quantity;
				//if (priceItem.purchased) {
				//	quantity = quantity - priceItem.purchased;
				//}
				if (quantity <= 0) {
					quantity = 0;
				}
				return `Tickets Available: ${priceItem.quantity}`;
			}
			return null;
		},
		getAgeRestriction(ageItem) {
			if (ageItem.endAge) {
				return `${ageItem.startAge} - ${ageItem.endAge}`;
			} else {
				return `${ageItem.startAge}+`;
			}
		},
		getClassUsersBottom() {
			if (this.classData.classUsers != null) {
				if (this.classData.classMaxUsers != null) {
					if (this.classData.classMaxUsers <= this.classData.classUsers) {
						return 'Maximum Capacity';
					} else {
						return `${
							this.classData.classMaxUsers - this.classData.classUsers
						} Spots Available`;
					}
				}
			}
			return null;
		},
		getRecurrence() {
			return Recurrence.returnReadableString(this.classData.recurrence);
		},
		imageClicked() {
			this.$emit('imageClicked');
		},
	},
	mounted() {
		if (this.classData.classLeaders) {
			this.selectedFacultyLeader = AppUserManager.allClassLeaders.find(
				(e) => e.uid == this.classData.classLeaders[0]
			);
		}

		if (this.classData.price) {
			if (this.classData.price.quantity != null) {
				//this.isSoldOut = this.classData.price.quantity <= 0;
			}
		}
		this.reoccuringOptions = reoccuringOptions;
	},
};
</script>
