<template>
	<div>
		<Card>
			<CardFooter
				slot="footer"
				title="TIP: Any changes to the title, description, or start time will reflect for this entire item."
			/>
			<form>
				<base-input
					:label="eventData ? 'Event Title' : 'Class Title'"
					id="title"
					placeholder="Title"
					:disabled="isDisabled"
					required
					v-model="title"
				/>

				<base-input :label="eventData ? 'Event Description' : 'Class Description'">
					<textarea
						placeholder="Enter your description..."
						v-model="description"
						:disabled="isDisabled"
						class="form-control"
						id="description"
						rows="4"
						required
					></textarea>
				</base-input>
				<b-row v-if="classData != null">
					<b-col>
						<base-input
							label="Class Category"
							id="class category"
							type="multiselect"
							:internalSearch="false"
							@remove="onCategoryRemoved"
							@select="onCategorySelected"
							:multiSelectOptions="categoryOptions"
							:multiSelectValue="category"
						/>
					</b-col>
				</b-row>
				<b-row
					v-if="
						classData != null || (eventData != null && eventData.recurrence == null)
					"
				>
					<b-col cols="12" md="6">
						<base-input
							:label="eventData ? 'Event Start' : 'Class Start'"
							id="start time"
							placeholder="Start Time"
							:disabled="isDisabled"
							required
							type="datetime-local"
							:max="
								this.$helpers.formatDate(
									this.$helpers.addYear(new Date(), 1),
									`yyyy-MM-dd'T'HH:mm`
								)
							"
							v-model="start"
						/>
					</b-col>
					<b-col cols="12" md="6">
						<base-input
							:label="eventData ? 'Event End' : 'Class End (recurring)'"
							id="end time"
							placeholder="End Time"
							:disabled="isDisabled"
							required
							type="datetime-local"
							:max="
								this.$helpers.formatDate(
									this.$helpers.addYear(new Date(), 1),
									`yyyy-MM-dd'T'HH:mm`
								)
							"
							v-model="end"
						/>
					</b-col>
				</b-row>
				<b-row v-if="showWeekDays">
					<b-col>
						<ButtonCheckboxGroup
							title="Reoccuring Days"
							:options="daysOfWeek"
							required
							@change="onDaysOfWeekCheckboxChanged"
						/>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CategoryItemManager from '../../../models/CategoryItem/CategoryItemManager';
import ButtonCheckboxGroup from '../../../components/ButtonCheckboxGroup.vue'
export default {
	props: {
		eventData: null,
		classData: null,
		isDisabled: false,
	},
	components: { Card, CardFooter, ButtonCheckboxGroup },
	computed: {},
	data() {
		return {
			title: null,
			description: null,
			start: null,
			end: null,
			category: null,
			categoryOptions: [],
			recurrenceDays: [],
			daysOfWeek: [],
			showWeekDays: false,
		};
	},
	watch: {
		title: {
			handler(val) {
				return this.checkIfUpdate();
			},
		},
		description: {
			handler(val) {
				return this.checkIfUpdate();
			},
		},
		start: {
			handler(val) {
				return this.checkIfUpdate();
			},
		},
		end: {
			handler(val) {
				return this.checkIfUpdate();
			},
		},
		recurrenceDays: {
			handler(val) {
				return this.checkIfUpdate();
			},
		},
		category: {
			handler(val) {
				return this.checkIfUpdate();
			},
		}
	},
	methods: {
		checkIfUpdate() {
			if (this.eventData) {
				const isDifferent =
					this.title != this.eventData.eventTitle ||
					this.description != this.eventData.eventDescription ||
					this.start != this.eventData.eventStart;
					this.end != this.eventData.eventEnd
				this.$emit('onChange', {
					title: this.title,
					description: this.description,
					start: this.start,
					end: this.end,
					isDifferent: isDifferent,
				});
			} else if (this.classData) {
				const isDifferent =
					this.title != this.classData.classTitle ||
					this.description != this.classData.classDescription ||
					this.start != this.classData.classStart ||
					this.end != this.classData.classEnd ||
					this.recurrenceDays != this.classData.recurrence.recurrenceDays ||
					this.category.categoryId != this.classData.categoryId
				this.$emit('onChange', {
					title: this.title,
					description: this.description,
					start: this.start,
					end: this.end,
					category: this.category,
					recurrenceDays: this.recurrenceDays,
					isDifferent: isDifferent,
				});
			}
		},
		resetDaysOfWeek() {
			return [
				{
					name: 'Monday',
					selected: false,
				},
				{
					name: 'Tuesday',
					selected: false,
				},
				{
					name: 'Wednesday',
					selected: false,
				},
				{
					name: 'Thursday',
					selected: false,
				},
				{
					name: 'Friday',
					selected: false,
				},
				{
					name: 'Saturday',
					selected: false,
				},
				{
					name: 'Sunday',
					selected: false,
				},
			];
		},
		onDaysOfWeekCheckboxChanged(values) {
			const daysofWeek = [];
			values.forEach((index) => {
				daysofWeek.push(this.daysOfWeek[index].name.toLowerCase());
			});
			this.recurrenceDays = daysofWeek;
		},
		onCategoryRemoved() {
			this.category = null;
			//this.classData.categoryId = null;
		},
		onCategorySelected(categoryData) {
			this.category = categoryData
		},
	},
	mounted() {
		this.daysOfWeek = this.resetDaysOfWeek();
		if (this.eventData) {
			this.title = this.eventData.eventTitle;
			this.description = this.eventData.eventDescription;
			this.start = this.$helpers.formatDate(
				this.eventData.eventStart,
				`yyyy-MM-dd'T'HH:mm`
			);
			this.end = this.$helpers.formatDate(this.eventData.eventEnd, `yyyy-MM-dd'T'HH:mm`)
		} else if (this.classData) {
			this.title = this.classData.classTitle;
			this.description = this.classData.classDescription;
			this.start = this.$helpers.formatDate(
				this.classData.classStart,
				`yyyy-MM-dd'T'HH:mm`
			);
			this.end = this.$helpers.formatDate(this.classData.classEnd, `yyyy-MM-dd'T'HH:mm`)
			this.categoryOptions = CategoryItemManager.allCategories.filter((item) => {
				var isClass = true
					var isProduct = false
					var isEvent = false
					if (item.isClass != null){isClass = item.isClass}
					if (item.isProduct != null){isProduct = item.isProduct}
					return isClass && !isProduct
			});
			if (this.classData.categoryId){
				this.category = this.categoryOptions.find((e) => e.categoryId == this.classData.categoryId)
			}
			if (this.classData.recurrence){
				this.showWeekDays = true
				var recurrenceDays = this.classData.recurrence.recurrenceDays
				this.daysOfWeek.forEach((option) => {
					const findDay = recurrenceDays.find((x) => x == option.name.toLowerCase())
					if (findDay){
						option.selected = true
					}
				})
			}
		}
		
	},
};
</script>
