<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<ClassesTable
			:isLoading="isLoading"
			:tableData="allUserClasses"
			@viewClicked="openClass"
		/>
	</div>
</template>
<script>
import Card from '../../../../components/Cards/Card.vue';
import ClassesTable from '../../ClassesViews/ClassesTable.vue';
import { DashboardChildren } from '../../../../routes/routesNames';
import ClassItemManager from '../../../../models/ClassItem/ClassItemManager';

export default {
	props: {
		userData: null,
		refresh: null,
	},
	data() {
		return {
			isLoading: false,
			allUserClasses: [],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserClasses();
				}
			},
		},
	},
	methods: {
		getUserClasses() {
			this.isLoading = true;
			ClassItemManager.getUserClasses(this.userData.uid)
				.then((items) => {
					if (items) {
						this.allUserClasses = items;
					}
					this.isLoading = false;
				})
				.catch((error) => {});
		},
		openClass(classData) {
			this.$router.push({
				name: DashboardChildren.classView.name,
				params: {
					classData: classData,
				},
				query: {
					classId: classData.classId,
				},
			});
		},
	},
	components: { Card, ClassesTable },
};
</script>
