import { collection, collectionGroup, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import store from '../../store/store';

export default class InvoiceManager {
	static getAllInvoices() {
		return new Promise((resolve, reject) => {
			this.allInvoices = [];
			const firestore = getOrganizationFirestore();
			const invoicesRef = collectionGroup(firestore, 'invoices');
			getDocs(invoicesRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								this.allInvoices = items;
								return resolve(items);
							}
							counter += 1;
						});
						return resolve(null);
					} else {
						//return reject('No users found.');
						return resolve(null);
					}
				})
				.catch((error) => {
					//return reject(error.message);
					return resolve(null);
				});
		});
	}

	static deleteInvoice(uid, invoiceId){
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const invoiceRef = doc(firestore, `${FirebaseManager.allUserRef(firestore).path}/${uid}/invoices/${invoiceId}`)
			deleteDoc(invoiceRef).then(() => {
				return resolve();
			})
			.catch((error) => {
				return reject(error);
			});
		})
	}

	static addInvoice(uid, data) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const publicRef = store.state.publicInformation.publicRef ? store.state.publicInformation.publicRef : ''
			const userInvoiceRef = collection(firestore, `${FirebaseManager.allUserRef(firestore).path}/${uid}/invoices`)
			const newInvoiceRef = doc(userInvoiceRef);

			var invoiceData = data
			invoiceData.created = new Date()
			invoiceData.invoiceId = newInvoiceRef.id
			invoiceData.status = 'pending'
			invoiceData.invoice_url = `https://client.dominionstudio.com/${publicRef}/invoice/${uid}/${newInvoiceRef.id}`
			
			setDoc(newInvoiceRef, invoiceData)
			.then(() => {
				return resolve(invoiceData);
			})
			.catch((error) => {
				return reject(error);
			});
		});
	}

}
