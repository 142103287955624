<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new resource for all users to view.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form class="pt-1 pb-1">
                    <div class="my-3">
						<BaseDropbox
							is-file
							ar="4:1"
							uploadDefaultText="UPLOAD FILE"
							:accepted-files="'application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation'"
							@onFile="onFileChange"
						>
						</BaseDropbox>
					</div>
					<BaseInput
						label="Resource Title"
						id="title"
						placeholder="Resource Title"
						required
						type="text"
						v-model="resource.resourceTitle"
					/>
					<BaseInput
						label="Resource Description"
						id="description"
						placeholder="Enter your resource description..."
						rows="9"
						required
						type="textarea"
						v-model="resource.resourceDescription"
					/>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Resource</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BaseDropbox from '../../components/Inputs/BaseDropbox.vue'
import BaseSwitch from '../../components/BaseSwitch.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';
import ClassItemManager from '../../models/ClassItem/ClassItemManager'
import ResourceItemManager from '../../models/ResourceItem/ResourceItemManager';

export default {
	props: {
		fileData: null,
		headerTitle: {
			type: String,
			default: 'New Resource',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		classData: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
            resourceItem: null,
			resource: {
				resourceTitle: null,
				resourceDescription: null,
                resourceFile: null,
                resourceContentType: null
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
        onFileChange(file) {
			this.resourceItem = file;
			this.resource.resourceTitle = this.resourceItem.name.replace(/\s+/g, '');
			this.resource.resourceContentType = this.resourceItem.type;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			if (this.resourceItem == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else if (
				this.$helpers.isBlank(this.resource.resourceTitle) ||
				this.$helpers.isBlank(this.resource.resourceDescription)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				if (this.classData){
					this.addClassResourceItem(this.classData.classId)
				} else {
					this.addResourceItem()
				}
			}
		},
        addResourceItem(){
            this.isLoading = true;
            ResourceItemManager.addResourceToDB(this.resource, this.resourceItem, this.resource.resourceTitle, this.resource.resourceContentType).then((data) => {
						this.$emit('newResource', data);
						this.isLoading = false;
						this.closeModal();
					})
					.catch((errorMessage) => {
						this.isLoading = false;
						this.showNotification({
							type: 'danger',
							message: errorMessage,
						});
					});
        },
		addClassResourceItem(classId){
			this.isLoading = true;
			ClassItemManager.addClassResourceToDB(classId, this.resource, this.resourceItem, this.resource.resourceTitle, this.resource.resourceContentType).then((data) => {
						this.$emit('newResource', data);
						this.isLoading = false;
						this.closeModal();
					})
					.catch((errorMessage) => {
						this.isLoading = false;
						this.showNotification({
							type: 'danger',
							message: errorMessage,
						});
					});
		}
	},
	mounted() {
		
	},
	components: { Modal, BaseInput, BaseDropbox, BaseSwitch },
};
</script>
