<template>
	<div v-if="priceItem">
		<b-overlay :show="isLoading" rounded="sm">
			<Card
				shadow
				header-classes="bg-white pb-5"
				body-classes="px-lg-3 py-lg-3"
				class="border-0"
			>
				<template>
					<base-alert v-if="errorMessage" type="danger">
						<div class="containter m-1">
							<h6 class="text-white">{{ errorMessage }}</h6>
						</div>
					</base-alert>
					<form ref="purchaseForm">
						<b-row>
							<b-col>
								<MemberHeaderCard v-if="userData" :userData="userData" />
							</b-col>
						</b-row>
						<div v-if="false" class="input-group input-group-alternative mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text"><i class="ni ni-email-83"></i></span>
							</div>
							<input
								class="form-control form-control-alternative"
								ref="email"
								id="email"
								:required="true"
								placeholder="Email"
								type="email"
								v-model="userData.email"
							/>
						</div>
						<div class="row">
							<div class="col pt-2 pb-2">
								<div class="input-group input-group-alternative">
									<div class="input-group-prepend">
										<span class="input-group-text"><i class="ni ni-tag"></i></span>
									</div>
									<input
										class="form-control form-control-alternative"
										ref="couponCode"
										id="couponCode"
										placeholder="Coupon Code"
										type="text"
										v-model="checkoutData.couponCode"
										oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
									/>
								</div>
							</div>
							<div class="col-sm-auto pt-2 pb-2">
								<base-button
									block
									style="min-height: 100%"
									type="secondary"
									@click="
										() => {
											couponCodeClicked();
										}
									"
								>
									Apply
								</base-button>
							</div>
						</div>
						<div v-if="checkoutData.validCouponData" class="mt-1">
							<p
								style="
									color: green;
									font-size: 12px;
									font-family: Open Sans, sans-serif;
								"
							>
								{{ getValidCouponString() }} has been applied to this order.
							</p>
						</div>
						<div class="p-0">
							<div class="pb-2 dropdown-divider"></div>
							<div>
								<div class="row auto">
									<div class="col">
										<p style="font-size: 12px; font-family: Open Sans, sans-serif">
											Subtotal:
										</p>
									</div>
									<div class="col">
										<p
											style="
												text-align: right;
												font-size: 12px;
												font-family: Open Sans, sans-serif;
											"
										>
											{{ toCurrencyFormat(this.checkoutData.subAmount) }}
										</p>
									</div>
								</div>
								<div v-if="checkoutData.validCouponData" class="row auto">
									<div class="col">
										<p
											style="
												color: green;
												font-size: 12px;
												font-family: Open Sans, sans-serif;
											"
										>
											Coupon Applied:
										</p>
									</div>
									<div class="col">
										<p
											style="
												color: green;
												text-align: right;
												font-size: 12px;
												font-family: Open Sans, sans-serif;
											"
										>
											-({{ getCouponDiscount(this.checkoutData.subAmount)[1] }})
										</p>
									</div>
								</div>
								<div class="row auto">
									<div class="col">
										<p style="font-size: 12px; font-family: Open Sans, sans-serif">
											Transaction Fee:
										</p>
									</div>
									<div class="col">
										<p
											style="
												text-align: right;
												font-size: 12px;
												font-family: Open Sans, sans-serif;
											"
										>
											{{ getTransactionFee(this.checkoutData.finalAmount)[1] }}
										</p>
									</div>
								</div>
								<div class="row auto">
									<div class="col">
										<p style="font-size: 12px; font-family: Open Sans, sans-serif">
											Total:
										</p>
									</div>
									<div class="col">
										<p
											style="
												text-align: right;
												font-size: 12px;
												font-weight: bold;
												font-family: Open Sans, sans-serif;
											"
										>
											{{ getFinalAmount(this.checkoutData.finalAmount)[1] }}
										</p>
									</div>
								</div>
							</div>

							<div class="pt-2 dropdown-divider"></div>
						</div>
						<div class="text-center mt-3">
							<BaseButton
								@click="submitPayment"
								:disabled="isLoading || userData == null"
								primary
								block
								class="my-1"
							>
								<span v-if="!isLoading">
									{{
										userData == null
											? `Select Customer`
											: `Collect Payment
									${getFinalAmount(this.checkoutData.finalAmount)[1]}`
									}}</span
								>
							</BaseButton>
						</div>
					</form>
				</template>
			</Card>
		</b-overlay>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import Card from '../../../components/Cards/Card.vue';
import CouponItemManager from '../../../models/CouponItem/CouponItemManager';
import MemberHeaderCard from '../MemberViews/BaseMemberViews/MemberHeaderCard.vue';

export default {
	props: {
		userData: null,
		eventData: null,
		productData: null,
		priceItem: null,
		isLoading: false,
	},
	data() {
		return {
			errorMessage: null,
			checkoutData: {
				couponCode: null,
				validCouponData: null,
				itemQuantity: 1,
				dominionFee: 0,
				transactionFee: 0,
				subAmount: 0,
				finalAmount: 0,
			},
			stripeFee: 0.027,
			dominionFee: 0.023,
		};
	},
	watch: {
		checkoutData: {
			deep: true,
			handler(newValue, oldValue) {
				this.errorMessage = null;
				return;
			},
		},
		'checkoutData.validCouponData': {
			handler(val) {
				return this.setAmounts();
			},
		},
	},
	methods: {
		couponCodeClicked() {
			if (this.checkoutData.couponCode) {
				this.isLoading = true;
				CouponItemManager.fetchCouponByCode(this.checkoutData.couponCode).then(
					(couponData) => {
						if (couponData) {
							this.errorMessage = null;
							this.checkoutData.validCouponData = couponData;
							this.isLoading = false;
						} else {
							this.errorMessage = 'Invalid Coupon';
							this.checkoutData.validCouponData = null;
							this.isLoading = false;
						}
					}
				);
			}
		},
		toCurrencyFormat(amount) {
			return this.$helpers.amountToString(amount * 100);
		},
		getSubAmount(priceItem, quantity) {
			var itemAmount = 0;
			itemAmount = (priceItem.amount * quantity).toFixed(2) / 100;
			return parseFloat(itemAmount);
		},
		getValidCouponString() {
			return CouponItemManager.getDiscountAmount(
				this.checkoutData.validCouponData
			)[1];
		},
		getCouponDiscount(amount) {
			var newItemAmount = amount;
			const couponItem = this.checkoutData.validCouponData;
			if (couponItem) {
				var discountAmount = 0;
				var discountText = null;
				if (couponItem.amountOff) {
					discountAmount = CouponItemManager.getDiscountAmount(couponItem)[0];
					discountText = CouponItemManager.getDiscountAmount(couponItem)[1];
					newItemAmount -= discountAmount;
				} else if (couponItem.percentOff) {
					discountAmount =
						amount * CouponItemManager.getDiscountAmount(couponItem)[0];
					discountText = this.toCurrencyFormat(parseInt(discountAmount));
					newItemAmount -= discountAmount;
				}
				return [discountAmount, discountText, newItemAmount];
			} else {
				return [0, null, newItemAmount];
			}
		},
		getDominionFee(amount) {
			if (amount <= 0) {
				return 0;
			}
			const finalAmount = this.getFinalAmount(amount)[0];
			return parseFloat((finalAmount * this.dominionFee).toFixed(2));
		},
		getTransactionFee(amount) {
			if (amount <= 0) {
				this.checkoutData.transactionFee = 0;
				return [parseFloat(0), this.toCurrencyFormat(0)];
			}
			if (this.checkTransactionFee()) {
				this.checkoutData.transactionFee = this.checkTransactionFee();
				return this.checkTransactionFee();
			}
			const step1 = amount + 0.05;
			const step2 = 1 - (this.stripeFee + this.dominionFee);
			const step3 = step1 / step2 - amount;
			const finalFeeAmount = step3.toFixed(2);
			return [parseFloat(finalFeeAmount), this.toCurrencyFormat(finalFeeAmount)];
		},
		checkTransactionFee() {
			if (this.eventData) {
				const transactionFee = this.eventData.transactionFee;
				if (!transactionFee) {
					return null;
				} else if (transactionFee <= 0) {
					return [parseFloat(0), this.toCurrencyFormat(0)];
				}
				const fixedTransactionFee = transactionFee.toFixed(2) / 100;
				return [
					parseFloat(fixedTransactionFee),
					this.toCurrencyFormat(fixedTransactionFee),
				];
			} else if (this.productData) {
				const transactionFee = this.productData.transactionFee;
				if (!transactionFee) {
					return null;
				} else if (transactionFee <= 0) {
					return [parseFloat(0), this.toCurrencyFormat(0)];
				}
				const fixedTransactionFee = transactionFee.toFixed(2) / 100;
				return [
					parseFloat(fixedTransactionFee),
					this.toCurrencyFormat(fixedTransactionFee),
				];
			}
			return null;
		},
		getFinalAmount(amount) {
			if (amount <= 0) {
				return [parseFloat(0), this.toCurrencyFormat(0)];
			}
			const transactionFee = this.getTransactionFee(amount)[0];
			const finalAmount = amount + transactionFee;
			return [parseFloat(finalAmount), this.toCurrencyFormat(finalAmount)];
		},
		setAmounts() {
			var itemAmount = this.getSubAmount(
				this.priceItem,
				this.checkoutData.itemQuantity
			);
			this.checkoutData.subAmount = itemAmount;
			if (this.checkoutData.validCouponData) {
				itemAmount = this.getCouponDiscount(itemAmount)[2];
			}
			this.checkoutData.finalAmount = itemAmount;
		},
		prepareCheckoutItem() {
			const itemUniqueId = this.eventData
				? this.eventData.eventId
				: this.productData.productId;
			var itemTitle = this.eventData
				? `${this.eventData.eventTitle} · ${this.$helpers.formatDate(
						this.eventData.eventStart.toDate(),
						"MMM d, yyyy 'at' h:mm a"
				  )})`
				: `${this.productData.productTitle}`;
			if (this.checkoutData.validCouponData) {
				itemTitle += ` - (${this.checkoutData.validCouponData.couponCode} Discount Applied)`;
			}
			itemTitle += ` *(In-Person Purchase)*`;
			return [itemTitle, itemUniqueId];
		},
		submitPayment() {
			const [itemTitle, itemUniqueId] = this.prepareCheckoutItem();
			var checkoutData = {
				name: `${this.userData.firstName} ${this.userData.lastName}`,
				email: this.userData.email,
				description: itemTitle,
				amount: parseInt(
					this.getFinalAmount(this.checkoutData.finalAmount)[0] * 100
				),
				stripeAccount: this.$store.state.paymentInformation.stripeAccountId,
				uniqueId: itemUniqueId,
				uniqueName: this.eventData ? 'eventItem' : 'productItem',
				quantity: this.checkoutData.itemQuantity,
				couponItem: this.checkoutData.validCouponData,
				customerId: this.userData.stripeId,
			};

			this.$emit('confirmPayment', checkoutData);
		},
	},
	mounted() {
		this.setAmounts();
	},
	components: { Card, MemberHeaderCard, BaseButton },
};
</script>
