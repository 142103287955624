<template>
	<div>
		<Card v-if="isNew || eventData != null" :no-body="price.isFree">
			<CardHeader
				slot="header"
				title="Pricing Information"
				:endSwitch="
					isNew
						? { title: 'Free Event?' }
						: !price.amount && classData == null && isNewClass
						? { title: 'Free Class' }
						: null
				"
				:endSwitchValue="price.isFree"
				@change="eventPriceSwitchChanged"
			/>
			<div v-if="!price.isFree">
				<b-row>
					<b-col>
						<CurrencyInput
							label="Amount"
							v-model="price.amount"
							:disabled="!isNew && !isNewClass"
						/>
					</b-col>
					<b-col cols="auto">
						<BaseInput
							label="Currency"
							id="currency"
							placeholder="Currency"
							disabled
							v-model="
								this.$store.state.accountInformation.paymentInformation.currency
							"
							:required="!price.isFree"
							type="text"
						/>
					</b-col>
				</b-row>
				<b-row v-if="false">
					<b-col class="d-flex align-items-center" style="gap: 5px">
						<ButtonCheckboxGroup
							:showBorder="false"
							:options="checkboxOptions"
							@change="onCheckboxChanged"
						/>
					</b-col>
				</b-row>
				<b-row v-if="false">
					<b-col class="d-flex align-items-center" style="gap: 5px">
						<ButtonCheckboxGroup
							:showBorder="false"
							:options="checkboxOnePriceOptions"
							@change="onCheckboxPriceChange"
						/>
					</b-col>
				</b-row>
				<b-row v-if="false">
					<b-col>
						<CurrencyInput v-model="eventData.transactionFee" />
					</b-col>
				</b-row>
			</div>
		</Card>
		<Card
			v-if="classData != null || isNewClass"
			:no-body="!price.subscriptionBilling"
		>
			<CardHeader
				slot="header"
				title="Class Pricing Information"
				:endSwitch="{ title: 'Price Class?', disabled: classData != null }"
				:endSwitchValue="price.subscriptionBilling"
				@change="onSubscriptionSwitchChanged"
			/>
			<div v-if="price.subscriptionBilling">
				<b-row>
					<b-col>
						<CurrencyInput
							label="Amount"
							v-model="price.amount"
							:disabled="!isNew && !isNewClass"
						/>
					</b-col>
					<b-col cols="auto">
						<BaseInput
							label="Currency"
							id="currency"
							placeholder="Currency"
							disabled
							v-model="
								this.$store.state.accountInformation.paymentInformation.currency
							"
							:required="!price.isFree"
							type="text"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<BaseInput
							label="Billing Recurrence"
							id="recurrence"
							placeholder="Billing Recurrence"
							required
							type="text"
						>
							<select
								class="form-control"
								id="recurrence"
								v-model="price.subscription.recurrence.recurrenceTime"
								:disabled="!isNew && !isNewClass"
								required
							>
								<option
									v-for="recurrence in recurrenceOptions"
									:key="recurrence.value"
									:value="recurrence.value"
								>
									{{ recurrence.name }}
								</option>
							</select></BaseInput
						>
					</b-col>
				</b-row>
				<b-row>
					<b-col sm="6" lg="6">
						<BaseInput
							label="Billing Recurrence Start Date"
							id="start date"
							placeholder="Recurrence Start Date"
							type="datetime-local"
							:disabled="!isNew && !isNewClass"
							required
							v-model="price.subscription.subscriptionStart"
						/>
					</b-col>
					<b-col sm="6" lg="6">
						<BaseInput
							label="Billing Recurrence End Date"
							id="event date"
							placeholder="Recurrence End Date"
							type="datetime-local"
							:disabled="!isNew && !isNewClass"
							:max="
								this.$helpers.formatDate(
									this.$helpers.addYear(price.subscription.subscriptionStart, 1),
									`yyyy-MM-dd'T'HH:mm`
								)
							"
							:min="price.subscription.subscriptionStart"
							required
							v-model="price.subscription.subscriptionEnd"
						/>
					</b-col>
				</b-row>
			</div>
		</Card>
		<Card
			v-if="classData != null || isNewClass"
			:no-body="!price.oneTimePurchase"
		>
			<CardHeader
				slot="header"
				title="One-Time Purchase Information"
				:endSwitch="{ title: '', disabled: classData != null }"
				:endSwitchValue="price.oneTimePurchase"
				@change="oneTimeSwitchChanged"
			/>
			<b-row v-if="price.oneTimePurchase">
				<b-col>
					<CurrencyInput
						label="Amount"
						v-model="price.oneTimePuchaseAmount"
						:disabled="!isNewClass"
					/>
				</b-col>
			</b-row>
		</Card>
		<Card v-if="!price.isFree && classData == null && !isNewClass">
			<CardHeader
				slot="header"
				title="Price Quantity"
				tooltip="The maximum amount of tickets that can be purchased for this event."
			/>

			<b-row v-if="options.length > 0">
				<b-col>
					<ButtonRadioGroup
						:showBorder="false"
						:options="options"
						@change="onRadioChanged"
					>
						<div
							v-if="options[1].value == selected"
							:slot="options[1].value"
							class="mt-0"
						>
							<b-row class="mx-1">
								<b-col>
									<BaseInput
										label="Quantity Available"
										id="quantity available"
										placeholder="Quantity Available"
										type="number"
										:min="isNew ? '1' : '0'"
										v-model="price.quantity"
									/>
								</b-col>
							</b-row>
						</div>
					</ButtonRadioGroup>
				</b-col>
			</b-row>
		</Card>
		<Card :no-body="!showCustomButtonOption">
			<CardHeader
				slot="header"
				title="Custom Button"
				tooltip="Replace the default event button with your own custom button title or link. By adding a button link, you will not be able to accept payment for this event."
				:endSwitch="{ title: '' }"
				:endSwitchValue="showCustomButtonOption"
				@change="eventCustomButtonSwitchChanged"
			/>
			<b-row v-if="showCustomButtonOption">
				<b-col>
					<BaseInput
						label="Button Title"
						id="button title"
						placeholder="Button Title"
						type="text"
						v-model="price.eventButtonTitle"
					/>
				</b-col>
				<b-col>
					<BaseInput
						label="Button Link (optional)"
						id="button link"
						placeholder="Button Link"
						type="text"
						:disabled="!price.isFree && classData == null && !isNewClass"
						v-model="price.eventButtonLink"
					/>
				</b-col>
			</b-row>
		</Card>
		<Card
			v-if="!price.isFree && classData == null && !isNewClass"
			:no-body="true"
		>
			<CardHeader
				slot="header"
				title="Allow Subscription Purchases"
				tooltip="Users can purchase this item with an active subscription."
				:endSwitch="{ title: '' }"
				:endSwitchValue="price.isPrepaidOption"
				@change="onPrepaidSwitchChanged"
			/>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseButton from '../../../components/BaseButton.vue';
import ButtonRadioGroup from '../../../components/ButtonRadioGroup.vue';
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ButtonCheckboxGroup from '../../../components/ButtonCheckboxGroup.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import store from '../../../store/store';
import Recurrence from '../../../models/DataModels/Reccurrence';
export default {
	components: {
		Card,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		BaseButton,
		VueDropZone,
		ButtonRadioGroup,
		ButtonCheckboxGroup,
		CardHeader,
		CardFooter,
	},
	props: {
		isNew: false,
		isNewClass: false,
		eventData: null,
		classData: null,
		showCustomButton: {
			type: Boolean,
			default: false,
		},
		showOneTimeOption: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showCustomButtonOption: false,
			selected: null,
			showTransactionFee: false,

			price: {
				isFree: false,
				isPrepaidOption: true,
				amount: 0,
				subscription: {
					recurrence: {
						recurrenceTime: null,
					},
					subscriptionEnd: null,
					subscriptionStart: null,
				},
				subscriptionBilling: true,
				oneTimePurchase: false,
				oneTimePuchaseAmount: 0,
				quantity: null,
				eventButtonTitle: null,
				eventButtonLink: null,
				eventCurrency: 'US Dollar - USD ($)',
				transactionFee: null,
			},
			recurrenceOptions: [],
			options: [],
			checkboxOptions: [
				{
					name: 'Include Default Transaction Fee',
					tooltip:
						'The credit card transaction fee of both Stripe & Dominion will be passed onto the customer on purchase.',
					selected: true,
					disabled: true,
				},
			],
			checkboxOnePriceOptions: [],
		};
	},
	watch: {
		eventData: {
			handler() {
				return this.setupView();
			},
			deep: true,
		},
		price: {
			handler(val) {
				if (!val.eventButtonTitle) {
					this.price.eventButtonTitle = null;
				}
				if (!val.eventButtonLink) {
					this.price.eventButtonLink = null;
				}
				if (val.quantity) {
					this.price.quantity = parseInt(val.quantity);
				}
				return this.checkIfUpdate();
			},
			deep: true,
		},
		showCustomButton: {
			handler(val) {
				this.showCustomButtonOption = val;
			},
			immediate: true,
		},
	},
	methods: {
		setQuantityOptions() {
			var unlimitedOption = {
				value: 1,
				name: 'Unlimited number of tickets available',
			};
			var limitedOption = {
				value: 2,
				name: 'Limited number of tickets available',
			};
			if (!this.isNew && this.price.quantity != null) {
				limitedOption['selected'] = true;
				unlimitedOption['selected'] = false;
				this.onRadioChanged(2);
			} else {
				limitedOption['selected'] = false;
				unlimitedOption['selected'] = true;
			}
			this.options = [unlimitedOption, limitedOption];
		},
		setOneTimeOptions() {
			var checkOneTimePurchase = {
				name: 'One-Time Purchase',
				tooltip:
					'One time cost for this recurrence event. For example: A 2-day dance camp with one overall cost.',
			};
			if (!this.isNew) {
				checkOneTimePurchase['disabled'] = true;
				checkOneTimePurchase['selected'] = this.price.oneTimePurchase;
			} else {
				checkOneTimePurchase['disabled'] = false;
				checkOneTimePurchase['selected'] = false;
			}
			this.checkboxOnePriceOptions = [checkOneTimePurchase];
		},
		onRadioChanged(value) {
			if (value == 1) {
				this.price.quantity = null;
			}
			this.selected = value;
		},
		onCheckboxChanged(values) {},
		onCheckboxPriceChange(values) {
			if (this.checkboxOnePriceOptions[values]) {
				this.price.oneTimePurchase =
					this.checkboxOnePriceOptions[0].name ==
					this.checkboxOnePriceOptions[values].name;
			} else {
				this.price.oneTimePurchase = false;
			}
		},
		eventPriceSwitchChanged(value) {
			this.price.isFree = value;
			this.price.amount = 0;
			this.adjustPrice();
		},
		onSubscriptionSwitchChanged(value) {
			this.price.subscriptionBilling = value;
			this.price.isFree = !value;
			this.price.amount = 0;
		},
		oneTimeSwitchChanged(value) {
			this.price.oneTimePurchase = value;
			this.price.oneTimePurchaseAmount = 0;
		},
		onPrepaidSwitchChanged(value) {
			this.price.isPrepaidOption = value;
		},
		eventCustomButtonSwitchChanged(value) {
			this.showCustomButtonOption = value;
			if (!value){
				this.price.eventButtonTitle = null
				this.price.eventButtonLink = null
			}
		},
		adjustPrice() {
			if (
				!this.price.isFree &&
				this.price.amount > 0 &&
				this.classData == null &&
				!this.isNewClass
			) {
				this.price.eventButtonLink = null;
			}
		},
		checkIfUpdate() {
			if (this.eventData) {
				const isDifferent = () => {
					const quantityCheck =
						this.price.quantity != null && this.eventData.price.quantity != null
							? this.price.quantity != this.eventData.price.quantity
							: false;
					const buttonTitleCheck =
						this.price.eventButtonTitle != this.eventData.eventButtonTitle;
					const buttonTitleLinkCheck =
						this.price.eventButtonLink != this.eventData.eventButtonLink;
					const subscriptionCheck =
						this.price.isPrepaidOption != null &&
						this.eventData.isPrepaidOption != null
							? this.price.isPrepaidOption != this.eventData.isPrepaidOption
							: true;
					return (
						quantityCheck ||
						buttonTitleCheck ||
						buttonTitleLinkCheck ||
						subscriptionCheck
					);
				};
				this.$emit('onChange', {
					price: this.price,
					isDifferent: isDifferent(),
				});
			} else if (this.classData) {
				const isDifferent = () => {
					const quantityCheck =
						this.price.quantity != null && this.classData.price.quantity != null
							? this.price.quantity != this.classData.price.quantity
							: false;
					const buttonTitleCheck =
						this.price.eventButtonTitle != this.classData.customButtonTitle;
					const buttonTitleLinkCheck =
						this.price.eventButtonLink != this.classData.customButtonLink;
					return quantityCheck || buttonTitleCheck || buttonTitleLinkCheck;
				};
				this.$emit('onChange', {
					price: this.price,
					isDifferent: isDifferent(),
				});
			} else {
				this.onChange();
			}
		},
		onChange() {
			//this.adjustPrice();
			//this.setQuantityOptions();
			//this.setOneTimeOptions();
			this.$emit('onChange', { price: this.price });
		},
		setupView() {
			if (this.eventData) {
				this.showCustomButton =
					this.eventData.eventButtonTitle != null ||
					this.eventData.eventButtonLink != null;

				if (this.eventData.price) {
					this.price.isFree = this.eventData.price.amount ? false : true;
					this.price.oneTimePurchase = this.eventData.price.oneTimePurchase
						? this.eventData.price.oneTimePurchase
						: false;
					this.showOneTimeOption = this.eventData.price.oneTimePurchase
						? this.eventData.price.oneTimePurchase
						: false;
					this.price.amount = this.eventData.price.amount
						? this.eventData.price.amount / 100
						: 0;

					if (this.eventData.price.quantity != null) {
						var quantity = this.eventData.price.quantity;
						//if (this.eventData.price.purchased) {
						//	quantity = quantity - this.eventData.price.purchased;
						//}
						if (quantity <= 0) {
							quantity = 0;
						}
						this.price.quantity = quantity;
					} else {
						this.price.quantity = null;
					}
				} else {
					this.price.isFree = true;
					this.price.oneTimePurchase = false;
					this.price.amount = 0;
					this.price.quantity = null;
					this.showOneTimeOption = false;
				}

				this.price.isPrepaidOption =
					this.eventData.isPrepaidOption != null
						? this.eventData.isPrepaidOption
						: true;
				this.price.eventButtonTitle = this.eventData.eventButtonTitle;
				this.price.eventButtonLink = this.eventData.eventButtonLink;
				this.price.transactionFee = this.eventData.transactionFee
					? this.eventData.transactionFee
					: store.state.accountInformation.paymentInformation.transactionFee;
			} else if (this.classData) {
				if (this.classData.price) {
					var amount = null;
					if (this.classData.price.subscription) {
						amount = this.classData.price.subscription.amount;
						this.price.subscriptionBilling = true;
						if (this.classData.price.subscription) {
							if (this.classData.price.subscription.recurrence.recurrenceTime) {
								this.price.subscription.recurrence.recurrenceTime =
									this.classData.price.subscription.recurrence.recurrenceTime;
							}
							if (this.classData.price.subscription.subscriptionStart) {
								this.price.subscription.subscriptionStart = this.$helpers.formatDate(
									this.classData.price.subscription.subscriptionStart,
									`yyyy-MM-dd'T'HH:mm`
								);
							}
							if (this.classData.price.subscription.subscriptionEnd) {
								this.price.subscription.subscriptionEnd = this.$helpers.formatDate(
									this.classData.price.subscription.subscriptionEnd,
									`yyyy-MM-dd'T'HH:mm`
								);
							}
						}
						if (this.classData.price.amount) {
							this.price.oneTimePuchaseAmount = this.classData.price.amount / 100;
							this.price.oneTimePurchase = true;
						}
					} else {
						amount = this.classData.price.amount;
						this.price.subscriptionBilling = false;
						this.price.oneTimePurchase = false;
					}
					this.price.isFree = amount ? false : true;
					this.price.amount = amount ? amount / 100 : 0;

					if (this.classData.price.quantity != null) {
						var quantity = this.classData.price.quantity;
						//if (this.eventData.price.purchased) {
						//	quantity = quantity - this.eventData.price.purchased;
						//}
						if (quantity <= 0) {
							quantity = 0;
						}
						this.price.quantity = quantity;
					} else {
						this.price.quantity = null;
					}
				} else {
					this.price.isFree = true;
					this.price.oneTimePurchase = false;
					this.price.subscriptionBilling = false;
					this.price.amount = 0;
					this.price.quantity = null;
				}

				this.price.isPrepaidOption = this.classData.isPrepaidOption
					? this.classData.isPrepaidOption
					: true;

				this.price.eventButtonTitle = this.classData.customButtonTitle;
				this.price.eventButtonLink = this.classData.customButtonLink;
				this.price.transactionFee = this.classData.transactionFee
					? this.classData.transactionFee
					: store.state.accountInformation.paymentInformation.transactionFee;
			}
		},
	},
	mounted() {
		this.setupView();
		this.setQuantityOptions();
		this.setOneTimeOptions();
		this.recurrenceOptions = Recurrence.options;
	},
};
</script>
