<template>
	<div>
		<DashboardHeaderVue
			:title="this.tabMutlipleTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<ClassesTable
						:isLoadingGroup="isLoading"
						:headerTitle="`Active ${this.tabMutlipleTitle}`"
						:tableData="allClasses"
						@viewClicked="openClass"
						@deleteClicked="deleteClicked"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<ClassesTable
						v-if="allInactiveClasses"
						:isLoading="isLoading"
						:headerTitle="`Inactive ${this.tabMutlipleTitle}`"
						:tableData="allInactiveClasses"
						@viewClicked="openClass"
						@deleteClicked="deleteClicked"
					/>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import ClassesTable from '../../Components/ClassesViews/ClassesTable.vue';
import ClassItemManager from '../../../models/ClassItem/ClassItemManager';
import ReportsManager from '../../../models/ReportsManager/ReportsManager';
import { DashboardChildren } from '../../../routes/routesNames';

export default {
	name: 'classes',
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
	},
	props: {
		refresh: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		DashboardHeaderVue,
		ClassesTable,
	},
	data() {
		return {
			isLoading: true,
			tabMutlipleTitle: this.$route.name,
			tabSingleTitle: 'Class',
			allClasses: [],
			allInactiveClasses: null,
			selectedId: null,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	watch: {
		refresh: {
			handler() {
				return this.getAllClasses();
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		setupView() {
			/*
			if (this.publicInformation.appTitles) {
				const newSingleTitle = this.publicInformation.appTitles.find(
					(e) => e.type == 'classSingle' && e.title
				);
				const newMultipleTitle = this.publicInformation.appTitles.find(
					(e) => e.type == 'classMultiple' && e.title
				);
				this.tabMutlipleTitle = newMultipleTitle
					? newMultipleTitle.title
					: this.tabMutlipleTitle;
				this.tabSingleTitle = newSingleTitle
					? newSingleTitle.title
					: this.tabSingleTitle;
			}
			*/
			this.buttonItems[0].children = [
				{
					index: 0,
					title: `New ${this.tabSingleTitle}`,
					type: 'primary',
					icon: 'fas fa-plus',
				},
				/*
				{
					index: 1,
					title: 'Remove Members',
				},
				*/
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: 2,
					title: `Export ${this.tabMutlipleTitle}`,
				},
				/*
				{
					index: 3,
					title: 'Send Payment Link',
				},
				*/ 
			];
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			if (this.selectedId == 0) {
				this.openNewEventView();
			} else if (this.selectedId == 2){
				this.exportClasses()
			}
		},
		openNewEventView() {
			this.$router.push({
				name: DashboardChildren.newClassView.name,
			});
		},
		viewClicked(data) {
			console.log(data);
		},
		deleteClicked(data) {},
		openClass(classData) {
			this.$router.push({
				name: DashboardChildren.classView.name,
				params: {
					classId: classData.classId,
					classData: classData,
				},
			});
		},
		getAllClasses() {
			this.isLoading = true;
			this.allClasses = [];
			ClassItemManager.getAllClasses().then(() => {
				this.allClasses = ClassItemManager.allClasses;
				this.isLoading = false;
			});
		},
		exportClasses() {
			if (this.isLoading){return}
			this.isLoading = true;
			ReportsManager.exportClasses(this.allClasses)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					if (reportData.reportDownloadLink) {
						var open = window.open(reportData.reportDownloadLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
	},
	mounted() {
		this.setupView();
		if (this.refresh) {
			this.getAllClasses();
		} else {
			this.allClasses = [];
			this.allClasses = ClassItemManager.allClasses;
			this.isLoading = false;
		}
	},
};
</script>
