import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
	apiKey: "AIzaSyCPPf8zMBgCTmSq-VTsGIASUCKy2T-ljKo",
    authDomain: "dominionstudio-d9898.firebaseapp.com",
    projectId: "dominionstudio-d9898",
    storageBucket: "dominionstudio-d9898.appspot.com",
    messagingSenderId: "735333130839",
    appId: "1:735333130839:web:d34be000469aa06098b912",
    measurementId: "G-8EYV45N55C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Authentication
const auth = getAuth(app);

// Firestore
const firestore = getFirestore(app);

// Functions
const functions = getFunctions(app);

export { auth, firestore, functions };
