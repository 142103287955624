<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<MembersTable
			:isLoading="isLoading"
			:tableData="allUserRelationships"
			:tableHeaders="userTitles"
			@viewClicked="viewClicked"
		/>
	</div>
</template>
<script>
import MembersTable from '../../MembersTable.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import { DashboardChildren } from '../../../../routes/routesNames';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
			allUserRelationships: [],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '90%' },
					formatter: (value, key, item) => {
						return item;
					},
					sortByFormatted: (value, key, item) => {
						return item;
					},
				},
				{
					key: 'relationshipStatus',
					label: 'Status',
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return item.isPending != null || item.isRequesting != null
							? 'Pending'
							: '';
					},
					sortByFormatted: (value, key, item) => {
						return item.isPending != null || item.isRequesting != null;
					},
				},
				{
					key: 'relationshipType',
					label: 'Relationship',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return value.charAt(0).toUpperCase() + value.slice(1);
					},
					sortByFormatted: (value, key, item) => {
						return value.charAt(0).toUpperCase() + value.slice(1);
					},
				},
			],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserRelationships();
				}
			},
		},
	},
	methods: {
		viewClicked(item) {
			if (item.tempUid) {
			} else {
				this.$router.push({
					name: DashboardChildren.memberProfile.name,
					params: {
						userData: item,
					},
					query: {
						uid: item.uid,
					},
				});
				this.$forceUpdate();
			}
		},
		getUserRelationships() {
			this.isLoading = true;
			AppUserManager.getUserRelationships(this.userData.uid)
				.then((items) => {
					if (items) {
						this.allUserRelationships = items;
					}
					this.isLoading = false;
				})
				.catch(() => {});
		},
	},
	components: { MembersTable },
};
</script>
