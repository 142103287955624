<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<DocumentTable
						:isLoading="isLoading"
						:tableData="allFiles"
						@viewClicked="viewClicked"
						@deleteClicked="deleteClicked"
					/>
				</b-col>
			</b-row>
		</div>
		<NewDocument
			:headerTitle="buttonItems[0].title"
			:showModal="showNewDocumentModal"
			@close="showNewDocumentModal = false"
			@newDocument="onNewDocument"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deleteFile"
			@noClicked="selectedFile == null"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import FileItemManager from '../../../models/FileItem/FileItemManager';
import DocumentTable from '../../Components/DocumentViews/DocumentTable.vue';
import NewDocument from '../../mainForms/NewDocument.vue';
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';

export default {
	components: { DashboardHeader, DocumentTable, NewDocument, QuestionModal },
	props: {
		refresh: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isLoading: true,
			showQuestionModal: false,
			showNewDocumentModal: false,
			modalQuestion: null,
			selectedFile: null,
			allFiles: [],
			buttonItems: [
				{
					index: 0,
					title: 'New Document',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			this.showQuestionModal = false;
			if (buttonItem.index == 0) {
				this.showNewDocumentModal = true;
			}
		},
		viewClicked(item) {
			if (item.fileLink) {
				var open = window.open(item.fileLink, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
		deleteClicked(item) {
			this.modalQuestion = 'Are you sure you want to delete this file?';
			this.selectedFile = item;
			this.showQuestionModal = true;
		},
		deleteFile() {
			this.isLoading = true;
			FileItemManager.deleteFile(this.selectedFile.fileId)
				.then(() => {
					this.getAllFiles();
					this.showNotification({
						type: 'success',
						message: `${this.selectedFile.fileTitle} has been deleted.`,
					});
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		onNewDocument(document) {
			this.allFiles.push(document);
			this.showNotification({
				type: 'success',
				message: `${document.fileTitle} Added.`,
			});
		},
		getAllFiles() {
			this.isLoading = true;
			FileItemManager.getAllFiles().then(() => {
				this.allFiles = FileItemManager.allFiles;
				this.isLoading = false;
			});
		},
	},
	mounted() {
		if (this.refresh) {
			this.getAllFiles();
		} else {
			this.allFiles = FileItemManager.allFiles;
			this.isLoading = false;
		}
	},
};
</script>
