<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>

		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<CalendarView :calendarData="calendarData"
								@next="next" @prev="prev" @event-click="eventClicked"/>
			</b-overlay>
		</div>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deleteCalendarItem"
			@noClicked="calendarItem == null"
		/>
		<NewCalendarItem
			:headerTitle="buttonItems[0].title"
			:showModal="showNewCalendarItemModal"
			@close="showNewCalendarItemModal = false"
			@newCalendarItem="onNewCalendarItem"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Modal from '../../../components/Modals/Modal.vue';
import calendarSettings from '../../../data/calendarSettings';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridDay from '@fullcalendar/timegrid';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import NewEvent from '../../mainForms/NewEvent.vue';
import NewCalendarItem from '../../mainForms/NewCalendarItem.vue';
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../routes/routesNames';
import BaseButton from '../../../components/BaseButton.vue';
import EventManager from '../../../models/EventItem/EventManager';
import helpers from '../../../helpers/helpers';
import CalendarObject from '../../../models/DataModels/CalendarObject';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import ClassItemManager from '../../../models/ClassItem/ClassItemManager';
import CalendarItemManager from '../../../models/CalendarItem/CalendarItemManager';
import CalendarView from '../../../components/Calendar/CalendarView.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import ReportsManager from '../../../models/ReportsManager/ReportsManager';

export default {
	name: 'calendar',
	props: {
		refresh: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		DashboardHeaderVue,
		RouteBreadCrumb,
		Modal,
		NewEvent,
		NewCalendarItem,
		BaseButton,
		QuestionModal,
		CalendarView
	},
	data() {
		return {
			isLoading: false,
			isWeekView: false,
			showQuestionModal: false,
			modalQuestion: null,
			showNewCalendarItemModal: false,
			calendarItem: null,
			calendarData: {
				title: null,
				events: []
			},
			calendarSettings: calendarSettings,
			buttonItems: [
				{
					index: 0,
					title: 'New Item',
					outline: true,
					type: 'primary',
					icon: 'fas fa-plus',
				},
				{
					index: 1,
					title: 'New Event',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
			calendarEvents: [],
			previouslyAdded: [],
			allEvents: [],
			allGroups: [],
			allCalendarItems: [],
			allClassLeaders: [],
			eventData: {},
			eventColors: [
				'bg-info',
				'bg-orange',
				'bg-red',
				'bg-green',
				'bg-default',
				'bg-blue',
				'bg-purple',
				'bg-yellow',
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.showNewCalendarItemModal = true;
			} else {
				this.openNewEventView();
			}
		},
		setupTargetDate(){
			this.targetDate = {
				rawDate: new Date().toLocaleString('default'),
				month: new Date().toLocaleString('default', { month: 'long' }),
				year: new Date().toLocaleString('default', { year: 'numeric' }),
			}
		},
		addCalendarItems(newDate) {
			this.isLoading = true;
			var currentMonthDate = newDate
			currentMonthDate.setDate(1);
			currentMonthDate.setHours(0, 0, 0, 0);
			var endMonthDate = new Date(
				currentMonthDate.getFullYear(),
				currentMonthDate.getMonth() + 1,
				24
			);
			const thisMonthsItems = CalendarObject.allScheduleItems.filter(
				(e) =>
					e.start >= currentMonthDate &&
					e.start <= endMonthDate &&
					!this.previouslyAdded.find((pe) => e.id == pe.id)
			);
			thisMonthsItems.forEach((item) => {
				this.previouslyAdded.push(item);
				this.calendarData.events.push(item)
			});
			this.isLoading = false;
		},
		addCalendarItemsORGINIAL() {
			return this.addCalendarItemsForMonth();
			console.log('reseting5');
			this.clearCalendarItems();
			console.log('reseting55');
			return console.log(`${CalendarObject.allScheduleItems[0].start}`);
			return console.log(
				'Calendar Objects: ',
				CalendarObject.allScheduleItems.length
			);
			CalendarObject.allScheduleItems.forEach((item) => {
				console.log('reseting6');
				calendar.addEvent(item);
			});
			console.log('reseting7');
			this.isLoading = false;
		},
		openNewEventView() {
			this.$router.push({
				name: DashboardChildren.newEventView.name,
			});
		},
		onNewCalendarItem(calendarItem) {
			this.resetView();
		},
		eventClicked(info){
			const eventSelectedStart = info.event.start.getTime();
			if (info.event.extendedProps.eventData) {
				this.openEvent(eventSelectedStart, info.event.extendedProps.eventData);
			} else if (info.event.extendedProps.classData) {
				this.openClass(info.event.extendedProps.classData);
			} else if (info.event.extendedProps.calendarData) {
				this.askToDeleteCalendarItem(info.event.extendedProps.calendarData);
			}
		},
		openEvent(eventStart, eventData) {
			this.$router.push({
				name: DashboardChildren.eventView.name,
				params: {
					eventId: eventData.eventId,
					eventData: eventData,
				},
				query: {
					eventStart: eventStart,
				},
			});
		},
		openClass(classData) {
			this.$router.push({
				name: DashboardChildren.classView.name,
				params: {
					classId: classData.classId,
					classData: classData,
				},
			});
		},
		next(newDate) {
			this.isLoading = true;
			this.addCalendarItems(newDate);
		},
		prev(newDate) {
			this.isLoading = true;
			this.addCalendarItems(newDate);
		},
		askToDeleteCalendarItem(calendarItem) {
			this.calendarItem = calendarItem;
			this.modalQuestion = 'Do you want to delete this calendar item?';
			this.showQuestionModal = true;
		},
		deleteCalendarItem() {
			this.isLoading = true;
			CalendarItemManager.deleteCalendarItem(this.calendarItem.calendarId)
				.then(() => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Calendar Item has been deleted.',
					});
					this.resetView();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		saveEvent() {
			this.events.push(JSON.parse(JSON.stringify(event)));
		},
		resetView() {
			this.isLoading = true;
			this.previouslyAdded = [];
			this.calendarData.events = [];
			CalendarObject.allScheduleItems = [];
			EventManager.getAllEvents().then(() => {
				ClassItemManager.getAllClasses().then(() => {
					CalendarItemManager.getAllCalendarItems().then(() => {
						this.addCalendarItems(new Date());
						this.exportMonthly();
					});
				});
			});
		},
		exportMonthly() {
			ReportsManager.exportEventMonthlyReport(3)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					if (reportData.reportDownloadLink) {
						var open = window.open(reportData.reportDownloadLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
				})
				.catch((error) => {
					console.log(error.message);
				});
		},
	},
	mounted() {
		this.setupTargetDate()
		if (!this.refresh) {
			this.isLoading =
				EventManager.isLoading ||
				ClassItemManager.isLoading ||
				CalendarItemManager.isLoading;
			if (
				!EventManager.isLoading &&
				!ClassItemManager.isLoading &&
				!CalendarItemManager.isLoading
			) {
				this.addCalendarItems();
			}
		} else {
			this.resetView();
		}
	},
};
</script>
<style lang="scss">
@import '~@/assets/sass/core/vendors/fullcalendar';
</style>
