import { deleteDoc, doc, getDocs, setDoc, Timestamp } from 'firebase/firestore';
import { resolve } from 'path';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import CalendarObject from '../DataModels/CalendarObject';
import Recurrence from '../DataModels/Reccurrence';
import FirebaseManager from '../Firebase/FirebaseManager';

export default class CalendarItemManager {
	static isLoading = false;
	static allCalendarItems = [];

	static getAllCalendarItems() {
		return new Promise((resolve, reject) => {
			this.allCalendarItems = [];
			this.isLoading = true;
			const calendarItemRef = FirebaseManager.allCalendarItemsRef(getOrganizationFirestore() );
			getDocs(calendarItemRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							this.processCalendarItem(documentData).then(() => {
								if (counter == total - 1) {
									this.isLoading = false;
									return resolve();
								}
								counter += 1;
							})
						});
					} else {
						this.isLoading = false;
						return resolve();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					return reject(error.message);
				});
		});
	}

	static processCalendarItem(calendarData) {
		return new Promise((resolve, reject) => {
			if (calendarData) {
				if (
					this.allCalendarItems.find(
						($0) => $0.calendarId == calendarData.calendarId
					)
				) {
					this.allCalendarItems.push(calendarData);
				}
				if (calendarData.recurrence) {
					const dates = Recurrence.getRecurrenceDates(
						calendarData.calendarStart,
						calendarData.calendarEnd,
						calendarData.recurrence
					);
					const total = dates.length;
					var counter = 0;
					if (total == 0){return resolve(true)}
					dates.forEach((newStartDate) => {
						var newItem = structuredClone(calendarData);
						newItem.calendarStart = Timestamp.fromDate(newStartDate);
						CalendarObject.addObjectForSchedule(null, null, newItem);
						if (counter == total - 1) {
							return resolve(true);
						}
						counter += 1;
					});
				} else {
					CalendarObject.addObjectForSchedule(null, null, calendarData);
					return resolve(true);
				}
			} else {
				return resolve(true);
			}
		});
	}

	static addCalendarItem(calendarData) {
		return new Promise((resolve, reject) => {
			const newCalendarItemRef = doc(
				FirebaseManager.allCalendarItemsRef(getOrganizationFirestore())
			);
			var data = calendarData;
			data['calendarId'] = newCalendarItemRef.id;
			setDoc(newCalendarItemRef, data)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteCalendarItem(calendarId, classId) {
		return new Promise((resolve, reject) => {
			var calendarIdRef = doc(
				getOrganizationFirestore(),
				`${
					FirebaseManager.allCalendarItemsRef(getOrganizationFirestore()).path
				}/${calendarId}`
			);
			if (classId){
				calendarIdRef = doc(
					getOrganizationFirestore(),
					`${
						FirebaseManager.allClassesRef(getOrganizationFirestore()).path
					}/${classId}/calendarItems/${calendarId}`
				);
			}
			deleteDoc(calendarIdRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
