<template>
	<footer class="footer px-4 mx-2">
		<b-row align-v="center" class="justify-content-lg-between">
			<b-col lg="10" md="8">
				<b-nav
					class="nav-footer justify-content-lg-start justify-content-md-start justify-content-center"
				>
					<b-nav-item :href="DominionInformation.dominionWebsite" target="_blank">
						© {{ new Date().getFullYear() }}
						{{ DominionInformation.dominionName }}
					</b-nav-item>
				</b-nav>
			</b-col>
			<b-col lg="2" md="4" class="w-sm-25">
				<a
					target="_blank"
					class="nav-footer justify-content-lg-end justify-content-md-end justify-content-center"
					style="opacity: 0.5"
					:href="DominionInformation.dominionWebsite"
				>
					<b-img fluid :src="Logo" />
				</a>
			</b-col>
		</b-row>
	</footer>
</template>
<script>
import { DominionInformation } from '../../../resources/DominionInformation';
import Logo from 'logo/LogoHorizontal_Dark.png';

export default {
	data() {
		return {
			DominionInformation,
			Logo,
		};
	},
};
</script>
<style></style>
