<template>
	<div v-if="productData">
		<DashboardHeaderVue
			:title="productData.productTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<MembersTable
						:isLoading="isLoading"
						footerTitle=""
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@viewClicked="viewClicked"
						@declineClicked="removeClicked"
                        @receiptClicked="receiptClicked"
						@headerButtonClicked="saveClicked"
					/>
				</div>
			</div>
		</div>
		<UsersModal
			headerTitle="All Users"
			:show="showUsersModal"
			:exempted-users="rawUsers"
			@selectedUsers="selectedUsers"
			@close="showUsersModal = false"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="addUsers"
			@noClicked="selectedUsers == null"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import MembersTable from '../../../Components/MembersTable.vue';
import ProductItemManager from '../../../../models/Payment-Billing/ProductItemManager'
import UsersModal from '../../../../components/Modals/UsersModal.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import ReportsManager from '../../../../models/ReportsManager/ReportsManager';

export default {
	props: {
		productId: {
			type: String,
			default: null,
		},
	},
	components: {
		DashboardHeaderVue,
		MembersTable,
		UsersModal,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			isUpdatedNeeded: false,
			showUsersModal: false,
			showQuestionModal: false,
			modalQuestion: null,
			productData: null,
			allUsers: [],
			rawUsers: [],
			selectedAllUsers: [],
			attendanceUpdate: [],
			isUpdating: false,
			selectedId: null,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					tdClass: 'align-middle',
					sortable: true,
					thStyle: { width: '80%' },
					sortByFormatted: (value, key, item) => {
						return `${item.userData.firstName} ${item.userData.lastName}`;
					},
					formatter: (value, key, item) => {
						return item.userData;
					},
				},
				{
					key: 'quantity',
					label: 'Quantity',
					sortable: true,
					tdClass: 'align-middle text-center',
					sortByFormatted: (value, key, item) => {
						return item.productData.quantity ? item.productData.quantity : 1;
					},
					formatter: (value, key, item) => {
						return item.productData.quantity ? item.productData.quantity : 1;
					},
				},
				{
					key: 'timestamp',
					label: 'Purchased',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return this.$helpers.formatDate(item.productData.timestamp, "MMM dd, yyyy 'at' hh:mm a")
					},
					formatter: (value, key, item) => {
						return this.$helpers.formatDate(item.productData.timestamp, "MMM dd, yyyy 'at' hh:mm a")
					},
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: 2,
					title: 'Export Data',
				},
			];
		},
		getProductData() {
			this.isLoading = true;
            ProductItemManager.getProductById(this.productId)
				.then((data) => {
					this.productData = data;
					this.getProductUsers();
				})
				.catch((error) => {
					this.navigateToShop();
				});
		},
		getProductUsers() {
			this.isLoading = true;
			ProductItemManager.getProductUsers(this.productData).then((userItems) => {
				this.allUsers = userItems;
				if (this.allUsers) {
					this.rawUsers = this.allUsers.map((e) => e.userData);
				}
				this.setButtons();
				this.isLoading = false;
			});
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (this.selectedId) {
				case 0:
					this.showUsersModal = true;
					break;
				case 2:
					this.checkIfExportAllowed();
					break;
				default:
					break;
			}
		},
		saveClicked() {
			
		},
		checkIfExportAllowed() {
			this.isLoading = true;
			ReportsManager.checkIfReportsAllowed(true).then((allowed) => {
				if (allowed) {
					this.exportUsers();
				} else {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: `You have exceeded the amount of reports for this month. To increase this limit, please reach out to our team.`,
					});
				}
			});
		},
		exportUsers() {
			this.isLoading = true;
			ReportsManager.exportProductUsers(
				`${this.productData.productTitle}`,
				this.allUsers
			)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					if (reportData.reportDownloadLink) {
						var open = window.open(reportData.reportDownloadLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		selectedUsers(item) {
			this.selectedAllUsers = item;
			this.modalQuestion =
				'Are you sure you want to add these users to this class? They will bypass any registration or billing.';
			this.showQuestionModal = true;
		},
		receiptClicked() {
			
		},
		viewClicked(item) {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
				query: {
					uid: item.userData.uid,
				},
			});
		},
		removeClicked(item) {
			
		},
		navigateToShop() {
			this.$router.replace({
				name: DashboardChildren.shop.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		if (!this.productId) {
			this.navigateToShop();
		} else if (this.$route.params.productData) {
			this.productData = this.$route.params.productData;
			this.getProductUsers();
		} else {
			this.getProductData();
		}
	},
};
</script>
