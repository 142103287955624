<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<MembersTable
						:isLoading="isLoading"
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@viewClicked="viewClicked"
						@deleteClicked="deleteClicked"
					/>
				</b-col>
			</b-row>
		</div>
		<NewUser
			:show-modal="showNewUser"
			@close="showNewUser = false"
			@newUser="getUsers"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import { mapActions } from 'vuex';
import MembersTable from '../../Components/MembersTable.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import ReportsManager from '../../../models/ReportsManager/ReportsManager';
import NewUser from '../../mainForms/NewUser.vue';

export default {
	name: 'shop',
	components: {
		DashboardHeaderVue,
		MembersTable,
		NewUser,
	},
	data() {
		return {
			isLoading: true,
			showNewUser: false,
			lastDocument: null,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
			activeWaiverId: null,
			allUsers: [],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return item;
					},
					sortByFormatted: (value, key, item) => {
						return item;
					},
				},
				{
					key: 'email',
					label: 'Email',
					tdClass: 'align-middle',
				},
				{
					key: 'createdTimestamp',
					label: 'Signed Up',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return this.$helpers.formatDate(value.toDate());
					},
				},
				{
					key: 'waiverStatus',
					label: 'Documents',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item);
					},
					formatter: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item);
					},
				},
				{
					key: 'subscriptionStatus',
					label: 'Subscription',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.activeSubscriptions
							? item.activeSubscriptions.length > 0
							: false;
					},
					formatter: (value, key, item) => {
						return item.activeSubscriptions
							? item.activeSubscriptions.length > 0
							: false;
					},
				},
			],
		};
	},
	watch: {
		isLoading(val) {
			this.buttonItems[0].isDisabled = val;
		},
	},
	methods: {
		...mapActions(['showNotification']),
		setupButtons() {
			this.buttonItems[0].children = [
				{
					index: 0,
					title: 'Create New User',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: 1,
					title: 'Export Users',
				},
			];
		},
		childClick(childClicked) {
			switch (childClicked.index) {
				case 0:
					this.showNewUser = true;
					break;
				case 1:
					this.checkIfExportAllowed();
					break;
				default:
					break;
			}
		},

		getUsers() {
			AppUserManager.getUsers(this.lastDocument)
				.then((allUsers, lastDocument) => {
					this.allUsers = allUsers;
					this.lastDocument = lastDocument;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log('Error: ', error);
				});
		},
		checkIfExportAllowed() {
			this.isLoading = true;
			ReportsManager.checkIfReportsAllowed(false).then((allowed) => {
				console.log(allowed);
				if (allowed) {
					this.exportUsers();
				} else {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: `You have exceeded the amount of reports for this month. To increase this limit, please reach out to our team.`,
					});
				}
			});
		},
		exportUsers() {
			this.isLoading = true;
			ReportsManager.exportUsers(this.allUsers)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					if (reportData.reportDownloadLink) {
						var open = window.open(reportData.reportDownloadLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		viewClicked(userData) {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
				params: {
					userData: userData,
				},
				query: {
					uid: userData.uid,
				},
			});
		},
		deleteClicked(item) {
			console.log(`Moses delete on ${item}`);
		},
	},
	mounted() {
		this.setupButtons();
		this.getUsers();
	},
};
</script>
