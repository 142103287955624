<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new document for all users.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form class="pt-1 pb-1">
					<BaseInput
						label="Document Title"
						id="title"
						placeholder="Document Title"
						required
						type="text"
						v-model="file.fileTitle"
					/>
					<BaseInput
						label="Document Sub Title"
						id="title"
						placeholder="Document Sub Title"
						required
						type="text"
						v-model="file.fileSubTitle"
					/>
					<BaseInput
						label="Document Description"
						id="description"
						placeholder="Enter your document description..."
						rows="9"
						required
						type="textarea"
						v-model="file.fileDescription"
					/>
					<b-row class="d-flex justify-content-center">
						<b-col>
							<label class="form-control-label">Set Document To Active</label>
						</b-col>
						<b-col class="d-flex justify-content-end">
							<BaseSwitch v-model="file.isActive"></BaseSwitch>
						</b-col>
					</b-row>
					<b-row class="d-flex justify-content-center">
						<b-col>
							<label class="form-control-label">Document Is Required</label>
						</b-col>
						<b-col class="d-flex justify-content-end">
							<BaseSwitch v-model="file.isRequired"></BaseSwitch>
						</b-col>
					</b-row>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Document</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BaseSwitch from '../../components/BaseSwitch.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';
import FileItemManager from '../../models/FileItem/FileItemManager';

export default {
	props: {
		fileData: null,
		headerTitle: {
			type: String,
			default: 'New Product',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			file: {
				isActive: true,
				isRequired: true,
				fileTitle: null,
				fileSubTitle: null,
				fileDescription: null,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			if (
				this.$helpers.isBlank(this.file.fileTitle) ||
				this.$helpers.isBlank(this.file.fileSubTitle) ||
				this.$helpers.isBlank(this.file.fileDescription)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else {
				const fileDescription =
					'<p>' + this.file.fileDescription.replace(/\n|\r/g, '</p><p>') + '</p>';

				var fileData = {
					isActive: this.file.isActive,
					isRequired: this.file.isRequired,
					isSignable: true,
					fileTitle: this.file.fileTitle,
					fileSubTitle: this.file.fileSubTitle,
					fileDescription: fileDescription,
					fileContentType: 'application/pdf',
				};

				this.isLoading = true;
				FileItemManager.addFileToDB(fileData)
					.then((data) => {
						this.$emit('newDocument', data);
						this.isLoading = false;
						this.closeModal();
					})
					.catch((errorMessage) => {
						this.isLoading = false;
						this.showNotification({
							type: 'danger',
							message: errorMessage,
						});
					});
			}
		},
	},
	mounted() {
		if (this.fileData) {
		}
	},
	components: { Modal, BaseInput, BaseSwitch },
};
</script>
