<template>
	<div v-if="eventData">
		<DashboardHeaderVue
			:title="eventData.eventTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<MembersTable
						:isLoading="isLoading"
						:headerTitle="`${eventData.eventTitle} · ${$helpers.formatDate(
							eventData.eventStart.toDate()
						)}`"
						:headerButtonData="{
							index: 0,
							title: 'Save',
							isDisabled: !isUpdatedNeeded,
						}"
						footerTitle="TIP: When adding or removing users, please remember to update your ticket quantity, if necessary."
						:tableData="allUsers"
						:tableHeaders="userTitles"
						@attendanceQuantityChange="attendanceQuantityChange"
						@onChanged="onSwitchChange"
						@viewClicked="viewClicked"
						@declineClicked="removeClicked"
						@headerButtonClicked="saveClicked"
					/>
				</div>
			</div>
		</div>
		<UsersModal
			headerTitle="All Users"
			:show="showUsersModal"
			:exempted-users="rawUsers"
			@selectedUsers="selectedUsers"
			@close="showUsersModal = false"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="addUsers"
			@noClicked="selectedUsers == null"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeaderVue from '../../HeaderFooterComponents/DashboardHeader.vue';
import MembersTable from '../../../Components/MembersTable.vue';
import EventManager from '../../../../models/EventItem/EventManager';
import UsersModal from '../../../../components/Modals/UsersModal.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import ReportsManager from '../../../../models/ReportsManager/ReportsManager';

export default {
	props: {
		eventId: {
			type: String,
			default: null,
		},
		eventStart: {
			type: [String, Number],
			default: null,
		},
	},
	components: {
		DashboardHeaderVue,
		MembersTable,
		UsersModal,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			isUpdatedNeeded: false,
			showUsersModal: false,
			showQuestionModal: false,
			modalQuestion: null,
			eventData: null,
			allUsers: [],
			rawUsers: [],
			selectedAllUsers: [],
			attendanceUpdate: [],
			isUpdating: false,
			selectedId: null,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					tdClass: 'align-middle',
					sortable: true,
					thStyle: { width: '80%' },
					sortByFormatted: (value, key, item) => {
						return `${item.userData.firstName} ${item.userData.lastName}`;
					},
					formatter: (value, key, item) => {
						return item.userData;
					},
				},
				{
					key: 'quantity',
					label: 'Quantity',
					sortable: true,
					tdClass: 'align-middle text-center',
					sortByFormatted: (value, key, item) => {
						return item.eventData.quantity;
					},
					formatter: (value, key, item) => {
						return item.eventData.quantity;
					},
				},
				{
					key: 'waiverStatus',
					label: 'Documents',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item.userData);
					},
					formatter: (value, key, item) => {
						return this.$helpers.isAllRequiredDocumentsSigned(item.userData);
					},
				},
				{
					key: 'attendanceQuantity',
					label: 'Attendance',
					sortable: true,
					tdClass: 'align-middle text-center',
					sortByFormatted: (value, key, item) => {
						return item.eventData.attendance != null
							? item.eventData.attendance.quantity
							: 0;
					},
					formatter: (value, key, item) => {
						return item.eventData.attendance != null
							? item.eventData.attendance.quantity
							: 0;
					},
				},
				{
					key: 'classActions',
					label: '',
					tdClass: 'align-middle',
				},
				/*
				{
					key: 'attendanceStatus',
					label: 'Attendance Status',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '20%' },
					sortByFormatted: (value, key, item) => {
						return item.attendance;
					},
					formatter: (value, key, item) => {
						return false;
					},
				},

				{
					key: 'attendanceProgress',
					label: 'Attendance Progress',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '20%' },
					sortByFormatted: (value, key, item) => {
						return item.attendance;
					},
					formatter: (value, key, item) => {
						return false;
					},
				},

				{
					key: 'attendanceType',
					label: 'Attendance Type',
					sortable: true,
					tdClass: 'align-middle text-center',
					sortByFormatted: (value, key, item) => {
						return 'manual';
					},
					formatter: (value, key, item) => {
						return 'manual';
					},
				},
				*/
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: 0,
					title: 'Add Attendees',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: 2,
					title: 'Export Data',
				},
				/*
				{
					index: 3,
					title: 'Send Payment Link',
					isDisabled: true,
				},
				*/
			];
		},
		getEventData() {
			this.isLoading = true;
			EventManager.getEventById(this.eventId, this.eventStart).then((result) => {
				if (result) {
					this.eventData = result;
					this.getEventUsers();
				} else {
					this.navigateToSchedule();
				}
			});
		},
		getEventUsers() {
			this.isLoading = true;
			EventManager.getEventUsers(this.eventData).then((userItems) => {
				this.allUsers = userItems;
				if (this.allUsers) {
					this.rawUsers = this.allUsers.map((e) => e.userData);
				}
				this.setButtons();
				this.isLoading = false;
			});
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (this.selectedId) {
				case 0:
					this.showUsersModal = true;
					break;
				case 2:
					this.checkIfExportAllowed();
					break;
				default:
					break;
			}
		},
		onSwitchChange(item) {
			const isChecked = item.value;
			const userData = item.data.userData;
		},
		attendanceQuantityChange(item) {
			const value = item.value;
			const userData = item.userData;
			const userIndex = this.allUsers.findIndex(
				(e) => e.userData.uid == userData.uid
			);
			const updatedAttendance = {
				quantity: value,
			};

			const foundUserValue = this.allUsers[userIndex].eventData.attendance
				? this.allUsers[userIndex].eventData.attendance.quantity
				: 0;

			const isDifferent = value !== foundUserValue;

			this.allUsers[userIndex].eventData['attendance'] = updatedAttendance;

			if (isDifferent) {
				this.isUpdatedNeeded = true;
				if (!this.attendanceUpdate.find((e) => e.uid == userData.uid)) {
					this.attendanceUpdate.push({
						uid: userData.uid,
						attendanceQuantity: value,
					});
				} else {
					const index = this.attendanceUpdate.findIndex(
						(i) => i.uid == userData.uid
					);
					this.attendanceUpdate[index].attendanceQuantity = value;
				}
			} else {
				const index = this.attendanceUpdate.findIndex((i) => i.uid == userData.uid);
				if (index >= 0) {
					this.attendanceUpdate.splice(index, 1);
				}
			}
		},
		saveClicked() {
			this.isLoading = true;
			const total = this.attendanceUpdate.length;
			var counter = 0;
			this.attendanceUpdate.forEach((updateInfo) => {
				var attendanceData = {
					attendance: {
						quantity: updateInfo.attendanceQuantity,
						type: 'manual',
						timestamp: this.$helpers.returnTimestamp(),
					},
				};
				EventManager.updateEventAttendance(
					this.eventData,
					updateInfo.uid,
					attendanceData
				)
					.then(() => {
						if (counter == total - 1) {
							this.getEventUsers();
							this.showNotification({
								type: 'success',
								message: `Attendance Updated`,
							});
						}
						counter += 1;
					})
					.catch((error) => {
						if (counter == total - 1) {
							this.getEventUsers();
						}
						counter += 1;
					});
			});
		},
		checkIfExportAllowed() {
			this.isLoading = true;
			ReportsManager.checkIfReportsAllowed(true).then((allowed) => {
				if (allowed) {
					this.exportUsers();
				} else {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: `You have exceeded the amount of reports for this month. To increase this limit, please reach out to our team.`,
					});
				}
			});
		},
		exportUsers() {
			this.isLoading = true;
			ReportsManager.exportEventUsers(
				`${this.eventData.eventTitle} · ${this.$helpers.formatDate(
					this.eventData.eventStart.toDate()
				)}`,
				this.allUsers
			)
				.then((reportData) => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Success',
					});
					if (reportData.reportDownloadLink) {
						var open = window.open(reportData.reportDownloadLink, '_blank');
						if (open == null || typeof open == 'undefined') {
							alert('Turn off your pop-up blocker to view receipt!');
						} else {
							open.focus();
						}
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		selectedUsers(item) {
			this.selectedAllUsers = item;
			this.modalQuestion =
				'Are you sure you want to add these users to this class? They will bypass any registration or billing.';
			this.showQuestionModal = true;
		},
		addUsers() {
			this.showQuestionModal = false;
			this.showUsersModal = false;
			this.isLoading = true;
			EventManager.manuallyAddUsersToEvent(this.selectedAllUsers, this.eventData)
				.then(() => {
					this.getEventUsers();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		viewClicked(item) {
			this.$router.push({
				name: DashboardChildren.memberProfile.name,
				query: {
					uid: item.userData.uid,
				},
			});
		},
		removeClicked(item) {
			this.isLoading = true;
			EventManager.removeUserFromEvent(item.userData, this.eventData)
				.then(() => {
					this.getEventUsers();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		navigateToSchedule() {
			this.$router.replace({
				name: DashboardChildren.schedule.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		if (!this.eventId) {
			this.navigateToSchedule();
		} else if (this.$route.params.eventData) {
			this.eventData = this.$route.params.eventData;
			this.getEventUsers();
		} else {
			this.getEventData();
		}
	},
};
</script>
