<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<Card>
			<CardHeader
				slot="header"
				:title="`Invoice · ${allUserInvoices.length} Total`"
				:end-button="{ title: 'New Invoice', disabled: false }"
				@click="openNewInvoiceView"
			/>
			<b-list-group>
				<b-list-group-item
					v-for="invoiceData in allUserInvoices"
					:key="invoiceData.invoiceId"
					@click="openInvoice(invoiceData)"
				>
					<InvoiceHistoryCard :invoiceData="invoiceData" />
				</b-list-group-item>
			</b-list-group>
		</Card>
	</div>
</template>
<script>
import InvoiceHistoryCard from './InvoiceHistoryCard.vue';
import Card from '../../../../components/Cards/Card.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import { DashboardChildren } from '../../../../routes/routesNames';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
			allUserInvoices: [],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserInvoices();
				}
			},
		},
	},
	methods: {
		openNewInvoiceView() {
			this.$router.push({
				name: DashboardChildren.newInvoiceView.name,
				params: {
					userData: this.userData
				}
			});
		},
		getUserInvoices() {
			this.isLoading = true;
			AppUserManager.getUserInvoices(this.userData.uid)
				.then((allInvoices) => {
					if (allInvoices) {
						this.allUserInvoices = allInvoices.sort((a, b) => {
							return new Date(b.created * 1000) - new Date(a.created * 1000);
						});
					}
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
		openInvoice(invoiceData) {
			if (invoiceData.invoice_url) {
				var open = window.open(invoiceData.invoice_url, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
	},
	components: { Card, CardHeader, InvoiceHistoryCard },
};
</script>
