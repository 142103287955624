<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new product for all users to purchase.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form class="pt-1 pb-1">
					<VueDropZone
						class="p-0 form-control-auto form-control mb-3"
						id="image"
						style="border: 1px solid lightgray; border-radius: 5px"
						:duplicateCheck="true"
						v-on:vdropzone-files-added="addedItem"
						:options="$helpers.getVueDropZoneOptions()"
					>
					</VueDropZone>

					<BaseInput
						label="Product Title"
						id="product title"
						placeholder="Product Title"
						required
						v-model="product.productTitle"
					/>
					<BaseInput
						label="Product Description"
						id="description"
						placeholder="Enter your description..."
						rows="6"
						required
						type="textarea"
						v-model="product.productDescription"
					/>
					<BaseInput
							label="Product Category"
							id="category"
							type="multiselect"
							:allow-multiple="true"
							:internalSearch="false"
							@remove="onCategoryRemoved"
							@select="onCategorySelected"
							:multiSelectOptions="categoryOptions"
							:multiSelectValue="product.categoryIds"
						/>
						<CurrencyInput
						label="Product Price"
						v-model="product.price.amount"
						required
						id="product price"
					/>
					<BaseInput v-if="!isSubscription"
								label="Product Quantity"
								id="product quantity"
								placeholder="Product Quantity"
								type="number"
								min="1"
								required
								:disabled="isSubscription"
								v-model="product.price.quantity"
								tooltip="The amount of times a user can purchase this item at once."
							/>
					
					<b-row>
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Available Sizes</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch
								v-model="isProductSizes"
								@change="onProductAvaiableSizeChange"
							></BaseSwitch>
						</b-col>
					</b-row>

					<div
						class="form-control-auto form-control my-2 px-3"
						v-if="isProductSizes"
					>
						<b-row class="justify-content-between align-items-center">
							<div v-for="size in productSizes">
								<b-col cols="auto">
									<BaseInput :label="size.title + ':'" placeholder="Sizes Available">
										<select
											class="form-control"
											id="size"
											v-model="product.productSizes[size.title]"
										>
											<option v-for="value in arrayOfNumbers" :key="value">
												{{ value }}
											</option>
										</select>
									</BaseInput>
								</b-col>
							</div>
						</b-row>
					</div>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Product Subscription</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch
								v-model="isSubscription"
								@change="onProductSubscriptionChange"
							></BaseSwitch>
						</b-col>
					</b-row>

					<div
						class="form-control-auto form-control my-2 px-3"
						v-if="isSubscription"
					>
						<label class="form-control-label">Subscription Tokens</label>

						<BaseInput placeholder="Tokens Available Per Cycle">
							<select class="form-control" id="size" v-model="product.price.quantity">
								<option v-for="value in arrayOfNumbers" :key="value">
									{{ value }}
								</option>
							</select>
						</BaseInput>

						<b-row>
							<b-col>
								<BaseInput
									label="Subscription Billing Recurrence"
									id="recurrence"
									placeholder="Subscription Billing Recurrence"
									required
									type="text"
								>
									<select
										class="form-control"
										id="recurrence"
										v-model="product.recurrence"
										required
									>
										<option
											v-for="recurrence in recurrenceOptions"
											:key="recurrence.value"
											:value="recurrence.value"
										>
											{{ recurrence.name }}
										</option>
									</select></BaseInput
								>
							</b-col>
						</b-row>
					</div>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Feature This Product</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch v-model="product.isFeatured"></BaseSwitch>
						</b-col>
					</b-row>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Product</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import reoccuringOptions from '../../data/reoccuringOptions';
import Recurrence from '../../models/DataModels/Reccurrence';
import ProductItemManager from '../../models/Payment-Billing/ProductItemManager';
import { mapActions } from 'vuex';
import CategoryItemManager from '../../models/CategoryItem/CategoryItemManager';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Product',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueDropZone,
		Modal,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		ButtonRadioGroup,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			arrayOfNumbers: [],
			recurrenceOptions: [],
			productSizes: [
				{
					title: 'XS',
					value: null,
				},
				{
					title: 'S',
					value: null,
				},
				{
					title: 'M',
					value: null,
				},
				{
					title: 'L',
					value: null,
				},
				{
					title: 'XL',
					value: null,
				},
				{
					title: 'XXL',
					value: null,
				},
				{
					title: 'XXXL',
					value: null,
				},
			],
			errorMessage: null,
			isQuantity: false,
			isSubscription: false,
			isProductSizes: false,
			categoryOptions: [],
			product: {
				isFeatured: false,
				productImage: null,
				productTitle: '',
				productDescription: '',
				categoryIds: [],
				price: {
					amount: 0,
					quantity: 1,
					subscription: null,
				},
				recurrence: null,
				productSizes: [],
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		addedItem(file) {
			this.product.productImage = file;
		},
		onProductAvaiableSizeChange(value) {
			const isChecked = value.target.checked;
			if (isChecked) {
				this.isSubscription = false;
				this.product.price.quantity = 'Unlimited';
				this.product.price.subscription = null;
				this.product.recurrence = null;
			} else {
				this.product.productSizes = null;
			}
		},
		onProductSubscriptionChange(value) {
			const isChecked = value.target.checked;
			if (isChecked) {
				this.isProductSizes = false;
				this.product.productSizes = null;
			} else {
				this.product.price.quantity = 'Unlimited';
				this.product.price.subscription = null;
				this.product.recurrence = null;
			}
		},
		onCategoryRemoved(categoryData) {
			this.product.categoryIds = this.product.categoryIds.filter((e) => {
				return e.categoryId !== categoryData.categoryId
			})
		},
		onCategorySelected(categoryData) {
			this.product.categoryIds.push(categoryData)
		},
		submit() {
			this.shake = false;

			const productImage = this.product.productImage;
			const productTitle = this.product.productTitle;
			const productDescription = this.product.productDescription;
			const productPrice = this.product.price;
			const isSubscription = this.isSubscription;

			const recurrence = this.product.recurrence;

			if (productImage == null) {
				this.errorMessage = 'Please select a product image.';
				this.shake = true;
				return;
			} else if (
				this.$helpers.isBlank(productTitle) ||
				this.$helpers.isBlank(productDescription)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else if (productPrice == null || productPrice.amount < 1) {
				this.errorMessage = 'Product price needs to be greater than $1.00.';
				this.shake = true;
				return;
			} else if (this.isSubscription && this.product.recurrence == null) {
				this.errorMessage = 'Please select a billing reoccuring type.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				var productInfo = {
					isFeatured: this.product.isFeatured,
					isActive: true,
					uploadTimestamp: this.$helpers.returnTimestamp(),
					productTitle: productTitle,
					productDescription: productDescription,
				};
				if (this.product.categoryIds.length > 0){
					productInfo.categoryIds = this.product.categoryIds.map(e => (e.categoryId))
				}
				var priceInfo = {};
				if (isSubscription && this.product.recurrence) {
					var subscription = {
						amount: this.product.price.amount * 100,
						recurrence: {
							recurrenceTime: this.product.recurrence,
						},
					};
					if (this.product.price.quantity != 'Unlimited') {
						subscription['quantity'] = parseInt(this.product.price.quantity);
					}
					priceInfo['amount'] = 0;
					priceInfo['subscription'] = subscription;
				} else {
					priceInfo['amount'] = this.product.price.amount * 100;
					priceInfo['quantity'] = this.product.price.quantity == 'Unlimited' ? 100 : parseInt(this.product.price.quantity)
					if (this.isProductSizes && this.product.productSizes) {
						var productSizes = {};
						Object.entries(this.product.productSizes).forEach(
							(data, index, array) => {
								const key = data[0].toLowerCase();
								const value = data[1];
								if (value != null && value > 0) {
									if (value == 'Unlimited') {
										productSizes[key] = 1000;
									} else {
										productSizes[key] = parseInt(value);
									}
								}
							}
						);
						productInfo['productSizes'] = productSizes;
					}
				}
				productInfo['price'] = priceInfo;

				this.isLoading = true;
				ProductItemManager.addProduct(productImage[0], productInfo)
					.then((productData) => {
						this.$emit('newProduct', productData);
						this.isLoading = false;
						this.showNotification({
							type: 'success',
							message: 'New Product has been added.',
						});
						this.closeModal();
					})
					.catch((errorMessage) => {
						this.isLoading = false;
						this.showNotification({
							type: 'error',
							message: errorMessage,
						});
					});
			}
		},
	},
	mounted() {
		this.recurrenceOptions = Recurrence.options;
		this.categoryOptions = CategoryItemManager.allCategories.filter((item) => {
			var isProduct = false
					if (item.isProduct != null){isProduct = item.isProduct}
					return isProduct
			});
		for (let index = 0; index < 101; index++) {
			if (index == 0) {
				this.arrayOfNumbers.push('Unlimited');
			} else {
				this.arrayOfNumbers.push(`${index}`);
			}
		}
	},
};
</script>
