<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>

		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm" no-wrap />
			<Card no-body>
				<b-tabs
					active-nav-item-class="bg-white font-weight-bold description"
					nav-class="bg-white description"
					card
					no-body
				>
					<b-tab title="All Notifications" active>
						<NotificationCard
							:all-notifications="sentNotifications"
							@deleteNotification="askToDeleteNotification"
						/>
					</b-tab>
					<b-tab title="Scheduled Notifications">
						<NotificationCard
							:all-notifications="scheduledNotifications"
							@deleteNotification="askToDeleteNotification"
						/>
					</b-tab>
				</b-tabs>
			</Card>
		</div>
		<NewNotification
			:headerTitle="buttonItems[0].title"
			:showModal="showModal"
			@close="showModal = false"
			@newNotification="onNewNotification"
		/>

		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deletetNotification"
			@noClicked="notification == null"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import NewNotification from '../../mainForms/NewNotification.vue';
import { mapActions, mapGetters } from 'vuex';
import NotificationsManager from '../../../models/NotificationItem/NotificationsManager';
import NotificationCard from '../../Components/Notifications/NotificationCard.vue';
import Card from '../../../components/Cards/Card.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	name: 'notifications',

	components: {
		DashboardHeaderVue,
		NewNotification,
		NotificationCard,
		Card,
		QuestionModal,
	},
	data() {
		return {
			isLoading: true,
			allNotifications: [],
			sentNotifications: [],
			scheduledNotifications: [],
			lastFetchedDocument: null,
			lastScheduledFetchedDocument: null,
			showModal: false,
			showQuestionModal: false,
			modalQuestion: 'Are you sure you want to delete this notification?',
			notification: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Notification',
					type: 'primary',
					icon: 'fas fa-plus',
					link: 'google.com',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getNotifications() {
			this.allNotifications = [];
			//this.scheduledNotifications = [];
			this.sentNotifications = [];
			this.isLoading = true;
			NotificationsManager.fetchNotifications()
				.then((result) => {
					this.allNotifications = result[0];
					if (this.allNotifications) {
						this.sentNotifications = this.allNotifications.filter(
							(e) => e.uploadTimestamp.toDate() <= new Date()
						);
					}
					/*
					if (this.allNotifications) {
						this.scheduledNotifications = this.allNotifications.filter(
							(e) => e.uploadTimestamp.toDate() > new Date()
						);
						this.sentNotifications = this.allNotifications.filter(
							(e) => e.uploadTimestamp.toDate() <= new Date()
						);
					}
					*/
					this.lastFetchedDocument = result[1];
					this.isLoading = false;
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		getScheduledNotifications() {
			this.scheduledNotifications = [];
			NotificationsManager.fetchScheduledNotifications().then((result) => {
				this.scheduledNotifications = result[0];
				this.lastScheduledFetchedDocument = result[1];
				this.isLoading = false;
			});
		},
		onNewNotification(notification) {
			this.showModal = false;
			this.getNotifications();
			this.showNotification({
				type: 'success',
				message: 'Notification Sent.',
			});
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.showModal = !this.showModal;
			}
		},
		askToDeleteNotification(notification) {
			this.notification = notification;
			this.showQuestionModal = true;
			return;
		},
		deletetNotification() {
			NotificationsManager.deleteNotification(this.notification.messageId)
				.then(() => {
					this.getNotifications();
					this.showNotification({
						type: 'success',
						message: `Notification Deleted.`,
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
	},
	mounted() {
		this.getNotifications();
		this.getScheduledNotifications();
	},
};
</script>
