<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>

				<template #cell(status)="row">
					<Badge :type="getInvoiceStatus(row.item).type">{{
						getInvoiceStatus(row.item).status
					}}</Badge>
				</template>
				<template #cell(actions)="row">
					<b-dropdown id="actions" variant="null" dropleft no-caret>
						<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
						<b-dropdown-item @click="viewClicked(row.item)">View</b-dropdown-item>
						<b-dropdown-item @click="copyLinkClicked(row.item)">Copy Link</b-dropdown-item>
						<b-dropdown-divider v-if="getInvoiceStatus(row.item).type == 'info'"></b-dropdown-divider>
						<b-dropdown-item
							v-if="getInvoiceStatus(row.item).type == 'info'"
							variant="danger"
							@click="deleteClicked(row.item)"
							>Delete</b-dropdown-item
						>
					</b-dropdown>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Badge from '../../../components/Badge.vue';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import store from '../../../store/store';

export default {
	components: { Card, BaseButton, BaseInput, BaseSwitch, Badge },
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
			default() {
				return [
					{
						key: 'name',
						label: 'Name',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							return `${item.firstName} ${item.lastName}`;
						},
					},
					/*
					{
						key: 'invoiceId',
						label: 'ID',
						sortable: true,
						tdClass: 'align-middle',
					},
*/
					{
						key: 'created',
						label: 'Created',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							return this.$helpers.formatDate(value);
						},
					},
					{
						key: 'dueDate',
						label: 'Date Due',
						sortable: true,
						tdClass: 'align-middle',
						formatter: (value, key, item) => {
							return this.$helpers.formatDate(value);
						},
					},
					{
						key: 'status',
						label: 'Status',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'actions',
						label: '',
						tdClass: 'align-middle',
					},
				];
			},
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {
			filter: null,
			appLogo: null,
		};
	},
	methods: {
		getInvoiceStatus(invoiceData) {
			const invoiceStatus = invoiceData.status;
			if (invoiceStatus == 'paid') {
				return {
					type: 'success',
					status: 'Paid',
					icon: 'fas fa-check-circle',
				};
			} else if (invoiceStatus == 'outstanding') {
				return {
					type: 'warning',
					status: 'Past Due',
					icon: 'fas fa-history',
				};
			} else {
				return {
					type: 'info',
					status: 'Pending Payment',
					icon: 'fas fa-clock',
				};
			}
		},
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		copyLinkClicked(item){
			this.$emit('copyLinkClicked', item);
		},
		adjustActiveClicked(item) {
			this.$emit('adjustActiveClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('rowClicked', item);
		},
	},
	mounted() {
		this.appLogo = store.state.organization.appLogo;
	},
};
</script>
