var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.eventData)?_c('div',[_c('DashboardHeaderVue',{attrs:{"title":_vm.eventData.eventTitle,"buttonItems":_vm.buttonItems},on:{"childClick":_vm.childClick}}),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('MembersTable',{attrs:{"isLoading":_vm.isLoading,"headerTitle":`${_vm.eventData.eventTitle} · ${_vm.$helpers.formatDate(
						_vm.eventData.eventStart.toDate()
					)}`,"headerButtonData":{
						index: 0,
						title: 'Save',
						isDisabled: !_vm.isUpdatedNeeded,
					},"footerTitle":"TIP: When adding or removing users, please remember to update your ticket quantity, if necessary.","tableData":_vm.allUsers,"tableHeaders":_vm.userTitles},on:{"attendanceQuantityChange":_vm.attendanceQuantityChange,"onChanged":_vm.onSwitchChange,"viewClicked":_vm.viewClicked,"declineClicked":_vm.removeClicked,"headerButtonClicked":_vm.saveClicked}})],1)])]),_c('UsersModal',{attrs:{"headerTitle":"All Users","show":_vm.showUsersModal,"exempted-users":_vm.rawUsers},on:{"selectedUsers":_vm.selectedUsers,"close":function($event){_vm.showUsersModal = false}}}),_c('QuestionModal',{attrs:{"show":_vm.showQuestionModal,"question":_vm.modalQuestion},on:{"close":function($event){_vm.showQuestionModal = false},"yesClicked":_vm.addUsers,"noClicked":function($event){_vm.selectedUsers == null}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }