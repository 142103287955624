<template>
	<div slot="footer" v-if="title">
		<b-row>
			<b-col>
				<h6 class="mb-0">
					{{ title }}
				</h6>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import BaseSwitch from '../BaseSwitch.vue';
import BaseButton from '../BaseButton.vue';
export default {
	name: 'cardHeader',
	props: {
		title: {
			type: String,
			default: 'Card Footer',
		},
	},
	components: { BaseSwitch, BaseButton },
};
</script>
