<template>
	<BaseHeader class="pb-6">
		<div class="py-4">
			<div class="row">
				<div class="col-4">
					<h2 class="text-dark">{{ title }}</h2>
				</div>
				<div class="col-8 d-flex justify-content-end align-items-center">
					<div v-if="buttonItems">
						<div
							class="pl-2 d-inline-block"
							v-for="button in buttonItems"
							v-bind:key="button.index"
						>
							<BaseButton
								v-if="!button.children"
								:outline="button.outline"
								:disabled="button.isDisabled"
								:type="button.type"
								@click="handleClick(button)"
								size="sm"
							>
								<span v-if="button.icon" class="btn-inner--icon"
									><i :class="button.icon"></i
								></span>
								{{ button.title }}
							</BaseButton>

							<b-dropdown
								v-if="button.children"
								variant="primary"
								:text="button.title"
								size="sm"
								right
							>
								<div v-for="buttonChild in button.children" :key="buttonChild.index">
									<b-dropdown-item
										v-if="!buttonChild.isDivider"
										:disabled="buttonChild.isDisabled"
										@click="handleChildClick(buttonChild)"
									>
										<span :class="buttonChild.type ? `text-${buttonChild.type}` : ``">
											{{ buttonChild.title }}
										</span></b-dropdown-item
									>

									<b-dropdown-divider v-if="buttonChild.isDivider"></b-dropdown-divider>
								</div>
							</b-dropdown>
						</div>
					</div>
				</div>
			</div>
		</div>
	</BaseHeader>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseHeader from '../../../components/BaseHeader.vue';

export default {
	components: { BaseButton, BaseHeader },
	props: {
		title: String,
		buttonItems: [],
	},
	methods: {
		handleChildClick(buttonItem) {
			this.$emit('childClick', buttonItem);
		},
		handleClick(buttonItem) {
			this.$emit('click', buttonItem);
		},
	},
};
</script>
