<template>
	<div v-if="invoiceData && publicInformation">
		<Card no-body shadow ref="pdfElement">
			<div align-v="center" slot="header">
				<b-row class=" justify-content-between">
					<b-col>
						<h1 class="mb-3" v-if="!readOnly">Invoice</h1>
						<h4 class="m-0">Invoice Number #{{ invoiceData.invoiceId }}</h4>
						<h5 class="m-0" style="color: gray;">
							Date Issued: {{ $helpers.formatDate(invoiceData.created) }}
						</h5>
						<h5 v-if="invoiceData.dueDate" style="color: gray;">
							Due Date: {{ $helpers.formatDate(invoiceData.dueDate) }}
						</h5>
						<div class="d-flex align-items-center">
							<Badge :type="getInvoiceStatus().type">{{
								getInvoiceStatus().status
							}}</Badge>
							<BaseButton
								v-if="
									getInvoiceStatus().status == 'paid' && invoiceData.dominion_receipt_url
								"
								tag="a"
								target="_blank"
								:href="invoiceData.dominion_receipt_url"
								size="sm"
								type="link"
								class="text-muted mx-2 p-0 px-2 py-1"
								><h5
									class="m-0 text-muted"
									style="text-transform: none;text-decoration: underline;"
								>
									View Receipt
								</h5></BaseButton
							>
							<BaseButton
								v-if="false"
								size="sm"
								type=""
								class="text-muted mx-2 p-0 px-2 py-1"
								><h5 class="m-0 text-muted" style="text-transform: none;">
									Download <i class="fas fa-download"></i></h5
							></BaseButton>
						</div>
					</b-col>
					<b-col class="text-right">
						<b-img-lazy
							fluid
							:src="
								publicInformation.appLogoHorizontal
									? publicInformation.appLogoHorizontal
									: publicInformation.appLogo
							"
							:text="publicInformation.appName"
							:rounded="publicInformation.appLogoHorizontal ? '' : 'circle'"
							:class="publicInformation.appLogoHorizontal ? '' : 'avatar-xxl'"
							:style="{
								border: publicInformation.appLogoHorizontal
									? ''
									: '1px lightgray solid',
								width: publicInformation.appLogoHorizontal ? '60%' : '100%',
							}"
						/>
					</b-col>
				</b-row>
				<div class="my-3">
					<b-row>
						<b-col v-if="getInvoiceStatus().status !== 'paid'">
							<BaseButton
								v-if="!readOnly"
								block
								@click="payButtonClicked"
								:style="{ backgroundColor: publicInformation.baseColor, border: 0 }"
								>Pay Invoice {{ toCurrencyFormat(this.invoiceData.price.amount) }}
							</BaseButton>
							<BaseButton v-if="readOnly" outline block @click="viewInvoiceClicked"
								>View Invoice</BaseButton
							>
						</b-col>
						<b-col cols="auto" v-if="false">
							<BaseButton
								type="null"
								outline
								@click="showInformation = !showInformation"
								>{{ showInformation ? 'Hide Information' : 'View Information' }}
								<i
									:class="[showInformation ? 'fas fa-arrow-up' : 'fas fa-arrow-down']"
								></i
							></BaseButton>
						</b-col>
					</b-row>
				</div>
				<b-row class="mt-3" v-if="showInformation && !readOnly">
					<b-col>
						<h6 class="mb-1" style="color: gray;">Billed To:</h6>
						<h5 class="m-0">
							{{ invoiceData.firstName }} {{ invoiceData.lastName }}
						</h5>
						<h5 class="m-0">
							{{ invoiceData.email }}
						</h5>
						<h5>
							{{ invoiceData.address }}
						</h5>
					</b-col>
					<b-col v-if="false">
						<h6 class="mb-1" style="color: gray;">
							{{ publicInformation.appName }}
						</h6>
						<h5 class="m-0">{{ publicInformation.appAddress }}</h5>
						<h5 class="m-0">{{ publicInformation.appSupportEmail }}</h5>
					</b-col>
					<b-col v-if="invoiceData.invoiceMemo">
						<h6 class="mb-1" style="color: gray;">
							Memo:
						</h6>
						<h5>{{ invoiceData.invoiceMemo }}</h5>
					</b-col>
				</b-row>
			</div>
			<b-container class="py-sm" v-if="!readOnly">
				<div class="mx-lg-3">
					<b-table-simple outlined striped caption-top responsive="sm">
						<caption class="p-0">
							<h5 style="color: gray;">INVOICE DETAILS</h5>
						</caption>
						<b-tr>
							<b-th><h5 class="m-0 caption">Description</h5></b-th>
							<b-th style="text-align: right;"
								><h5 class="m-0 caption">Quantity</h5></b-th
							>
							<b-th style="text-align: right;"
								><h5 class="m-0 caption">Amount</h5></b-th
							>
						</b-tr>
						<b-tbody>
                            <b-tr v-for="(item, index) in invoiceData.invoiceItems">
								<b-th
									><h5 class="m-0 caption">{{ item.name }}</h5></b-th
								>
								<b-th style="text-align: right;"
									><h5 class="m-0 caption">
										{{ item.quantity ? item.quantity : 1 }}
									</h5></b-th
								>
								<b-th style="text-align: right;"
									><h5 class="m-0 caption">{{ toCurrencyFormat(item.amount) }}</h5></b-th
								>
							</b-tr>
							<b-tr><b-th class="p-1" colspan="3"></b-th></b-tr>
							<b-tr style="text-align: right;" v-if="this.invoiceData.price.subAmount">
								<b-th colspan="3" class="m-0 py-2 px-3">
									<b-row class="align-items-center">
										<b-col>
											<h5 class="m-0" style="color: gray;">Subtotal:</h5>
										</b-col>
										<b-col cols="auto">
											<h5 class="m-0">
												{{ toCurrencyFormat(this.invoiceData.price.subAmount) }}
											</h5>
										</b-col>
									</b-row>
								</b-th>
							</b-tr>
							<b-tr style="text-align: right;" v-if="this.invoiceData.price.transactionFee">
								<b-th colspan="3" class="m-0 py-2 px-3">
									<b-row class="align-items-center">
										<b-col>
											<h5 class="m-0" style="color: gray;">Transaction Fee:</h5>
										</b-col>
										<b-col cols="auto">
											<h5 class="m-0">{{ toCurrencyFormat(this.invoiceData.price.transactionFee) }}</h5>
										</b-col>
									</b-row>
								</b-th>
							</b-tr>
							<b-tr style="text-align: right;">
								<b-th colspan="3" class="m-0 py-2 px-3">
									<b-row class="align-items-center">
										<b-col>
											<h5 class="m-0">Total Amount:</h5>
										</b-col>
										<b-col cols="auto">
											<h4 class="m-0" style="font-weight: bold;">
												{{ getFinalAmount() }}
											</h4>
										</b-col>
									</b-row>
								</b-th>
							</b-tr>
						</b-tbody>
					</b-table-simple>

					<div v-if="!readOnly && showInformation && invoiceData.invoiceFooter">
						<hr class="my-3" />
						<h6 class="mb-1" style="color: gray;">
							Footer:
						</h6>
						<h6 style="color: gray;">{{ invoiceData.invoiceFooter }}</h6>
					</div>
				</div>
			</b-container>
			<div v-if="!readOnly" class="m-2" align-v="center" slot="footer">
				<h6 style="color: gray;text-align: center;">
					Need any help? Contact us: {{ publicInformation.appSupportEmail }}
				</h6>
				<h6 style="color: gray;text-align: center;">
					We will our best to make sure you enjoy your experience with our platform.
				</h6>
				<h6
					class="mt-3 mb-0"
					style="font-weight:bold;color: gray;text-align: center;"
				>
					{{ publicInformation.appName }}
				</h6>
				<h6 class="mb-0" style="color: gray;text-align: center;">
					{{ publicInformation.appAddress }}
				</h6>
				<h6 class="mb-0" style="color: gray;text-align: center;">
					{{ publicInformation.appWebsite }}
				</h6>
			</div>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Badge from '../../../components/Badge.vue';
import BaseButton from '../../../components/BaseButton.vue';
import Card from '../../../components/Cards/Card.vue';

export default {
    computed: {
        ...mapGetters({
            userData: 'getUser',
            publicInformation: 'getPublicInformation',
        }),
    },
    props: {
        invoiceData: {
            type: Object,
            default: null,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            needsLogin: true,
            showInformation: true,
        };
    },
    methods: {
        toCurrencyFormat(amount) {
            return this.$helpers.amountToString(amount);
        },
        getSubAmount(priceItem, quantity) {
            var itemAmount = 0.0;
            itemAmount = (priceItem.amount * quantity).toFixed(2) / 100;
            return parseFloat(itemAmount);
        },
        getFinalAmount(){
            const transactionFee = this.invoiceData.price.transactionFee ? this.invoiceData.price.transactionFee : 0
            const finalAmount = this.invoiceData.price.amount + transactionFee
            return this.toCurrencyFormat(finalAmount)
        },
        getInvoiceStatus() {
            if (this.invoiceData) {
                const invoiceStatus = this.invoiceData.status;
                if (invoiceStatus == 'paid') {
                    return {
                        type: 'success',
                        status: 'paid',
                    };
                }
                else if (invoiceStatus == 'outstanding') {
                    return {
                        type: 'warning',
                        status: 'Past Due',
                    };
                }
                else {
                    return {
                        type: 'info',
                        status: 'Pending Payment',
                    };
                }
            }
            else {
                return null;
            }
        },
        needsToLogin() {
            if (this.invoiceData && this.userData) {
                if (this.userData.uid == this.userId) {
                    return false;
                }
            }
            return true;
        },
        payButtonClicked() {
            this.$emit('payClicked');
        },
        viewInvoiceClicked() {
            this.$emit('viewClicked');
        },
    },
    mounted() { },
    components: { Card, Badge, BaseButton }
}
</script>