<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>

			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Add a new coupon for your users to claim at checkout.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form class="pt-1 pb-1">
					<BaseInput
						label="Coupon Promo Code"
						id="coupon code"
						placeholder="Coupon Promo Code"
						required
						v-model="coupon.couponCode"
					/>
					<BaseInput
						label="Coupon Description (not shown to users)"
						id="description"
						placeholder="Coupon Description"
						rows="4"
						required
						type="textarea"
						v-model="coupon.couponDescription"
					/>
					<b-row>
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Coupon Fixed Amount</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch
								v-model="isFixedAmount"
								@change="handleFixedSwitch"
							></BaseSwitch>
						</b-col>
					</b-row>
					<CurrencyInput
						v-if="isFixedAmount && !isPercentAmount"
						v-model="coupon.amountOff"
						:required="isFixedAmount"
						id="product price"
					/>
					<b-row>
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Coupon Percent Amount</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch
								v-model="isPercentAmount"
								@change="handlePercentSwitch"
							></BaseSwitch>
						</b-col>
					</b-row>
					<BaseInput
						v-if="!isFixedAmount && isPercentAmount"
						v-model="coupon.percentOff"
						:required="isPercentAmount"
						type="mask"
						:mask="['#%', '##%', '###%']"
						max="100"
						id="product percent"
					/>
					<b-row v-if="false">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Limited Coupon To Product Items</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch v-model="isProductIds" @change="!isProductIds"></BaseSwitch>
						</b-col>
					</b-row>
					<BaseInput
						v-if="isProductIds"
						v-model="coupon.percentOff"
						:required="isPercentAmount"
						type="mask"
						:mask="['#%', '##%', '###%']"
						max="100"
						id="product percent"
					/>
					<b-row>
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Limited Coupon Usage Per User</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch
								v-model="isUserLimited"
								@change="!isUserLimited"
							></BaseSwitch>
						</b-col>
					</b-row>
					<BaseInput
						v-if="isUserLimited"
						v-model="coupon.isUserLimited"
						:required="isUserLimited"
						type="number"
						min="1"
						max="99"
						id="product limit"
					/>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Coupon</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BaseSwitch from '../../components/BaseSwitch.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import Modal from '../../components/Modals/Modal.vue';
import CouponItemManager from '../../models/CouponItem/CouponItemManager';
import ProductItemManager from '../../models/Payment-Billing/ProductItemManager';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Coupon',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			isFixedAmount: true,
			isPercentAmount: false,
			isUserLimited: false,
			isProductIds: false,
			errorMessage: null,
			allShopItems: [],
			coupon: {
				couponCode: null,
				couponDescription: null,
				percentOff: 0,
				amountOff: 0,
				isUserLimited: null,
				productIds: [],
			},
		};
	},
	watch: {
		coupon: {
			handler(val) {
				if (val.couponCode) {
					this.coupon.couponCode = this.coupon.couponCode.toUpperCase();
				}
				if (val.percentOff) {
					console.log(val.percentOff);
				}
				return;
			},
			deep: true,
		},
	},
	components: { Modal, BaseInput, BaseSwitch, CurrencyInput },
	methods: {
		...mapActions(['showNotification']),
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		handleFixedSwitch() {
			if (this.isFixedAmount) {
				this.isPercentAmount = false;
			}
		},
		handlePercentSwitch() {
			if (this.isPercentAmount) {
				this.isFixedAmount = false;
			}
		},
		submit() {
			this.shake = false;
			if (
				this.$helpers.isBlank(this.coupon.couponCode) ||
				this.$helpers.isBlank(this.coupon.couponDescription)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else if (!this.isFixedAmount && !this.isPercentAmount) {
				this.errorMessage = 'Please add a discount amount.';
				this.shake = true;
				return;
			} else if (
				this.isPercentAmount &&
				parseInt(this.coupon.percentOff) <= 0 &&
				parseInt(this.coupon.percentOff) > 100
			) {
				this.errorMessage = 'Discount amount must be between 0%-100%.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;

				var couponInfo = {
					isValid: true,
					uploadTimestamp: this.$helpers.returnTimestamp(),
					couponCode: this.coupon.couponCode,
					couponDescription: this.coupon.couponDescription,
				};

				if (this.isFixedAmount) {
					couponInfo['amountOff'] = this.coupon.amountOff * 100;
				} else if (this.isPercentAmount) {
					couponInfo['percentOff'] = parseInt(this.coupon.percentOff);
				}

				if (this.isUserLimited) {
					couponInfo['isUserLimited'] = parseInt(this.coupon.isUserLimited);
				}

				this.isLoading = true;
				CouponItemManager.addCouponTODB(couponInfo)
					.then((data) => {
						this.$emit('newCoupon', data);
						this.isLoading = false;
						this.showNotification({
							type: 'success',
							message: 'New Coupon has been added.',
						});
						this.closeModal();
					})
					.catch((errorMessage) => {
						this.isLoading = false;
						this.showNotification({
							type: 'error',
							message: errorMessage,
						});
					});
			}
		},
	},
	mounted() {
		this.allShopItems = ProductItemManager.allProducts;
	},
};
</script>
