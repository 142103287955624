<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<Card>
			<CardHeader
				slot="header"
				:title="`Resources · ${allResources.length} Total`"
				:end-button="{ title: 'New Resources', disabled: false }"
				@click="openNewResourceView"
			/>
			<b-row>
				<b-col>
					<ResourceTable
                        isFiles
						:isLoading="isLoading"
						:tableData="allResources"
						@viewClicked="viewClicked"
						@deleteClicked="deleteClicked"
					/>
				</b-col>
			</b-row>
		</Card>
        <NewResourceItem
			headerTitle="New Resource"
			:showModal="showNewResouceModal"
            :class-data="classData"
			@close="showNewResouceModal = false"
			@newResource="onNewResource"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deleteResource"
			@noClicked="selectedResource == null"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import NewResourceItem from '../../mainForms/NewResourceItem.vue';
import ResourceTable from  '../ResourceViews/ResourceTable.vue'
import ClassItemManager from '../../../models/ClassItem/ClassItemManager'


export default {
	props: {
		classData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
            showQuestionModal: false,
            showNewResouceModal: false,
            modalQuestion: null,
            selectedResource: null,
			allResources: [],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getClassResources();
				}
			},
		},
	},
	methods: {
        ...mapActions(['showNotification']),
		openNewResourceView() {
			this.showNewResouceModal = false;
			this.showNewResouceModal = true;
		},
		getClassResources() {
			this.isLoading = true;
            ClassItemManager.getAllClassResources(this.classData.classId).then((items) => {
				this.allResources = items != null ? items : []
				this.isLoading = false;
			}).catch((error) => {
                this.isLoading = false;
            });
		},
        deleteClicked(item) {
			this.modalQuestion = 'Are you sure you want to delete this resource?';
			this.selectedResource = item;
			this.showQuestionModal = true;
		},
		viewClicked(item) {
			if (item.fileSignedLink) {
				var open = window.open(item.fileSignedLink, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view!');
				} else {
					open.focus();
				}
			}
		},
        deleteResource() {
			this.isLoading = true;
            ClassItemManager.deleteClassResourceItem(this.classData.classId, this.selectedResource.fileId)
				.then(() => {
					this.getClassResources();
					this.showNotification({
						type: 'success',
						message: `${this.selectedResource.fileTitle} has been deleted.`,
					});
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
        onNewResource(resource) {
			this.allResources.push(resource);
			this.showNotification({
				type: 'success',
				message: `${resource.fileId} Added.`,
			});
		},
	},
	components: { Card, CardHeader, ResourceTable, NewResourceItem, QuestionModal },
};
</script>