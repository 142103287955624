<template>
	<div v-if="updatedUserData">
		<b-overlay :show="isLoading" rounded="sm" no-wrap />
		<Card>
			<CardHeader
				slot="header"
				:title="'Account Information'"
				:end-button="{ title: 'Update Leader', disabled: !isUpdatedNeed }"
				@click="updateAppLeader"
			/>
			<form>
				<div>
					<BaseInput
						label="Leader Subtitle (optional)"
						type="text"
						placeholder="Leader Subtitle (optional)"
						name="subtitle"
						v-model="updatedUserData.title"
					/>
					<BaseInput label="Leader Bio">
						<textarea
							type="text"
							class="form-control"
							rows="8"
							placeholder="Enter bio..."
							name="leader bio"
							required
							v-model="updatedUserData.description"
						></textarea>
					</BaseInput>
					<BaseInput label="Leader Order" type="number" 
						:min="1"
						placeholder="Leader Order"
						name="subtitle"
						v-model="updatedUserData.order" />
				</div>
			</form>
		</Card>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../../components/Cards/Card.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../../components/Inputs/BaseInput.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';

export default {
	props: {
		userData: null,
	},
	data() {
		return {
			isLoading: false,
			isUpdatedNeed: false,
			updatedUserData: {
				title: null,
				description: null,
				order: null
			},
		};
	},
	watch: {
		updatedUserData: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
	},
	components: { Card, CardHeader, BaseInput },
	methods: {
		...mapActions(['showNotification']),
		checkIfValid() {
			const isDifferent =
				this.updatedUserData.title != this.userData.appLeader.title ||
				this.updatedUserData.description != this.userData.appLeader.description || 
				this.updatedUserData.order != this.getOrderDefault()
			return isDifferent;
		},
		checkIfUpdateNeeded() {
			if (this.updatedUserData.description) {
				if (this.userData.appLeader.title) {
					return this.checkIfValid();
				} else {
					return (
						this.updatedUserData.title ||
						this.updatedUserData.description != this.userData.appLeader.description || 
						this.updatedUserData.order != this.getOrderDefault()
					);
				}
			}
			return false;
		},
		getOrderDefault(){
			return this.userData.appLeader.order ? this.userData.appLeader.order : null
		},
		updateAppLeader() {
			this.isLoading = true;
			var appLeaderData = {
				description: this.updatedUserData.description,
			};
			if (this.updatedUserData.title) {
				appLeaderData['title'] = this.updatedUserData.title;
			}
			if (this.updatedUserData.order){
				appLeaderData['order'] = this.updatedUserData.order;
			}
			AppUserManager.updateAppLeader(this.userData.uid, {
				appLeader: appLeaderData,
			})
				.then(() => {
					this.isLoading = false;
					this.$emit('onUpdate', this.updatedUserData);
					this.showNotification({
						type: 'success',
						message: 'Leader Information Updated',
					});
					this.isUpdatedNeed = false;
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
	},
	mounted() {
		this.updatedUserData.title = this.userData.appLeader.title;
		this.updatedUserData.description = this.userData.appLeader.description;
		this.updatedUserData.order = this.getOrderDefault();
	},
};
</script>
