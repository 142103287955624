<template>
    <div class="wrapper">
		<Notifications />
		<SideMenu />
		<div class="main-content">
			<Header></Header>
			<div class="my-2" @click="$sidebar.displaySidebar(false)">
				<FadeTransition :duration="200" origin="center top" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</FadeTransition>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>
<script>
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import { FadeTransition } from 'vue2-transitions';
import { mapActions, mapGetters } from 'vuex';
import SideMenu from './SideMenu.vue'
import Header from './Header.vue'
import Footer from './Footer.vue'

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import FileItemManager from '../../models/FileItem/FileItemManager';
import AppUserManager from '../../models/AppUser/AppUserManager';
import EventManager from '../../models/EventItem/EventManager';
import ClassItemManager from '../../models/ClassItem/ClassItemManager';
import CalendarItemManager from '../../models/CalendarItem/CalendarItemManager';
import CategoryItemManager from '../../models/CategoryItem/CategoryItemManager';

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

export default {
    components: {
        FadeTransition, Notifications, SideMenu, Header, Footer
    },
    computed: {
		...mapGetters({
			notification: 'getNotification',
		}),
	},
    watch: {
		notification(newNotification, oldNotification) {
			if (newNotification) {
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'center',
					type: newNotification.type,
					message: newNotification.message,
				});
			}
		},
	},
    methods: {
		...mapActions([
			'showNotification',
			'removeNotification',
			'getTerminalReaders',
		]),
        loadDeskHelp(){
            
        },
		loadContent(){
			FileItemManager.getAllFiles().then(() => {
				//this.viewIsReady = true;
				
				AppUserManager.getAppLeadersUsers().then(() => {});
				EventManager.getAllEvents().then(() => {});
				ClassItemManager.getAllClasses().then(() => {});
				CalendarItemManager.getAllCalendarItems().then(() => {});
				CategoryItemManager.getAllCategories().then(() => {});
			});
		},
		closeNotificationModal() {
			this.removeNotification();
		},
		initScrollbar() {
			let isWindows = navigator.platform.startsWith('Win');
			if (isWindows) {
				initScrollbar('sidenav');
			}
		},
    },
    mounted(){
        this.loadDeskHelp()
		this.loadContent()
		this.initScrollbar();
    }
}

</script>