<template>
	<div>
		<b-row class="align-items-center justify-content-between">
			<b-col cols="auto">
				<div class="d-flex justify-content-end align-items-center m-0 p-0">
					<Badge :type="getInvoiceStatus().type">{{
						getInvoiceStatus().status
					}}</Badge>
				</div>
			</b-col>
			<b-col>
				<h5
					class="m-0"
					style="
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					"
				>
					Invoice #{{ invoiceData.invoiceId }}
				</h5>
				<h6
					class="m-0 text-muted"
					style="
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					"
				>
					Date Created: {{ $helpers.formatDate(this.invoiceData.created) }}
				</h6>
				<h6
					class="m-0 text-muted"
					style="
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					"
				>
					Date Paid: {{ $helpers.formatDate(this.invoiceData.chargeTimestamp) }}
				</h6>
			</b-col>
			<b-col cols="auto">
				<div
					v-if="false"
					class="d-flex justify-content-end align-items-center m-0 p-0"
				>
					<h5 class="m-0" :class="`text-${getInvoiceStatus().type}`">
						{{ this.$helpers.amountToString(this.invoiceData.price.amount) }}
					</h5>
					<i
						class="ml-2"
						:class="`${getInvoiceStatus().icon} text-${getInvoiceStatus().type}`"
					></i>
					<i class="fas fa-chevron-right ml-2 text-muted"></i>
				</div>
				<i v-else class="fas fa-chevron-right ml-2 text-muted"></i>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Badge from '../../../../components/Badge.vue';
export default {
	props: {
		invoiceData: {
			type: Object,
			default: null,
		},
	},
	methods: {
		getInvoiceStatus() {
			if (this.invoiceData) {
				const invoiceStatus = this.invoiceData.status;
				if (invoiceStatus == 'paid') {
					return {
						type: 'success',
						status: 'Paid',
						icon: 'fas fa-check-circle',
					};
				} else if (invoiceStatus == 'outstanding') {
					return {
						type: 'warning',
						status: 'Past Due',
						icon: 'fas fa-history',
					};
				} else {
					return {
						type: 'info',
						status: 'Pending Payment',
						icon: 'fas fa-clock',
					};
				}
			} else {
				return null;
			}
		},
	},
	components: { Badge },
};
</script>
