import {
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
} from 'firebase/auth';
import {
	arrayRemove,
	collection,
	deleteField,
	doc,
	and,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	startAfter,
	updateDoc,
	writeBatch,
	or, 
	where
} from 'firebase/firestore';
import helpers from '../../helpers/helpers';
import {
	getOrganizationAuth,
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export default class AppUserManager {
	static allClassLeaders = [];
	static limitFetch = 25;
	static returnDefaultProfilePicture() {
		return 'https://firebasestorage.googleapis.com/v0/b/dominionstudio-d9898.appspot.com/o/no-image-baby.png?alt=media&token=a50f9cfa-3bec-415d-9b50-90d1784f7bfa';
	}

	static isUserActive(userData) {
		if (userData.lastLoggedIn != null) {
			const today = new Date().getDate() - 30;
			const time30Before = new Date(new Date().setDate(today));
			const lastLoggedInDate =
				helpers.returnFirebaseDate(userData.lastLoggedIn) != null
					? helpers.returnFirebaseDate(userData.lastLoggedIn)
					: new Date();

			return time30Before.getTime() <= lastLoggedInDate.getTime();
		}
		return false;
	}

	static isAdminUser(userData) {
		if (userData.adminRoles) {
			const organization = store.state.organization;
			const adminRole = userData.adminRoles.find(
				(e) => e.orgId === organization.orgId
			);
			if (adminRole) {
				return true;
			}
		}
		return false;
	}

	static isUserFlagged(userData) {
		if (userData.isFlagged !== null) {
			return userData.isFlagged;
		}
		return false;
	}

	static getUsersP(lastDocument, maxCount) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const firestore = getOrganizationFirestore(organization);
			const fetchCount =
				maxCount != null
					? maxCount > AppUserManager.limitFetch
						? maxCount
						: AppUserManager.limitFetch
					: AppUserManager.limitFetch;
			const membersRef = collection(firestore, `users`);
			var queryRef = query(
				membersRef,
				orderBy('createdTimestamp', 'desc'),
				limit(fetchCount)
			);
			if (lastDocument) {
				queryRef = query(
					membersRef,
					orderBy('createdTimestamp', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument =
							total >= fetchCount ? documents[documents.length - 1] : null;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var memberData = document.data();
							if (memberData.uid) {
								this.fetchUserByUid(memberData.uid).then((userData) => {
									if (userData) {
										items.push(userData);
									}
									if (counter == total - 1) {
										return resolve([items, lastDocument]);
									}
									counter += 1;
								});
							} else {
								if (counter == total - 1) {
									return resolve([items, lastDocument]);
								}
								counter += 1;
							}
						});
					} else {
						return reject();
					}
				})
				.catch((error) => {
					console.error(error.message);
					return reject(error.message);
				});
		});
	}

	static getUsers(lastDocument) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const firestore = getOrganizationFirestore(organization);
			const usersRef = collection(firestore, `users`);
			var queryRef = query(
				usersRef,
				orderBy('createdTimestamp', 'desc', limit(25))
			);
			if (lastDocument) {
				queryRef = query(
					usersRef,
					orderBy('createdTimestamp', 'desc', startAfter(lastDocument), limit(25))
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = total >= 25 ? documents[documents.length - 1] : null;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var userData = document.data();
							if (!userData.profilePhotoURL) {
								userData['profilePhotoURL'] = this.returnDefaultProfilePicture();
							}
							items.push(userData);
							if (counter == total - 1) {
								return resolve(items, lastDocument);
							}
							counter += 1;
						});
					} else {
						//return reject('No users found.');
						return resolve();
					}
				})
				.catch((error) => {
					console.error(error.message);
					return reject(error.message);
				});
		});
	}

	static getAppLeadersUsers() {
		return new Promise((resolve, reject) => {
			this.allClassLeaders = [];
			const firestore = getOrganizationFirestore();
			const usersRef = collection(firestore, `users`);
			var queryRef = query(usersRef, orderBy('appLeader'));
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							var userData = document.data();
							if (!userData.profilePhotoURL) {
								userData['profilePhotoURL'] = this.returnDefaultProfilePicture();
							}
							if (!this.allClassLeaders.find(($0) => $0.uid == userData.uid)) {
								this.allClassLeaders.push(userData);
							}

							if (counter == total - 1) {
								return resolve();
							}
							counter += 1;
						});
					} else {
						//return reject('No users found.');
						return resolve();
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static fetchUserByUid(uid) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const userRef = doc(firestore, `users/${uid}`);
			getDoc(userRef).then((snapshot) => {
				if (snapshot.exists()) {
					var userData = snapshot.data();
					if (!userData.profilePhotoURL) {
						userData['profilePhotoURL'] = this.returnDefaultProfilePicture();
					}
					return resolve(userData);
				} else {
					return resolve(null);
				}
			});
		});
	}

	static createNewUser(userData) {
		return new Promise((resolve, reject) => {
			const auth = getOrganizationAuth();
			const organization = store.state.organization;
			const firestore = getOrganizationFirestore();
			createUserWithEmailAndPassword(auth, userData.email, userData.password)
				.then((result) => {
					const user = result.user;
					const userRef = doc(firestore, `users/${user.uid}`);
					const data = {
						uid: user.uid,
						firstName: userData.firstName,
						lastName: userData.lastName,
						email: userData.email,
						createdTimestamp: helpers.returnTimestamp(),
					};
					setDoc(userRef, data)
						.then(() => {
							this.sendUserPasswordReset(userData.email).finally(() => {
								auth.signOut();
								return resolve(data);
							});
						})
						.catch((error) => {
							auth.signOut();
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static searchUsersByText(text, maxCount){
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const membersRef = collection(firestore, `users`);
			const fetchCount = maxCount != null ? maxCount > AppUserManager.limitFetch ? maxCount : AppUserManager.limitFetch : AppUserManager.limitFetch;
			const queryRef = query(membersRef,
				and(
					where('firstName', '>=', text), 
					where('firstName', '<=', text + '\uf8ff'),
				),
				or(
					and(
						where('email', '>=', text), where('email', '<=', text + '\uf8ff')
					)
				),
			);
			getDocs(queryRef)
				.then((snapshot) => {
					this.returnSnapshotUsers(snapshot, fetchCount).then((data) => {
						return resolve(data)
					}).catch((error) => {
						return reject(error);
					})
				})
				.catch((error) => {
					return reject(error.message);
				});
		})
	}

	static listenerOnUser(userData) {
		return new Promise((resolve, reject) => {});
	}

	static sendUserPasswordReset(email) {
		return new Promise((resolve, reject) => {
			const auth = getOrganizationAuth();
			sendPasswordResetEmail(auth, email)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static returnUserData(data) {
		const userData = data;
		if (!userData.profilePhotoURL) {
			userData['profilePhotoURL'] = this.returnDefaultProfilePicture();
		}
		return userData;
	}

	static updateUserInformation(uid, data) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const userRef = doc(firestore, `users/${uid}`);
			setDoc(userRef, data, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static updateAppLeader(uid, data) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const userRef = doc(firestore, `users/${uid}`);
			setDoc(userRef, data, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static adjustAppLeader(uid, isAdding) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const userRef = doc(firestore, `users/${uid}`);
			if (isAdding) {
				//add new leader
				var appLeader = {
					appLeader: {
						description: 'Leader Bio Coming Soon...',
					},
				};
				updateDoc(userRef, appLeader)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				//remove leader
				var appLeader = {
					appLeader: deleteField(),
				};
				updateDoc(userRef, appLeader)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			}
		});
	}

	static getUserSubscriptions(uid) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const ref = collection(
				firestore,
				`${FirebaseManager.allUserRef(firestore).path}/${uid}/subscriptions`
			);
			getDocs(ref)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							const documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static getUserCharges(uid) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const ref = collection(
				firestore,
				`${FirebaseManager.allUserRef(firestore).path}/${uid}/charges`
			);
			getDocs(ref)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							const documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					return resolve(null);
				});
		});
	}

	static getUserInvoices(uid) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const ref = collection(
				firestore,
				`${FirebaseManager.allUserRef(firestore).path}/${uid}/invoices`
			);
			getDocs(ref)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							const documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static getUserRelationships(uid) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const ref = collection(
				firestore,
				`${FirebaseManager.allUserRef(firestore).path}/${uid}/userRelationships`
			);
			getDocs(ref)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var documentData = document.data();
							if (documentData.tempUid) {
								items.push(documentData);
								if (counter == total - 1) {
									return resolve(items);
								}
								counter += 1;
							} else {
								this.fetchUserByUid(documentData.uid).then((userInfo) => {
									if (userInfo) {
										const updateData = {
											...documentData,
											...userInfo,
										};
										items.push(updateData);
									}
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								});
							}
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static updateUserSubscription(uid, userSubscriptionItem, data) {
		return new Promise((resolve, reject) => {
			const userRef = doc(
				getOrganizationFirestore(),
				`${FirebaseManager.allUserRef(getOrganizationFirestore()).path}/${uid}`
			);
			const userSubscriptionRef = doc(
				getOrganizationFirestore(),
				`${userRef.path}/subscriptions/${userSubscriptionItem.subscriptionId}`
			);
			setDoc(userSubscriptionRef, data, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static changeUserImage(userFile, userData) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const userRef = doc(firestore, `users/${userData.uid}`);
			const newUserStoreRef = ref(storage, `users/${userData.uid}`);
			const metadata = {
				contentType: 'image/jpg',
			};
			uploadBytes(newUserStoreRef, userFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							setDoc(
								userRef,
								{
									profilePhotoURL: downloadURL,
								},
								{ merge: true }
							)
								.then(() => {
									return resolve();
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static adjustUserSubscription(
		uid,
		isCancelling,
		cancelImmediately,
		userSubscriptionItem,
		tokenCount,
		nextCharge,
		updatePrice
	) {
		return new Promise((resolve, reject) => {
			const userRef = doc(
				getOrganizationFirestore(),
				`${FirebaseManager.allUserRef(getOrganizationFirestore()).path}/${uid}`
			);
			const userSubscriptionRef = doc(
				getOrganizationFirestore(),
				`${userRef.path}/subscriptions/${userSubscriptionItem.subscriptionId}`
			);

			if (isCancelling && cancelImmediately) {
				const subscriptionUserData = {
					cancelDate: new Date(),
					nextCharge: deleteField(),
					isActive: false,
				};
				const userActiveSubscriptions = {
					activeSubscriptions: arrayRemove(userSubscriptionItem.subscriptionId),
				};
				const batch = writeBatch(getOrganizationFirestore());

				batch.update(userRef, userActiveSubscriptions);
				batch.update(userSubscriptionRef, subscriptionUserData);

				batch
					.commit()
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else if (tokenCount) {
				const subscriptionUserData = {
					quantityUsed: tokenCount,
				};
				updateDoc(userSubscriptionRef, subscriptionUserData)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else if (nextCharge) {
				const subscriptionUserData = {
					nextCharge: nextCharge,
				};
				updateDoc(userSubscriptionRef, subscriptionUserData)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else if (updatePrice){
				var subscription = userSubscriptionItem.subscription
				subscription.amount = updatePrice
				const subscriptionUserData = {
					subscription: subscription
				}
				updateDoc(userSubscriptionRef, subscriptionUserData)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				return resolve();
			}
		});
	}

	static returnSnapshotUsers(snapshot, fetchCount){
		return new Promise((resolve, reject) => {
			if (!snapshot.empty) {
				const documents = snapshot.docs;
				const total = documents.length;
				const lastDocument = total >= fetchCount ? documents[documents.length - 1] : null;
				var counter = 0;
				var items = [];
				documents.forEach((document) => {
					var memberData = document.data();
					if (memberData.uid) {
						this.fetchUserByUid(memberData.uid).then((userData) => {
							if (userData) {
								items.push(userData);
							}
							if (counter == total - 1) {
								return resolve([items, lastDocument]);
							}
							counter += 1;
						});
					} else {
						if (counter == total - 1) {
							return resolve([items, lastDocument]);
						}
						counter += 1;
					}
				});
			} else {
				return reject('No Users Found');
			}
		})
	}

	/*
	func adjustUserSubscription(_ uid: String, isCancelling: Bool, cancelImmediately: Bool, userSubscriptionItem: UserSubscriptionItem, completionHandler: @escaping (FirebaseManagerResult<(Date)>) -> Void){
        
        if (isCancelling && cancelImmediately){
            
        } else {
            if let nextDate = Recurrence.getNextRecurrenceDate(userSubscriptionItem.subscriptionDate, recurrence: userSubscriptionItem.subscriptionItem.recurrence) {
                let subscriptionUserData: [String: Any] = isCancelling ? ["cancelDate": FirebaseManager.convertDate(nextDate), "nextCharge": FieldValue.delete()] : ["nextCharge": FirebaseManager.convertDate(nextDate), "cancelDate": FieldValue.delete()]
                let userSubscriptionRef = FirebaseManager.allUserRef.document(uid).collection("subscriptions").document(userSubscriptionItem.subscriptionId)
                userSubscriptionRef.updateData(subscriptionUserData) { error in
                    if let error = error {
                        completionHandler(.failure(error, error.localizedDescription))
                    } else {
                        completionHandler(.success(nextDate))
                    }
                }
            } else {
                completionHandler(.failure(nil, Strings.UNKNOWN_ERROR))
            }
        }
    }
	*/
}
