<template>
	<div>
		<Card
			class="eventHomeCard"
			no-body
			:style="isActive ? 'opacity: 1' : 'opacity: 0.7'"
		>
			<VueAspectRatio ar="1:1">
				<div class="b-img-helpers card-img-top">
					<b-img-lazy
						fluid-grow
						:src="shopItem.productImages[0]"
						class="b-img-background"
					/>
					<div class="b-img-overlay"></div>
					<b-img-lazy
						fluid-grow
						:src="shopItem.productImages[0]"
						class="b-img-base"
					/>
				</div>
			</VueAspectRatio>

			<div class="m-3">
				<h3
					style="
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					"
				>
					{{ shopItem.productTitle }}
				</h3>
				<ReadMore :text="shopItem.productDescription" />
				<div v-if="shopItem.price.subscription" class="mb-3">
					<h4 class="small">
						(Subscription Includes
						{{
							shopItem.price.subscription.quantity != null
								? `${shopItem.price.subscription.quantity}`
								: 'Unlimited'
						}}
						Events)
					</h4>
					<h4 class="m-0">
						{{ $helpers.amountToString(shopItem.price.subscription.amount) }}
					</h4>
				</div>

				<h4 v-else class="m-0">
					{{ $helpers.amountToString(shopItem.price.amount) }}
				</h4>
				<div class="dropdown-divider"></div>
				<BaseButton
					v-if="!readOnly"
					block
					type="primary"
					class="mt-3"
					@click.stop.prevent="editProduct"
				>
					{{ isActive ? 'Edit' : 'Re-activate' }}
				</BaseButton>
			</div>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			:buttonData="modalButtonData"
			@close="showQuestionModal = false"
			@buttonClicked="questionButtonClicked"
		/>
		<QuestionModal
			:show="showSecondaryQuestionModal"
			:question="secondaryModalQuestion"
			@close="showSecondaryQuestionModal = false"
			@yesClicked="yesClicked"
		/>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Card from '../../../components/Cards/Card.vue';
import ReadMore from '../../../components/ReadMore.vue';
import Badge from '../../../components/Badge.vue';
import BaseButton from '../../../components/BaseButton.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { getOrganizationStorage } from '../../../resources/orgFirebase';
import { ref as firebaseDatabaseRef, remove, update } from '@firebase/database';
import { deleteObject, ref as firebaseStorageRef } from 'firebase/storage';
import { DashboardChildren } from '../../../routes/routesNames';
export default {
	props: {
		isActive: {
			type: Boolean,
			default: true,
		},
		shopItem: {
			type: Object,
			default: null,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		VueAspectRatio,
		Card,
		ReadMore,
		Badge,
		BaseButton,
		QuestionModal,
	},
	data() {
		return {
			productData: null,
			showQuestionModal: false,
			showSecondaryQuestionModal: false,
			modalQuestion: '',
			secondaryModalQuestion: '',
			modalButtonData: [],
			selectedButtonId: null,
			editId: 0,
			activateId: 1,
			deleteId: 2,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		askProductQuestion() {
			this.modalQuestion = 'Do you want to edit this product?';
			if (this.isActive) {
				this.modalButtonData = [
					{
						id: this.editId,
						title: 'Edit Product',
					},
					{
						id: this.activateId,
						title: 'Inactivate Product',
					},
					{
						id: this.deleteId,
						title: 'Delete Product',
						type: 'danger',
					},
				];
			} else {
				this.modalButtonData = [
					{
						id: this.activateId,
						title: 'Re-activate Product',
					},
					{
						id: this.deleteId,
						title: 'Delete Product',
						type: 'danger',
					},
				];
			}
			this.showQuestionModal = true;
		},
		questionButtonClicked(buttonItem) {
			this.selectedButtonId = buttonItem.id;
			switch (buttonItem.id) {
				case this.editId:
					this.editProduct();
					break;
				case this.activateId:
					this.askActivateQuestion();
					break;
				case this.deleteId:
					this.askDeleteQuestion();
					break;
				default:
					return;
			}
		},
		secondaryQuestionButtonClicked(buttonItem) {
			console.log(buttonItem);
		},
		askActivateQuestion() {
			this.secondaryModalQuestion = this.isActive
				? 'Do you want to inactivate this product?'
				: 'Do you want to activate this product?';
			this.showSecondaryQuestionModal = true;
		},
		askDeleteQuestion() {
			this.secondaryModalQuestion =
				'Are you sure you want to delete this product?';
			this.showSecondaryQuestionModal = true;
		},
		editProduct() {
			this.$router.push({
				name: DashboardChildren.shopView.name,
				params: {
					productId: this.shopItem.productId,
				},
			});
		},
		activationProduct() {
			console.log(`${this.isActive ? 'disactivate' : 'activate'}`);
			const database = getOrganizationDatabase(this.organization);
			const databaseRef = firebaseDatabaseRef(
				database,
				`productItems/${this.shopItem.productId}`
			);
			update(databaseRef, { productIsActive: !this.isActive })
				.then(() => {
					this.shopItem.productIsActive = !this.isActive;
					this.$emit('updateProduct', this.shopItem);
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		deleteProduct() {
			if (this.shopItem.productTimesPurchased) {
				if (Object.keys(this.shopItem.productTimesPurchased).length ?? 0 > 0) {
					return this.showNotification({
						type: 'danger',
						message: 'This product has already been purchased and cannot be deleted.',
					});
				}
			}

			const storage = getOrganizationStorage(this.organization);
			const database = getOrganizationDatabase(this.organization);
			const databaseRef = firebaseDatabaseRef(
				database,
				`productItems/${this.shopItem.productId}`
			);
			const storageRef = firebaseStorageRef(
				storage,
				this.shopItem.productMedia.productImage
			);

			deleteObject(storageRef)
				.then(() => {
					remove(databaseRef)
						.then(() => {
							this.$emit('deleteProduct', this.shopItem);
						})
						.catch((error) => {
							this.showNotification({
								type: 'danger',
								message: `${error.message}`,
							});
						});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		yesClicked() {
			if (this.selectedButtonId == this.activateId) {
				this.activationProduct();
			} else if (this.selectedButtonId == this.deleteId) {
				this.deleteProduct();
			}
		},
	},

	mounted() {},
};
</script>
