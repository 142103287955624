<template>
	<div v-if="productData">
		<DashboardHeader
			:title="productData.productTitle"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<ShopHeaderCard 
						:product-data="productData"
						@imageClicked="showEditImage = true"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<EditableShopCard :productData="productData" @onUpdate="getProduct" />
				</b-col>
			</b-row>
		</div>
		<QuestionModal
			:show="showSecondaryQuestionModal"
			:question="secondaryModalQuestion"
			@close="showSecondaryQuestionModal = false"
			@yesClicked="yesClicked"
		/>
		<ChangeImage
			:showModal="showEditImage"
			@updatedImage="addedImage"
			@close="showEditImage = false"
		/>
	</div>
</template>
<script>
import { deleteObject, ref as firebaseStorageRef } from '@firebase/storage';
import { mapActions, mapGetters } from 'vuex';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../../../resources/orgFirebase';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeader from '../../HeaderFooterComponents/DashboardHeader.vue';
import EditableShopCard from '../../../Components/ShopViews/EditableShopCard.vue';
import Card from '../../../../components/Cards/Card.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import Badge from '../../../../components/Badge.vue';
import ShopHeaderCard from '../../../Components/ShopViews/BaseShopView/ShopHeaderCard.vue';
import {
	deleteDoc,
	deleteField,
	doc,
	getDoc,
	setDoc,
} from '@firebase/firestore';
import ChangeImage from '../../../mainForms/ChangeImage.vue';
import ProductItemManager from '../../../../models/Payment-Billing/ProductItemManager';

export default {
	name: 'shopItem',
	computed: {
		...mapGetters({
			paymentInformation: 'getPaymentInformation',
		}),
	},
	props: {
		productId: {
			type: String,
			default: null,
		},
	},
	components: {
		DashboardHeader,
		EditableShopCard,
		QuestionModal,
		Card,
		Badge,
		ShopHeaderCard,
		ChangeImage,
	},
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			showSecondaryQuestionModal: false,
			showEditImage: false,
			modalQuestion: '',
			secondaryModalQuestion: '',
			productData: null,
			selectedButtonId: null,
			editId: 0,
			activateId: 1,
			deleteId: 2,
			terminalId: 3,
			viewPurchasedId: 4,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getProduct() {
			ProductItemManager.getProductById(this.productId)
				.then((data) => {
					this.productData = data;
					this.setButtons();
				})
				.catch((error) => {
					console.log(`Moses We Got No Product!: ${error}`);
				});
		},
		setButtons() {
			var children = [
				{
					index: this.viewPurchasedId,
					title: 'View Purchased',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.activateId,
					title: this.productData.isActive
						? 'Inactivate Product'
						: 'Re-activate Product',
					type: this.productData.isActive ? 'danger' : '',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.deleteId,
					title: 'Delete Product',
					type: 'danger',
				},
			];
			if (this.paymentInformation.activeTerminals) {
				children.splice(0, 0, {
					index: this.terminalId,
					title: 'Collect Payment',
				});
			}
			this.buttonItems[0].children = children;
		},
		childClick(childClicked) {
			this.selectedButtonId = childClicked.index;
			switch (childClicked.index) {
				case this.viewPurchasedId:
				this.$router.push({
						name: DashboardChildren.shopViewUsers.name,
						params: {
							productData: this.productData,
							productId: this.productId,
						},
					});
					break
				case this.activateId:
					this.askActivateQuestion();
					break;
				case this.deleteId:
					this.askDeleteQuestion();
					break;
				case this.terminalId:
					this.openTerminalPay();
					break;
				default:
					break;
			}
		},
		askActivateQuestion() {
			this.secondaryModalQuestion = this.productData.isActive
				? 'Do you want to inactivate this product?'
				: 'Do you want to activate this product?';
			this.showSecondaryQuestionModal = true;
		},
		askDeleteQuestion() {
			this.secondaryModalQuestion =
				'Are you sure you want to delete this product?';
			this.showSecondaryQuestionModal = true;
		},
		activationProduct() {
			const firestore = getOrganizationFirestore(this.organization);
			const productRef = doc(firestore, `products/${this.productId}`);
			setDoc(
				productRef,
				{
					isActive: !this.productData.isActive,
				},
				{ merge: true }
			)
				.then(() => {
					this.navigateToShop();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		addedImage(file) {
			const updatedImage = file[0];
			this.isLoading = true;
			ProductItemManager.changeProductImage(updatedImage, this.productData)
				.then(() => {
					this.isLoading = false;
					this.getProduct();
					this.showNotification({
						type: 'success',
						message: 'Product Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error, //'Unknown Error.',
					});
				});
		},
		deleteProduct() {
			const storage = getOrganizationStorage(this.organization);
			const firestore = getOrganizationFirestore(this.organization);
			const productRef = doc(firestore, `products/${this.productId}`);
			const storageRef = firebaseStorageRef(
				storage,
				this.productData.productImages[0]
			);
			deleteDoc(productRef)
				.then(() => {
					deleteObject(storageRef).finally(() => {
						this.navigateToShop();
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: `${error.message}`,
					});
				});
		},
		yesClicked() {
			if (this.selectedButtonId == this.activateId) {
				this.activationProduct();
			} else if (this.selectedButtonId == this.deleteId) {
				this.deleteProduct();
			}
		},
		openTerminalPay() {
			this.$router.push({
				name: DashboardChildren.terminalView.name,
				params: {
					readerId: this.paymentInformation.activeTerminals[0],
					productData: this.productData,
				},
				query: {
					productId: this.productId,
				},
			});
		},
		navigateToShop() {
			this.$router.replace({
				name: DashboardChildren.shop.name,
			});
		},
	},
	mounted() {
		if (!this.productId) {
			this.navigateToShop();
		} else {
			this.getProduct();
		}
	},
};
</script>
