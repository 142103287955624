import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	orderBy,
	query,
	setDoc,
} from 'firebase/firestore';
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytes,
} from 'firebase/storage';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import FirebaseManager from '../Firebase/FirebaseManager';

export default class FeaturedItemManager {
	static getAllFeatured() {
		return new Promise((resolve, reject) => {
			const featuredRef = FirebaseManager.allFeaturedRef(
				getOrganizationFirestore()
			);
			const queryRef = query(featuredRef, orderBy('index'));
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						return reject('No users found.');
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addFeaturedItem(featuredFile, featuredIndex, featuredLink) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const featuredRef = collection(firestore, `featured`);
			const newFeaturedRef = doc(featuredRef);
			const newFeatruedStorageRef = ref(storage, `featured/${newFeaturedRef.id}`);
			const index = featuredIndex == 0 ? 0 : featuredIndex - 1;
			const metadata = {
				contentType: 'image/jpg',
			};
			uploadBytes(newFeatruedStorageRef, featuredFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							var featuredData = {
								featuredId: newFeaturedRef.id,
								imageItem: {
									imageUrl: downloadURL,
								},
								index: index,
							};
							if (featuredLink) {
								featuredData['featuredLink'] = featuredLink;
							}
							setDoc(newFeaturedRef, featuredData)
								.then(() => {
									return resolve(featuredData);
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteFeaturedItem(featuredId) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const featuredRef = doc(firestore, `featured/${featuredId}`);
			const storageRef = ref(storage, `featured/${featuredId}`);

			deleteDoc(featuredRef)
				.then(() => {
					deleteObject(storageRef)
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							//return reject(error.message);
							return resolve();
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
