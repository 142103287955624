<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(titleAndDoc)="data">
					<i class="fas fa-file"></i>&nbsp;
					{{ data.item.fileTitle }}
				</template>
				<template #cell(isRequired)="data">
					<BaseSwitch
						:value="data.value"
						:is-disabled="true"
						:type="'success'"
						@input="switchChanged($event, data)"
					/>
				</template>
				<template #cell(isActive)="data">
					<BaseSwitch
						:value="data.value"
						:is-disabled="true"
						:type="'success'"
						@input="switchChanged($event, data)"
					/>
				</template>
				<template #cell(actions)="row">
					<b-dropdown id="actions" variant="null" dropleft no-caret>
						<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
						<b-dropdown-item v-if="false" @click="editClicked(row.item)"
							>Edit</b-dropdown-item
						>
						<b-dropdown-item @click="viewClicked(row.item)">View</b-dropdown-item>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item variant="danger" @click="deleteClicked(row.item)"
							>Delete</b-dropdown-item
						>
					</b-dropdown>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import BaseDropdown from '../../../components/BaseDropdown.vue';

export default {
	components: { Card, BaseButton, BaseInput, BaseSwitch, BaseDropdown },
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
			default() {
				return [
					{
						key: 'titleAndDoc',
						label: 'Title',
						sortable: true,
						thStyle: { width: '100%' },
						tdClass: 'align-middle',
					},
					{
						key: 'isRequired',
						label: 'Required',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'isActive',
						label: 'Active',
						sortable: true,
						tdClass: 'align-middle',
					},
					{
						key: 'actions',
						label: '',
						tdClass: 'align-middle',
					},
				];
			},
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {
			filter: null,
		};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		editClicked(item) {
			this.$emit('editClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('rowClicked', item);
		},
	},
};
</script>
