<template>
	<div>
		<Card no-body>
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="headerButtonData.isDisabled"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(label)="data">
					<b-img
						fluid
						style="max-width: 35px"
						:src="
							require(`../../../../public/img/icons/cards/${getDeviceType(data)}`)
						"
					/>
					{{ data.value }}
				</template>
				<template #cell(status)="data">
					<Badge :type="data.value == 'online' ? 'success' : 'primary'"
						><i
							:class="
								data.value == 'online' ? 'fas fa-check-circle' : 'fas fa-info-circle'
							"
						></i>
						{{ data.value == 'online' ? 'Online' : 'Inactive' }}</Badge
					>
				</template>
				<template #cell(actions)="row">
					<b-dropdown id="actions" variant="null" dropleft no-caret>
						<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
						<b-dropdown-item v-if="false" @click="collectClicked(row.item)"
							>Collect Payment</b-dropdown-item
						>
						<b-dropdown-divider v-if="false"></b-dropdown-divider>
						<b-dropdown-item
							v-if="false"
							variant="danger"
							@click="cancelClicked(row.item)"
							>Cancel Action</b-dropdown-item
						>
						<b-dropdown-item variant="danger" @click="deleteClicked(row.item)"
							>Delete Terminal</b-dropdown-item
						>
					</b-dropdown>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Badge from '../../../components/Badge.vue';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	components: { Card, BaseButton, BaseInput, BaseSwitch, Badge },
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		headerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
			default() {
				return [
					{
						key: 'label',
						label: 'Device Name',
						sortable: true,
						thStyle: { width: '100%' },
						tdClass: 'align-middle',
					},
					{
						key: 'status',
						label: 'Status',
						tdClass: 'align-middle text-center',
					},
					{
						key: 'actions',
						label: '',
						thStyle: { width: '10%' },
					},
				];
			},
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	methods: {
		getDeviceType(data) {
			if (data.item.device_type == 'bbpos_wisepos_e') {
				return 'wisepoe.png';
			} else {
				return 'unknown.png';
			}
		},
		headerButtonClicked() {
			this.$emit('headerButtonClicked');
		},
		collectClicked(item) {
			this.$emit('collectClicked', item);
		},
		adjustActiveClicked(item) {
			this.$emit('adjustActiveClicked', item);
		},
		cancelClicked(item) {
			this.$emit('cancelClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('rowClicked', item);
		},
	},
};
</script>
