import axios from 'axios';
import { collection, doc, setDoc } from 'firebase/firestore';
import { DominionTerminalFunctions } from '../../resources/DominionInformation';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import CouponItemManager from '../../models/CouponItem/CouponItemManager';
import EventManager from '../../models/EventItem/EventManager';
import ProductItemManager from './ProductItemManager';

export default class PaymentManager {
	static createStripeTerminalCharge(uid, data) {
		return new Promise((resolve, reject) => {
			var chargeData = data;
			const userCharges = collection(
				getOrganizationFirestore(),
				`${
					FirebaseManager.allUserRef(getOrganizationFirestore()).path
				}/${uid}/charges`
			);
			const databaseRef = doc(userCharges);
			const databaseId = databaseRef.id;

			chargeData['orgId'] = store.state.publicInformation.appOrgId;
			chargeData['chargeId'] = databaseId;
			chargeData['userId'] = uid;
			chargeData['currency'] = store.state.paymentInformation.currency;
			chargeData['stripeAccount'] = store.state.paymentInformation.stripeAccountId;
			chargeData['isTestMode'] = store.state.paymentInformation.isTestMode
				? store.state.paymentInformation.isTestMode
				: false;
			chargeData['isTerminalCharge'] = true;

			return axios
				.post(DominionTerminalFunctions.createStripeCharge, {
					data: chargeData,
				})
				.then((result) => {
					return resolve([
						result.data.result,
						uid,
						databaseId,
						databaseRef,
						chargeData,
					]);
				})
				.catch((error) => {
					const errorResult = error.response.data;
					const errorMessage = errorResult.error.details.message;
					return reject(errorMessage);
				});
		});
	}

	static capturePaymentIntent(
		paymentId,
		uid,
		databaseId,
		databaseRef,
		chargeData,
		eventData,
		productData
	) {
		return new Promise((resolve, reject) => {
			chargeData['paymentId'] = paymentId;
			return axios
				.post(DominionTerminalFunctions.capturePaymentIntent, { data: chargeData })
				.then((result) => {
					const dataResult = result.data;
					var data = dataResult.result.data;

					const receiptLink = data.dominion_receipt_url;
					data = Object.entries(data).reduce(
						(a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
						{}
					); //remove null values...
					data['chargeId'] = databaseId;
					data['quantity'] = chargeData.quantity;

					if (chargeData.couponItem) {
						data['couponId'] = chargeData.couponItem.couponId;
					}

					setDoc(databaseRef, data)
						.then(() => {
							if (chargeData.couponItem) {
								CouponItemManager.redeemCoupon(
									uid,
									chargeData.couponItem.couponId,
									databaseId,
									new Date(data.created)
								).finally(() => {
									if (eventData) {
										EventManager.manuallyAddUsersToEvent(
											[{ uid: uid }],
											eventData,
											databaseId,
											new Date(data.created)
										).finally(() => {
											return resolve(receiptLink);
										});
									} else if (productData) {
										ProductItemManager.redeemProduct(
											uid,
											productData,
											databaseId,
											new Date(data.created)
										).finally(() => {
											return resolve(receiptLink);
										});
									} else {
										return resolve(receiptLink);
									}
								});
							} else {
								if (eventData) {
									EventManager.manuallyAddUsersToEvent(
										[{ uid: uid }],
										eventData,
										databaseId,
										new Date(data.created)
									).finally(() => {
										return resolve(receiptLink);
									});
								} else if (productData) {
									ProductItemManager.redeemProduct(
										uid,
										productData,
										databaseId,
										new Date(data.created)
									).finally(() => {
										return resolve(receiptLink);
									});
								} else {
									return resolve(receiptLink);
								}
							}
						})
						.catch((error) => {
							//success but we didnt add to database? need to retry here!
							//setup a web hook?
							console.log('Error Here Weirdly....');
						});
				})
				.catch((error) => {
					const errorResult = error.response.data;
					const errorMessage = errorResult.error.details.message;
					return 'error';
				});
		});
	}

	static discoverReaders() {}

	static createTerminalReader(locationAddress) {
		return new Promise((resolve, reject) => {
			/*
            "data": {
        "locationName": "Dance Studio App",
        "locationAddress": {
            "line1": "6713 Swindon Place",
            "city": "Manassas",
            "state": "VA",
            "country": "US",
            "postal_code": "20112"
        },
        "readerCode": "wisely-absence-decision",
        "readerName": "Good Test Reader",
        "stripeAccount": "acct_1HQo30ACTETZhlHk",
        "isTestMode": true
    }
            */
			/*
{"result":{"id":"tmr_E8YocwyI3vE8dH","object":"terminal.reader","action":null,"device_sw_version":"2.9.2.0","device_type":"bbpos_wisepos_e","ip_address":"192.168.1.226","label":"Good Test Reader","livemode":false,"location":"tml_E8Yocw1IRfUSmx","metadata":{},"serial_number":"WSC513124019864","status":"online"}}
    */
		});
	}

	static createTerminalConnection() {
		const data = {
			locationId: store.state.paymentInformation.terminalLocationId,
			stripeAccount: store.state.paymentInformation.stripeAccountId,
			isTestMode: store.state.paymentInformation.isTestMode
				? store.state.paymentInformation.isTestMode
				: false,
		};
		return axios
			.post(DominionTerminalFunctions.createTerminalConnection, { data: data })
			.then((result) => {
				return result.data.result.secret;
			})
			.catch((error) => {
				const errorResult = error.response.data;
				const errorMessage = errorResult.error.details.message;
				return 'error';
			});
	}

	static setTerminalDisplay(readerId, lineItems, taxAmount, totalAmount) {
		return new Promise((resolve, reject) => {
			const data = {
				readerId: readerId,
				lineItems: lineItems,
				taxAmount: taxAmount,
				totalAmount: totalAmount,
				currency: store.state.paymentInformation.currency,
				stripeAccount: store.state.paymentInformation.stripeAccountId,
				isTestMode: store.state.paymentInformation.isTestMode
					? store.state.paymentInformation.isTestMode
					: false,
			};
			return axios
				.post(DominionTerminalFunctions.setTerminalDisplay, { data: data })
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					const errorResult = error.response.data;
					const errorMessage = errorResult.error.details.message;
					return reject(errorMessage);
				});
		});
	}

	static getAllReaders() {
		return new Promise((resolve, reject) => {
			const informationData = {
				stripeAccount: store.state.paymentInformation.stripeAccountId,
				isTestMode: store.state.paymentInformation.isTestMode
					? store.state.paymentInformation.isTestMode
					: false,
			};
			return axios
				.post(DominionTerminalFunctions.getAllReaders, {
					data: informationData,
				})
				.then((result) => {
					resolve(result.data.result.data);
				})
				.catch((error) => {
					const errorResult = error.response.data;
					const errorMessage = errorResult.error.details.message;
					return reject(errorMessage);
				});
		});
	}

	static cancelCurrentAction(readerId) {
		return new Promise((resolve, reject) => {
			const data = {
				readerId: readerId,
				stripeAccount: store.state.paymentInformation.stripeAccountId,
				isTestMode: store.state.paymentInformation.isTestMode
					? store.state.paymentInformation.isTestMode
					: false,
			};
			return axios
				.post(DominionTerminalFunctions.cancelReaderAction, {
					data: data,
				})
				.then((result) => {
					resolve(result);
				})
				.catch((error) => {
					const errorResult = error.response.data;
					const errorMessage = errorResult.error.details.message;
					return reject(errorMessage);
				});
		});
	}
}
