<template>
	<div v-if="productData">
		<Card>
			<b-row class="d-flex align-items-center" style="gap: 10px">
				<b-col
					cols="auto"
					@mouseover="imageHover = true"
					@mouseleave="imageHover = false"
				>
					<VueAspectRatio ar="1:1" style="width: 100px">
						<div class="b-img-helpers card-img">
							<div :style="{ opacity: imageHover ? 0.4 : 1 }">
								<b-img
									fluid-grow
									rounded
									:src="productData.productImages[0]"
									style="height: 100%; object-fit: cover"
								/>
							</div>
							<div
								v-if="imageHover"
								style="
									height: 100%;
									width: 100%;
									border: dashed 1px black;
									display: flex;
									flex-wrap: wrap;
									align-content: center;
								"
								class="justify-content-center b-img-base"
							>
								<BaseButton type="primary" size="sm" block @click="imageClicked"
									>Edit</BaseButton
								>
							</div>
						</div>
					</VueAspectRatio>
				</b-col>
				<b-col cols="auto" class="flex-grow-1 p-0">
					<div class="align-items-left text-left flex-grow-1">
						<div class="d-flex">
							<h3 class="mb-0">
								{{ productData.productTitle }}
							</h3>
						</div>
						<div class="d-flex">
							<h5 class="mb-0">
								Purchased:
								{{ productData.redeemed != null ? productData.redeemed : '0' }}
								times
							</h5>
						</div>
						<div v-if="productData.price.subscription" class="d-flex">
							<h6 class="mb-0">
								<i> {{ returnSubscriptionText(productData.price.subscription) }}</i>
							</h6>
						</div>
					</div>
				</b-col>
				<b-col cols="auto">
					<Badge :type="productData.isActive ? 'success' : 'danger'" rounded>{{
						productData.isActive ? 'Active' : 'Inactive'
					}}</Badge>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import Badge from '../../../../components/Badge.vue';
import VueAspectRatio from 'vue-aspect-ratio';
import Card from '../../../../components/Cards/Card.vue';
import BaseButton from '../../../../components/BaseButton.vue';

export default {
	props: {
		productData: null,
	},
	data() {
		return {
			imageHover: false,
		};
	},
	components: { Card, Badge, VueAspectRatio, BaseButton },
	methods: {
		returnSubscriptionText(subscriptionItem) {
			if (subscriptionItem.quantity) {
				if (subscriptionItem.quantity > 1) {
					return `Subscription Includes: ${subscriptionItem.quantity} Events`;
				}
			}
			return 'Subscription Includes: Unlimited Events';
		},
		imageClicked() {
			this.$emit('imageClicked');
		},
	},
};
</script>
