<template>
	<div>
		<Card>
			<b-row v-if="selectedFacultyLeader != null" align-v="center" slot="footer">
				<b-col>
					<div
						v-if="selectedFacultyLeader"
						class="d-flex justify-content-start align-items-center"
					>
						<b-avatar :src="selectedFacultyLeader.profilePhotoURL" variant="dark" />
						<h5 class="my-0 mx-2">
							{{ selectedFacultyLeader.firstName }}
							{{ selectedFacultyLeader.lastName }}
						</h5>
					</div>
				</b-col>
			</b-row>
			<b-row class="d-flex align-items-center" style="gap: 10px">
				<b-col
					cols="auto"
					@mouseover="imageHover = true"
					@mouseleave="imageHover = false"
				>
					<VueAspectRatio ar="1:1" style="width: 150px">
						<div class="b-img-helpers card-img">
							<div :style="{ opacity: imageHover ? 0.4 : 1 }">
								<b-img-lazy
									fluid-grow
									rounded
									:src="eventData.eventImages[0]"
									class="b-img-background"
								/>
								<div class="b-img-overlay"></div>
								<b-img-lazy
									fluid-grow
									:src="eventData.eventImages[0]"
									class="b-img-base"
								/>
							</div>
							<div
								v-if="imageHover"
								style="
									height: 100%;
									width: 100%;
									border: dashed 1px black;
									display: flex;
									flex-wrap: wrap;
									align-content: center;
								"
								class="justify-content-center b-img-base"
							>
								<BaseButton type="primary" size="sm" block @click="imageClicked"
									>Edit</BaseButton
								>
							</div>
						</div>
					</VueAspectRatio>
				</b-col>
				<b-col cols="auto" class="flex-grow-1 p-0">
					<div class="align-items-left text-left flex-grow-1">
						<div class="d-flex">
							<i class="fas fa-calendar mr-2"></i>
							<h5>
								{{
									`${$helpers.formatDate(
										eventData.eventStart.toDate(),
										'EEEE, MMMM dd, yyyy - hh:mm a'
									)}`
								}}
							</h5>
						</div>

						<div v-if="this.eventData.recurrence" class="d-flex">
							<i class="fas fa-redo mr-2"></i>
							<h5>
								{{ getRecurrence() }}
							</h5>
						</div>
						<div v-if="eventData.location" class="d-flex">
							<i class="fas fa-location-arrow mr-2"></i>
							<h5 v-if="eventData.location.title">
								{{ this.eventData.location.title }}
							</h5>
							<h5 v-if="eventData.location.description" class="text-muted">
								-
								{{ this.eventData.location.description }}
							</h5>
						</div>
						<div v-if="eventData.price" class="d-flex">
							<i class="fas fa-dollar mr-2"></i>
							<h5>
								{{ getPriceTop(this.eventData.price) }}
							</h5>
							<h5 v-if="getPriceBottom(this.eventData.price)" class="text-muted">
								-
								{{ getPriceBottom(this.eventData.price) }}
							</h5>
						</div>
						<div v-if="eventData.ageItem" class="d-flex">
							<i class="fas fa-user-shield mr-2"></i>
							<h5>Ages: {{ getAgeRestriction(eventData.ageItem) }}</h5>
						</div>
					</div>
				</b-col>
				<b-col cols="auto">
					<Badge v-if="isSoldOut" type="info" rounded> Sold Out</Badge>
					<Badge
						v-else
						:type="this.eventData.isCancelled ? 'danger' : 'success'"
						rounded
					>
						{{ this.eventData.isCancelled ? 'Cancelled' : 'On Time' }}</Badge
					>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Badge from '../../../../components/Badge.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import Card from '../../../../components/Cards/Card.vue';
import reoccuringOptions from '../../../../data/reoccuringOptions';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import Recurrence from '../../../../models/DataModels/Reccurrence';

export default {
	props: {
		eventData: {
			type: Object,
			default: null,
		},
	},
	components: {
		Card,
		VueAspectRatio,
		Badge,
		BaseButton,
	},
	data() {
		return {
			reoccuringOptions,
			selectedFacultyLeader: null,
			isSoldOut: false,
			imageHover: false,
		};
	},
	methods: {
		getPriceTop(priceItem) {
			if (priceItem.oneTimePurchase) {
				return `One Time: ${this.$helpers.amountToString(priceItem.amount)}`;
			} else {
				return this.$helpers.amountToString(priceItem.amount);
			}
		},
		getPriceBottom(priceItem) {
			var priceBottomString = '';
			if (priceItem.subscription) {
				if (priceItem.subscription && priceItem.amount) {
					priceBottomString += `One-time Fee: ${this.$helpers.amountToString(
						priceItem.amount
					)}`;
				}
				priceBottomString += `Billed: ${Recurrence.returnReadableString(
					priceItem.subscription.recurrence
				)}`;
				return priceBottomString;
			}

			if (priceItem.quantity != null) {
				var quantity = priceItem.quantity;
				//if (priceItem.purchased) {
				//	quantity = quantity - priceItem.purchased;
				//}
				if (quantity <= 0) {
					quantity = 0;
				}
				return `Tickets Available: ${priceItem.quantity}`;
			}
			return null;
		},
		getAgeRestriction(ageItem) {
			if (ageItem.endAge) {
				return `${ageItem.startAge} - ${ageItem.endAge}`;
			} else {
				return `${ageItem.startAge}+`;
			}
		},
		getRecurrence() {
			return Recurrence.returnReadableString(this.eventData.recurrence);
		},
		imageClicked() {
			this.$emit('imageClicked');
		},
	},
	mounted() {
		if (this.eventData.eventLeaders) {
			this.selectedFacultyLeader = AppUserManager.allClassLeaders.find(
				(e) => e.uid == this.eventData.eventLeaders[0]
			);
		}

		if (this.eventData.price) {
			if (this.eventData.price.quantity != null) {
				this.isSoldOut = this.eventData.price.quantity <= 0;
			}
		}
		this.reoccuringOptions = reoccuringOptions;
	},
};
</script>
