<template>
	<div>
		<Card>
			<CardHeader slot="header" title="Registration Settings" />
			<div>
				<b-row v-if="showMaxUsersOptions">
					<b-col>
						<BaseInput
							label="Maximum Users"
							placeholder="Maximum Users"
							min="1"
							type="number"
							v-model="registration.eventMaxUsers"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<label class="form-control-label"
							>When does this registration open before it starts?</label
						>
						<b-row>
							<b-col>
								<BaseInput
									id="hours"
									placeholder="Hours"
									type="mask"
									:mask="['# hours', '## hours']"
									v-model="eventStartHoursHandle"
								/>
							</b-col>
							<b-col>
								<BaseInput
									id="minutes"
									placeholder="Minutes"
									type="mask"
									:mask="['# minutes', '## minutes']"
									v-model="eventStartMinutesHandle"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<label class="form-control-label"
							>When does this registration close after it starts?</label
						>
						<b-row>
							<b-col>
								<BaseInput
									id="hours"
									placeholder="Hours"
									type="mask"
									:mask="['# hours', '## hours']"
									v-model="eventCloseHoursHandle"
								/>
							</b-col>
							<b-col>
								<BaseInput
									id="minutes"
									placeholder="Minutes"
									type="mask"
									:mask="['# minutes', '## minutes']"
									v-model="eventCloseMinutesHandle"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
		</Card>
		<Card :no-body="!ageLimitSwitch">
			<CardHeader
				slot="header"
				:title="ageTitle"
				:endSwitch="{ title: '' }"
				:endSwitchValue="ageLimitSwitch"
				@change="ageSwitchChanged"
			/>

			<form v-if="ageLimitSwitch">
				<b-row>
					<b-col>
						<BaseInput
							label="Start Age (in years)"
							id="start age"
							placeholder="Start Age"
							required
							min="1"
							type="number"
							v-model="registration.startAge"
						/>
					</b-col>
					<b-col>
						<BaseInput
							label="End Age (optional)"
							id="end age"
							placeholder="End Age (optional)"
							:min="registration.startAge"
							type="number"
							v-model="registration.endAge"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<ButtonCheckboxGroup
							:showBorder="false"
							:options="checkboxOptions"
							@change="onCheckboxChanged"
						/>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import ButtonCheckboxGroup from '../../../components/ButtonCheckboxGroup.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	components: { Card, CardHeader, BaseInput, ButtonCheckboxGroup },
	props: {
		isNew: false,
		isNewClass: false,
		eventData: null,
		classData: null,
		showMaxUsers: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			showMaxUsersOptions: false,
			ageLimitSwitch: false,
			ageTitle: 'Age Limit (optional)',
			eventStartHoursHandle: null,
			eventStartMinutesHandle: null,
			eventCloseHoursHandle: null,
			eventCloseMinutesHandle: null,
			registration: {
				eventMaxUsers: null,
				registrationStartDuration: null,
				registrationCloseDuration: null,
				startAge: null,
				endAge: null,
				enforced: false,
			},
			checkboxOptions: [],
		};
	},
	watch: {
		showMaxUsers: {
			handler(val) {
				return (this.showMaxUsersOptions = val);
			},
		},
		registration: {
			handler(val) {
				if (!val.startAge) {
					this.registration.startAge = null;
				}
				if (!val.endAge) {
					this.registration.endAge = null;
				}
				this.adjustAgeTitle();
				return this.checkIfUpdate();
			},
			deep: true,
		},
		eventStartHoursHandle: {
			handler() {
				return this.handleDuration(true);
			},
		},
		eventStartMinutesHandle: {
			handler() {
				return this.handleDuration(true);
			},
		},
		eventCloseHoursHandle: {
			handler() {
				return this.handleDuration(false);
			},
		},
		eventCloseMinutesHandle: {
			handler() {
				return this.handleDuration(false);
			},
		},
	},
	methods: {
		ageSwitchChanged(value) {
			this.ageLimitSwitch = value;
			this.registration.startAge = null;
			this.registration.endAge = null;
		},
		setupEnforcedOption(value) {
			this.checkboxOptions = [
				{
					name: 'Enforce Age Limit',
					tooltip:
						'When turned on, users must be this age limit to register or pay.',
					selected: value ? value : false,
				},
			];
		},
		onCheckboxChanged(values) {
			this.registration.enforced = this.checkboxOptions[values[0]] != null;
		},
		adjustAgeTitle() {
			if (this.registration.startAge) {
				if (this.registration.endAge) {
					this.ageTitle = `Age Limit: (${this.registration.startAge}yrs - ${this.registration.endAge}yrs)`;
				} else {
					this.ageTitle = `Age Limit: (${this.registration.startAge}+)`;
				}
			} else {
				this.ageTitle = this.eventData ? 'Age Limit' : 'Age Limit (optional)';
			}
		},
		handleDuration(isStart) {
			const hours = isStart
				? this.eventStartHoursHandle
				: this.eventCloseHoursHandle;
			const minutes = isStart
				? this.eventStartMinutesHandle
				: this.eventCloseMinutesHandle;
			const totalSeconds = hours * 3600 + minutes * 60;
			if (totalSeconds > 0) {
				if (isStart) {
					this.registration.registrationStartDuration = totalSeconds;
				} else {
					this.registration.registrationCloseDuration = totalSeconds;
				}
			} else {
				if (isStart) {
					this.registration.registrationStartDuration = null;
				} else {
					this.registration.registrationCloseDuration = null;
				}
			}
		},
		checkIfUpdate() {
			if (this.eventData) {
				const isDifferent = () => {
					const ageCheck =
						this.registration.startAge != this.eventData.ageItem.startAge ||
						this.registration.endAge != this.eventData.ageItem.endAge;
					const enforced =
						this.registration.enforced != this.eventData.ageItem.enforced;
					return ageCheck || enforced;
				};
				this.$emit('onChange', {
					registration: this.registration,
					isDifferent: isDifferent(),
				});
			} else if (this.classData) {
				const isDifferent = () => {
					var ageCheck = true;
					var enforced = false;
					if (this.classData.ageItem) {
						ageCheck =
							this.registration.startAge !=
								(this.classData.ageItem.startAge != null
									? this.classData.ageItem.startAge
									: false) ||
							this.registration.endAge !=
								(this.classData.ageItem.endAge != null
									? this.classData.ageItem.endAge
									: false);
						enforced = this.registration.enforced != this.classData.ageItem.enforced;
					}
					const maxUsersCheck =
						this.registration.eventMaxUsers !=
						(this.classData.classMaxUsers ? this.classData.classMaxUsers : 0);
					return ageCheck || enforced || maxUsersCheck;
				};
				this.$emit('onChange', {
					registration: this.registration, 
					isDifferent: isDifferent(),
				});
			} else {
				this.onChange();
			}
		},
		onChange() {
			this.$emit('onChange', { registration: this.registration });
		},
	},
	mounted() {
		if (this.eventData) {
			if (this.eventData.registrationStartDuration) {
				var registrationStartDuration = this.eventData.registrationStartDuration;
				this.eventStartHoursHandle = Math.floor(registrationStartDuration / 3600);
				this.eventStartMinutesHandle =
					Math.floor(registrationStartDuration / 60) < 60
						? Math.floor(registrationStartDuration / 60)
						: 0;
				this.registration.registrationStartDuration = registrationStartDuration;
			}
			if (this.eventData.registrationCloseDuration) {
				var registrationCloseDuration = this.eventData.registrationCloseDuration;
				this.eventCloseHoursHandle = Math.floor(registrationCloseDuration / 3600);
				this.eventCloseMinutesHandle =
					Math.floor(registrationCloseDuration / 60) < 60
						? Math.floor(registrationCloseDuration / 60)
						: 0;
				this.registration.registrationCloseDuration = registrationCloseDuration;
			}

			this.registration.eventMaxUsers = this.eventData.price
				? null
				: this.eventData.eventMaxUsers
				? this.eventData.eventMaxUsers
				: null;
			this.showMaxUsersOptions = this.registration.eventMaxUsers;
			if (this.eventData.ageItem) {
				this.ageLimitSwitch = true;
				this.registration.startAge = this.eventData.ageItem.startAge;
				this.registration.endAge = this.eventData.ageItem.endAge;
				this.registration.enforced = this.evendData.ageItem.enforced;
			}
		} else if (this.classData) {
			if (this.classData.registrationStartDuration) {
				var registrationStartDuration = this.classData.registrationStartDuration;
				this.eventStartHoursHandle = Math.floor(registrationStartDuration / 3600);
				this.eventStartMinutesHandle =
					Math.floor(registrationStartDuration / 60) < 60
						? Math.floor(registrationStartDuration / 60)
						: 0;
				this.registration.registrationStartDuration = registrationStartDuration;
			}
			if (this.classData.registrationCloseDuration) {
				var registrationCloseDuration = this.classData.registrationCloseDuration;
				this.eventCloseHoursHandle = Math.floor(registrationCloseDuration / 3600);
				this.eventCloseMinutesHandle =
					Math.floor(registrationCloseDuration / 60) < 60
						? Math.floor(registrationCloseDuration / 60)
						: 0;
				this.registration.registrationCloseDuration = registrationCloseDuration;
			}
			this.registration.eventMaxUsers = this.classData.classMaxUsers
				? this.classData.classMaxUsers
				: null;
			this.showMaxUsersOptions = true; // this.registration.eventMaxUsers;
			if (this.classData.ageItem) {
				this.ageLimitSwitch = true;
				this.registration.startAge = this.classData.ageItem.startAge;
				this.registration.endAge = this.classData.ageItem.endAge;
				this.registration.enforced = this.classData.ageItem.enforced;
			}
		} else {
			this.showMaxUsersOptions = this.showMaxUsers;
		}
		this.setupEnforcedOption(this.registration.enforced);
		this.adjustAgeTitle();
	},
};
</script>
