<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm" no-wrap />
			<Card no-body>
				<CardHeader
					slot="header"
					title="Active Items"
					:endTitle="`${allActiveProducts.length} Total`"
				/>

				<b-row class="m-2">
					<b-col
						v-for="item in allActiveProducts"
						:key="item.productId"
						class="p-1"
						md="2"
						cols="6"
					>
						<ShopCard
							:isActive="item.isActive"
							:shopItem="item"
							@deleteProduct="onDeleteProduct"
							@updateProduct="onUpdateProduct"
						/>
					</b-col>
				</b-row>
			</Card>
			<Card no-body>
				<CardHeader
					slot="header"
					title="Inactive Items"
					:endTitle="`${allInactiveProducts.length} Total`"
				/>
				<b-row class="m-2">
					<b-col
						v-for="item in allInactiveProducts"
						:key="item.productId"
						class="p-1"
						md="2"
						cols="6"
					>
						<ShopCard
							:isActive="item.productIsActive"
							:shopItem="item"
							@deleteProduct="onDeleteProduct"
							@updateProduct="onUpdateProduct"
						/>
					</b-col>
				</b-row>
			</Card>
		</div>

		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="actionOnProduct"
			@noClicked="productData == null"
		/>

		<NewProduct
			:headerTitle="buttonItems[0].title"
			:showModal="showNewProductModal"
			@close="showNewProductModal = false"
			@newProduct="onNewProduct"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import Card from '../../../components/Cards/Card.vue';
import Badge from '../../../components/Badge.vue';
import { mapActions, mapGetters } from 'vuex';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import ShopCard from '../../Components/ShopViews/ShopCard.vue';
import NewProduct from '../../mainForms/NewProduct.vue';
import ProductItemManager from '../../../models/Payment-Billing/ProductItemManager';
import CardHeader from '../../../components/Cards/CardHeader.vue';

export default {
	name: 'shop',
	components: {
		DashboardHeaderVue,
		Card,
		Badge,
		QuestionModal,
		ShopCard,
		NewProduct,
		CardHeader,
	},
	data() {
		return {
			isLoading: true,
			showNewProductModal: false,
			showQuestionModal: false,
			modalQuestion: '',
			productData: null,
			allProductItems: [],
			allActiveProducts: [],
			allInactiveProducts: [],
			buttonItems: [
				{
					index: 0,
					title: 'New Product',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			this.showQuestionModal = false;
			if (buttonItem.index == 0) {
				this.showNewProductModal = true;
			}
		},
		getAllProductItems() {
			ProductItemManager.getAllProductItems().then((result) => {
				this.allProductItems = result;
				this.sortProductItems();
				this.isLoading = false;
			});
		},
		sortProductItems() {
			if (this.allProductItems) {
				this.allProductItems.forEach((productData) => {
					if (productData.isActive) {
						this.allActiveProducts.push(productData);
						/*
					this.allActiveProducts.sort((a, b) =>
								a.productTimestamp < b.productTimestamp ? 1 : -1
							);
					*/
					} else {
						this.allInactiveProducts.push(productData);
					}
				});
			}
		},
		onItemClicked(productData) {
			this.productData = productData;
			if (productData.productIsActive) {
				this.editProduct();
			} else {
				this.modalQuestion = 'Do you want to reactivate this product?';
				this.showQuestionModal = true;
			}
		},
		actionOnProduct() {
			if (this.productData.productIsActive) {
			} else {
				//reactivate product
				console.log(`Moses Reactivate Called On ${this.productData.productId}`);
			}
		},
		editProduct() {
			console.log(`Moses edit on ${this.productData.productId}`);
		},
		onUpdateProduct(product) {
			this.isLoading = true;

			if (product.productIsActive) {
				//remove from inactive & add to active
				this.allInactiveProducts = this.allInactiveProducts.filter(
					(item) => item.productId != product.productId
				);
				this.allActiveProducts.push(product);
				this.allActiveProducts.sort((a, b) =>
					a.productTimestamp < b.productTimestamp ? 1 : -1
				);
			} else {
				//remove from active & add to inactive
				this.allActiveProducts = this.allActiveProducts.filter(
					(item) => item.productId != product.productId
				);
				this.allInactiveProducts.push(product);
				this.allInactiveProducts.sort((a, b) =>
					a.productTimestamp < b.productTimestamp ? 1 : -1
				);
			}

			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${product.productTitle} has been updated.`,
			});
		},
		onDeleteProduct(product) {
			this.isLoading = true;
			this.allProductItems = this.allProductItems.filter(
				(item) => item.productId != product.productId
			);
			this.allActiveProducts = this.allActiveProducts.filter(
				(item) => item.productId != product.productId
			);
			this.allInactiveProducts = this.allInactiveProducts.filter(
				(item) => item.productId != product.productId
			);

			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${product.productTitle} Deleted.`,
			});
		},
		onNewProduct(product) {
			this.isLoading = true;
			this.allActiveProducts.push(product);
			this.allActiveProducts.sort((a, b) =>
				a.productTimestamp < b.productTimestamp ? 1 : -1
			);
			this.isLoading = false;
			this.showNotification({
				type: 'success',
				message: `${product.productTitle} Added.`,
			});
		},
	},
	mounted() {
		this.getAllProductItems();
	},
};
</script>
