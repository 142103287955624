<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			:hideFooter="true"
			size="lg"
			body-classes="pt-0 pb-0"
			footer-classes="pt-0"
			header-classes="pb-1"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new calendar item to the calendar.
					</h5>
					<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
						{{ errorMessage }}
					</h6>
				</div>

				<form>
					<base-input
						label="Calendar Item Title"
						id="title"
						placeholder="Calendar Item Title"
						required
						v-model="calendarItem.calendarTitle"
					>
					</base-input>
					<base-input label="Calendar Item Description">
						<textarea
							placeholder="Enter your description..."
							v-model="calendarItem.calendarDescription"
							class="form-control"
							id="description"
							rows="4"
							required
						/>
					</base-input>
					<b-row>
						<b-col sm="6" lg="6">
							<BaseInput
								label="Calendar Item Start Date"
								id="calendar date"
								placeholder="Calendar Item Start Date"
								type="datetime-local"
								required
								v-model="calendarItem.calendarStart"
							/>
						</b-col>
						<b-col sm="6" lg="6">
							<label class="form-control-label">Duration</label>
							<b-row>
								<b-col>
									<BaseInput
										id="duration"
										placeholder="Hours"
										type="mask"
										:mask="['# hours', '## hours']"
										required
										v-model="calendarHoursHandle"
									/>
								</b-col>
								<b-col>
									<BaseInput
										id="duration"
										placeholder="Minutes"
										type="mask"
										:mask="['# minutes', '## minutes']"
										required
										v-model="calendarMinutesHandle"
									/>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<h5>Select Calendar Color Tag</h5>
						</b-col>
						<b-col cols="auto">
							<verte
								v-if="colorMounted"
								:enableAlpha="false"
								model="hex"
								menuPosition="center"
								v-model="calendarItem.calendarHex"
							/>
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<h5>Calendar Item is Reoccuring</h5>
						</b-col>
						<b-col cols="auto">
							<base-switch v-model="recurringSwitch"></base-switch>
						</b-col>
					</b-row>
					<b-row v-if="recurringSwitch">
						<b-col sm="6" lg="6">
							<BaseInput
								label="Recurrence"
								id="recurrence"
								placeholder="Calendar Item Recurrence"
								:required="recurringSwitch"
								type="text"
							>
								<select
									class="form-control"
									id="recurrence"
									v-model="calendarItem.recurrence"
									:required="recurringSwitch"
								>
									<option
										v-for="recurrence in recurrenceOptions"
										:key="recurrence.value"
										:value="recurrence.value"
									>
										{{ recurrence.name }}
									</option>
								</select></BaseInput
							>
						</b-col>
						<b-col sm="6" lg="6">
							<BaseInput
								label="Recurrence End Date"
								id="event date"
								placeholder="Recurrence End Date"
								type="datetime-local"
								:required="recurringSwitch"
								v-model="calendarItem.calendarEnd"
							/>
						</b-col>
					</b-row>
					<b-row v-if="recurringSwitch">
						<b-col>
							<ButtonCheckboxGroup
								title="Reoccuring Days"
								:options="daysOfWeek"
								:required="recurringSwitch"
								@change="onCheckboxChanged"
							/>
						</b-col>
					</b-row>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							@click="submit"
							:loading="isLoading"
							:shake="shake"
							>Add Item</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import verte from 'verte';
import 'verte/dist/verte.css';
import { mapActions, mapGetters } from 'vuex';
import ButtonCheckboxGroup from '../../components/ButtonCheckboxGroup.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Modal from '../../components/Modals/Modal.vue';
import CalendarItemManager from '../../models/CalendarItem/CalendarItemManager';
import Recurrence from '../../models/DataModels/Reccurrence';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Calendar Item',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		verte,
		BaseInput,
		ButtonCheckboxGroup,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			recurringSwitch: false,
			colorMounted: false,
			recurrenceOptions: [],
			daysOfWeek: [],
			calendarHoursHandle: null,
			calendarMinutesHandle: null,
			calendarItem: {
				calendarTitle: null,
				calendarDescription: null,
				calendarStart: null,
				calendarDuration: null,
				calendarEnd: null,
				calendarHex: null,
				recurrence: null,
				recurrenceDays: null,
			},
		};
	},
	watch: {
		calendarHoursHandle: {
			handler(val) {
				return this.handleDuration();
			},
		},
		calendarMinutesHandle: {
			handler(val) {
				return this.handleDuration();
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		handleDuration() {
			const hours = this.calendarHoursHandle;
			const minutes = this.calendarMinutesHandle;
			const totalSeconds = hours * 3600 + minutes * 60;
			if (totalSeconds > 0) {
				this.calendarItem.calendarDuration = totalSeconds;
			} else {
				this.calendarItem.calendarDuration = null;
				this.calendarHoursHandle = null;
				this.calendarMinutesHandle = null;
			}
		},
		onCheckboxChanged(values) {
			const daysofWeek = [];
			values.forEach((index) => {
				daysofWeek.push(this.daysOfWeek[index].name.toLowerCase());
			});
			if (daysofWeek.length > 0) {
				this.calendarItem.recurrenceDays = daysofWeek;
			} else {
				this.calendarItem.recurrenceDays = null;
			}
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.shake = false;
			const calendarItemTitle = this.calendarItem.calendarTitle;
			const calendarItemDescription = this.calendarItem.calendarDescription;
			const calendarItemStart = this.calendarItem.calendarStart;
			const calendarItemEnd = this.calendarItem.calendarEnd;
			const calendarItemDuration = this.calendarItem.calendarDuration;
			const calendarItemColor = this.calendarItem.calendarHex;

			if (
				this.isEmpty(calendarItemTitle) ||
				this.isEmpty(calendarItemDescription)
			) {
				this.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else if (calendarItemStart == null || calendarItemDuration == null) {
				this.errorMessage = 'Please select a start and duration.';
				this.shake = true;
				return;
			} else if (
				calendarItemEnd != null &&
				new Date(calendarItemStart).getTime() > new Date(calendarItemEnd).getTime()
			) {
				this.errorMessage = 'End time must be after the start date.';
				this.shake = true;
				return;
			} else if (this.recurringSwitch && this.calendarItem.recurrence == null) {
				this.errorMessage = 'Please select a recurrence option.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.shake = false;

				//calendarEnd: new Date(calendarItemEnd)

				var data = {
					uploadTimestamp: this.$helpers.returnTimestamp(),
					calendarTitle: calendarItemTitle,
					calendarDescription: calendarItemDescription,
					calendarStart: new Date(calendarItemStart),
					calendarHex: calendarItemColor.toLowerCase(),
					calendarDuration: calendarItemDuration,
				};

				if (this.recurringSwitch) {
					var recurrence = {
						recurrenceTime: this.calendarItem.recurrence,
					};
					if (this.calendarItem.calendarEnd) {
						data['calendarEnd'] = new Date(this.calendarItem.calendarEnd);
					}
					if (this.calendarItem.recurrenceDays) {
						recurrence['recurrenceDays'] = this.calendarItem.recurrenceDays;
					}
					data['recurrence'] = recurrence;
				} else {
					data['calendarEnd'] = data.calendarStart;
				}

				this.loading = true;

				CalendarItemManager.addCalendarItem(data)
					.then(() => {
						this.isLoading = false;
						this.showNotification({
							type: 'success',
							message: 'Calendar Item Added.',
						});
						this.$emit('newCalendarItem', data);
						this.closeModal();
					})
					.catch((error) => {
						this.isLoading = false;
						this.showNotification({
							type: 'danger',
							message: error,
						});
					});
			}
		},
		isEmpty(str) {
			return this.$helpers.isBlank(str);
		},
		resetDaysOfWeek() {
			return [
				{
					name: 'Monday',
					selected: false,
				},
				{
					name: 'Tuesday',
					selected: false,
				},
				{
					name: 'Wednesday',
					selected: false,
				},
				{
					name: 'Thursday',
					selected: false,
				},
				{
					name: 'Friday',
					selected: false,
				},
				{
					name: 'Saturday',
					selected: false,
				},
				{
					name: 'Sunday',
					selected: false,
				},
			];
		},
	},
	mounted() {
		this.daysOfWeek = this.resetDaysOfWeek();
		this.recurrenceOptions = Recurrence.options;
		this.colorMounted = true
	},
};
</script>
