<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
	</div>
</template>
<script>
import { DashboardChildren } from '../../../routes/routesNames';
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';

export default {
	name: 'settings',
	components: {
		DashboardHeaderVue,
	},
	data() {
		return {
			buttonItems: [
				{
					index: 0,
					title: 'New Event',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
	methods: {
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.openNewEventView();
			}
		},
		openNewEventView() {
			this.$router.push({
				name: DashboardChildren.newEventView.name,
			});
		},
		openEvent(eventStart, eventData) {
			this.$router.push({
				name: DashboardChildren.eventView.name,
				params: {
					eventData: eventData,
				},
				query: {
					eventId: eventData.eventId,
					eventStart: eventStart,
				},
			});
		},
	},
};
</script>
