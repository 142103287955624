<template>
	<SlideYUpTransition :duration="animationDuration">
		<b-modal
			class="modal fade"
			ref="app-modal"
			:size="size"
			:hide-header="hideHeader"
			:modal-class="[{ 'modal-mini': type === 'mini' }, ...modalClasses]"
			@mousedown.self="closeModal"
			tabindex="-1"
			role="dialog"
			centered
			:content-class="[
				gradient ? `bg-gradient-${gradient}` : '',
				...modalContentClasses,
			]"
			@close="closeModal"
			@hide="closeModal"
			:hide-footer="hideFooter"
			:header-class="headerClasses"
			:footer-class="footerClasses"
			:body-class="bodyClasses"
			:aria-hidden="!show"
		>
			<b-overlay :show="isLoading" rounded="sm" no-wrap />
			<template v-slot:modal-header>
				<slot name="header"></slot>
				<slot name="close-button">
					<button
						type="button"
						class="close"
						v-if="showClose"
						@click="closeModal"
						data-dismiss="modal"
						aria-label="Close"
					>
						<span :aria-hidden="!show">×</span>
					</button>
				</slot>
			</template>
			<slot>
				<form v-if="inputData">
					<CurrencyInput v-if="inputData.type == 'currency'"
							:label="inputData.label"
							:placeholder="inputData.placeholder"
							v-model="inputValue"
						/>
					<BaseInput v-else
						:label="inputData.label"
						:placeholder="inputData.placeholder"
						:type="inputData.type"
						:max="inputData.max"
						:min="inputData.min"
						v-model="inputValue"
					/>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button type="primary" @click="submit">Done</base-button>
					</b-row>
				</form>
			</slot>
			<template v-slot:modal-footer>
				<slot name="footer"></slot>
			</template>
		</b-modal>
	</SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import CurrencyInput from '../Inputs/CurrencyInput.vue';
import MembersTable from '../../views/Components/MembersTable.vue';
import BaseInput from '../Inputs/BaseInput.vue';

export default {
	name: 'modal',
	components: {
		SlideYUpTransition,
		CurrencyInput,
		MembersTable,
		BaseInput,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		showClose: {
			type: Boolean,
			default: true,
		},
		inputData: {
			type: Object,
			default: {
				label: 'Label',
				placeholder: 'Empty Placeholder',
				type: 'text',
			},
		},
		type: {
			type: String,
			default: '',
			validator(value) {
				let acceptedValues = ['', 'notice', 'mini'];
				return acceptedValues.indexOf(value) !== -1;
			},
			description: 'Modal type (notice|mini|"") ',
		},
		modalClasses: {
			type: [Object, String],
			description: 'Modal dialog css classes',
			default: '',
		},
		size: {
			type: String,
			description: 'Modal size',
			default: 'lg',
			validator(value) {
				let acceptedValues = ['', 'sm', 'lg'];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		modalContentClasses: {
			type: [Object, String],
			description: 'Modal dialog content css classes',
			default: '',
		},
		gradient: {
			type: String,
			description: 'Modal gradient type (danger, primary etc)',
		},
		headerClasses: {
			type: [Object, String],
			description: 'Modal Header css classes',
		},
		bodyClasses: {
			type: [Object, String],
			description: 'Modal Body css classes',
		},
		footerClasses: {
			type: [Object, String],
			description: 'Modal Footer css classes',
		},
		hideHeader: {
			type: Boolean,
			default: true,
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
		animationDuration: {
			type: Number,
			default: 500,
			description: 'Modal transition duration',
		},
	},
	data() {
		return {
			isLoading: false,
			inputValue: null,
		};
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		submit() {
			this.$emit('submit', this.inputValue);
			this.closeModal();
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.$refs['app-modal'].show();
			} else {
				this.$refs['app-modal'].hide();
			}
		},
		inputData(val) {
			if (val.value) {
				return (this.inputValue = val.value);
			}
		},
	},
	mounted() {},
};
</script>
<style>
.modal-backdrop {
	background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
