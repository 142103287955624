<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<TerminalTable
				headerTitle="All Terminals"
				:isLoading="isLoading"
				:tableData="allReaders"
				@rowClicked="collectClicked"
				@collectClicked="collectClicked"
				@cancelClicked="cancelClicked"
			/>
		</div>
	</div>
</template>
<script>
import DashboardHeader from '../HeaderFooterComponents/DashboardHeader.vue';
import TerminalTable from '../../Components/PaymentViews/TerminalTable.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			allReaders: 'getStripeTerminalReaders',
		}),
	},
	components: { DashboardHeader, TerminalTable },
	data() {
		return {
			isLoading: true,
			//allReaders: [],
			buttonItems: [
				{
					index: 0,
					title: 'Refresh',
					type: 'primary',
					icon: 'fas fa-sync',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.getAllReaders();
			}
		},
		getAllReaders() {
			this.isLoading = false;

			/*
			this.isLoading = true;
			PaymentManager.getAllReaders()
				.then((response) => {
					const allReaders = response.data;
					this.allReaders = allReaders;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(`Error: ${error}`);
					this.isLoading = false;
				});
				*/
		},
		refreshSearch() {},
		collectClicked(readerData) {
			if (readerData.status == 'online') {
			} else {
				this.showNotification({
					type: 'info',
					message: 'This reader is currently offline.',
				});
			}
		},
		cancelClicked(readerData) {},
	},
	mounted() {
		if (store.state.appInformation.isTerminalActive) {
			console.log('Moses Layout 3');
			this.getAllReaders();
		}
	},
};
</script>
