import {
	collection,
	collectionGroup,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	increment,
	query,
	setDoc,
	Timestamp,
	where,
	writeBatch,
} from 'firebase/firestore';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import CalendarObject from '../DataModels/CalendarObject';
import Recurrence from '../DataModels/Reccurrence';
import FirebaseManager from '../Firebase/FirebaseManager';
import AppUserManager from '../AppUser/AppUserManager';
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytes,
} from 'firebase/storage';
import store from '../../store/store';
import mimiDB from 'mime-db';

export default class ClassItemManager {
	static isLoading = false;
	static allClasses = [];

	static getAllClasses() {
		return new Promise((resolve, reject) => {
			this.allClasses = [];
			this.isLoading = true;
			const classesRef = FirebaseManager.allClassesRef(getOrganizationFirestore());
			getDocs(classesRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							var documentData = document.data();
							if (!documentData.classId){
								if (counter == total - 1) {
									this.isLoading = false;
									return resolve();
								}
								counter += 1;
							} else {
								this.processClass(documentData).then(() => {
									if (counter == total - 1) {
										this.isLoading = false;
										return resolve();
									}
									counter += 1;
								});
							}
						});
					} else {
						this.isLoading = false;
						return resolve();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					return reject(error.message);
				});
		});
	}

	static getClassById(classId) {
		return new Promise((resolve, reject) => {
			const eventRef = doc(getOrganizationFirestore(), `classes/${classId}`);
			getDoc(eventRef)
				.then((snapshot) => {
					if (snapshot.exists) {
						const documentData = snapshot.data();
						return resolve(documentData);
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static getClassUsers(classData) {
		return new Promise((resolve, reject) => {
			var classRef = collection(
				getOrganizationFirestore(),
				`classes/${classData.classId}/classUsers`
			);
			getDocs(classRef)
				.then((snapshot) => {
					if (snapshot.empty) {
						return resolve(null);
					} else {
						var items = [];
						var total = snapshot.size;
						var counter = 0;
						snapshot.forEach((document) => {
							const documentData = document.data();
							if (documentData.uid) {
								AppUserManager.fetchUserByUid(documentData.uid).then((user) => {
									if (user) {
										items.push({
											userData: user,
											classData: documentData,
										});
									}
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								});
							}
						});
					}
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static getClassPendingUsers(classData) {
		return new Promise((resolve, reject) => {
			var classRef = collection(
				getOrganizationFirestore(),
				`classes/${classData.classId}/pendingUsers`
			);
			getDocs(classRef)
				.then((snapshot) => {
					if (snapshot.empty) {
						return resolve(null);
					} else {
						var items = [];
						var total = snapshot.size;
						var counter = 0;
						snapshot.forEach((document) => {
							const documentData = document.data();
							if (documentData.uid) {
								AppUserManager.fetchUserByUid(documentData.uid).then((user) => {
									if (user) {
										items.push({
											userData: user,
											classData: documentData,
										});
									}
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								});
							}
						});
					}
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static adjustUserAttendance(userData, classData, isClassMember, isPending) {
		return new Promise((resolve, reject) => {
			if (isClassMember) {
				if (classData.price) {
					this.requestAddUserToClass(userData, classData, true)
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error.message);
						});
				} else {
					//add user to group
					this.addUserToClass(userData, classData)
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error.message);
						});
				}
			} else {
				//remove user from group
				this.removeUserFromClass(userData, classData, isPending)
					.then(() => {
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			}
		});
	}

	static sendNotificationToDB(classId, classTitle, message, scheduledDate) {
		return new Promise((resolve, reject) => {
			const currentUser = store.state.user;
			const classRef = collection(
				getOrganizationFirestore(),
				`${
					FirebaseManager.allClassesRef(getOrganizationFirestore()).path
				}/${classId}/notifications`
			);
			const newItemRef = doc(classRef);
			var data = {
				notificationTitle: classTitle,
				message: message,
				messageId: newItemRef.id,
				uid: currentUser.appUid,
				sent: false,
			};
			if (scheduledDate) {
				data['uploadTimestamp'] = helpers.changeTimeZone(scheduledDate);
			} else {
				data['uploadTimestamp'] = helpers.returnTimestamp();
			}
			setDoc(newItemRef, data)
				.then(() => {
					return resolve(data);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addUserToClass(userData, classData) {
		return new Promise((resolve, reject) => {
			const batch = writeBatch(getOrganizationFirestore());
			const ref = doc(getOrganizationFirestore(), 'classes', classData.classId);
			const classUsersRef = doc(
				getOrganizationFirestore(),
				`${ref.path}/classUsers/${userData.uid}`
			);
			const pendingUsersRef = doc(
				getOrganizationFirestore(),
				`${ref.path}/pendingUsers/${userData.uid}`
			);

			const userInfo = {
				isSubscribed: true,
				uid: userData.uid,
				timestamp: FirebaseManager.timestamp(),
			};
			batch.set(classUsersRef, userInfo);
			batch.delete(pendingUsersRef);

			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static manuallyAddUsersToClass(users, classData) {
		return new Promise((resolve, reject) => {
			const batch = writeBatch(getOrganizationFirestore());
			const ref = doc(getOrganizationFirestore(), 'classes', classData.classId);
			const total = users.length;
			var counter = 0;
			var batchItems = [];
			users.forEach((userData) => {
				const classUsersRef = doc(
					getOrganizationFirestore(),
					`${ref.path}/classUsers/${userData.uid}`
				);

				const userInfo = {
					isSubscribed: true,
					uid: userData.uid,
					timestamp: FirebaseManager.timestamp(),
				};
				batchItems.push(batch.set(classUsersRef, userInfo));
				if (counter == total - 1) {
					batch
						.commit()
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error);
						});
				}
				counter += 1;
			});
		});
	}

	static removeUserFromClass(userData, classData, isPending) {
		return new Promise((resolve, reject) => {
			const batch = writeBatch(getOrganizationFirestore());
			const ref = doc(getOrganizationFirestore(), 'classes', classData.classId);
			var classUsersRef = doc(
				getOrganizationFirestore(),
				`${ref.path}/classUsers/${userData.uid}`
			);

			if (isPending) {
				classUsersRef = doc(
					getOrganizationFirestore(),
					`${ref.path}/pendingUsers/${userData.uid}`
				);
			}
			batch.delete(classUsersRef);

			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static requestAddUserToClass(userData, classData, isPendingPayment) {
		return new Promise((resolve, reject) => {
			const batch = writeBatch(getOrganizationFirestore());
			const ref = doc(getOrganizationFirestore(), 'classes', classData.classId);
			const pendingUserRef = doc(
				getOrganizationFirestore(),
				`${ref.path}/pendingUsers/${userData.uid}`
			);

			var userInfo = {
				uid: userData.uid,
				isPending: true,
				timestamp: FirebaseManager.timestamp(),
			};

			if (isPendingPayment) {
				userInfo['isPendingPayment'] = isPendingPayment;
			}
			batch.set(pendingUserRef, userInfo);

			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static processClass(classData) {
		return new Promise((resolve, reject) => {
			if (classData) {
				if (!this.allClasses.find(($0) => $0.classId == classData.classId)) {
					this.allClasses.push(classData);
				}
				if (classData.recurrence) {
					const dates = Recurrence.getRecurrenceDates(
						classData.classStart,
						classData.classEnd,
						classData.recurrence
					);
					const total = dates.length;
					var counter = 0;
					if (total <= 0) {
						return resolve(true);
					}
					dates.forEach((newStartDate) => {
						var newClassItem = structuredClone(classData); 
						newClassItem.classStart = Timestamp.fromDate(newStartDate);
						if (
							newClassItem.isOnCalendar != null ? newClassItem.isOnCalendar : true
						) {
							CalendarObject.addObjectForSchedule(null, newClassItem);
						}
						if (counter == total - 1) {
							return resolve(true);
						}
						counter += 1;
					});
				} else {
					if (classData.isOnCalendar != null ? classData.isOnCalendar : true) {
						CalendarObject.addObjectForSchedule(null, classData);
					}
					return resolve(true);
				}
			} else {
				return resolve(true);
			}
		});
	}

	static deleteClass(classId) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const classRef = FirebaseManager.allClassesRef(firestore);
			const classDocRef = doc(firestore, `${classRef.path}/${classId}`);
			const classStorageRef = ref(storage, `${classRef.path}/${classId}`);
			deleteObject(classStorageRef)
				.finally(() => {
					deleteDoc(classDocRef)
						.then(() => {
							return resolve();
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				/*
				.catch((error) => {
					return reject(error.message);
				});
				*/
		});
	}

	static updateClass(classData, updateData) {
		return new Promise((resolve, reject) => {
			if (classData && updateData) {
				const classsRef = doc(
					getOrganizationFirestore(),
					'classes',
					classData.classId
				);

				setDoc(classsRef, updateData, { merge: true })
					.then(() => {
						this.getAllClasses();
						return resolve();
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				return reject();
			}
		});
	}

	static changeClassImage(classFile, classData) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const classRef = doc(firestore, `classes/${classData.classId}`);
			const newClassStoreref = ref(storage, `classes/${classData.classId}`);
			const metadata = {
				contentType: 'image/jpg',
			};
			uploadBytes(newClassStoreref, classFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							setDoc(
								classRef,
								{
									classImages: [downloadURL],
								},
								{ merge: true }
							)
								.then(() => {
									return resolve();
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addClass(classFile, classData) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const classRef = FirebaseManager.allClassesRef(firestore);
			const newClassRef = doc(classRef);
			const newClassStorageRef = ref(
				storage,
				`${classRef.path}/${newClassRef.id}`
			);

			const metadata = {
				contentType: 'image/jpg',
			};

			uploadBytes(newClassStorageRef, classFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							var data = classData;
							if (data.isFeatured) {
								const featuredRef = FirebaseManager.allFeaturedRef(firestore);
								const newFeaturedRef = doc(featuredRef);
								var featuredData = {
									featuredId: newFeaturedRef.id,
									featuredItemId: newClassRef.id,
									featuredItemName: 'classItem',
									imageItem: {
										imageUrl: downloadURL,
									},
									index: 0,
								};
								setDoc(newFeaturedRef, featuredData).then(() => {
									this.addClassToDB(classData, downloadURL, newClassRef)
										.then(() => {
											return resolve(data);
										})
										.catch((error) => {
											return reject(error.message);
										});
								});
							} else {
								this.addClassToDB(classData, downloadURL, newClassRef)
									.then(() => {
										return resolve(data);
									})
									.catch((error) => {
										return reject(error.message);
									});
							}
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addClassToDB(data, downloadURL, newClassRef) {
		return new Promise((resolve, reject) => {
			var classImages = [downloadURL];
			data['classId'] = newClassRef.id;
			data['classImages'] = classImages;

			const urlExtended = `class?&classId=${newClassRef.id}`;

			FirebaseManager.createDynamicLink(
				urlExtended,
				data.classTitle,
				downloadURL
			).then((shareLink) => {
				if (shareLink) {
					data['shareLink'] = shareLink;
				}

				setDoc(newClassRef, data)
					.then(() => {
						return resolve(data);
					})
					.catch((error) => {
						return reject(error);
					});
			});
		});
	}

	static getAllClassResources(classId) {
		return new Promise((resolve, reject) => {
			this.allResources = [];
			this.isLoading = true;
			const firestore = getOrganizationFirestore();
			const classRef = FirebaseManager.allClassesRef(firestore);
			const resourceRef = collection(firestore, `${classRef.path}/${classId}/files`)
			getDocs(resourceRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							if (!this.allResources.find(($0) => $0.fileId == documentData.fileId)) {
								this.allResources.push(documentData);
							}
							if (counter == total - 1) {
								this.isLoading = false;
								return resolve(this.allResources);
							}
							counter += 1;
						});
					} else {
						this.isLoading = false;
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.isLoading = false;
					return resolve(null);
				});
		});
	}

	static addClassResourceToDB(classId, fileData, file, fileName, fileType,loadingCallback) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
            const storage = getOrganizationStorage()
			const classRef = FirebaseManager.allClassesRef(firestore);
			const resourceRef = collection(firestore, `${classRef.path}/${classId}/files`)
			const newFileRef = doc(resourceRef);
            const cleanFileName = fileName.replace(/\s+/g, '');

            const storageRef = ref(
				storage,
				`${resourceRef.path}/${newFileRef.id}/file:${cleanFileName}.${mimiDB[fileType].extensions[0]}`
			);

            FirebaseManager.uploadFile(storageRef, file, fileType, (progress) => {
                loadingCallback(progress);
            }).then((downloadURL) => {
                const urlExtended = `classResources?&fileId=${newFileRef.id}`
                FirebaseManager.createDynamicLink(urlExtended, cleanFileName).then(
                    (shareLink) => {
                        var data = fileData
						fileData.fileTitle = data.resourceTitle
                        fileData.fileId = newFileRef.id
                        fileData.fileLink = downloadURL;
                        fileData.timestamp = FirebaseManager.timestamp();
                        if (shareLink) {
                            data['shareLink'] = shareLink;
                        }
                        setDoc(newFileRef, data)
                            .then(() => {
                                return resolve(data);
                            })
                            .catch((error) => {
                                return reject(error);
                            });
                    }
                );
            })
		});
	}

	static getUserClasses(uid) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const ref = collectionGroup(firestore, 'classUsers');
			const queryRef = query(ref, where('uid', '==', uid));
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							const classRef = document.ref.parent.parent;
							getDoc(classRef)
								.then((snap) => {
									const documentData = snap.data();
									items.push(documentData);
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								})
								.catch((error) => {
									console.log(error.message);
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								});
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					console.log(error.message);
					return resolve(null);
				});
		});
	}

	static deleteClassResourceItem(classId, resourceId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
            const storage = getOrganizationStorage()
			const classRef = FirebaseManager.allClassesRef(firestore);
			const resourceRef = collection(firestore, `${classRef.path}/${classId}/files`)
			const storageRef = ref(storage, `${resourceRef.path}/${resourceId}`);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
                
				return this.completeClassFileDelete(classId, resourceId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeClassFileDelete(classId, resourceId) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const classRef = FirebaseManager.allClassesRef(firestore);
			const resourceRef = collection(firestore, `${classRef.path}/${classId}/files`)
			const resourceDocRef = doc(firestore, `${resourceRef.path}/${resourceId}`);
			deleteDoc(resourceDocRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
