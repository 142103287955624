<template>
	<div>
		<Card no-body>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">{{ isActive ? 'Active' : 'Inactive' }} Subscriptions</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allSubscriptions.length }} Total</h3>
				</b-col>
			</b-row>
			<b-row v-if="allSubscriptions.length > 0" align-v="center" slot="footer">
				<h6 class="text-muted m-0 px-2">{{ getFooterInformation() }}</h6>
			</b-row>
			<b-list-group>
				<b-list-group-item
					v-for="subscription in allSubscriptions"
					:key="subscription.subscriptionId"
					:style="!isActive ? 'opacity: 0.65' : 'opacity:1'"
					@click="askSubscriptionQuestion(subscription)"
				>
					<div class="d-flex justify-content-between align-items-center">
						<div class="d-flex align-items-center">
							<div>
								<h5
									class="mb-0 mr-2"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ subscription.subscriptionDescription }}
								</h5>
								<h6
									class="text-muted m-0"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ getMiddleLabel(subscription) }}
								</h6>
								<h6
									class="text-muted m-0"
									style="
										overflow: hidden;
										text-overflow: ellipsis;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									"
								>
									{{ getBottomLabel(subscription) }}
								</h6>
							</div>
						</div>
						<div class="d-flex justify-content-end align-items-center m-0 p-0">
							<h4 class="m-0">{{ getSubscriptionPrice(subscription) }}</h4>
							<i class="fas fa-chevron-right ml-2 text-muted"></i>
						</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			:buttonData="modalButtonData"
			@close="showQuestionModal = false"
			@buttonClicked="questionButtonClicked"
		/>
		<QuestionModal
			:show="showQuestionModal2"
			:question="modalQuestion2"
			@close="showQuestionModal2 = false"
			@yesClicked="cancelSubscription"
			@noClicked="subscription = null"
		/>
		<BaseInputModal
			:show="showBaseInputModal"
			:inputData="showBaseInputModalData"
			@close="showBaseInputModal = false"
			@submit="inputSubmit"
		/>
	</div>
</template>
<script>
import { sub } from 'date-fns';
import Card from '../../../../components/Cards/Card.vue';
import BaseInputModal from '../../../../components/Modals/BaseInputModal.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
export default {
	props: {
		allSubscriptions: {
			type: Array,
			default: [],
		},
		isActive: true,
	},
	components: { Card, QuestionModal, BaseInputModal },
	data() {
		return {
			showQuestionModal: false,
			showQuestionModal2: false,
			showBaseInputModal: false,
			showBaseInputModalData: null,
			isNextCharge: false,
			subscription: null,
			modalQuestion: null,
			modalQuestion2: null,
			modalButtonData: [],
			selectedId: null,
			adjustNextChargeId: 0,
			adjustSubscriptionTokensId: 1,
			adjustSubscriptionNameId: 2,
			adjustSubscriptionPriceId: 3,
			cancelSubscriptionId: 5,
		};
	},
	methods: {
		inputSubmit(value) {
			if (this.selectedId == this.adjustNextChargeId) {
				var nextChargeDate = new Date(value);
				nextChargeDate = this.$helpers.addDays(nextChargeDate, 1);
				nextChargeDate.setHours(6, 0, 0, 0);
				this.$emit('updateNextCharge', {
					subscription: this.subscription,
					nextChargeDate: this.$helpers.changeTimeZone(nextChargeDate),
				});
			} else if (this.selectedId == this.adjustSubscriptionTokensId) {
				this.$emit('updateTokens', {
					subscription: this.subscription,
					tokens: value,
				});
			} else if (this.selectedId == this.adjustSubscriptionPriceId){
				this.$emit('updatePrice', {
					subscription: this.subscription,
					amount: value,
				});
			} else if (this.selectedId == this.adjustSubscriptionNameId) {
				this.$emit('onSubscriptionUpdate', {
					subscription: this.subscription,
					updateData: {
						subscriptionDescription: value,
					},
				});
			}
		},
		askSubscriptionQuestion(subscription) {
			if (this.isActive) {
				this.modalQuestion = 'Do you want to edit this subscription for this user?';
				this.modalButtonData = [
					{
						id: this.adjustNextChargeId,
						title: 'Adjust Next Charge Date',
					},
					{
						id: this.adjustSubscriptionPriceId,
						title: 'Adjust Charge Price',
					},
					{
						id: this.adjustSubscriptionNameId,
						title: 'Change Subscription Name',
					},
				];
				if (
					subscription.uniqueName == 'productItem' &&
					subscription.subscription.quantity
				) {
					this.modalButtonData.push({
						id: this.adjustSubscriptionTokensId,
						title: 'Adjust Remaining Cycle Amount',
					});
				}
				this.modalButtonData.push({
					id: this.cancelSubscriptionId,
					title: 'Cancel Subscription',
					type: 'danger',
				});
				this.subscription = subscription;
				this.showQuestionModal = true;
			}
		},
		questionButtonClicked(buttonItem) {
			this.selectedId = buttonItem.id;
			switch (buttonItem.id) {
				case this.adjustNextChargeId:
					this.adjustNextChargeDate();
					break;
				case this.adjustSubscriptionTokensId:
					this.adjustSubscriptionTokens();
					break;
				case this.adjustSubscriptionNameId:
					this.adjustSubscriptionName();
					break;
				case this.adjustSubscriptionPriceId:
					this.adjustSubscriptionPrice()
					break
				case this.cancelSubscriptionId:
					this.askToCancelSubscription();
					break;
				default:
					return;
			}
		},
		adjustSubscriptionName() {
			this.showBaseInputModalData = {
				label: 'Subscription Name',
				placeholder: 'Enter Subscription Name',
				value: this.subscription.subscriptionDescription,
				type: 'text',
			};
			this.showBaseInputModal = true;
		},
		adjustNextChargeDate() {
			this.isNextCharge = true;
			this.showBaseInputModalData = {
				label: 'Next Charge Date',
				placeholder: 'Next Charge Date',
				value: this.$helpers.formatDate(new Date(), 'yyyy-MM-dd'),
				type: 'date',
				min: this.$helpers.formatDate(new Date(), 'yyyy-MM-dd'),
			};
			this.showBaseInputModal = true;
		},
		adjustSubscriptionPrice(){
			this.showBaseInputModalData = {
				label: 'Subscription Amount',
				placeholder: '$0.00',
				type: 'currency',
				value: this.subscription.subscription.amount / 100 
			}
			this.showBaseInputModal = true;
		},
		adjustSubscriptionTokens() {
			this.isNextCharge = false;
			this.showBaseInputModalData = {
				label: 'Remaining Token Amount',
				placeholder: 'Enter Token Amount',
				type: 'number',
				max: this.subscription.subscription.quantity,
				min: 0,
			};
			if (
				this.subscription.subscription.quantity &&
				this.subscription.quantityUsed
			) {
				this.showBaseInputModalData['value'] =
					this.subscription.subscription.quantity - this.subscription.quantityUsed;
			}
			this.showBaseInputModal = true;
		},
		askToCancelSubscription() {
			this.modalQuestion2 = 'Are you sure you want to cancel this subscription?';
			this.showQuestionModal2 = true;
		},
		cancelSubscription() {
			this.$emit('cancelSubscription', this.subscription);
		},
		getFooterInformation() {
			if (this.isActive) {
				return 'All active subscriptions will be billed approximately at 12:00am PST. You can unsubscribe anytime by clicking on the active subscription.';
			} else {
				return 'Inactive subscriptions can only be re-activated by the user on the mobile app.';
			}
		},
		getSubscriptionPrice(subscription){
			if (subscription.subscription){
				return this.$helpers.amountToString(subscription.subscription.amount)
			}
		},
		getMiddleLabel(subscription) {
			if (subscription.nextCharge) {
				return `Next Charge: ${this.$helpers.formatDate(
					subscription.nextCharge.toDate()
				)}`;
			} else if (subscription.cancelDate) {
				return `Cancellation Date: ${this.$helpers.formatDate(
					subscription.cancelDate.toDate()
				)}`;
			}
		},
		getBottomLabel(subscription) {
			if (subscription.uniqueName == 'classItem') {
				if (subscription.subscription.subscriptionEnd) {
					return `Class Subscription Ends: ${this.$helpers.formatDate(
						subscription.subscription.subscriptionEnd.toDate()
					)}`;
				} else {
					return `Class Subscription Started: ${this.$helpers.formatDate(
						subscription.subscription.subscriptionStart
					)}`;
				}
			} else if (subscription.uniqueName == 'productItem') {
				if (
					subscription.subscription.quantity != null &&
					subscription.quantityUsed != null
				) {
					return `Remaining In Cycle: ${
						subscription.subscription.quantity - subscription.quantityUsed
					}`;
				} else if (subscription.subscription.quantity != null) {
					return `Remaining In Cycle: ${subscription.subscription.quantity}`;
				} else if (subscription.uniqueName == 'productItem') {
					return `Remaining In Cycle: Unlimited`;
				}
			}
			return '';
		},
	},
	mounted() {},
};
</script>
