<template>
	<div>
		<DashboardHeaderVue :title="$route.name" :buttonItems="buttonItems" @childClick="childClick" />
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<Card no-body>
						<b-tabs active-nav-item-class="bg-white font-weight-bold description"
							nav-class="bg-white description" card no-body>
							<b-tab title="Invoices" active>
								<InvoiceTable :isLoading="isInvoiceLoading" :tableData="allInvoices"
									@viewClicked="viewInvoiceClicked" @copyLinkClicked="copyInvoiceLinkClicked" @deleteClicked="deleteInvoiceClicked" />
							</b-tab>

							<b-tab title="Coupons">
								<CouponTable :isLoading="isCouponLoading" :tableData="allCoupons"
									@adjustActiveClicked="adjustCouponActiveClicked" @deleteClicked="deleteCouponClicked" />
							</b-tab>

						</b-tabs>

					</Card>

				</b-col>
			</b-row>
		</div>
		<NewCouponItem :headerTitle="buttonItems[0].title" :showModal="showNewCouponModal"
			@close="showNewCouponModal = false" @newCoupon="onNewCoupon" />
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import CouponItemManager from '../../../models/CouponItem/CouponItemManager';
import InvoiceManager from '../../../models/Payment-Billing/InvoiceManager';
import CouponTable from '../../Components/BillingViews/CouponTable.vue';
import InvoiceTable from '../../Components/InvoiceViews/InvoiceTable.vue';
import NewCouponItem from '../../mainForms/NewCouponItem.vue';
import { mapActions } from 'vuex';

export default {
	name: 'billing',
	components: {
		DashboardHeaderVue,
		Card,
		CouponTable,
		InvoiceTable,
		NewCouponItem,
	},
	data() {
		return {
			isCouponLoading: false,
			isInvoiceLoading: false,
			showNewCouponModal: false,
			newInvoiceId: 0,
			newCouponId: 1,
			allCoupons: [],
			allInvoices: [],
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		setupButtons() {
			this.buttonItems[0].children = [
				{
					index: this.newInvoiceId,
					title: 'Create New Invoice',
				},
				{
					index: this.newCouponId,
					title: 'Create New Coupon',
				},
			];
		},
		childClick(childClicked) {
			switch (childClicked.index) {
				case this.newInvoiceId:
					this.openInvoice()
					break;
				case this.newCouponId:
					this.showNewCouponModal = true;
					break;
				default:
					break;
			}
		},
		getCouponItems() {
			this.isCouponLoading = true;
			CouponItemManager.getAllCoupons().then((items) => {
				this.allCoupons = items;
				this.isCouponLoading = false;
			});
		},
		getInvoices() {
			this.isInvoiceLoading = true;
			InvoiceManager.getAllInvoices()
				.then((items) => {
					this.allInvoices = items;
					this.isInvoiceLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		viewInvoiceClicked(invoiceData) {
			if (invoiceData.invoice_url) {
				var open = window.open(invoiceData.invoice_url, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
		copyInvoiceLinkClicked(invoiceData){
			if (invoiceData.invoice_url) {
				navigator.clipboard.writeText(invoiceData.invoice_url);
				this.showNotification({
					type: 'success',
					message: 'Link Copied',
				});
			} else {
				this.showNotification({
					type: 'danger',
					message: 'Unable To Copy Link',
				});
			}
		},
		deleteInvoiceClicked(item) {
			if (item.status != 'paid') {
				this.isInvoiceLoading = true
				InvoiceManager.deleteInvoice(item.uid, item.invoiceId).then(() => {
					this.allInvoices = this.allInvoices.filter((e) => e.invoiceId != item.invoiceId)
					this.isInvoiceLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Invoice Deleted.',
					});
				}).catch((errorMessage) => {
					this.isInvoiceLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
			}
		},

		adjustCouponActiveClicked(item) {
			this.isCouponLoading = true;
			CouponItemManager.deactivateCoupon(item)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: item.isValid
							? 'Coupon has been deactivated.'
							: 'Coupon has been made active.',
					});
					this.getCouponItems();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
					this.isCouponLoading = false;
				});
		},
		deleteCouponClicked(item) {
			if (item.redeemed) {
				this.adjustActiveClicked(item);
			} else {
				this.isCouponLoading = true;
				CouponItemManager.deleteCoupon(item.couponId)
					.then(() => {
						this.showNotification({
							type: 'success',
							message: 'Coupon has been deleted.',
						});
						this.getCouponItems();
					})
					.catch((error) => {
						this.showNotification({
							type: 'danger',
							message: error,
						});
						this.isCouponLoading = false;
					});
			}
		},
		onNewCoupon() {
			this.getCouponItems();

		},
		openInvoice() {
			this.$router.push({
				name: DashboardChildren.newInvoiceView.name,
			});
		},
	},
	mounted() {
		this.setupButtons()
		this.getCouponItems();
		this.getInvoices()
	},
};
</script>
