<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm">
			<Card>
				<CardHeader
					slot="header"
					title="Notes"
					:end-button="{ title: 'Update Notes', disabled: !updateNotes }"
					@click="updateAdminNotes"
				/>
				<BaseInput
					type="textarea"
					rows="10"
					v-model="notes"
					placeholder="Add any special notes related to this profile. For example: This user has requested handicap accessiblity."
				/>
				<CardFooter
					slot="footer"
					title="This information is private to you and your admins."
				/>
			</Card>
		</b-overlay>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../../components/Cards/Card.vue';
import CardFooter from '../../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../../components/Inputs/BaseInput.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';

export default {
	props: {
		userData: null,
	},
	components: { Card, CardHeader, CardFooter, BaseInput },
	data() {
		return {
			isLoading: false,
			updateNotes: false,
			notes: null,
		};
	},
	watch: {
		notes: {
			handler(val) {
				return (this.updateNotes = this.checkIfUpdateNeed());
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		checkIfUpdateNeed() {
			if (this.userData.adminNotes) {
				return this.notes != this.userData.adminNotes.notes;
			}
			return this.notes;
		},
		updateAdminNotes() {
			this.isLoading = true;
			AppUserManager.updateAppLeader(this.userData.uid, {
				adminNotes: { notes: this.notes },
			})
				.then(() => {
					//this.userData.adminNotes.notes = this.notes;
					this.updateNotes = false;
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Notes Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: 'Unknown Error.',
					});
				});
		},
	},
	mounted() {
		this.notes = this.userData.adminNotes ? this.userData.adminNotes.notes : null;
	},
};
</script>
