<template>
	<div>
		<DashboardHeaderVue
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<div class="container-fluid mt--6">
			<b-row>
				<b-col>
					<FeaturedCard
						:allFeaturedItems="allFeaturedItems"
						@deleteFeaturedItem="onDeleteFeatured"
					/>
				</b-col>
				<b-col class="d-none d-md-inline" md="3">
					<b-row>
						<b-col>
							<Card no-body class="d-flex align-items-center bg-gradient-success">
								<b-img
									class="card-img-top"
									src="https://static.wixstatic.com/media/88f5c1_5a0a69f5c7bc4416845d29c5b26ba012~mv2.png/v1/fill/w_580,h_391,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/img9web.png"
									style="background-color: transparent"
								/>
								<div class="px-3 py-3">
									<h4 class="text-center text-white">Need A Website Uplift? 💻</h4>
									<p class="small text-center text-white">
										Now you can add your app information to your current website or a
										custom designed website!
									</p>
									<hr class="my-3 bg-white" />
									<BaseButton block type="info" @click="openWebsite"
										>Learn More</BaseButton
									>
								</div>
							</Card>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<Card no-body class="d-flex align-items-center bg-gradient-info">
								<b-img
									class="card-img-top"
									src="https://static.wixstatic.com/media/88f5c1_450244acd9cc481aae360e3ae8fcd4d3~mv2.webp"
									style="background-color: #f9f9f9"
								/>
								<div class="px-3 py-3">
									<h4 class="text-center text-white">😃 Introducing Dominion Pay!</h4>
									<p class="small text-center text-white">
										Now you can accept payment in-person at studio and link it to your
										entire system! No more unaccounted for or unorganized payments.
									</p>
									<hr class="my-3 bg-white" />
									<BaseButton block type="secondary" @click="openWebsite"
										>Learn More (beta)</BaseButton
									>
								</div>
							</Card>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</div>
		<NewFeatured
			:headerTitle="buttonItems[0].title"
			:showModal="showFeaturedModal"
			@close="showFeaturedModal = false"
			@newFeatured="onNewFeatured"
		/>
	</div>
</template>
<script>
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import StatsCard from '@/components/Cards/StatsCard';
import { mapActions, mapGetters } from 'vuex';
import FeaturedCard from '../../Components/FeaturedViews/FeaturedCard.vue';
import NewFeatured from '../../mainForms/NewFeatured.vue';
import FeaturedItemManager from '../../../models/FeaturedItem/FeaturedItemManager';
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';
import { DominionInformation } from '../../../resources/DominionInformation';

export default {
	name: 'dashboard',
	components: {
		DashboardHeaderVue,
		StatsCard,
		FeaturedCard,
		NewFeatured,
		Card,
		BaseButton,
	},
	data() {
		return {
			showFeaturedModal: false,
			allFeaturedItems: [],
			buttonItems: [
				{
					index: 0,
					title: 'New Featured',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.showFeaturedModal = !this.showFeaturedModal;
			}
		},
		getData() {
			FeaturedItemManager.getAllFeatured().then((result) => {
				this.allFeaturedItems = result;
			});
		},
		onNewFeatured(item) {
			this.allFeaturedItems.push(item);
			this.allFeaturedItems.sort((a, b) => {
				return a.index - b.index;
			});
			this.showNotification({
				type: 'success',
				message: `New Featured item has been added.`,
			});
		},
		onDeleteFeatured(item) {
			const featuredId = item.featuredId;
			FeaturedItemManager.deleteFeaturedItem(featuredId)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: 'Featured Item has been deleted.',
					});
					this.allFeaturedItems = this.allFeaturedItems.filter((item) => {
						return item.featuredId !== featuredId;
					});
				})
				.catch((error) => {
					this.showNotification({
						type: 'error',
						message: error,
					});
				});
		},
		openWebsite() {
			if (DominionInformation.dominionWebsiteFeatures) {
				var open = window.open(
					DominionInformation.dominionWebsiteFeatures,
					'_blank'
				);
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
	},
	mounted() {
		this.getData();
	},
};
</script>
