import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';
import store from '../store/store';
import { getAuth } from 'firebase/auth';

function initialOrganizationFirebase() {
	const organization = store.getters.getOrganization;
	const firebaseConfig = {
		apiKey: organization.apiKey,
		authDomain: organization.authDomain,
		projectId: organization.projectId,
		storageBucket: organization.storageBucket,
		messagingSenderId: organization.messagingSenderId,
		appId: organization.appId,
		measurementId: organization.measurementId,
	};

	const app = getApps().find((x) => x.name === organization.projectId);

	if (app) {
		return app;
	} else {
		// Initialize Firebase
		const app = initializeApp(firebaseConfig, organization.projectId);
		return app;
	}
}

function getOrganizationAuth() {
	const app = initialOrganizationFirebase();
	//Auth
	const auth = getAuth(app);
	return auth;
}

function getOrganizationStorage() {
	const app = initialOrganizationFirebase();
	//Storage
	const storage = getStorage(app);

	return storage;
}

function getOrganizationFirestore() {
	const app = initialOrganizationFirebase();
	// Firestore
	const firestore = getFirestore(app);

	return firestore;
}

function getOrganizationMessaging() {
	const app = initialOrganizationFirebase();
	//Messaging
	const messaging = getMessaging(app);
	return messaging;
}

export {
	getOrganizationAuth,
	getOrganizationFirestore,
	getOrganizationStorage,
	getOrganizationMessaging,
};
