import {
	collection,
	doc,
	getDoc,
	getDocs,
	increment,
	query,
	setDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import AppUserManager from '../AppUser/AppUserManager'

export default class ProductItemManager {
	static isLoading = false;
	static allProducts = [];

	static getAllProductItems() {
		return new Promise((resolve, reject) => {
			this.allProducts = [];
			const firestore = getOrganizationFirestore();
			const productRef = collection(firestore, `products`);
			getDocs(productRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var documentData = document.data();
							items.push(documentData);
							if (counter == total - 1) {
								this.allProducts = items;
								return resolve(items);
							}
							counter += 1;
						});
						return resolve(null);
					} else {
						//return reject('No users found.');
						return resolve(null);
					}
				})
				.catch((error) => {
					//return reject(error.message);
					return resolve(null);
				});
		});
	}

	static updateProductInformation(productId, data) {
		return new Promise((resolve, reject) => {
			const firestore = getOrganizationFirestore();
			const productRef = doc(firestore, `products/${productId}`);
			setDoc(productRef, data, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addProduct(productFile, productData) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const productRef = FirebaseManager.allProductRef(firestore);
			const newProductRef = doc(productRef);
			const newProductStorageRef = ref(
				storage,
				`${productRef.path}/${newProductRef.id}`
			);

			const metadata = {
				contentType: 'image/jpg',
			};

			uploadBytes(newProductStorageRef, productFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							var data = productData;
							if (data.isFeatured) {
								const newFeaturedRef = doc(FirebaseManager.allFeaturedRef(firestore));
								var featuredData = {
									featuredId: newFeaturedRef.id,
									featuredItemId: newProductRef.id,
									featuredItemName: 'productItem',
									imageItem: {
										imageUrl: downloadURL,
									},
									index: 0,
								};
								setDoc(newFeaturedRef, featuredData).then(() => {
									this.addProductToDB(data, downloadURL, newProductRef)
										.then(() => {
											return resolve(data);
										})
										.catch((error) => {
											return reject(error.message);
										});
								});
							} else {
								this.addProductToDB(data, downloadURL, newProductRef)
									.then(() => {
										return resolve(data);
									})
									.catch((error) => {
										return reject(error.message);
									});
							}
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getProductById(productId) {
		return new Promise((resolve, reject) => {
			const productRef = doc(getOrganizationFirestore(), `products/${productId}`);
			getDoc(productRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const documentData = snapshot.data();
						return resolve(documentData);
					} else {
						return reject(`No product`);
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getProductUsers(productData) {
		return new Promise((resolve, reject) => {
			const productRef = collection(
				getOrganizationFirestore(),
				`products/${productData.productId}/productRedeemed`
			);
			getDocs(productRef)
				.then((snapshot) => {
					if (snapshot.empty) {
						return resolve(null);
					} else {
						var items = [];
						var total = snapshot.size;
						var counter = 0;
						snapshot.forEach((document) => {
							const documentData = document.data();
							if (documentData.uid) {
								AppUserManager.fetchUserByUid(documentData.uid).then((user) => {
									if (user) {
										items.push({
											userData: user,
											productData: documentData,
										});
									}
									if (counter == total - 1) {
										return resolve(items);
									}
									counter += 1;
								});
							}
						});
					}
				})
				.catch((error) => {
					return reject(error);
				});
		});
	}

	static changeProductImage(productFile, productData) {
		return new Promise((resolve, reject) => {
			const storage = getOrganizationStorage();
			const firestore = getOrganizationFirestore();
			const productRef = doc(firestore, `products/${productData.productId}`);
			const newProductStoreref = ref(storage, `products/${productData.productId}`);
			const metadata = {
				contentType: 'image/jpg',
			};
			uploadBytes(newProductStoreref, productFile, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							setDoc(
								productRef,
								{
									productImages: [downloadURL],
								},
								{ merge: true }
							)
								.then(() => {
									return resolve();
								})
								.catch((error) => {
									return reject(error.message);
								});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addProductToDB(data, downloadURL, newProductRef) {
		return new Promise((resolve, reject) => {
			var productImages = [downloadURL];
			data['productId'] = newProductRef.id;
			data['productImages'] = productImages;
			const urlExtended = `product?&productId=${newProductRef.id}`;
			FirebaseManager.createDynamicLink(
				urlExtended,
				data.productTitle,
				downloadURL
			).then((shareLink) => {
				if (shareLink) {
					data['shareLink'] = shareLink;
				}
				setDoc(newProductRef, data)
					.then(() => {
						return resolve(data);
					})
					.catch((error) => {
						return reject(error);
					});
			});
		});
	}

	static redeemProduct(uid, productData, chargeId, chargeTimestamp) {
		return new Promise((resolve, reject) => {
			const itemInfo = {
				uid: uid,
				chargeId: chargeId,
				timestamp: chargeTimestamp,
			};

			const batch = writeBatch(getOrganizationFirestore());
			const productRef = doc(
				getOrganizationFirestore(),
				`${FirebaseManager.allProductRef(getOrganizationFirestore()).path}/${
					productData.productId
				}`
			);
			const redeemRef = doc(
				getOrganizationFirestore(),
				`${productRef.path}/productRedeemed/${chargeId}`
			);

			batch.set(
				productRef,
				{
					redeemed: increment(1),
				},
				{ merge: true }
			);
			batch.set(redeemRef, itemInfo);
			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					console.log(error.message);
					return reject();
				});
		});
	}
}
