<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm">
			<b-row>
				<b-col>
					<SubscriptionCard
						:allSubscriptions="allActiveSubscriptions"
						@updateNextCharge="onNextChargeUpdate"
						@updateTokens="onTokensUpdate"
						@onSubscriptionUpdate="onSubscriptionUpdate"
						@updatePrice="onUpdatePrice"
						@cancelSubscription="onCancelSubscription"
						:isActive="true"
					/>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<SubscriptionCard
						:allSubscriptions="allInactiveSubscriptions"
						:isActive="false"
					/>
				</b-col>
			</b-row>
		</b-overlay>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import SubscriptionCard from './SubscriptionCard.vue';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
			allSubscriptions: [],
			allActiveSubscriptions: [],
			allInactiveSubscriptions: [],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserSubscriptions();
				}
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		onSubscriptionUpdate(data) {
			const subscription = data.subscription;
			const updateData = data.updateData;
			AppUserManager.updateUserSubscription(
				this.userData.uid,
				subscription,
				updateData
			)
				.then(() => {
					this.getUserSubscriptions();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		onNextChargeUpdate(data) {
			const subscription = data.subscription;
			const nextChargeDate = data.nextChargeDate;
			AppUserManager.adjustUserSubscription(
				this.userData.uid,
				false,
				false,
				subscription,
				null,
				nextChargeDate,
				null
			)
				.then(() => {
					this.getUserSubscriptions();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		onUpdatePrice(data){
			const subscription = data.subscription
			const amount = (parseFloat(data.amount) * 100).toFixed(0)
			this.isLoading = true;
			AppUserManager.adjustUserSubscription(
				this.userData.uid,
				false,
				false,
				subscription,
				null,
				null,
				parseInt(amount)
			).then(() => {
					this.getUserSubscriptions();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
			console.log('amount', amount)
		},
		onTokensUpdate(data) {
			const subscription = data.subscription;
			const tokensRemaining = data.tokens; //remaining ...
			var tokenMaxQuantity = subscription.subscription.quantity;
			var tokensUsed = tokenMaxQuantity - tokensRemaining;
			this.isLoading = true;
			AppUserManager.adjustUserSubscription(
				this.userData.uid,
				false,
				false,
				subscription,
				tokensUsed,
				null,
				null
			)
				.then(() => {
					this.getUserSubscriptions();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		onCancelSubscription(subscription) {
			this.isLoading = true;
			AppUserManager.adjustUserSubscription(
				this.userData.uid,
				true,
				true,
				subscription
			)
				.then(() => {
					this.getUserSubscriptions();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		getUserSubscriptions() {
			this.isLoading = true;
			AppUserManager.getUserSubscriptions(this.userData.uid)
				.then((allSubscriptions) => {
					if (allSubscriptions) {
						this.allSubscriptions = allSubscriptions;
						this.allActiveSubscriptions = this.allSubscriptions.filter(
							(subscription) => subscription.isActive
						);
						this.allInactiveSubscriptions = this.allSubscriptions.filter(
							(subscription) => !subscription.isActive
						);
					}
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
	},
	components: { SubscriptionCard },
	mounted() {},
};
</script>
