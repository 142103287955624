import { Timestamp } from 'firebase/firestore';
import helpers from '../../helpers/helpers';
import store from '../../store/store';

export default class Recurrence {
	static options = [
		{
			name: 'Every Day',
			value: 'everyDay',
		},
		{
			name: 'Weekly',
			value: 'everyWeek',
		},
		{
			name: 'Bi-Weekly',
			value: 'biWeekly',
		},
		{
			name: 'Every 3 Weeks',
			value: 'triWeekly',
		},
		{
			name: 'Monthly',
			value: 'everyMonth',
		},
		{
			name: 'Yearly',
			value: 'everyYear',
		},
	];

	static returnReadableString(recurrence) {
		var firstString = 'Every';
		if (recurrence.recurrenceTime) {
			switch (recurrence.recurrenceTime) {
				case 'everyDay':
					return 'Every Day';
				case 'everyWeek':
					firstString = 'Every Week';
					break;
				case 'everyMonth':
					firstString = 'Every Month';
					break;
				case 'everyYear':
					firstString = 'Every Year';
					break;
				case 'biWeekly':
					firstString = 'Bi-Weekly';
					break;
				case 'triWeekly':
					firstString = 'Every 3 Weeks';
					break;
			}
			if (recurrence.recurrenceDays) {
				recurrence.recurrenceDays.forEach((day, index) => {
					switch (day) {
						case 'monday':
							firstString += index == 0 ? ': Mondays' : ', Mondays';
							break;
						case 'tuesday':
							firstString += index == 0 ? ': Tuesdays' : ', Tuesdays';
							break;
						case 'wednesday':
							firstString += index == 0 ? ': Wednesdays' : ', Wednesdays';
							break;
						case 'thursday':
							firstString += index == 0 ? ': Thursdays' : ', Thursdays';
							break;
						case 'friday':
							firstString += index == 0 ? ': Fridays' : ', Fridays';
							break;
						case 'saturday':
							firstString += index == 0 ? ': Saturdays' : ', Saturdays';
							break;
						case 'sunday':
							firstString += index == 0 ? ': Sundays' : ', Sundays';
							break;
					}
				});
			}
		}
		return firstString;
	}

	static getRecurrenceDates(startDate, endDate, recurrence) {
		if (!startDate || !recurrence) {
			return null;
		}
		if (startDate instanceof Timestamp) {
			startDate = startDate.toDate();
		}
		if (endDate) {
			if (endDate instanceof Timestamp) {
				endDate = endDate.toDate();
			}
		} else {
			endDate = helpers.addYear(startDate, 1);
		}

		startDate = helpers.changeTimeZone(
			startDate,
			store.state.publicInformation.timezone
		);
		endDate = helpers.changeTimeZone(
			endDate,
			store.state.publicInformation.timezone
		);

		var result = [];
		if (recurrence.recurrenceTime) {
			switch (recurrence.recurrenceTime) {
				case 'everyDay':
					var date = startDate;
					while (date < endDate) {
						result.push(date);
						date = helpers.addDays(date, 1);
					}
					return result;
				case 'everyWeek':
					var date = startDate;
					while (date <= endDate) {
						if (recurrence.recurrenceDays) {
							const dayNames = recurrence.recurrenceDays.map((element) => {
								return element.toLowerCase();
							});
							const daysInWeek = helpers
								.getDaysOfWeek(date)
								.filter(($0) => {
									return dayNames.includes(helpers.dayOfWeek($0).toLowerCase()) === true;
								})
								.filter(($0) => {
									return $0 <= endDate;
								});
							date = helpers.addWeek(date, 1);
							result.push(...daysInWeek);
						} else {
							result.push(date);
							date = helpers.addWeek(date, 1);
						}
					}
					return result;
				case 'biWeekly':
					var date = startDate;
					while (date <= endDate) {
						if (recurrence.recurrenceDays) {
							const dayNames = recurrence.recurrenceDays.map((element) => {
								return element.toLowerCase();
							});
							const daysInWeek = helpers
								.getDaysOfWeek(date)
								.filter(($0) => {
									return dayNames.includes(helpers.dayOfWeek($0).toLowerCase()) === true;
								})
								.filter(($0) => {
									return $0 <= endDate;
								});
							date = helpers.addWeek(date, 2);
							result.push(...daysInWeek);
						} else {
							result.push(date);
							date = helpers.addWeek(date, 2);
						}
					}
					return result;
				case 'triWeekly':
					var date = startDate;
					while (date <= endDate) {
						if (recurrence.recurrenceDays) {
							const dayNames = recurrence.recurrenceDays.map((element) => {
								return element.toLowerCase();
							});
							const daysInWeek = helpers
								.getDaysOfWeek(date)
								.filter(($0) => {
									return dayNames.includes(helpers.dayOfWeek($0).toLowerCase()) === true;
								})
								.filter(($0) => {
									return $0 <= endDate;
								});
							date = helpers.addWeek(date, 3);
							result.push(...daysInWeek);
						} else {
							result.push(date);
							date = helpers.addWeek(date, 3);
						}
					}
					return result;
				case 'everyMonth':
					var date = startDate;
					while (date <= endDate) {
						if (recurrence.recurrenceDays) {
							const dayNames = recurrence.recurrenceDays.map((element) => {
								return element.toLowerCase();
							});
							const daysInWeek = helpers
								.getDaysOfWeek(date)
								.filter(($0) => {
									return dayNames.includes(helpers.dayOfWeek($0).toLowerCase()) === true;
								})
								.filter(($0) => {
									return $0 <= endDate;
								});
							date = helpers.addMonth(date, 1);
							result.push(...daysInWeek);
						} else {
							result.push(date);
							date = helpers.addMonth(date, 1);
						}
					}
					return result;
				case 'everyYear':
					var date = startDate;
					while (date <= endDate) {
						if (recurrence.recurrenceDays) {
							const dayNames = recurrence.recurrenceDays.map((element) => {
								return element.toLowerCase();
							});
							const daysInWeek = helpers
								.getDaysOfWeek(date)
								.filter(($0) => {
									return dayNames.includes(helpers.dayOfWeek($0).toLowerCase()) === true;
								})
								.filter(($0) => {
									return $0 <= endDate;
								});
							date = helpers.addYear(date, 1);
							result.push(...daysInWeek);
						} else {
							result.push(date);
							date = helpers.addYear(date, 1);
						}
					}
					return result;
				default:
					return result;
			}
		}
	}
}
