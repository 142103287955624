<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="mt-2">
					<h5 style="flex: auto; text-align: center">
						{{ headerDescription }}
					</h5>
					<h6
						v-if="model.showErrorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ model.errorMessage }}
					</h6>
				</b-row>
				<form style="flex-grow: 1">
					<base-input class="mb-0">
						<textarea
							placeholder="Enter your message..."
							name="message"
							v-model="model.message"
							class="form-control mb-0"
							id="notification"
							rows="9"
							@input="onInput"
						></textarea>
						<b-row class="mr-0 mt-1 mb-0">
							<div style="flex-grow: 1"></div>
							<h6 class="">
								{{ model.messageCharCount }}/{{ model.messageCharMaxCount }}
							</h6>
						</b-row>
					</base-input>
					<b-row v-if="!classData" class="mt-0">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Schedule Notification</label>
						</b-col>
						<b-col cols="auto">
							<BaseSwitch
								v-model="isScheduled"
								@change="onScheduledChange"
							></BaseSwitch>
						</b-col>
					</b-row>
					<div v-if="isScheduled">
						<base-input
							class="mt-2 pb-0"
							:required="isScheduled"
							type="datetime-local"
							v-model="model.scheduledDate"
							id="schedule time"
							:min="minDate"
						>
						</base-input>
						<h6 class="mt-0 pt-0">
							TIP: Please allow an additional 10-15 minutes for schedule notifications
							to trigger
						</h6>
					</div>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Send Notification</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import Modal from '../../components/Modals/Modal.vue';
import Card from '../../components/Cards/Card.vue';
import NotificationsManager from '../../models/NotificationItem/NotificationsManager';
import ClassItemManager from '../../models/ClassItem/ClassItemManager';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import BaseCheckbox from '../../components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import { mapActions } from 'vuex';

export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Notification',
		},
		headerDescription: {
			type: String,
			default: 'Send your message as a notification to all users.',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
		classData: {
			type: Object,
			default: null,
		},
	},
	components: {
		Modal,
		Card,
		BaseInput,
		BaseCheckbox,
		BaseSwitch,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			isScheduled: false,
			minDate: null,
			model: {
				message: '',
				messageCharCount: 0,
				messageCharMinCount: 10,
				messageCharMaxCount: 500,
				scheduledDate: null,
				showErrorMessage: false,
				errorMessage: 'Please enter a valid notification message.',
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		closeModal() {
			if (!this.isLoading) {
				this.$emit('update:show', false);
				this.$emit('close');
			}
		},
		onInput() {
			this.model.messageCharCount = this.model.message.length;
			if (this.model.messageCharCount > 0) {
				this.model.errorMessage == null;
				this.model.showErrorMessage = false;
			} else {
				this.model.showErrorMessage = true;
			}
			if (this.model.messageCharCount >= this.model.messageCharMaxCount) {
				this.model.message = this.model.message.substring(
					0,
					this.model.messageCharMaxCount
				);
				this.model.messageCharCount = this.model.message.length;
			}
		},
		onScheduledChange() {
			if (!this.isScheduled) {
				this.model.scheduledDate = null;
			}
		},
		submit() {
			this.shake = false;
			if (this.model.messageCharCount < this.model.messageCharMinCount) {
				this.model.errorMessage = 'Please enter a valid notification message.';
				this.model.showErrorMessage = true;
				this.shake = true;
				return;
			} else if (this.isScheduled && !this.model.scheduledDate) {
				this.model.errorMessage = 'Please enter a valid scheduled date.';
				this.model.showErrorMessage = true;
				this.shake = true;
				return;
			} else {
				this.isLoading = true;
				if (this.classData) {
					ClassItemManager.sendNotificationToDB(
						this.classData.classId,
						this.classData.classTitle,
						this.model.message,
						this.model.scheduledDate
					)
						.then((notification) => {
							this.isLoading = false;
							this.$emit('newNotification', notification);
						})
						.catch((error) => {
							this.showNotification({
								type: 'danger',
								message: error,
							});
						});
				} else {
					NotificationsManager.sendNotificationToDB(
						this.model.message,
						this.model.scheduledDate
					)
						.then((notification) => {
							this.isLoading = false;
							this.$emit('newNotification', notification);
						})
						.catch((error) => {
							this.showNotification({
								type: 'danger',
								message: error,
							});
						});
				}
			}
		},
	},
	mounted() {
		this.minDate = this.$helpers.formatDate(new Date(), "yyyy-MM-dd'T'HH:mm");
		//console.log(this.minDate);
	},
};
</script>
