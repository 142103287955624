<template>
	<div>
		<Card>
			<CardHeader slot="header" title="Date and Time" />
			<form>
				<b-row>
					<b-col>
						<ButtonRadioGroup
							:showBorder="false"
							:options="options"
							@change="onEventOptionChanged"
						>
							<div
								v-if="options[0].value == selected"
								:slot="options[0].value"
								class="mt-4"
							>
								<b-row class="mx-1">
									<b-col sm="6" lg="6">
										<BaseInput
											label="Event Start Date"
											id="event date"
											placeholder="Event Start Date"
											type="datetime-local"
											:required="options[0].value == selected"
											v-model="eventDetails.eventStartDate"
										/>
									</b-col>
									<b-col sm="6" lg="6">
										<label class="form-control-label">Event Duration</label>
										<b-row>
											<b-col>
												<BaseInput
													id="event duration"
													placeholder="Hours"
													type="mask"
													:mask="['# hours', '## hours']"
													:required="options[0].value == selected"
													v-model="eventHoursHandle"
												/>
											</b-col>
											<b-col>
												<BaseInput
													id="event duration"
													placeholder="Minutes"
													type="mask"
													:mask="['# minutes', '## minutes']"
													:required="options[0].value == selected"
													v-model="eventMinutesHandle"
												/>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</div>
							<div
								v-if="options[1].value == selected"
								:slot="options[1].value"
								class="mt-4"
							>
								<b-row class="mx-1">
									<b-col sm="4" lg="6">
										<BaseInput
											label="Event Start Date"
											id="event date"
											placeholder="Event Start Date"
											type="datetime-local"
											:required="options[1].value == selected"
											v-model="eventDetails.eventStartDate"
										/>
									</b-col>
									<b-col sm="4" lg="6">
										<label class="form-control-label">Event Duration</label>
										<b-row>
											<b-col>
												<BaseInput
													id="event duration"
													placeholder="Hours"
													type="mask"
													:mask="['# hours', '## hours']"
													:required="options[0].value == selected"
													v-model="eventHoursHandle"
												/>
											</b-col>
											<b-col>
												<BaseInput
													id="event duration"
													placeholder="Minutes"
													type="mask"
													:mask="['# minutes', '## minutes']"
													:required="options[0].value == selected"
													v-model="eventMinutesHandle"
												/>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
								<b-row class="mx-1">
									<b-col sm="6" lg="6">
										<BaseInput
											label="Recurrence"
											id="event recurrence"
											placeholder="Event Recurrence"
											:required="options[1].value == selected"
											type="text"
										>
											<select
												class="form-control"
												id="recurrence"
												v-model="eventDetails.eventRecurrence"
												:required="options[1].value == selected"
											>
												<option
													v-for="recurrence in recurrenceOptions"
													:key="recurrence.value"
													:value="recurrence.value"
												>
													{{ recurrence.name }}
												</option>
											</select></BaseInput
										>
									</b-col>
									<b-col sm="6" lg="6">
										<BaseInput
											label="Recurrence End Date"
											id="event date"
											placeholder="Recurrence End Date"
											type="datetime-local"
											:max="
												this.$helpers.formatDate(
													this.$helpers.addYear(eventDetails.eventStartDate, 1),
													`yyyy-MM-dd'T'HH:mm`
												)
											"
											:min="eventDetails.eventStartDate"
											:required="options[1].value == selected"
											v-model="eventDetails.eventEndDate"
										/>
									</b-col>
								</b-row>
								<b-row v-if="showWeekDays" class="mx-1">
									<b-col>
										<ButtonCheckboxGroup
											title="Reoccuring Days"
											:options="daysOfWeek"
											:required="options[1].value == selected"
											@change="onCheckboxChanged"
										/>
									</b-col>
								</b-row>
							</div>
						</ButtonRadioGroup>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../../../components/Cards/Card.vue';
import BaseInput from '../../../../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../../../../components/Inputs/CurrencyInput.vue';
import BaseSwitch from '../../../../../components/BaseSwitch.vue';
import BaseButton from '../../../../../components/BaseButton.vue';
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import ButtonRadioGroup from '../../../../../components/ButtonRadioGroup.vue';
import ButtonCheckboxGroup from '../../../../../components/ButtonCheckboxGroup.vue';
import { options } from 'preact';
import CardHeader from '../../../../../components/Cards/CardHeader.vue';
import Recurrence from '../../../../../models/DataModels/Reccurrence';

export default {
	components: {
		Card,
		BaseInput,
		CurrencyInput,
		BaseSwitch,
		BaseButton,
		VueDropZone,
		ButtonRadioGroup,
		ButtonCheckboxGroup,
		CardHeader,
	},
	props: {
		eventDetails: {
			type: Object,
			default() {
				return {
					eventStartDate: null,
					eventDuration: null,
					eventRecurrence: null,
					eventRecurrenceDays: [],
					eventEndDate: null,
				};
			},
		},
	},
	data() {
		return {
			eventHoursHandle: null,
			eventMinutesHandle: null,
			options: [
				{
					value: 1,
					name: 'Single Event',
					selected: true,
					description:
						'An event that happens only once, like a dance recital or a drop-in class.',
				},
				{
					value: 2,
					name: 'Recurring Event',
					description:
						'A series of events that repeat. Once you publish the recurring event, you can manage the individual event dates seperately.',
				},
			],
			recurrenceOptions: [],
			daysOfWeek: [],
			selected: null,
			showWeekDays: true,
		};
	},
	watch: {
		eventHoursHandle: {
			handler(val) {
				return this.handleEventDuration();
			},
		},
		eventMinutesHandle: {
			handler(val) {
				return this.handleEventDuration();
			},
		},
		eventDetails: {
			handler(val) {
				if (val.eventRecurrence) {
					if (
						val.eventRecurrence == 'everyDay' ||
						val.eventRecurrence == 'everyMonth' ||
						val.eventRecurrence == 'everyYear'
					) {
						this.showWeekDays = false;
						this.daysOfWeek = [];
					} else {
						this.showWeekDays = true;
					}
				}
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		onEventOptionChanged(value) {
			this.selected = value;
			if (value == 1) {
				//reset recurring
				this.daysOfWeek = this.resetDaysOfWeek();
				this.eventDetails.eventRecurrence = null;
				this.eventDetails.eventRecurrenceDays = [];
				this.eventDetails.eventEndDate = null;
			} else if (value == 2) {
				//console.log('Moses Reset Sign Event');
			}
		},
		onCheckboxChanged(values) {
			const daysofWeek = [];
			values.forEach((index) => {
				daysofWeek.push(this.daysOfWeek[index].name.toLowerCase());
			});
			this.eventDetails.eventRecurrenceDays = daysofWeek;
		},
		resetDaysOfWeek() {
			return [
				{
					name: 'Monday',
					selected: false,
				},
				{
					name: 'Tuesday',
					selected: false,
				},
				{
					name: 'Wednesday',
					selected: false,
				},
				{
					name: 'Thursday',
					selected: false,
				},
				{
					name: 'Friday',
					selected: false,
				},
				{
					name: 'Saturday',
					selected: false,
				},
				{
					name: 'Sunday',
					selected: false,
				},
			];
		},
		handleStartDate() {
			if (this.eventDetails.eventStartDate && this.selected == 2) {
				var startDate = new Date(this.eventDetails.eventStartDate);
				var startWeekday = this.$helpers.formatDate(startDate, 'EEEE');
				const optionIndex = this.daysOfWeek.findIndex(
					(e) => e.name == startWeekday
				);
				this.daysOfWeek[optionIndex].selected = true;
			}
		},
		handleEventDuration() {
			const hours = this.eventHoursHandle;
			const minutes = this.eventMinutesHandle;
			const totalSeconds = hours * 3600 + minutes * 60;
			if (totalSeconds > 0) {
				this.eventDetails.eventDuration = totalSeconds;
			} else {
				this.eventDetails.eventDuration = null;
				this.eventHoursHandle = null;
				this.eventMinutesHandle = null;
			}
		},
		onChange() {
			this.handleStartDate();
			this.$emit('onChange', this.eventDetails);
		},
	},
	mounted() {
		this.daysOfWeek = this.resetDaysOfWeek();
		this.recurrenceOptions = Recurrence.options;
		this.eventDetails.eventStartDate = this.$helpers.formatDate(
			new Date(),
			"yyyy-MM-dd'T'HH:mm"
		);
	},
};
</script>
