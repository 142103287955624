<template>
	<div>
		<!-- Dropdown header -->
		<div class="px-3 pt-3">
			<h6 class="text-sm text-muted m-0">Admin Notifications</h6>
		</div>
		<NotificationCard
			:all-notifications="allAdminNotifications"
			:isHeader="true"
			@viewNotification="navigateToLink"
		/>
		<hr class="mt-0 mb-0" />
		<a
			href=""
			v-on:click.prevent="navigateToLink"
			class="dropdown-item text-center text-primary font-weight-bold py-3"
			>View all</a
		>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DashboardChildren } from '../../../routes/routesNames';
import NotificationsManager from '../../../models/NotificationItem/NotificationsManager';
import NotificationCard from '../../Components/Notifications/NotificationCard.vue';

export default {
	props: {},
	computed: {
		routeName() {
			const { name } = this.$route;
			return this.capitalizeFirstLetter(name);
		},
	},
	data() {
		return {
			isLoading: false,
			activeNotifications: false,
			showMenu: false,
			allAdminNotifications: [],
			DashboardChildren,
		};
	},
	methods: {
		sortNotifications() {
			if (NotificationsManager.allAdminNotifications.length >= 5) {
				this.allAdminNotifications =
					NotificationsManager.allAdminNotifications.slice(0, 5);
			} else {
				this.allAdminNotifications = NotificationsManager.allAdminNotifications;
			}
		},
		toggleNotificationDropDown() {
			this.activeNotifications = !this.activeNotifications;
		},
		closeDropDown() {
			this.activeNotifications = false;
		},
		navigateToLink() {
			if (this.$route.name != DashboardChildren.adminNotifications.name) {
				this.$router.replace({
					path: DashboardChildren.adminNotifications.path,
				});
			}
		},
	},
	mounted() {
		if (NotificationsManager.didCheckAdminNotifications) {
			this.sortNotifications();
		} else {
			NotificationsManager.fetchAdminNotifications().then(() => {
				this.sortNotifications();
			});
		}
	},
	components: { NotificationCard },
};
</script>
