<template>
	<side-bar
		v-if="
			this.$store.state.appInformation.isActive
				? this.$store.state.appInformation.isActive
				: false
		"
		:logo="this.$store.state.publicInformation.appLogo"
		:title="this.$store.state.publicInformation.appName"
	>
		<div slot="links">
			<div v-for="menuItem in menuItems" v-if="!menuItem.isComingSoon">
				<sidebar-item :link="menuItem">
					<div v-if="menuItem.children" v-for="children in menuItem.children">
						<sidebar-item :link="children" />
					</div>
				</sidebar-item>
			</div>
		</div>

		<div slot="links-after">
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Coming Soon</h6>

			<b-nav class="navbar-nav mb-md-3">
				<div v-for="menuItem in menuItems" v-if="menuItem.isComingSoon">
					<b-nav-item href="">
						<i :class="menuItem.icon"></i>
						<b-nav-text class="p-0 mr-1">{{ menuItem.name }}</b-nav-text>
					</b-nav-item>
				</div>
			</b-nav>
		</div>

		<div slot="links-after">
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Support Center</h6>

			<b-nav class="navbar-nav mb-md-3">
				<b-nav-item :href="DominionInformation.dominionSupport" target="_blank">
					<i class="fas fa-question-circle"></i>
					<b-nav-text class="p-0">Help Center</b-nav-text>
				</b-nav-item>
				<b-nav-item
					:href="DominionInformation.dominionSupportTicket"
					target="_blank"
				>
					<i class="fas fa-info-circle"></i>
					<b-nav-text class="p-0">Submit A Ticket</b-nav-text>
				</b-nav-item>
				<b-nav-item href="#">
					<i class="fas fa-clock"></i>
					<b-nav-text class="p-0">9am - 4:30pm EST</b-nav-text>
				</b-nav-item>
			</b-nav>
		</div>

        <div slot="footer">
			<hr class="my-3 bg-light" />
			<b-navbar-brand
				class="p-0 text-center"
				:href="DominionInformation.dominionWebsite"
				target="_blank"
			>
				<b-img
					style="opacity: 60%;"
					fluid
					src="/img/logo/LogoHorizontal_Light.png"
				/>
			</b-navbar-brand>
		</div>

	</side-bar>
</template>
<script>
import { DashboardChildren } from '../../routes/routesNames.js';
import { DominionInformation } from '../../resources/DominionInformation.js';
import Badge from '../../components/Badge.vue';
import { mapGetters } from 'vuex';
import store from '../../store/store';

export default {
	name: 'sideMenu',
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
	},
	data() {
		return {
			DominionInformation,
			menuItems: [],
		};
	},
	methods: {
		setupSideMenu() {
			Object.values(DashboardChildren).forEach((entry) => {
				if (entry.icon && !entry.isHidden) {
					var tabTitle = entry.name;
					if (entry.id == DashboardChildren.schedule.id) {
						const isScheduleActive =
							store.state.appInformation.isScheduleActive != null
								? store.state.appInformation.isScheduleActive
								: true;
						if (isScheduleActive) {
							this.menuItems.push(entry);
						}
					} else if (entry.id == DashboardChildren.booking.id) {
						const bookingInfo = store.state.bookingInformation;
						if (bookingInfo != null) {
							const isBookingActive = bookingInfo.isActive;
							if (isBookingActive) {
								this.menuItems.push(entry);
							}
						}
					} else if (entry.id == DashboardChildren.billing.id) {
						const isPaymentActive =
							store.state.appInformation.isPaymentActive != null
								? store.state.appInformation.isPaymentActive
								: false;
						if (isPaymentActive) {
							this.menuItems.push(entry);
						}
					} else if (entry.id == DashboardChildren.terminal.id) {
						if (store.state.appInformation.isTerminalActive) {
							this.menuItems.push(entry);
						}
					} else {
						this.menuItems.push(entry);
					}
					
					//isHidden: store.state.appInformation.isTerminalActive
			//? !store.state.appInformation.isTerminalActive
			//: true,
					/*
					if (this.publicInformation.appTitles) {
						const appTitles = this.publicInformation.appTitles;
						var type = null;
						switch (tabTitle) {
							case DashboardChildren.schedule.name:
								type = 'schedule';
								break;
							case DashboardChildren.classes.name:
								type = 'classMultiple';
								break;
							case DashboardChildren.videos.name:
								type = 'videoMultiple';
								break;
							case DashboardChildren.shop.name:
								type = 'shop';
								break;
							default:
								break;
						}
						const newTitle = appTitles.find((e) => e.type == type && e.title);
						tabTitle = newTitle ? newTitle.title : entry.name;
					}
					entry.name = tabTitle;
					*/
				}
			});
		},
		returnTitleTypes() {
			return [
				'schedule',
				'eventSingle',
				'eventMultiple',
				'classSingle',
				'classMultiple',
				'shop',
				'videoSingle',
				'videoMultiple',
				'leaderSingle',
				'leaderMultiple',
				'profile',
			];
		},
	},
	mounted() {
		this.setupSideMenu();
	},
	components: { Badge },
};
</script>
