import FirebaseManager from '../Firebase/FirebaseManager';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import { deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore';

export default class CategoryItemManager {
	static isLoading = false;
	static allCategories = [];

	static getAllCategories() {
		return new Promise((resolve, reject) => {
			const categoryRef = FirebaseManager.allCategoriesRef(
				getOrganizationFirestore()
			);
			this.isLoading = true;
			getDocs(categoryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						this.allCategories = [];
						documents.forEach((document) => {
							const documentData = document.data();
							this.allCategories.push(documentData);
							if (counter == total - 1) {
								this.isLoading = false;
								return resolve();
							}
							counter += 1;
						});
					} else {
						this.isLoading = false;
						return resolve();
					}
				})
				.catch((error) => {
					this.isLoading = false;
					return reject(error.message);
				});
		});
	}

	static addNewCategory(name, isProduct) {
		return new Promise((resolve, reject) => {
			const categoryRef = FirebaseManager.allCategoriesRef(
				getOrganizationFirestore()
			);
			const newCateogryRef = doc(categoryRef);
			var categoryData = {
				categoryName: name,
				categoryId: newCateogryRef.id,
				isActive: true,
				isProduct: isProduct,
			};
			setDoc(newCateogryRef, categoryData)
				.then(() => {
					return resolve(categoryData);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteCategory(categoryId) {
		return new Promise((resolve, reject) => {
			const categoryIdRef = doc(
				getOrganizationFirestore(),
				`${
					FirebaseManager.allCategoriesRef(getOrganizationFirestore()).path
				}/${categoryId}`
			);
			deleteDoc(categoryIdRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
