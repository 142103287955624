import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import { getOrganizationFirestore } from '../../resources/orgFirebase';
import store from '../../store/store';
import helpers from '../../helpers/helpers';
import FirebaseManager from '../Firebase/FirebaseManager';

export default class NotificationsManager {
	static allAdminNotifications = [];
	static didCheckAdminNotifications = false;
	static fetchNotifications(lastDocument) {
		return new Promise((resolve, reject) => {
			const notificationRef = FirebaseManager.allNotificationsRef(
				getOrganizationFirestore()
			);
			var queryRef = query(
				notificationRef,
				where('uploadTimestamp', '<=', new Date()),
				orderBy('uploadTimestamp', 'desc'),
				limit(25)
			);
			if (lastDocument) {
				queryRef = query(
					notificationRef,
					where('uploadTimestamp', '<=', new Date()),
					orderBy('uploadTimestamp', 'desc'),
					startAfter(lastDocument),
					limit(25)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = total >= 25 ? documents[documents.length - 1] : null;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var notificationData = document.data();
							notificationData['icon'] = store.state.organization.appLogo;
							notificationData['name'] = store.state.organization.appName;
							items.push(notificationData);
							if (counter == total - 1) {
								return resolve([items, lastDocument]);
							}
							counter += 1;
						});
					} else {
						return resolve([null, null]);
					}
				})
				.catch((error) => {
					//return reject(error.message);
					return resolve([null, null]);
				});
		});
	}

	static fetchScheduledNotifications(lastDocument) {
		return new Promise((resolve, reject) => {
			const notificationRef = FirebaseManager.allNotificationsRef(
				getOrganizationFirestore()
			);
			var queryRef = query(
				notificationRef,
				where('uploadTimestamp', '>', new Date()),
				orderBy('uploadTimestamp', 'asc'),
				limit(25)
			);
			if (lastDocument) {
				queryRef = query(
					notificationRef,
					where('uploadTimestamp', '>', new Date()),
					orderBy('uploadTimestamp', 'asc'),
					startAfter(lastDocument),
					limit(25)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = total >= 25 ? documents[documents.length - 1] : null;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var notificationData = document.data();
							notificationData['icon'] = store.state.organization.appLogo;
							notificationData['name'] = store.state.organization.appName;
							items.push(notificationData);
							if (counter == total - 1) {
								return resolve([items, lastDocument]);
							}
							counter += 1;
						});
					} else {
						return resolve([null, null]);
					}
				})
				.catch((error) => {
					//return reject(error.message);
					return resolve([null, null]);
				});
		});
	}

	static fetchAdminNotifications() {
		return new Promise((resolve, reject) => {
			this.allAdminNotifications = [];
			const firestore = getOrganizationFirestore();
			const adminNotificationsRef =
				FirebaseManager.allAdminNotificationsRef(firestore);
			const queryRef = query(adminNotificationsRef, limit(15));
			//queryRef
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						documents.forEach((document) => {
							const documentData = document.data();
							this.allAdminNotifications.push(documentData);
							this.allAdminNotifications.sort((a, b) => {
								return b.uploadTimestamp - a.uploadTimestamp;
							});
							if (counter == total - 1) {
								this.didCheckAdminNotifications = true;
								return resolve(this.allAdminNotifications);
							}
							counter += 1;
						});
					} else {
						return resolve(this.allAdminNotifications);
					}
				})
				.catch((error) => {
					console.log(error);
					return resolve(this.allAdminNotifications);
				});
		});
	}

	static sendNotificationToDB(message, scheduledDate) {
		return new Promise((resolve, reject) => {
			const currentUser = store.state.user;
			const notificationRef = FirebaseManager.allNotificationsRef(
				getOrganizationFirestore()
			);
			const newItemRef = doc(notificationRef);
			var data = {
				message: message,
				messageId: newItemRef.id,
				uid: currentUser.appUid,
				sent: false,
			};
			if (scheduledDate) {
				data['uploadTimestamp'] = new Date(helpers.changeTimeZone(scheduledDate));
			} else {
				data['uploadTimestamp'] = helpers.returnTimestamp();
			}
			setDoc(newItemRef, data)
				.then(() => {
					return resolve(data);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteNotification(messageId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const firestore = getOrganizationFirestore(organization);
			const itemRef = doc(firestore, `notifications/${messageId}`);
			deleteDoc(itemRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
