import Login from '../views/mainViews/Login.vue';
import Dashboard from '../views/DashboardViews/Dashboard'
import DashboardLayout from '../views/mainViews/DashboardLayout.vue';
import Home from '../views/mainViews/DashboardViews/Home.vue';
import Events from '../views/mainViews/DashboardViews/Events.vue';
import Schedule from '../views/mainViews/DashboardViews/Schedule.vue';
import Settings from '../views/mainViews/DashboardViews/Settings.vue';
import Classes from '../views/mainViews/DashboardViews/Classes.vue';
import Videos from '../views/mainViews/DashboardViews/Videos.vue';
import Shop from '../views/mainViews/DashboardViews/Shop.vue';
import ShopView from '../views/mainViews/DashboardViews/SecondaryViews/ShopItemView.vue';
import ShopItemUsersView from '../views/mainViews/DashboardViews/SecondaryViews/ShopItemUsersView.vue'
//import Members from '../views/BaseViews/Members/Members.vue'
import Members from '../views/mainViews/DashboardViews/Members.vue';
import MemberView from '../views/mainViews/DashboardViews/SecondaryViews/MemberView.vue';
import AdminNotifications from '../views/mainViews/DashboardViews/AdminNotifications.vue';
import Notifications from '../views/mainViews/DashboardViews/Notifications.vue';
import Documents from '../views/mainViews/DashboardViews/Documents.vue';
import Booking from '../views/mainViews/DashboardViews/Booking.vue';
import NewInvoiceView from '../views/Components/InvoiceViews/NewInvoiceView/NewInvoiceView.vue';
import Terminal from '../views/mainViews/DashboardViews/Terminal.vue';
import TerminalView from '../views/mainViews/DashboardViews/SecondaryViews/TerminalView.vue';
import Billing from '../views/mainViews/DashboardViews/Billing.vue';
import EventItemView from '../views/mainViews/DashboardViews/SecondaryViews/EventItemView';
import EventItemUsersView from '../views/mainViews/DashboardViews/SecondaryViews/EventItemUsersView.vue';
import ClassItemView from '../views/mainViews/DashboardViews/SecondaryViews/ClassItemView';
import ClassItemUsersView from '../views/mainViews/DashboardViews/SecondaryViews/ClassItemUsersView.vue';
import NewEventView from '../views/Components/EventViews/NewEventView/NewEventView.vue';
import NewClassView from '../views/Components/ClassesViews/NewClassView.vue';
import NotFoundPage from '../views/mainViews/NotFoundPage';
import Resources from '../views/mainViews/DashboardViews/Resources';

export const LoginPaths = {
	login: {
		id: 'login',
		name: 'Login',
		path: '/login',
		view: Login,
		meta: { alreadyAuth: true },
		props: {
			default: (route) => ({
				isSuperAdmin: route.query.isSuperAdmin
			}),
		},
	},
};

export const DashboardPaths = {
	home: {
		id: 'home',
		name: 'Dashboard',
		path: '/',
		redirect: '/dashboard',
		view: Dashboard,
		meta: { requiresAuth: true },
	},
	notFound: {
		id: 'home',
		name: 'Page Not Found',
		path: '*',
		view: NotFoundPage,
	},
};

export const DashboardChildren = {
	home: {
		id: 'dashboardHome',
		name: 'Home',
		icon: 'fas fa-home',
		path: '/dashboard',
		view: Home,
	},
	/*
	events: {
		id: 'events',
		name: 'Events',
		icon: 'fas fa-list-alt',
		path: '/dashboard/events',
		view: Events,
	},
	*/
	schedule: {
		id: 'schedule',
		name: 'Schedule',
		icon: 'fas fa-calendar-alt',
		path: '/dashboard/schedule',
		view: Schedule,
		props: true,
	},
	booking: {
		id: 'booking',
		name: 'Booking',
		icon: 'fas fa-check-square',
		path: '/dashboard/booking',
		view: Booking,
	},
	eventView: {
		id: 'eventView',
		name: 'Event View',
		path: '/dashboard/schedule/event/:eventId/',
		view: EventItemView,
		props: {
			default: (route) => ({
				eventId: route.params.eventId,
				eventStart: route.query.eventStart,
				classId: route.query.classId,
				classTitle: route.query.classTitle
			}),
		},
	},
	eventViewUsers: {
		id: 'eventViewUsers',
		name: 'Event View Users',
		path: '/dashboard/schedule/event/:eventId/users',
		view: EventItemUsersView,
		props: {
			default: (route) => ({
				eventId: route.params.eventId,
				eventStart: route.query.eventStart,
				classId: route.query.classId,
				classTitle: route.query.classTitle
			}),
		},
	},
	newEventView: {
		id: 'newEvent',
		name: 'New Event',
		path: '/dashboard/schedule/newevent',
		view: NewEventView,
		props: {
			default: (route) => ({
				classId: route.query.classId,
				classTitle: route.query.classTitle
			}),
		},
	},
	shop: {
		id: 'shop',
		name: 'Shop',
		icon: 'fas fa-shopping-bag',
		path: '/dashboard/shop/',
		view: Shop,
	},
	shopView: {
		id: 'shopView',
		name: 'Shop View',
		path: '/dashboard/shop/:productId/',
		view: ShopView,
		props: {
			default: (route) => ({
				productId: route.params.productId,
				productData: route.params.productData,
			}),
		},
	},
	shopViewUsers: {
		id: 'shopViewUsers',
		name: 'Shop View Users',
		path: '/dashboard/schedule/shop/:productId/users',
		view: ShopItemUsersView,
		props: {
			default: (route) => ({
				productId: route.params.productId,
				productData: route.params.productData,
			}),
		},
	},
	classes: {
		id: 'classes',
		name: 'Classes',
		icon: 'fas fa-chalkboard-teacher',
		path: '/dashboard/classes/',
		view: Classes,
	},
	classView: {
		id: 'classView',
		name: 'Class View',
		path: '/dashboard/classes/:classId/',
		view: ClassItemView,
		props: {
			default: (route) => ({
				classId: route.params.classId,
			}),
		},
	},
	classViewUsers: {
		id: 'classViewUsers',
		name: 'Class View Users',
		path: '/dashboard/classes/:classId/users',
		view: ClassItemUsersView,
		props: {
			default: (route) => ({
				classId: route.params.classId,
			}),
		},
	},
	newClassView: {
		id: 'newClass',
		name: 'New Class',
		path: '/dashboard/classes/newclass',
		view: NewClassView,
	},
	videos: {
		id: 'videos',
		name: 'Videos',
		icon: 'fas fa-video',
		path: '/dashboard/videos',
		view: Videos,
	},
	documents: {
		id: 'documents',
		name: 'Documents',
		icon: 'fas fa-file-signature',
		path: '/dashboard/documents',
		view: Documents,
	},
	resources: {
		id: 'resources',
		name: 'Resources',
		icon: 'fas fa-file-alt',
		path: '/dashboard/resources',
		view: Resources,
	},
	billing: {
		id: 'billing',
		name: 'Billing',
		icon: 'fas fa-wallet',
		path: '/dashboard/billing',
		view: Billing,
	},
	newInvoiceView: {
		id: 'newInvoice',
		name: 'New Invoice',
		path: '/dashboard/invoices/newinvoice',
		view: NewInvoiceView,
		props: {
			default: (route) => ({
				userData: route.params.userData,
			}),
		},
	},
	attendance: {
		id: 'attendance',
		name: 'Attendance',
		icon: 'fas fa-calendar',
		path: '/dashboard/attendance',
		isHidden: true,
		isComingSoon: true,
		children: [
			{
				id: 'dailyAttendance',
				name: 'Daily Attendance',
				path: '/dashboard/attendance/daily',
				view: Classes,
			},
			{
				id: 'attendanceReport',
				name: 'Attendance Report',
				path: '/dashboard/attendance/report',
				view: Classes,
			},
		],
		view: Classes,
	},
	reports: {
		id: 'reports',
		name: 'Reports',
		icon: 'fas fa-bar-chart',
		path: '/dashboard/reports',
		isComingSoon: true,
	},
	messages: {
		id: 'messages',
		name: 'Messages',
		icon: 'fas fa-envelope',
		path: '/dashboard/messages',
		isComingSoon: true,
	},
	forms: {
		id: 'forms',
		name: 'Forms',
		icon: 'fas fa-edit',
		path: '/dashboard/forms',
		isComingSoon: true,
	},
	members: {
		id: 'members',
		name: 'Members',
		icon: 'fas fa-user-friends',
		path: '/dashboard/members',
		view: Members,
	},
	memberProfile: {
		id: 'profile',
		name: 'Profile',
		path: '/dashboard/members/profile',
		view: MemberView,
		props: { default: (route) => ({ uid: route.query.uid }) },
	},
	terminal: {
		id: 'terminal',
		name: 'Dominion Pay',
		icon: 'fas fa-credit-card',
		path: '/dashboard/terminal/',
		view: Terminal,
	},
	terminalView: {
		id: 'terminalView',
		name: 'Terminal',
		path: '/dashboard/terminal/:readerId/',
		view: TerminalView,
		props: {
			default: (route) => ({
				readerId: route.params.readerId,
				readerData: route.params.readerData,
				eventId: route.query.eventId,
				productId: route.query.productId,
				eventStart: route.query.eventStart,
				eventData: route.params.eventData,
				productData: route.params.productData,
			}),
		},
	},
	notifications: {
		id: 'notifications',
		name: 'Notifications',
		icon: 'fas fa-bell',
		path: '/dashboard/notifications',
		view: Notifications,
	},

	adminNotifications: {
		id: 'adminNotifications',
		name: 'Admin Notifications',
		path: '/dashboard/admin-notifications',
		view: AdminNotifications,
	},
	settings: {
		id: 'settings',
		name: 'Settings',
		icon: 'fas fa-cog',
		path: '/dashboard/settings',
		view: Settings,
		isHidden: true,
	},
};
