<template>
	<div>
		<Card no-body>
			<CardFooter v-if="footerTitle" slot="footer" :title="footerTitle" />
			<b-row v-if="headerTitle" align-v="center" slot="header">
				<b-col>
					<h3 class="mb-0">{{ headerTitle }}</h3>
				</b-col>
				<b-col v-if="headerButtonData" cols="4" class="text-right">
					<BaseButton
						@click="headerButtonClicked"
						:disabled="
							!isSelecting && headerButtonData.isDisabled != null
								? headerButtonData.isDisabled
								: selected.length == 0
						"
						size="sm"
						>{{ headerButtonData.title }}</BaseButton
					>
				</b-col>
			</b-row>
			<BaseInput
				id="filter-input"
				v-model="filter"
				type="search"
				class="mb-0 mt-3 mx-3"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>
			<b-table
				:fields="tableHeaders"
				:items="tableData"
				:busy="isLoading"
				:filter="filter"
				small
				hover
				responsive="sm"
				@row-clicked="rowClicked"
				class="px-1"
			>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell(selected)="data" v-if="isSelecting">
					<input type="checkbox" @change="selectedItem($event, data.item)" />
				</template>
				<template #cell(photo)="data">
					<b-avatar :src="data.value" variant="dark" />
				</template>
				<template #cell(nameAndPhoto)="data">
					<b-avatar
						badge-variant="success"
						variant="dark"
						class="mr-2"
						:text="data.value.firstName"
						:src="data.value.profilePhotoURL"
					>
						<template v-if="data.value.appLeader" #badge
							><b-icon icon="star-fill"></b-icon
						></template>
					</b-avatar>
					{{ data.value.firstName }} {{ data.value.lastName }}
				</template>
				<template #cell(waiverStatus)="data">
					<BaseSwitch :value="data.value" :isDisabled="true" type="success" />
				</template>
				<template #cell(subscriptionStatus)="data">
					<BaseSwitch
						:value="data.value"
						:isDisabled="true"
						:type="data.value ? 'success' : 'primary'"
					/>
				</template>
				<template #cell(attendanceQuantity)="data">
					<input
						class="form-control"
						type="number"
						:value="data.value"
						:max="
							data.item.eventData.quantity != null
								? `${data.item.eventData.quantity}`
								: '1'
						"
						min="0"
						@change="
							attendanceQuantityChange({
								value: parseInt($event.target.value),
								userData: data.item.userData,
							})
						"
					/>
				</template>
				<template #cell(attendanceStatus)="data">
					<select class="form-control" id="recurrence" required>
						<option
							v-for="status in attendanceStatusOption"
							:key="status.value"
							:value="status.value"
						>
							{{ status.name }}
						</option>
					</select>
					<!--
<BaseSwitch
						:value="data.value"
						:type="'success'"
						@input="switchChanged($event, data)"
					/>
					-->
				</template>
				<template #cell(attendanceProgress)="data">
					<b-progress max="2" value="1" variant="info"></b-progress>
				</template>
				<template #cell(actions)="row">
					<BaseButton v-if="false" size="sm" outline @click="viewClicked(row.item)"
						>View</BaseButton
					>
					<BaseButton
						v-if="false"
						size="sm"
						type="danger"
						@click="deleteClicked(row.item)"
						>Report</BaseButton
					>
				</template>
				<template #cell(pendingActions)="row">
					<div v-if="row.item.classData.isPendingPayment">
						<BaseButton size="sm" type="info" @click="pendingClicked(row.item)"
							>Pending Payment</BaseButton
						>
						<BaseButton
							size="sm"
							outline
							type="danger"
							@click="declineClicked(row.item)"
							>Remove</BaseButton
						>
					</div>
					<div v-else>
						<BaseButton
							size="sm"
							outline
							type="danger"
							@click="declineClicked(row.item)"
							>Decline</BaseButton
						>
						<BaseButton size="sm" type="success" @click="acceptClicked(row.item)"
							>Accept</BaseButton
						>
					</div>
				</template>
				<template #cell(classActions)="row">
					<BaseButton
						size="sm"
						outline
						type="danger"
						@click="declineClicked(row.item)"
						>Remove</BaseButton
					>
				</template>
				<template #cell(productActions)="row">
					<BaseButton size="sm" outline @click="receiptClicked(row.item)"
						>View Receipt</BaseButton
					>
				</template>
			</b-table>
		</Card>
	</div>
</template>
<script>
import BaseButton from '../../components/BaseButton.vue';
import BaseSwitch from '../../components/BaseSwitch.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import Card from '../../components/Cards/Card.vue';
import CardFooter from '../../components/Cards/CardFooter.vue';
export default {
	components: { BaseButton, BaseSwitch, BaseInput, Card, CardFooter },
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	props: {
		isLoading: false,
		isSelecting: false,
		headerTitle: {
			type: String,
			default: null,
		},
		footerTitle: {
			type: String,
			default: null,
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
	},
	data() {
		return {
			filter: null,
			selected: [],
			attendanceStatus: null,
			attendanceStatusOption: [
				{
					name: 'Partial Attendance',
					value: 'partial',
				},
				{
					name: 'Present',
					value: 'present',
				},
				{
					name: 'No Show',
					value: 'no show',
				},
			],
		};
	},
	methods: {
		headerButtonClicked() {
			this.$emit('headerButtonClicked', this.selected);
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		attendanceQuantityChange(item) {
			this.$emit('attendanceQuantityChange', item);
		},
		selectedItem(event, userData) {
			const isChecked = event.target.checked;
			if (isChecked) {
				if (!this.selected.find((e) => e.uid == userData.uid)) {
					this.selected.push(userData);
				}
			} else {
				this.selected = this.selected.filter((e) => e.uid !== userData.uid);
			}
		},
		receiptClicked(item){
			this.$emit('receiptClicked', item);
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		pendingClicked(item) {
			this.$emit('pendingClicked', item);
		},
		acceptClicked(item) {
			this.$emit('acceptClicked', item);
		},
		declineClicked(item) {
			this.$emit('declineClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('viewClicked', item);
		},
	},
};
</script>
