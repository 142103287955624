<template>
	<footer class="footer px-4 mx-2 border-top">
		<b-row align-v="center" class="justify-content-center text-center">
			<b-col>
                <a :href="DominionInformation.dominionWebsite" target="_blank">
					<h5>
						© {{ new Date().getFullYear() }}
						{{ DominionInformation.dominionName }}
					</h5>
				</a>
			</b-col>
		</b-row>
        <b-row class="text-center">
			<b-col>
				<h6 class="text-light">Version {{ returnPackageVersion() }}</h6>
			</b-col>
		</b-row>
	</footer>
</template>
<script>
import { DominionInformation } from '../../resources/DominionInformation';
import Logo from 'logo/LogoHorizontal_Dark.png';
import pck from '../../../package.json'

export default {
	data() {
		return {
			DominionInformation,
			Logo,
		};
	},
    methods: {
		returnPackageVersion(){
			return pck.version
		}
	}
};
</script>
<style></style>
