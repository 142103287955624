<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm" no-wrap />
		<Card :style="productData.isActive ? 'opacity: 1' : 'opacity: 0.7'">
			<CardHeader
				slot="header"
				title="Product Information"
				:end-button="{ title: 'Update Product', disabled: !isUpdatedNeed }"
				@click="updateProductInformation"
			/>
			<CardFooter
				slot="footer"
				title="Please note: Only the product title, description, quantity and sizes can be edited."
			/>

			<form>
				<BaseInput
					label="Product Title"
					id="product title"
					placeholder="Product Title"
					:disabled="!productData.isActive"
					required
					v-model="updatedData.productTitle"
				/>

				<BaseInput label="Product Description">
					<textarea
						placeholder="Enter your description..."
						v-model="updatedData.productDescription"
						:disabled="!productData.isActive"
						class="form-control"
						id="product description"
						rows="4"
						required
					></textarea>
				</BaseInput>

				<BaseInput
							label="Product Category"
							id="category"
							type="multiselect"
							:internalSearch="false"
							@remove="onCategoryRemoved"
							@select="onCategorySelected"
							:multiSelectOptions="categoryOptions"
							:multiSelectValue="category"
						/>
				
				<b-row>
					<b-col>
						<CurrencyInput
					:value="
						productData.price.subscription
							? productData.price.subscription.amount / 100
							: productData.price.amount / 100
					"
					:disabled="true"
					label="Product Price"
				/>
					</b-col>
					<b-col cols="12" md="2">
						<BaseInput
							label="Product Quantity"
							id="product quantity"
							placeholder="Product Quantity"
							type="number"
							min="1"
							required
							:disabled="productData.price.subscription"
							v-model="updatedData.productQuantity"
							tooltip="The amount of times a user can purchase this item at once."
						/>
					</b-col>
				</b-row>

				
				<div v-if="this.productData.productSizes">
					<label class="form-control-label">Available Sizes</label>
					<div class="form-control-auto form-control my-2 px-3">
						<b-row class="justify-content-between align-items-center">
							<div v-for="size in productSizes" v-bind:key="size.title">
								<b-col cols="auto">
									<BaseInput :label="size.title + ':'" placeholder="Sizes Available">
										<select class="form-control" id="size" v-model="size.value">
											<option v-for="value in arrayOfNumbers" :key="value">
												{{ value }}
											</option>
										</select>
									</BaseInput>
								</b-col>
							</div>
						</b-row>
					</div>
				</div>
				<div v-if="productData.price.subscription">
					<label class="form-control-label">Subscription Information</label>
					<div class="form-control-auto form-control my-2 px-3">
						<label class="form-control-label">Subscription Tokens</label>

						<BaseInput placeholder="Tokens Available Per Cycle">
							<select
								class="form-control"
								id="size"
								:disabled="true"
								v-model="updatedData.productSubscriptionTokens"
							>
								<option v-for="value in arrayOfNumbers" :key="value">
									{{ value }}
								</option>
							</select>
						</BaseInput>

						<label class="form-control-label"
							>Subscription Billing Reoccurrence</label
						>
						<ButtonRadioGroup
							:options="reoccuringOptions"
							:disabled="true"
							v-model="productData.price.subscription.reoccurrence"
						/>
					</div>
				</div>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';
import CurrencyInput from '../../../components/Inputs/CurrencyInput.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import reoccuringOptions from '../../../data/reoccuringOptions';
import ButtonRadioGroup from '../../../components/ButtonRadioGroup.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import CategoryItemManager from '../../../models/CategoryItem/CategoryItemManager'
import ProductItemManager from '../../../models/Payment-Billing/ProductItemManager';
import { mapActions } from 'vuex';
export default {
	props: {
		productData: null,
	},
	components: {
		Card,
		BaseButton,
		BaseInput,
		CurrencyInput,
		ButtonRadioGroup,
		CardFooter,
		CardHeader,
	},
	data() {
		return {
			isLoading: false,
			isUpdatedNeed: false,
			arrayOfNumbers: [],
			reoccuringOptions,
			category: null,
			categoryOptions: [],
			productSizes: [
				{
					title: 'XS',
					value: null,
				},
				{
					title: 'S',
					value: null,
				},
				{
					title: 'M',
					value: null,
				},
				{
					title: 'L',
					value: null,
				},
				{
					title: 'XL',
					value: null,
				},
				{
					title: 'XXL',
					value: null,
				},
				{
					title: 'XXXL',
					value: null,
				},
			],
			updatedData: {
				productTitle: null,
				productDescription: null,
				productQuantity: null,
				categoryIds: [],
			},
		};
	},
	watch: {
		productSizes: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
		category: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
		},
		updatedData: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
	},
	methods: {
		...mapActions(['showNotification']),
		checkIfUpdateNeeded() {
			if (
				this.updatedData.productTitle !== this.productData.productTitle ||
				this.updatedData.productDescription !== this.productData.productDescription
			) {
				return true;
			} else if (this.category !== this.setProductCategories()){
				return true
			} else if (this.productData.productSizes) {
				const previousSizes = {};
				const checkedSizes = {};
				Object.entries(this.productSizes).forEach((data, index, array) => {
					if (data[1].value !== null) {
						const value = data[1].value;
						checkedSizes[data[1].title.toLowerCase()] =
							value == 'Unlimited' ? 1000 : parseInt(value);
					}
				});
				Object.entries(this.productData.productSizes).forEach(
					(data, index, array) => {
						var key = data[0];
						var value = data[1];
						previousSizes[key.toLowerCase()] = value;
					}
				);

				const objectsEqual = (o1, o2) =>
					typeof o1 === 'object' && Object.keys(o1).length > 0
						? Object.keys(o1).length === Object.keys(o2).length &&
						  Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
						: o1 === o2;
				return !objectsEqual(checkedSizes, previousSizes);
			} else if (this.updatedData.productQuantity !== this.setProductQuantity()) {
				return true;
			} 
			return false;
		},
		updateProductInformation() {
			this.isLoading = true;
			var priceData = this.productData.price
			priceData.quantity = parseInt(this.updatedData.productQuantity)
			this.updatedData['price'] = priceData
			if (this.productData.productSizes) {
				var productSizes = {};
				Object.entries(this.productSizes).forEach((data, index, array) => {
					const key = data[1].title.toLowerCase();
					const value = data[1].value;
					if (value == 'Unlimited') {
						productSizes[key] = 1000;
					} else if (value != null && value >= 0) {
						productSizes[key] = parseInt(value);
					}
				});
				this.updatedData['productSizes'] = productSizes;
			}
			
			if (this.category){
				const categoryId = this.getValue(this.category.categoryId)
				if (categoryId){
					this.updatedData.categoryIds.push(categoryId)
				}
			}
			ProductItemManager.updateProductInformation(
				this.productData.productId,
				this.updatedData
			)
				.then(() => {
					this.isLoading = false;
					this.$emit('onUpdate');
					this.showNotification({
						type: 'success',
						message: 'Information Updated',
					});
					this.isUpdatedNeed = false;
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		getValue(value) {
			if (value === undefined || value === null || this.$helpers.isBlank(value)) {
				return null;
			}
			return value;
		},
		setProductSizes() {
			Object.entries(this.productData.productSizes).forEach((entry) => {
				var key = entry[0];
				var value = entry[1];
				var index = this.productSizes.findIndex(
					(e) => e.title.toLowerCase() == key.toLowerCase()
				);
				this.productSizes[index].value = `${value}`;
			});
		},
		setProductQuantity(){
			return this.productData.price.quantity ? this.productData.price.quantity : 1
		},
		setProductCategories(){
			if (this.productData.categoryIds){
				const foundCategories = this.categoryOptions.filter((e) => e.categoryId == this.productData.categoryIds) 
				if (foundCategories.length > 0){
					this.updatedData.categoryIds = this.productData.categoryIds
					return foundCategories
				}
			}
			return null
		},
		setSubscription() {
			//productSubscriptionTokens
			if (this.productData.subscription.subscriptionTokenQuantity != null) {
				this.updatedData.productSubscriptionTokens =
					this.productData.subscription.subscriptionTokenQuantity;
			}
		},
		onCategoryRemoved() {
			this.category = null;
			//this.classData.categoryId = null;
		},
		onCategorySelected(categoryData) {
			this.category = categoryData
			
		},
	},
	mounted() {
		CategoryItemManager.getAllCategories().finally(() => {
			this.updatedData.productTitle = this.productData.productTitle;
			this.updatedData.productDescription = this.productData.productDescription;
			this.updatedData.productQuantity = this.setProductQuantity();
		
		for (let index = -1; index < 1001; index++) {
			if (index == -1) {
				this.arrayOfNumbers.push('Unlimited');
			} else {
				this.arrayOfNumbers.push(`${index}`);
			}
		}
		if (this.productData.subscription) {
			this.setSubscription();
		}
		if (this.productData.productSizes) {
			this.setProductSizes();
		}

		this.categoryOptions = CategoryItemManager.allCategories.filter((item) => {
			var isProduct = false
				if (item.isProduct != null){isProduct = item.isProduct}
				return isProduct
			});
			this.category = this.setProductCategories()
		})

		
	},
};
</script>
