<template>
	<div v-if="eventData">
		<DashboardHeader
			v-if="eventData"
			:title="`${classId ? getClassTitle() : this.eventData.eventTitle}`"
			:buttonItems="buttonItems"
			@childClick="childClick"
		/>
		<div v-if="eventData" class="container-fluid mt--6">
			<b-overlay :show="isLoading" rounded="sm">
				<b-row>
					<b-col>
						<EventHeaderCard
							:eventData="eventData"
							@imageClicked="showEditImage = true"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<Card no-body>
							<CardHeader
								slot="header"
								title="Event Information"
								:end-button="{ title: 'Update Event', disabled: !needUpdateEvent }"
								@click="onUpdateEvent"
							/>
							<b-tabs
								active-nav-item-class="bg-white font-weight-bold description"
								nav-class="bg-white description"
								card
								no-body
							>
								<b-tab title="Event Details">
									<TitleCard
										:eventData="eventData"
										:isDisabled="eventData.isCancelled"
										@onChange="onEventTitle"
									/>
									<LocationCard
										:eventData="eventData"
										:isDisabled="eventData.isCancelled"
										@onChange="onEventLocation"
									/>
								</b-tab>
								<b-tab title="Price Information" v-if="appInformation.isPaymentActive">
									<PriceCard
										:eventData="eventData"
										:isDisabled="eventData.isCancelled"
										@onChange="onEventPrice"
									/>
								</b-tab>
								<b-tab title="Registration">
									<RegistrationCard
										:eventData="eventData"
										:isDisabled="eventData.isCancelled"
										@onChange="onEventRegistration"
									/>
								</b-tab>
								<b-tab title="Additional Settings">
									<AdditionalSettingsCard
										:eventData="eventData"
										@onChange="onEventSettings"
									/>
								</b-tab>
							</b-tabs>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</div>
		<NewNotification
			:headerTitle="buttonItems[0].children[this.sendNotificationId].title"
			:headerDescription="`Send notification To '${this.eventData.eventTitle}' Attending Users`"
			:showModal="showNotificationModal"
			@close="showNotificationModal = false"
			@sendNotification="sendNotification"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="yesClicked"
		/>
		<ChangeImage
			:showModal="showEditImage"
			@updatedImage="addedImage"
			@close="showEditImage = false"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { DashboardChildren } from '../../../../routes/routesNames';
import DashboardHeader from '../../../mainViews/HeaderFooterComponents/DashboardHeader.vue';
import NewNotification from '../../../mainForms/NewNotification.vue';
import BaseButton from '../../../../components/BaseButton.vue';
import Badge from '../../../../components/Badge.vue';
import Card from '../../../../components/Cards/Card.vue';
import QuestionModal from '../../../../components/Modals/QuestionModal.vue';
import EventManager from '../../../../models/EventItem/EventManager';
import EventHeaderCard from '../../../Components/EventViews/BaseEventView/EventHeaderCard.vue';
import CardHeader from '../../../../components/Cards/CardHeader.vue';
import LocationCard from '../../../Components/GeneralViews/LocationCard.vue';
import TitleCard from '../../../Components/GeneralViews/TitleCard.vue';
import PriceCard from '../../../Components/GeneralViews/PriceCard.vue';
import RegistrationCard from '../../../Components/GeneralViews/RegistrationCard.vue';
import AdditionalSettingsCard from '../../../Components/GeneralViews/AdditionalSettingsCard.vue';
import ChangeImage from '../../../mainForms/ChangeImage.vue';

export default {
	name: 'eventView',
	computed: {
		...mapGetters({
			appInformation: 'getAppInformation',
			paymentInformation: 'getPaymentInformation',
		}),
	},
	props: {
		eventId: {
			type: String,
			default: null,
		},
		eventStart: {
			type: [String, Number],
			default: null,
		},
		classId: {
			type: String,
			default: null,
		},
		classTitle: {
			type: String,
			default: null,
		},
	},
	components: {
		DashboardHeader,
		NewNotification,
		Card,
		BaseButton,
		Badge,
		QuestionModal,
		EventHeaderCard,
		CardHeader,
		LocationCard,
		TitleCard,
		PriceCard,
		RegistrationCard,
		AdditionalSettingsCard,
		ChangeImage,
	},
	data() {
		return {
			isLoading: false,
			eventData: null,
			needUpdateEvent: false,
			titleUpdate: null,
			locationUpdate: null,
			priceUpdate: null,
			registrationUpdate: null,
			settingsUpdate: null,
			showNotificationModal: false,
			showQuestionModal: false,
			showEditImage: false,
			modalQuestion: '',
			selectedId: null,
			viewAttendeesId: 0,
			sendNotificationId: 1,
			shareEventId: 2,
			cancelEventId: 3,
			deleteEventId: 4,
			terminalId: 5,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	watch: {
		titleUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		locationUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		priceUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		registrationUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
		settingsUpdate: {
			handler() {
				return this.checkIfUpdateNeed();
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		setButtons() {
			var children = [
				{
					index: this.viewAttendeesId,
					title: 'View Attendees',
				},
				/*
				{
					index: this.sendNotificationId,
					title: 'Send Notification',
				},
				*/
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.shareEventId,
					title: 'Share Event',
				},
				{
					index: this.cancelEventId,
					title: 'Cancel Event',
				},
				{
					index: -2,
					title: '',
					isDivider: true,
				},
				{
					index: this.deleteEventId,
					title: 'Delete Event',
					type: 'danger',
				},
			];
			if (this.paymentInformation){
				if (this.paymentInformation.activeTerminals) {
					children.splice(1, 0, {
						index: this.terminalId,
						title: 'Collect Payment',
					});
				}
			}
			this.buttonItems[0].children = children;
		},
		getClassTitle(){
			return `${this.eventData.eventTitle} · ${this.classTitle}`
		},
		getEventData() {
			this.isLoading = true;
			EventManager.getEventById(this.eventId, this.eventStart, this.classId).then((result) => {
				if (result) {
					this.setButtons();
					this.eventData = result;
					this.isLoading = false;
				} else {
					this.navigateToSchedule();
				}
			});
		},
		sendNotification(message) {},
		addedImage(file) {
			const updatedImage = file[0];
			this.isLoading = true;
			EventManager.changeEventImage(updatedImage, this.eventData)
				.then(() => {
					this.isLoading = false;
					this.getEventData();
					this.showNotification({
						type: 'success',
						message: 'Event Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error, //'Unknown Error.',
					});
				});
		},
		askEditImageModel() {
			this.showEditImage = true;
		},
		askCancelQuestion() {
			this.modalQuestion =
				'Are you sure you want to cancel this event? (This action cannot be undone)';
			this.showQuestionModal = true;
		},
		askDeleteQuestion() {
			this.modalQuestion = this.eventData.recurrence
				? 'something here'
				: 'Are you sure you want to delete this event? (This action cannot be undone)';
			this.showQuestionModal = true;
		},
		yesClicked() {
			if (this.selectedId == this.cancelEventId) {
				this.cancelEvent();
			} else if (this.selectedId == this.deleteEventId) {
				this.deleteEvent();
			}
		},
		cancelEvent() {
			this.isLoading = true;
			EventManager.cancelEvent(this.eventData)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: 'Event Cancelled',
					});
					this.navigateToSchedule();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		deleteEvent() {
			this.isLoading = true;
			EventManager.deleteEvent(this.eventData)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: 'Event Deleted',
					});
					this.navigateToSchedule();
				})
				.catch((error) => {
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
		shareEvent() {
			if (this.eventData.shareLink) {
				navigator.clipboard.writeText(this.eventData.shareLink);
				this.showNotification({
					type: 'success',
					message: 'Link Copied',
				});
			} else {
				this.showNotification({
					type: 'danger',
					message: 'Unable To Copy Link',
				});
			}
		},
		openTerminalPay() {
			this.$router.push({
				name: DashboardChildren.terminalView.name,
				params: {
					readerId: this.paymentInformation.activeTerminals[0],
					eventData: this.eventData,
				},
				query: {
					eventId: this.eventId,
					eventStart: this.eventStart,
				},
			});
		},
		onEventTitle(data) {
			this.titleUpdate = data.isDifferent ? data : null;
		},
		onEventLocation(data) {
			this.locationUpdate = data.isDifferent ? data : null;
		},
		onEventPrice(data) {
			this.priceUpdate = data.isDifferent ? data.price : null;
		},
		onEventRegistration(data) {
			this.registrationUpdate = data.isDifferent ? data.registration : null;
		},
		onEventSettings(data) {
			this.settingsUpdate = data.isDifferent ? data.settings : null;
		},
		checkIfUpdateNeed() {
			this.needUpdateEvent =
				this.titleUpdate ||
				this.locationUpdate ||
				this.priceUpdate ||
				this.registrationUpdate ||
				this.settingsUpdate;
		},
		onUpdateEvent() {
			var updatedData = {};
			var recurringData = {};
			if (this.titleUpdate) {
				if (this.titleUpdate.title) {
					updatedData['eventTitle'] = this.titleUpdate.title;
				}
				if (this.titleUpdate.description) {
					updatedData['eventDescription'] = this.titleUpdate.description;
				}
				if (this.titleUpdate.start && this.eventData.recurrence == null) {
					updatedData['eventStart'] = new Date(this.titleUpdate.start);
				}
			}
			if (this.locationUpdate) {
				if (this.eventData.recurrence) {
					recurringData['location'] = this.locationUpdate;
				} else {
					updatedData['location'] = this.locationUpdate;
				}
			}
			if (this.priceUpdate) {
				var updatedPrice = {
					quantity: this.priceUpdate.quantity,
				};
				if (this.priceUpdate.eventButtonTitle) {
					updatedData['eventButtonTitle'] = this.priceUpdate.eventButtonTitle;
				}
				if (this.priceUpdate.eventButtonLink) {
					updatedData['eventButtonLink'] = this.priceUpdate.eventButtonLink;
				}
				if (this.eventData.recurrence) {
					recurringData['price'] = updatedPrice;
				} else {
					updatedData['price'] = updatedPrice;
				}
				if (this.priceUpdate.isPrepaidOption != null) {
					updatedData['isPrepaidOption'] = this.priceUpdate.isPrepaidOption;
				}
			}
			if (this.registrationUpdate) {
				if (this.eventData.recurrence) {
					recurringData['eventMaxUsers'] = this.registrationUpdate.eventMaxUsers;
				} else {
					updatedData['eventMaxUsers'] = this.registrationUpdate.eventMaxUsers;
				}
				if (this.registrationUpdate.registrationStartDuration) {
					updatedData['registrationStartDuration'] =
						this.registrationUpdate.registrationStartDuration;
				}
				if (this.registrationUpdate.registrationCloseDuration) {
					updatedData['registrationCloseDuration'] =
						this.registrationUpdate.registrationCloseDuration;
				}
				if (this.registrationUpdate.startAge) {
					var ageItem = {
						startAge: this.registrationUpdate.startAge,
						enforced: this.registrationUpdate.enforced,
					};
					if (this.registrationUpdate.endAge) {
						ageItem['endAge'] = this.registrationUpdate.endAge;
					}
					updatedData['ageItem'] = ageItem;
				}
			}
			if (this.settingsUpdate) {
				if (this.settingsUpdate.facultyLeaders) {
					if (this.eventData.recurrence) {
						recurringData['eventLeaders'] = [this.settingsUpdate.facultyLeaders];
					} else {
						updatedData['eventLeaders'] = [this.settingsUpdate.facultyLeaders];
					}
				} else {
					//updatedData['eventLeaders'] = deleteField()
				}
				if (this.settingsUpdate.calendarHex) {
					updatedData['calendarHex'] = this.settingsUpdate.calendarHex;
				}
				if (this.settingsUpdate.eventPolicy) {
					updatedData['eventPolicy'] = this.settingsUpdate.eventPolicy;
				}
			}

			this.isLoading = true;
			EventManager.updateEvent(
				this.eventData,
				updatedData,
				recurringData,
				this.eventStart
			)
				.then(() => {
					this.isLoading = false;
					this.getEventData();
					this.showNotification({
						type: 'success',
						message: 'Event Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: 'Unknown Error.',
					});
				});
		},
		childClick(childClicked) {
			this.selectedId = childClicked.index;
			switch (childClicked.index) {
				case this.viewAttendeesId:
					this.$router.push({
						name: DashboardChildren.eventViewUsers.name,
						params: {
							eventData: this.eventData,
							eventId: this.eventId,
						},
						query: {
							eventStart: this.eventStart,
						},
					});
					break;
				case this.terminalId:
					this.openTerminalPay();
					break;
				case this.sendNotificationId:
					//this.showNotificationModal = true;
					break;
				case this.shareEventId:
					this.shareEvent();
					break;
				case this.cancelEventId:
					this.askCancelQuestion();
					break;
				case this.deleteEventId:
					this.askDeleteQuestion();
					break;
				default:
					break;
			}
		},
		navigateToSchedule() {
			this.$router.replace({
				name: this.eventData.classId ? DashboardChildren.classView.name : DashboardChildren.schedule.name,
				params: this.eventData.classId ? {
					classId: this.eventData.classId,
					refresh: true
				} : { refresh: true }
			});
		},
	},
	mounted() {
		if (!this.eventId) {
			this.navigateToSchedule();
		} else if (this.$route.params.eventData) {
			this.setButtons();
			this.eventData = this.$route.params.eventData;
		} else {
			this.getEventData();
		}
	},
};
</script>
<style></style>
