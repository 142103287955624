<template>
	<div v-if="eventData">
		<Card no-body shadow>
			<VueAspectRatio ar="1:1">
				<div class="b-img-helpers card-img-top">
					<b-img-lazy
						fluid-grow
						:src="eventData.eventImages[0]"
						class="b-img-background"
					/>
					<div class="b-img-overlay"></div>
					<b-img-lazy
						fluid-grow
						:src="eventData.eventImages[0]"
						class="b-img-base"
					/>
				</div>
			</VueAspectRatio>

			<div class="m-3">
				<h3
					style="
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						font-weight: 600;
					"
				>
					{{ eventData.eventTitle }}
				</h3>
				<ReadMore :text="eventData.eventDescription" />

				<ul class="list-unstyled">
					<li class="py-2">
						<div class="d-flex align-items-center">
							<badge
								type="secondary"
								circle
								class="mr-2"
								icon="fa fa-calendar"
							></badge>
							<h6 class="mb-0">
								{{
									$helpers.formatDate(eventData.eventStart, "MMM dd, yyyy 'at' hh:mm a")
								}}
							</h6>
						</div>
					</li>
					<li v-if="eventData.location" class="py-2">
						<div class="d-flex align-items-center">
							<badge
								type="secondary"
								circle
								class="mr-2"
								icon="fa fa-location-arrow"
							></badge>
							<div>
								<h6 v-if="eventData.location.title" class="mb-0">
									{{ eventData.location.title }}
								</h6>
								<h6 v-if="eventData.location.description" class="mb-0">
									{{ eventData.location.description }}
								</h6>
							</div>
						</div>
					</li>
					<li v-if="eventData.price" class="py-2">
						<div class="d-flex align-items-center">
							<badge type="secondary" circle class="mr-2" icon="fa fa-dollar"></badge>
							<div>
								<h6 style="font-weight: bold" class="mb-0">
									{{ getPriceTop(eventData.price) }}
								</h6>
								<h6 v-if="getPriceBottom(eventData.price)" class="mb-0">
									{{ getPriceBottom(eventData.price) }}
								</h6>
							</div>
						</div>
					</li>
				</ul>
				<div class="dropdown-divider"></div>
				<div
					v-if="returnAppLeader(eventData)"
					class="mt-3 d-flex align-items-center"
				>
					<b-avatar :src="returnAppLeader(eventData).profilePhotoURL" size="sm" />
					<h6 class="mb-0 ml-2">
						{{ returnAppLeader(eventData).firstName
						}}{{ returnAppLeader(eventData).lastName }}
					</h6>
				</div>
				<base-button
					v-if="!readOnly"
					block
					class="mt-3"
					@click="eventItemClicked"
					:type="
						getEventBottomButton(eventData) == 'Cancelled'
							? 'danger'
							: getEventBottomButton(eventData) == 'Sold Out'
							? 'info'
							: 'primary'
					"
				>
					{{ getEventBottomButton(eventData) }}
				</base-button>
			</div>
		</Card>
	</div>
</template>
<script>
import Card from '../../../../components/Cards/Card.vue';
import VueAspectRatio from 'vue-aspect-ratio';
import ReadMore from '../../../../components/ReadMore.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';

export default {
	props: {
		eventData: null,
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		returnAppLeader(eventData) {
			if (eventData.eventLeaders) {
				return AppUserManager.allClassLeaders.find(
					(e) => e.uid == eventData.eventLeaders[0]
				);
			} else {
				return null;
			}
		},
		getPriceTop(priceItem) {
			if (priceItem.oneTimePurchase) {
				return `One Time: ${this.$helpers.amountToString(priceItem.amount)}`;
			} else {
				return this.$helpers.amountToString(priceItem.amount);
			}
		},
		getPriceBottom(priceItem) {
			var priceBottomString = '';
			if (priceItem.subscription) {
				if (priceItem.subscription && priceItem.amount) {
					priceBottomString += `One-time Fee: ${this.$helpers.amountToString(
						priceItem.amount
					)}`;
				}
				priceBottomString += `Billed: ${Recurrence.returnReadableString(
					priceItem.subscription.recurrence
				)}`;
				return priceBottomString;
			}
			if (priceItem.quantity != null) {
				var quantity = priceItem.quantity;
				if (quantity <= 0) {
					quantity = 0;
				}
				return `Tickets Available: ${priceItem.quantity}`;
			}
			return null;
		},
		getEventCanPurchase(eventData) {
			if (eventData.isCancelled) {
				return false;
			} else if (eventData.price) {
				const priceItem = eventData.price;
				if (priceItem.quantity != null) {
					var quantity = priceItem.quantity;
					if (quantity <= 0) {
						return false;
					}
				}
				return true;
			}
			return false;
		},
		getEventBottomButton(eventData) {
			const eventButtonTitle = eventData.eventButtonTitle
				? eventData.eventButtonTitle
				: 'Learn More';
			if (eventData.isCancelled) {
				return 'Cancelled';
			} else if (eventData.price) {
				return this.getPriceQuantity(
					eventData.price,
					eventData.eventButtonTitle
				)[0];
			}
			return eventButtonTitle;
		},
		getPriceQuantity(priceItem, eventButtonTitle) {
			if (priceItem) {
				if (priceItem.quantity != null) {
					var quantity = priceItem.quantity;
					if (quantity <= 0) {
						return ['Sold Out', false];
					}
				}
				return [eventButtonTitle ? eventButtonTitle : 'Purchase', true];
			} else {
				return [null, false];
			}
		},
		eventItemClicked() {
			if (this.getEventCanPurchase(this.eventData)) {
				this.$emit('buttonClicked', this.eventData);
			}
		},
	},
	components: { Card, ReadMore, VueAspectRatio },
};
</script>
