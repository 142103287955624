<template>
	<div class="mb-3">
		<div v-if="title">
			<label class="form-control-label">
				{{ title }}
			</label>
		</div>
		<div :class="{ 'form-control-auto form-control': showBorder }">
			<b-form-group class="m-0">
				<b-form-checkbox
					v-for="(option, index) in options"
					:key="option.name"
					:value="index"
					:state="state"
					:disabled="option.disabled"
					v-model="selected"
					inline
				>
					<label class="form-control-label">
						{{ option.name }}
					</label>
					<i
						v-if="option.tooltip"
						class="ml-1 fa fa-question-circle"
						v-b-tooltip.hover
						:title="option.tooltip"
					></i>
				</b-form-checkbox>
			</b-form-group>
		</div>
	</div>
</template>
<script>
export default {
	name: 'button-radio-group',
	props: {
		title: {
			type: String,
			default: null,
		},
		options: {
			type: Array,
			description: 'Radio options. Should be an array of objects [string,string]',
			default: () => [],
		},
		disabled: {
			type: Boolean,
		},
		value: {
			type: String,
			description: 'Radio value',
		},
		buttonClasses: {
			type: [String, Object],
			description: 'Inner button css classes',
		},
		showBorder: {
			type: Boolean,
			default: true,
		},
	},
	watch: {
		options: {
			handler(val) {
				return this.updateOptions();
			},
			deep: true,
		},
	},
	model: {
		prop: 'value',
		event: 'change',
	},
	data() {
		return {
			selected: [],
		};
	},
	computed: {
		state() {
			this.$emit('change', this.selected);
		},
	},
	methods: {
		updateOptions() {
			this.selected = [];
			this.options.forEach((option, index) => {
				if (option.selected) {
					this.selected.push(index);
				}
			});
		},
	},
	mounted() {
		this.updateOptions(this.options);
	},
};
</script>
<style></style>
