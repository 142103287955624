<template>
	<div>
		<DashboardHeaderVue :title="$route.name" :buttonItems="buttonItems" />
		<div class="container-fluid mt--6">
			<div class="row">
				<div class="col">
					<VideoTable
						:isLoading="isLoading"
						:tableData="allVideos"
						@viewClicked="viewClicked"
						@deleteClicked="askToDelete"
					/>
				</div>
			</div>
		</div>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@close="showQuestionModal = false"
			@yesClicked="deleteClicked"
			@noClicked="selectedVideo == null"
		/>
	</div>
</template>
<script>
import VideoItemManager from '../../../models/VideoItem/VideoItemManager';
import VideoTable from '@/views/Components/VideoViews/VideoTable.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import DashboardHeaderVue from '../HeaderFooterComponents/DashboardHeader.vue';
import { mapActions } from 'vuex';

export default {
	name: 'videos',
	components: {
		DashboardHeaderVue,
		VideoTable,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			modalQuestion: null,
			selectedVideo: null,
			allVideos: [],
			buttonItems: [
				{
					index: 0,
					title: 'Sync Videos',
					type: 'primary',
					icon: 'fas fa-redo',
					isDisabled: true,
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		viewClicked(videoItem) {
			console.log(videoItem);
		},
		askToDelete(videoItem) {
			this.selectedVideo = videoItem;
			this.modalQuestion = 'Are you sure you want to delete this video item?';
			this.showQuestionModal = true;
		},
		getVideos() {
			this.isLoading = true;
			this.allVideos = [];
			VideoItemManager.getAllVideos().then(() => {
				this.allVideos = VideoItemManager.allVideoItems;
				this.isLoading = false;
			});
		},
		deleteClicked() {
			this.isLoading = true;
			VideoItemManager.deleteVideo(this.selectedVideo.videoId)
				.then(() => {
					this.allVideos = this.allVideos.filter(
						(e) => e.videoId != this.selectedVideo.videoId
					);
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Video Deleted.',
					});
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
	},
	mounted() {
		this.isLoading = VideoItemManager.isLoading;
		this.getVideos();
	},
};
</script>
