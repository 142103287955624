const mutations = {
	setUser(state, data) {
		state.user = data;
	},
	setOrganization(state, data) {
		state.organization = data;
	},
	setNotification(state, data) {
		state.notification = data;
	},
	setAccountInformation(state, data) {
		state.accountInformation = data;
	},
	setAppInformation(state, data) {
		state.appInformation = data;
	},
	setWebsiteInformation(state, data) {
		state.websiteInformation = data;
	},
	setPaymentInformation(state, data) {
		state.paymentInformation = data;
	},
	setPublicInformation(state, data) {
		state.publicInformation = data;
	},
	setSocialInformation(state, data) {
		state.socialInformation = data;
	},
	setBookingInformation(state, data) {
		state.bookingInformation = data;
	},
	setStripeTerminal(state, data) {
		state.stripeTerminal = data;
	},
	setStripeTerminalReaders(state, data) {
		state.stripeTerminalReaders = data;
	},
};

export default mutations;
