<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm"> </b-overlay>
		<Card>
			<b-row align-v="center" slot="header">
				<b-col cols="8">
					<h3 class="mb-0">Purchase History</h3>
				</b-col>
				<b-col cols="4" class="text-right">
					<h3 class="mb-0">{{ allUserCharges.length }} Total</h3>
				</b-col>
			</b-row>
			<b-list-group>
				<b-list-group-item
					v-for="userCharge in allUserCharges"
					:key="userCharge.chargeId"
					@click="openPayment(userCharge)"
				>
					<PaymentHistoryCard :charge-item="userCharge" />
				</b-list-group-item>
			</b-list-group>
		</Card>
	</div>
</template>
<script>
import Card from '../../../../components/Cards/Card.vue';
import AppUserManager from '../../../../models/AppUser/AppUserManager';
import PaymentHistoryCard from './PaymentHistoryCard.vue';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	data() {
		return {
			isLoading: false,
			allUserCharges: [],
		};
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserCharges();
				}
			},
		},
	},
	methods: {
		getUserCharges() {
			this.isLoading = true;
			AppUserManager.getUserCharges(this.userData.uid)
				.then((allCharges) => {
					if (allCharges) {
						this.allUserCharges = allCharges.sort((a, b) => {
							return new Date(b.created * 1000) - new Date(a.created * 1000);
						});
					}
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
		openPayment(chargeItem) {
			if (chargeItem.dominion_receipt_url) {
				var open = window.open(chargeItem.dominion_receipt_url, '_blank');
				if (open == null || typeof open == 'undefined') {
					alert('Turn off your pop-up blocker to view receipt!');
				} else {
					open.focus();
				}
			}
		},
	},
	components: { Card, PaymentHistoryCard },
};
</script>
