import { format, utcToZonedTime } from 'date-fns-tz';
import { Timestamp } from 'firebase/firestore';
import FileItemManager from '../models/FileItem/FileItemManager';
import store from '../store/store';

export default {
	isBlank(str) {
		return !str || /^\s*$/.test(str);
	},
	returnTimestamp() {
		return Timestamp.now();
	},
	returnFirebaseDate(value) {
		if (value instanceof Timestamp) {
			return value.toDate();
		} else if (typeof value == 'number' || value instanceof number) {
			return new Date(value);
		}
		return null;
	},
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	maskPhoneNumber(value) {
		var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	},
	addSeconds(date, seconds) {
		var result = new Date(date);
		result.setSeconds(result.getSeconds() + seconds);
		return result;
	},
	addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	},
	addWeek(date, weeks) {
		var result = new Date(date);
		var numberOfDays = weeks * 7;
		result.setDate(result.getDate() + numberOfDays);
		return result;
	},
	addMonth(date, months) {
		var result = new Date(date);
		result.setMonth(result.getMonth() + months);
		return result;
	},
	addYear(date, years) {
		var result = new Date(date);
		result.setFullYear(result.getFullYear() + years);
		return result;
	},
	getDaysOfWeek(date) {
		var week = [];
		var result = new Date(date);
		result.setDate(result.getDate() - result.getDay());
		for (var i = 0; i < 7; i++) {
			week.push(new Date(result));
			result.setDate(result.getDate() + 1);
		}
		return week;
	},
	dayOfWeek(date) {
		return this.formatDate(date, 'EEEE');
	},
	changeTimeZone(date, timezone) {
		return date;
		const orgTimezone = timezone
			? timezone
			: store.state.publicInformation.timezone;
		return utcToZonedTime(date, orgTimezone);
	},
	formatDate(value, formatString) {
		if (value == null) {
			return null;
		}
		if (value instanceof Timestamp) {
			value = value.toDate();
		} else if (!value instanceof Date) {
			return null;
		}
		var dateString = formatString;
		if (dateString == null) {
			dateString = 'MMMM dd, yyyy';
		}
		return format(value, dateString);
	},
	isAllRequiredDocumentsSigned(userData) {
		if (FileItemManager.allFiles.length <= 0) {
			return false;
		}
		if (userData.files == null) {
			return false;
		}
		const allUserIds = userData.files.slice().sort();
		const allRequiredIds = FileItemManager.allFiles
			.map((files) => files.fileId)
			.slice()
			.sort();

		return JSON.stringify(allUserIds) == JSON.stringify(allRequiredIds);
	},
	userReturnSignedWaiver(userData, activeWaiverId) {
		var activeWavier = null;
		if (userData.waiverForms != null && activeWaiverId != null) {
			let waiverSigned = Object.values(userData.waiverForms).find(
				(waiver) => waiver.waiverId === activeWaiverId
			);
			activeWavier = waiverSigned;
			return activeWavier;
		} else {
			return activeWavier;
		}
	},
	userReturnSubscription(userData) {
		var activeSubscription = null;
		if (userData.subscription) {
			Object.values(userData.subscription).forEach((subscription) => {
				if (subscription.subscriptionIsActive) {
					activeSubscription = subscription;
				}
			});
			return activeSubscription;
		} else {
			return activeSubscription;
		}
	},
	amountToString(amount, currency = 'USD') {
		return Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency,
		}).format(amount / 100);
	},
	getTransactionFee(amount) {
		if (amount <= 0) {
			return 0;
		}
		const stripeFee = 0.029;
		const dominionFee = 0.02;
		var step1 = amount + 0.3;
		var step2 = 1 - (stripeFee + dominionFee);
		var step3 = step1 / step2 - amount;
		return parseFloat(step3.toFixed(2));
	},
	getReoccurrenceDates(startDate, endDate, reoccurrenceString) {
		var date = new Date(startDate);
		var result = [];
		result.push(new Date(date));

		if (reoccurrenceString == 'everyDay') {
			var newDate = date;
			for (let index = 1; index < 730; index++) {
				newDate.setDate(newDate.getDate() + 1);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'everyWeek') {
			var newDate = date;
			for (let index = 1; index < 104; index++) {
				newDate.setDate(newDate.getDate() + 7);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'everyMonth') {
			var newDate = date;
			for (let index = 1; index < 24; index++) {
				newDate.setMonth(newDate.getMonth() + 1);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'everyYear') {
			var newDate = date;
			for (let index = 1; index < 2; index++) {
				newDate.setFullYear(newDate.getFullYear() + 1);
				result.push(new Date(newDate));
			}
			return result;
		} else if (reoccurrenceString == 'biWeekly') {
			var newDate = date;
			for (let index = 1; index < 52; index++) {
				newDate.setDate(newDate.getDate() + 14);
				result.push(new Date(newDate));
			}
			return result;
		} else {
			return result;
		}
	},
	timeSince(date) {
		var seconds = Math.floor((new Date() - date) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			const years = Math.floor(interval);
			if (years == 1) {
				return years + ' year ago';
			}
			return years + ' years ago';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			const months = Math.floor(interval);
			if (months == 1) {
				return months + ' month ago';
			}
			return months + ' months ago';
		}
		interval = seconds / 86400;
		if (interval > 1) {
			const days = Math.floor(interval);
			if (days == 1) {
				return days + ' day ago';
			}
			return days + ' days ago';
		}
		interval = seconds / 3600;
		if (interval > 1) {
			const hours = Math.floor(interval);
			if (hours == 1) {
				return hours + ' hour ago';
			}
			return hours + ' hours ago';
		}
		interval = seconds / 60;
		if (interval > 1) {
			const minutes = Math.floor(interval);
			if (minutes == 1) {
				return minutes + ' minute ago';
			}
			return minutes + ' minutes ago';
		} else {
			return this.formatDate(date, "MMMM dd, yyyy 'at' hh:mm a");
		}
		return Math.floor(seconds) + ' seconds ago';
	},
	getVueDropZoneOptions() {
		const dropzoneOptions = {
			url: 'https://httpbin.org/post',
			thumbnailWidth: 100,
			height: 100,
			addRemoveLinks: true,
			maxFiles: 1,
			acceptedFiles: 'image/*',
			dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD IMAGE",
		};
		return dropzoneOptions;
	},
};
