<template>
    <div class="card card-calendar">
        <!-- Card header -->
        <div class="card-header">
            <!-- Title -->
            <div class="row justify-content-between align-items-center mx-2">
                <div class="d-flex justify-content-start align-items-center">
                    <BaseButton size="sm" :icon="true" @click="prev"
                        ><i class="fas fa-angle-left"></i>
                    </BaseButton>
                    <h2 class="text-dark mb-0 mx-3">
                        {{ targetDate.month }} {{ targetDate.year }}
                    </h2>
                    <BaseButton size="sm" :icon="true" @click="next"
                        ><i class="fas fa-angle-right"></i>
                    </BaseButton>
                </div>
                <div v-if="!isWeekView">
                    <BaseButton
                        class="btn btn-sm btn-default"
                        @click="changeView('dayGridWeek')"
                    >
                        Week View
                    </BaseButton>
                </div>
                <div v-else>
                    <BaseButton
                        class="btn btn-sm btn-default"
                        @click="changeView('dayGridMonth')"
                    >
                        Month View
                    </BaseButton>
                </div>
            </div>
            <div
                v-if="false"
                class="row justify-content-between align-items-center"
            >
                <BaseButton size="sm" :icon="true" @click.prevent="prev"
                    ><i class="fas fa-angle-left"></i>
                </BaseButton>
                <h3 class="text-dark mb-0 ml-auto mr-auto">
                    {{ targetDate.month }} {{ targetDate.year }}
                </h3>
                <BaseButton size="sm" :icon="true" @click.prevent="prev"
                    ><i class="fas fa-angle-right"></i>
                </BaseButton>
                <div class="col-lg-6 col-7 justify-items-center d-flex">
                    <h3 class="text-dark mb-0 ml-auto mr-auto">
                        {{ targetDate.month }} {{ targetDate.year }}
                    </h3>
                </div>

                <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
                    <a
                        href="#"
                        @click.prevent="prev"
                        class="fullcalendar-btn-prev btn btn-sm btn-default"
                    >
                        <i class="fas fa-angle-left"></i>
                    </a>
                    <a
                        href="#"
                        @click.prevent="next"
                        class="fullcalendar-btn-next btn btn-sm btn-default"
                    >
                        <i class="fas fa-angle-right"></i>
                    </a>
                    <BaseButton
                        v-if="false"
                        class="btn btn-sm btn-default"
                        @click="changeView('dayGridMonth')"
                    >
                        Month
                    </BaseButton>
                    <BaseButton
                        v-if="false"
                        class="btn btn-sm btn-default"
                        @click="changeView('dayGridWeek')"
                    >
                        Week
                    </BaseButton>
                    <BaseButton
                        class="btn btn-sm btn-default"
                        @click="changeView('timeGridDay')"
                    >
                        Day
                    </BaseButton>
                </div>
            </div>
        </div>
        <!-- Card body -->
        <div class="card-body p-0 card-calendar-body">
            <div id="fullCalendar"></div>
        </div>
    </div>
</template>
<script>
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridDay from '@fullcalendar/timegrid';
import calendarSettings from '../../data/calendarSettings';
import BaseButton from '../BaseButton.vue';

const targetDate = new Date();
const y = targetDate.getFullYear();
const m = targetDate.getMonth();
const d = targetDate.getDate();
var calendar;

export default {
    name: 'calendar-view',
    components: {
        
    },
    props: {
		calendarData: {
            type: Object,
            default: {
                return: {
                    title: null,
                    events: []
                }
            }
        }
	},
    watch: {
		calendarData: {
			handler(val) {
                this.clearCalendarItems();
                 val.events.forEach((e) => this.calendar.addEvent(e))
			},
            deep: true
		},
	},
    data() {
		return {
			isWeekView: false,
            calendarSettings: calendarSettings,
            currentEvents: [],
            previousEvents: [],
            targetDate: {
				rawDate: targetDate.toLocaleString('default'),
				month: targetDate.toLocaleString('default', { month: 'long' }),
				year: targetDate.toLocaleString('default', { year: 'numeric' }),
			},
        }
    },
    methods: {
        initCalendar() {
			var calendarEl = document.getElementById('fullCalendar');
			this.calendar = new Calendar(calendarEl, {
				plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
				selectable: true,
				headerToolbar: false,
				select: (info) => {
					this.$emit('select', info)
				},
				eventClick: (info) => {
					this.$emit('event-click', info)
				},
			});
			this.calendar.render();
		},
        clearCalendarItems() {
			this.calendar.getEvents().forEach((eventItem) => {
					eventItem.remove();
				});
		},
        changeView(newView) {
			this.isWeekView = newView == 'dayGridWeek';
			this.calendar.changeView(newView);
			this.calendar.view.title;
            this.$emit('changeView', newView)
		},
        next() {
			this.calendar.next();
			this.isLoading = true;
			this.targetDate.rawDate = this.calendar.getDate().toLocaleString('default');
			this.targetDate.month = this.calendar
				.getDate()
				.toLocaleString('default', { month: 'long' });
			this.targetDate.year = this.calendar
				.getDate()
				.toLocaleString('default', { year: 'numeric' });
			 this.$emit('next', this.calendar.getDate())
		},
		prev() {
			this.calendar.prev();
			 this.isLoading = true;
			this.targetDate.rawDate = this.calendar.getDate().toLocaleString('default');
			this.targetDate.month = this.calendar
				.getDate()
				.toLocaleString('default', { month: 'long' });
			this.targetDate.year = this.calendar
				.getDate()
				.toLocaleString('default', { year: 'numeric' });
             this.$emit("prev", this.calendar.getDate())
		},
    },
    mounted(){
        this.initCalendar();
    },
    components: {BaseButton}
}
</script>
<style lang="scss">
@import '~@/assets/sass/core/vendors/fullcalendar';
</style>