<template>
	<validation-provider
		:rules="rules"
		:name="name"
		v-bind="$attrs"
		v-slot="{ errors, valid, invalid, validated }"
	>
		<b-form-group>
			<slot name="label">
				<label v-if="label" :class="labelClasses">
					{{ label }}
				</label>
				<i
					v-if="tooltip"
					class="mx-1 fa fa-question-circle"
					v-b-tooltip.hover
					:title="tooltip"
				></i>
			</slot>
			<div
				:class="[
					{ 'input-group': hasIcon },
					{ focused: focused },
					{ 'input-group-alternative': alternative },
					{ 'has-label': label || $slots.label },
					inputGroupClasses,
				]"
			>
				<div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
					<span class="input-group-text">
						<slot name="prepend">
							<i :class="prependIcon"></i>
						</slot>
					</span>
				</div>
				<slot v-bind="slotData">
					<textarea
						v-if="type === 'textarea'"
						:value="value"
						v-on="listeners"
						v-bind="$attrs"
						:valid="valid"
						:required="required"
						:rows="rows"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					></textarea>
					<TheMask
						v-else-if="type === 'mask'"
						:mask="mask"
						:valid="valid"
						:value="value"
						v-on="listeners"
						v-bind="$attrs"
						:required="required"
						:disabled="disabled"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					>
					</TheMask>
					<Multiselect
						v-else-if="type === 'multiselect'"
						v-model="multiSelectValue"
						:valid="valid"
						:required="required"
						:options="multiSelectOptions"
						label="categoryName"
						track-by="categoryId"
						@tag="onAddTag"
						@select="onSelectedTag"
						@remove="onTagRemove"
						:show-labels="false"
						:multiple="allowMultiple"
						:taggable="multiSelectTaggable"
						:hideSelected="allowMultiple"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					>
					</Multiselect>
					<select v-else-if="type === 'select'" class="form-select form-control" id="recurrence" v-on="listeners" :value="value" :required="required">
							<option v-if="placeholderOption" selected disabled hidden :value="null">{{ placeholderOption }}</option>
                                <option
                                    v-for="option in options"
                                    :key="option.key"
                                    :value="option.key"
                                >
                                    {{ option.value }}
                                </option></select>
					<input
						v-else
						:value="value"
						:type="type"
						v-on="listeners"
						v-bind="$attrs"
						:valid="valid"
						:required="required"
						:disabled="disabled"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					/>
				</slot>
				<div v-if="appendIcon || $slots.append" class="input-group-append">
					<span class="input-group-text">
						<slot name="append">
							<i :class="appendIcon"></i>
						</slot>
					</span>
				</div>
				<slot name="infoBlock"></slot>
			</div>
			<slot name="success">
				<div class="valid-feedback" v-if="valid && validated && successMessage">
					{{ successMessage }}
				</div>
			</slot>
			<slot name="error">
				<div v-if="errors[0]" class="invalid-feedback" style="display: block">
					{{ errors[0] }}
				</div>
			</slot>
		</b-form-group>
	</validation-provider>
</template>
<script>
import Multiselect from 'vue-multiselect';
import { TheMask } from 'vue-the-mask';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	inheritAttrs: false,
	name: 'base-input',
	props: {
		required: {
			type: Boolean,
			description: 'Whether input is required (adds an asterix *)',
		},
		disabled: {
			type: Boolean,
			description: 'Whether input is disabled',
			default: false,
		},
		group: {
			type: Boolean,
			description:
				'Whether input is an input group (manual override in special cases)',
		},
		alternative: {
			type: Boolean,
			description: 'Whether input is of alternative layout',
		},
		label: {
			type: String,
			description: 'Input label (text before input)',
		},
		placeholderOption: {
			type: String,
			description: 'Input label (text before input)',
		},
		error: {
			type: String,
			description: 'Input error (below input)',
		},
		successMessage: {
			type: String,
			description: 'Input success message',
			default: '',
		},
		labelClasses: {
			type: String,
			description: 'Input label css classes',
			default: 'form-control-label',
		},
		inputClasses: {
			type: String,
			description: 'Input css classes',
		},
		inputGroupClasses: {
			type: String,
			description: 'Input group css classes',
		},
		value: {
			type: [String, Number],
			description: 'Input value',
		},
		type: {
			type: String,
			description: 'Input type',
			default: 'text',
		},
		appendIcon: {
			type: String,
			description: 'Append icon (right)',
		},
		prependIcon: {
			type: String,
			description: 'Prepend icon (left)',
		},
		rules: {
			type: [String, Array, Object],
			description: 'Vee validate validation rules',
			default: '',
		},
		mask: {
			type: [String, Array],
			default: null,
		},
		name: {
			type: String,
			description: 'Input name (used for validation)',
			default: '',
		},
		allowMultiple: {
			type: Boolean,
			default: false,
		},
		multiSelectOptions: {
			type: [Array, Object],
			description: 'Category Options for Multi-select',
		},
		multiSelectValue: {
			type: [Array, Object],
			description: 'Category Selected Values',
		},
		multiSelectTaggable: {
			type: Boolean,
			description: 'If new tag items can be added',
			default: false,
		},
		rows: {
			type: String,
			description: 'Rows Height',
			default: '3',
		},
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		tooltip: {
			type: String,
			default: null,
		},
	},
	components: { Multiselect, TheMask },
	data() {
		return {
			focused: false,
			selections: [],
		};
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: this.updateValue,
				focus: this.onFocus,
				blur: this.onBlur,
			};
		},
		slotData() {
			return {
				focused: this.focused,
				error: this.error,
				...this.listeners,
			};
		},
		hasIcon() {
			const { append, prepend } = this.$slots;
			return (
				append !== undefined ||
				prepend !== undefined ||
				this.appendIcon !== undefined ||
				this.prependIcon !== undefined ||
				this.group
			);
		},
	},
	methods: {
		updateValue(evt) {
			if (this.type === 'mask') {
				this.$emit('input', evt);
			} else {
				let value = evt.target.value;
				this.$emit('input', value);
			}
		},
		onFocus(evt) {
			this.focused = true;
			this.$emit('focus', evt);
		},
		onBlur(evt) {
			this.focused = false;
			this.$emit('blur', evt);
		},
		onAddTag(tag) {
			this.$emit('tag', tag);
		},
		onSelectedTag(tag) {
			this.$emit('select', tag);
		},
		onTagRemove(tag) {
			this.$emit('remove', tag);
		},
	},
};
</script>
<style></style>
