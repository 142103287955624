<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			body-classes="m-0 pt-0 pb-0"
			:hideFooter="true"
			footer-classes="pt-0"
			header-classes="pb-1"
			size="lg"
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
			</template>
			<b-overlay :show="isLoading" rounded="sm">
				<div class="pt-2">
					<h5 style="flex: auto; text-align: center">
						Upload a new event for all users to view.
					</h5>
					<h6
						v-if="event.errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ event.errorMessage }}
					</h6>
				</div>

				<form class="pt-1 pb-1">
					<VueDropZone
						class="p-0 m-0"
						id="eventImage"
						style="border: 0; z-index: 1"
						v-on:vdropzone-files-added="addedImage"
						:options="dropzoneOptions"
					>
					</VueDropZone>

					<base-input
						label="Event Title"
						id="event title"
						placeholder="Event Title"
						required
						v-model="event.eventTitle"
					>
					</base-input>
					<base-input label="Event Description">
						<textarea
							placeholder="Enter your description..."
							v-model="event.eventDescription"
							class="form-control"
							id="event description"
							rows="4"
							required
						/>
					</base-input>
					<base-input
						required
						label="Event Start Time"
						type="datetime-local"
						v-model="event.eventStart"
						id="event start"
					/>
					<base-input
						required
						label="Event End Time"
						type="datetime-local"
						v-model="event.eventEnd"
						id="event end"
					/>
					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Paid Event</label>
						</b-col>
						<b-col cols="auto">
							<base-switch
								v-model="event.eventIsPaid"
								@change="onEventIsPaidChange"
							></base-switch>
						</b-col>
					</b-row>

					<b-row v-if="event.eventIsPaid">
						<b-col md="6">
							<CurrencyInput
								v-model="event.eventPrice"
								:required="event.eventIsPaid"
								:value="event.eventPrice"
								id="eventPrice"
							/>
						</b-col>
						<b-col md="6">
							<base-input
								placeholder="Tickets Available"
								:required="event.eventIsPaid"
							>
								<select
									class="form-control"
									id="eventPriceQuantity"
									v-model="event.eventPriceQuantity"
								>
									<option v-for="value in arrayOfNumbers" :key="value">
										{{ value }}
									</option>
								</select>
							</base-input>
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label"
								>Event Will Be Held At Our Location</label
							>
						</b-col>
						<b-col cols="auto">
							<base-switch v-model="event.eventIsHomeLocation"></base-switch>
						</b-col>
					</b-row>

					<b-row v-if="!event.eventIsHomeLocation">
						<b-col md="6">
							<base-input
								:required="!event.eventIsHomeLocation"
								v-model="event.eventLocationTitle"
								placeholder="Event Location Title"
								id="event location title"
							/>
						</b-col>
						<b-col md="6">
							<base-input
								:required="!event.eventIsHomeLocation"
								v-model="event.eventLocationAddress"
								placeholder="Event Location Address"
								id="event location address"
							/>
						</b-col>
					</b-row>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Custom Event Button Link</label>
						</b-col>
						<b-col cols="auto">
							<base-switch
								v-model="event.eventCustomButtonLinkSwitch"
								@change="onCustomButtonLinkChange"
							></base-switch>
						</b-col>
					</b-row>
					<base-input
						v-if="event.eventCustomButtonLinkSwitch"
						:required="event.eventCustomButtonLinkSwitch"
						v-model="event.eventCustomButtonLink"
						type="url"
						placeholder="Event Button Link"
						id="event button link"
					/>
					<b-row v-if="classLeaders" class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Event Faculty Leader</label>
						</b-col>
						<b-col cols="auto">
							<base-switch v-model="event.eventFacultyLeaderSwitch"></base-switch>
						</b-col>
					</b-row>
					<base-input
						v-if="event.eventFacultyLeaderSwitch"
						:required="event.eventFacultyLeaderSwitch"
						placeholder="Select A Faculty Leader"
					>
						<select
							class="form-control"
							id="eventFacultyLeader"
							v-model="event.eventFacultyLeader"
						>
							<option
								v-for="leader in classLeaders"
								:key="leader.uid"
								:value="leader.uid"
							>
								{{ leader.fullname }}
							</option>
						</select>
					</base-input>
					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Event Is Reoccuring</label>
						</b-col>
						<b-col cols="auto">
							<base-switch v-model="event.eventReoccuringSwitch"></base-switch>
						</b-col>
					</b-row>

					<ButtonRadioGroup
						v-if="event.eventReoccuringSwitch"
						:options="reoccuringOptions"
						v-model="event.eventReoccuring"
					/>

					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Disable Prepaid Purchase</label>
						</b-col>
						<b-col cols="auto">
							<base-switch v-model="event.eventDisablePrepaidSwitch"></base-switch>
						</b-col>
					</b-row>
					<b-row class="mt-2">
						<b-col cols="auto" class="mr-auto">
							<label class="form-control-label">Feature This Event</label>
						</b-col>
						<b-col cols="auto">
							<base-switch v-model="event.eventIsFeaturedSwitch"></base-switch>
						</b-col>
					</b-row>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<base-button
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Event</base-button
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../components/Modals/Modal.vue';
import Card from '../../components/Cards/Card.vue';
import DropzoneFileUpload from '../../components/Inputs/DropzoneFileUpload.vue';
import CurrencyInput from '../../components/Inputs/CurrencyInput.vue';
import ButtonRadioGroup from '../../components/ButtonRadioGroup.vue';
import reoccuringOptions from '../../data/reoccuringOptions';
import {
	getOrganizationFirestore,
	getOrganizationStorage,
} from '../../resources/orgFirebase';
import { mapGetters } from 'vuex';
import {
	uploadBytes,
	getDownloadURL,
	ref as firebaseStorageRef,
} from 'firebase/storage';
import { httpsCallable } from '@firebase/functions';
import { functions } from '../../resources/firebase';

export default {
	computed: {
		...mapGetters({
			publicInformation: 'getPublicInformation',
		}),
	},
	props: {
		headerTitle: {
			type: String,
			default: 'New Notification',
		},
		classLeaders: {
			type: Array,
			default: [],
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		Card,
		VueDropZone,
		ButtonRadioGroup,
		DropzoneFileUpload,
		CurrencyInput,
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			arrayOfNumbers: [],
			reoccuringOptions,
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 200,
				addRemoveLinks: true,
				acceptedFiles: 'image/*',
				dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
			},
			event: {
				eventImage: null,
				eventTitle: '',
				eventDescription: '',
				eventStart: null,
				eventEnd: null,
				eventIsPaid: true,
				eventPrice: 0,
				eventPriceQuantity: 'Unlimited',
				eventIsHomeLocation: true,
				eventLocationTitle: '',
				eventLocationAddress: '',
				eventCustomButtonLinkSwitch: false,
				eventCustomButtonLink: '',
				eventFacultyLeaderSwitch: false,
				eventFacultyLeader: '',
				eventReoccuringSwitch: false,
				eventReoccuring: '',
				eventDisablePrepaidSwitch: false,
				eventIsFeaturedSwitch: false,
				errorMessage: null,
			},
		};
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		onEventIsPaidChange() {
			if (this.event.eventIsPaid) {
				this.event.eventCustomButtonLinkSwitch = false;
			}
		},
		onCustomButtonLinkChange() {
			if (this.event.eventCustomButtonLinkSwitch) {
				this.event.eventIsPaid = false;
			}
		},
		addedImage(file) {
			this.event.eventImage = file;
		},
		submit() {
			this.shake = false;
			const eventImage = this.event.eventImage;
			const eventTitle = this.event.eventTitle;
			const eventDescription = this.event.eventDescription;
			const eventStart = this.event.eventStart;
			const eventEnd = this.event.eventEnd;
			const eventPrice = this.event.eventPrice;
			const eventPriceQuantity = this.event.eventPriceQuantity;
			const eventClassLeader = this.event.eventFacultyLeader;
			const eventButtonLink = this.event.eventCustomButtonLink;
			const eventLocationTitle = this.event.eventLocationTitle;
			const eventLocationAddress = this.event.eventLocationAddress;
			const eventReoccuring = this.event.eventReoccuring;

			if (this.event.eventImage == null) {
				this.event.errorMessage = 'Please select an event image.';
				this.shake = true;
				return;
			} else if (this.isEmpty(eventTitle) || this.isEmpty(eventDescription)) {
				this.event.errorMessage = 'Please complete all the necessary fields.';
				this.shake = true;
				return;
			} else if (eventStart == null || eventEnd == null) {
				this.event.errorMessage = 'Please select a start and end date.';
				this.shake = true;
				return;
			} else if (new Date(eventStart).getTime() > new Date(eventEnd).getTime()) {
				this.event.errorMessage = 'Event end time must be after the start date.';
				this.shake = true;
				return;
			} else if (
				(this.event.eventIsPaid && eventPrice == null) ||
				(this.event.eventIsPaid && eventPrice < 1)
			) {
				this.event.errorMessage = 'Event price needs to be greater than $1.00.';
				this.shake = true;
				return;
			} else if (
				!this.event.eventIsHomeLocation &&
				(this.isEmpty(eventLocationTitle) || this.isEmpty(eventLocationAddress))
			) {
				this.event.errorMessage = 'Please enter an event button url link.';
				this.shake = true;
				return;
			} else if (
				this.event.eventCustomButtonLinkSwitch &&
				this.isEmpty(eventButtonLink)
			) {
				this.event.errorMessage =
					'Please complete all the fields required for the event location.';
				this.shake = true;
				return;
			} else if (
				this.event.eventFacultyLeaderSwitch &&
				this.isEmpty(eventClassLeader)
			) {
				this.event.errorMessage =
					'Please select a faculty leader or turn this feature off.';
				this.shake = true;
				return;
			} else if (
				this.event.eventReoccuringSwitch &&
				this.isEmpty(eventReoccuring)
			) {
				this.event.errorMessage = 'Please select a reoccuring type.';
				this.shake = true;
				return;
			} else {
				this.event.errorMessage = null;
				/*
				const storage = getOrganizationStorage(this.organization);
				const database = getOrganizationDatabase(this.organization);
				const databaseRef = firebaseDatabaseRef(database, 'calendarEvents');
				const newDatabaseRef = push(databaseRef);
				const featuredDatabaseRef = firebaseDatabaseRef(
					database,
					`featuredItems/${newDatabaseRef.key}`
				);

				const storageRef = firebaseStorageRef(
					storage,
					`calendarEvents/${newDatabaseRef.key}`
				);

				this.isLoading = true;

				this.uploadEventImage(storageRef, newDatabaseRef, featuredDatabaseRef);
				*/
			}
		},
		uploadEventImage(storageRef, newDatabaseRef, featuredDatabaseRef) {
			const metadata = {
				contentType: 'image/jpg',
			};

			uploadBytes(storageRef, this.event.eventImage[0], metadata)
				.then((result) => {
					const resultRef = result.ref;
					getDownloadURL(resultRef).then((downloadURL) => {
						this.sendEventToFirebase(
							downloadURL,
							newDatabaseRef,
							featuredDatabaseRef
						);
					});
				})
				.catch((error) => {
					//error uploading
					this.isLoading = false;
					this.event.errorMessage = error.message;
					this.shake = true;
				});
		},
		getShareLink(eventId, imageURL, callback) {
			const createShareLink = httpsCallable(functions, 'createShareLink');
			createShareLink({
				organization: this.organization,
				urlExtended: `/event&eventId=${eventId}`,
				title: this.event.eventTitle,
				socialLinkImageUrl: imageURL,
			})
				.then((response) => {
					callback(response.data.link);
				})
				.catch((error) => {
					callback('nil');
				});
		},
		sendEventToFirebase(imageURL, newDatabaseRef, featuredDatabaseRef) {
			/*
			const eventId = newDatabaseRef.key;

			this.getShareLink(eventId, imageURL, (eventShare) => {
				const eventMedia = {
					eventImage: imageURL,
				};

				const eventLocation = {
					eventLocationTitle: this.event.eventLocationTitle,
					eventLocationAddress: this.event.eventLocationAddress,
				};

				var eventStart = new Date(this.event.eventStart);
				var eventEnd = new Date(this.event.eventEnd);

				var eventInfo = {
					eventDescription: this.event.eventDescription,
					eventEnd: eventEnd.getTime(),
					eventId: eventId,
					eventMedia: eventMedia,
					eventShare: eventShare,
					eventStart: eventStart.getTime(),
					eventTitle: this.event.eventTitle,
					eventLocation: eventLocation,
					eventIsFeatured: this.event.eventIsFeaturedSwitch,
					eventIsPrepaidAvailable: !this.event.eventDisablePrepaidSwitch, //check this
				};

				if (this.event.eventReoccuringSwitch) {
					eventInfo['reoccurrence'] = this.event.eventReoccuring;
				}

				if (this.event.eventFacultyLeaderSwitch) {
					eventInfo['eventLeader'] = this.event.eventFacultyLeader;
				}

				if (this.event.eventIsPaid) {
					eventInfo['eventPrice'] = this.event.eventPrice; //check this value
					if (this.event.eventPriceQuantity != 'Unlimited') {
						eventInfo['eventPriceQuantity'] = parseInt(
							this.event.eventPriceQuantity
						);
					}
				}

				if (this.event.eventCustomButtonLinkSwitch) {
					eventInfo['eventButtonLink'] = this.event.eventCustomButtonLink;
				}

				set(newDatabaseRef, eventInfo)
					.then(() => {
						if (this.event.eventIsFeaturedSwitch) {
							const featuredItemId = newDatabaseRef.key;
							var featuredInfo = {
								featuredId: featuredItemId,
								featuredImage: imageURL,
								featuredItem: 'event',
								featuredItemId: featuredItemId,
							};
							set(featuredDatabaseRef, featuredInfo)
								.then(() => {
									this.isLoading = false;
									this.$emit('newEvent', eventInfo);
									this.closeModal();
								})
								.catch((error) => {
									this.isLoading = false;
									this.event.errorMessage = error.message;
									this.shake = true;
								});
						} else {
							this.isLoading = false;
							this.$emit('newEvent', eventInfo);
							this.closeModal();
						}
					})
					.catch((error) => {
						this.isLoading = false;
						this.event.errorMessage = error.message;
						this.shake = true;
					});
			});
			*/
		},
		isEmpty(str) {
			return this.$helpers.isBlank(str);
		},
	},

	mounted() {
		this.event.eventLocationTitle = this.publicInformation.appName;
		this.event.eventLocationAddress = this.publicInformation.appAddress;
		for (let index = 0; index < 101; index++) {
			if (index == 0) {
				this.arrayOfNumbers.push('Unlimited');
			} else {
				this.arrayOfNumbers.push(`${index}`);
			}
		}
	},
};
</script>
<style>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
</style>
