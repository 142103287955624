<template>
	<div v-if="viewIsReady" class="wrapper">
		<!-- <Notifications /> THIS CREATES DOUBLE NOTIFCATIONS -->
		<DashboardSideMenu />
		<div class="main-content">
			<DashboardNavbar />
			<Modal
				:show="showModal"
				@close="showModal = false"
				:hideFooter="true"
				:hideHeader="true"
				no-body
				size="sm"
				:allowClose="false"
			>
				<div class="text-center">
					<div class="d-flex align-items-center justify-content-center">
						<b-spinner small></b-spinner>
						<h5 class="mb-0 ml-2">{{ textDescription }}</h5>
					</div>

					<hr class="mt-2 mb-2" />
					<BaseButton block type="danger" @click="cancelTerminal"
						>Cancel Payment</BaseButton
					>
				</div>
			</Modal>
			<div @click="$sidebar.displaySidebar(false)">
				<fade-transition :duration="200" origin="center top" mode="out-in">
					<!-- your content here -->
					<div>
						<router-view> </router-view>
					</div>
				</fade-transition>
			</div>
			<DashboardFooter v-if="!$route.meta.hideFooter" />
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
import { loadStripeTerminal } from '@stripe/terminal-js';
import DashboardNavbar from '../mainViews/DashboardNavbar.vue';
import DashboardSideMenu from '../mainViews/HeaderFooterComponents/DashboardSideMenu.vue';
import DashboardFooter from '../mainViews/HeaderFooterComponents/DashboardFooter.vue';
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import { FadeTransition } from 'vue2-transitions';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { mapActions, mapGetters } from 'vuex';
import AppUserManager from '../../models/AppUser/AppUserManager';
import EventManager from '../../models/EventItem/EventManager';
import ClassItemManager from '../../models/ClassItem/ClassItemManager';
import CalendarItemManager from '../../models/CalendarItem/CalendarItemManager';
import FileItemManager from '../../models/FileItem/FileItemManager';
import CategoryItemManager from '../../models/CategoryItem/CategoryItemManager';
import PaymentManager from '../../models/Payment-Billing/PaymentManager';
import store from '../../store/store';
import BaseInputModal from '../../components/Modals/BaseInputModal.vue';
import QuestionModal from '../../components/Modals/QuestionModal.vue';
import Modal from '../../components/Modals/Modal.vue';
import BaseButton from '../../components/BaseButton.vue';

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

export default {
	metaInfo: {},
	components: {
		DashboardNavbar,
		DashboardFooter,
		DashboardSideMenu,
		FadeTransition,
		Notifications,
		BaseInputModal,
		QuestionModal,
		Modal,
		BaseButton,
	},
	computed: {
		...mapGetters({
			notification: 'getNotification',
		}),
	},
	data() {
		return {
			viewIsReady: false,
			showModal: false,
			textDescription: null,
		};
	},
	watch: {
		notification(newNotification, oldNotification) {
			if (newNotification) {
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'center',
					type: newNotification.type,
					message: newNotification.message,
				});
			}
		},
	},
	methods: {
		...mapActions([
			'showNotification',
			'removeNotification',
			'getTerminalReaders',
		]),
		closeNotificationModal() {
			this.removeNotification();
		},
		initScrollbar() {
			let isWindows = navigator.platform.startsWith('Win');
			if (isWindows) {
				initScrollbar('sidenav');
			}
		},
		addHelpChat() {},
		loadContent() {
			AppUserManager.getAppLeadersUsers().then(() => {});
			EventManager.getAllEvents().then(() => {});
			ClassItemManager.getAllClasses().then(() => {});
			CalendarItemManager.getAllCalendarItems().then(() => {});
			CategoryItemManager.getAllCategories().then(() => {});
			this.loadTerminal();
		},
		loadTerminal() {
			loadStripeTerminal()
				.then((terminal) => {
					const stripeTerminal = terminal.create({
						onFetchConnectionToken: PaymentManager.createTerminalConnection,
						onUnexpectedReaderDisconnect: this.terminalReaderDisconnected,
						onConnectionStatusChange: this.terminalStatusChanged,
						onPaymentStatusChange: this.terminalPaymentStatusChanged,
					});
					store.commit('setStripeTerminal', stripeTerminal);
					this.getTerminalReaders()
						.then(() => {})
						.catch((error) => {
							//console.log('terminal error', error.message);
							return;
						});
				})
				.catch((error) => {
					//console.log('terminal error', error.message);
					return;
				});
		},

		terminalReaderDisconnected() {
			return 'Could not communicate with the Reader. Please make sure your reader is online and on the same network as your device.';
		},
		terminalStatusChanged() {
			//ConnectionStatus can be one of connecting, connected, or not_connected.
			this.showModal = false;
			if (store.getters.getStripeTerminal) {
				const status = store.getters.getStripeTerminal.getConnectionStatus();
				switch (status) {
					case 'connecting':
						this.showModal = false;
						break;
					case 'connected':
						this.showModal = false;
						this.$notify({
							verticalAlign: 'top',
							horizontalAlign: 'center',
							type: 'success',
							message: 'Pay Is Ready.',
							//timeout: 0,
						});
						break;
					case 'not_connected':
						this.showModal = false;
					default:
						this.showModal = false;
						break;
				}
			} else {
				this.showModal = false;
			}
		},
		terminalPaymentStatusChanged() {
			//PaymentStatus can be one of not_ready, ready, waiting_for_input, or processing.
			//const status = store.getters.getStripeTerminal.getPaymentStatus();
			if (store.getters.getStripeTerminal) {
				const status = store.getters.getStripeTerminal.getPaymentStatus();
				switch (status) {
					case 'not_ready':
						this.showModal = false;
						break;
					case 'ready':
						this.showModal = false;
						break;
					case 'waiting_for_input':
						this.textDescription = 'Waiting For Customer Input...';
						this.showModal = true;
						break;
					case 'processing':
						this.textDescription = 'Processing Payment';
						this.showModal = true;
						break;
					default:
						this.showModal = false;
						break;
				}
			} else {
				this.showModal = false;
			}
		},
		cancelTerminal() {
			if (store.getters.getStripeTerminal) {
				store.getters.getStripeTerminal.cancelCollectPaymentMethod();
				store.getters.getStripeTerminal.clearReaderDisplay();
				this.showModal = false;
			} else {
				this.showModal = false;
			}
		},
	},
	mounted() {
		FileItemManager.getAllFiles().then(() => {
			this.viewIsReady = true;
			this.initScrollbar();
			this.loadContent();
		});
	},
};
</script>
<style lang="scss"></style>
