<template>
	<div>
		<Card :no-body="!facultySwitch">
			<CardHeader
				slot="header"
				title="Event Leader"
				:endSwitch="{ title: '' }"
				:endSwitchValue="facultySwitch"
				@change="facultySwitchChanged"
			/>

			<form v-if="facultySwitch">
				<b-row>
					<b-col>
						<BaseInput
							id="event leader"
							placeholder="Select Leader"
							:required="facultySwitch"
						>
							<select
								class="form-control"
								id="eventFacultyLeader"
								v-model="eventData.facultyLeaders"
							>
								<option
									v-for="leader in facultyLeaders"
									:key="leader.uid"
									:value="leader.uid"
								>
									{{ leader.firstName }} {{ leader.lastName }}
								</option>
							</select>
						</BaseInput>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../../../components/Cards/Card.vue';
import CardHeader from '../../../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../../../components/Inputs/BaseInput.vue';
export default {
	components: { Card, CardHeader, BaseInput },
	data() {
		return {
			facultySwitch: true,
			facultyLeaders: [
				{
					uid: '1234',
					firstName: 'John',
					lastName: 'Test',
				},
			],
			eventData: {
				facultyLeaders: [],
			},
		};
	},
	watch: {
		eventData: {
			handler(val) {
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		facultySwitchChanged(value) {
			this.facultySwitch = value;
		},
		onChange() {
			this.$emit('onChange', this.eventData);
		},
	},
};
</script>
